<template lang="pug">
  .calendar-column-wrapper.flex.flex-col
    .header.flex.items-center.justify-between.top-0.py-2.px-6
      .flex.h-full.items-center.h-11
        q-avatar.mr-2(
          size="40px",
          round
        )
          img(:src="medic?.photo || defaultPhoto", alt="doctor")
        .flex.flex-col.justify-between
          span.text-dark {{trimName(medic?.last_name, medic?.first_name, medic?.patronymic)}}
          span.text-xsx.color-grey {{medic?.job || "Терапевт"}}
      button(@click="locked = !locked")
        q-icon.lock-icon(
          :name="locked ? 'app:lock' : 'app:lock-open'",
          size="24px"
        )
    .body.h-full.px-1.py-1
      calendar-record-card(
        v-for="record in filteredRecords",
        :key="record?.id",
        :record="record",
        :expanded-type="true",
        :style="eventCardPosition(record.start, record.end)",
        @click="changeSelectedRecordId(record?.id)",
      )
</template>

<script>
import { columnMixin } from "@/pages/newCalendar/mixins/columnMixin.js";
import { trimName } from "@/pages/newCalendar/utils/calendarFunctions.js";
import doctorAvatar from "@/assets/images/doctor.png";
import { mapState } from "vuex";
export default {
  name: "CalendarColumn",
  mixins: [columnMixin],
  props: {
    medic: Object,
  },
  data() {
    return {
      trimName: trimName,
      defaultPhoto: doctorAvatar,
      locked: false,
    };
  },
  computed: {
    ...mapState({
      events: (state) => state.calendar.events,
    }),
    filteredRecords() {
      return this.events.filter((elem) => elem?.medic?.ID === this.medic?.ID);
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/pages/newCalendar/sass/columnStyle.sass"
.lock-icon :deep(path)
  fill: var(--font-grey-color)
</style>
