<template lang="pug">
  .flex.flex-col.w-full.h-full.gap-y-2.pb-2.no-scroll
    the-header(
      :url="url",
      :is-open-form="isOpenForm",
      :close-form="closeForm",
      :open-form="openForm",
      :set-updated-clients="setUpdatedClients",
      :write-created-client-id="writeCreatedClientId"
    )
    .flex.grow.no-scroll
      the-sidebar
      router-view.mx-2(
        :open-form="openForm",
        :is-open-form="isOpenForm",
        :updated-clients="updatedClients",
        @reset-updated-clients="resetUpdatedClients",
        :url="url",
        :created-client-id="createdClientId"
      )
    the-notification-provider
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheSidebar from "@/components/TheSidebar";
import TheNotificationProvider from "@/components/Notifications/TheNotificationProvider";
import { mapState } from "vuex";
export default {
  name: "LoggedInLayout",
  components: { TheNotificationProvider, TheHeader, TheSidebar },
  data() {
    return {
      isOpenForm: false,
      updatedClients: false,
      createdClientId: "",
    };
  },
  computed: {
    ...mapState({
      url: (state) => state.imgUrl,
    }),
  },
  methods: {
    openForm() {
      this.isOpenForm = true;
    },
    closeForm() {
      this.isOpenForm = false;
    },
    setUpdatedClients() {
      if (this.$router.currentRoute._value.path === "/clients")
        this.updatedClients = true;
    },
    resetUpdatedClients() {
      this.updatedClients = false;
      this.createdClientId = "";
    },
    writeCreatedClientId(id) {
      if (this.$router.currentRoute._value.path === "/clients")
        this.createdClientId = id;
    },
  },
};
</script>
