<template lang="pug">
.patient-info.pt-4.pr-6.flex.flex-col.justify-between.rounded.font-medium.text-m    
  .ml-6.flex.justify-between
    //- TODO по клику переход на предыдущую страницу
    q-breadcrumbs
      template(v-slot:separator)
        q-icon.rotate(
          size="16px",
          name="app:long-arrow",
          color="grey",
        )
      q-breadcrumbs-el(:label="routes[routingHistory.state.back]")
      q-breadcrumbs-el(:label="`Медкарта #${medicalCardData?.number}`")
      
    q-btn(
      @click="toggleEditMode",
      :style="{backgroundColor: 'var(--bg-light-grey)', color: 'blue'}",
      padding="10px",
      )
      q-icon(
        name="edit",
        size="20px",
        )
  .ml-6.flex.gap-x-4
    q-avatar(size="80px", :style="{'background-color': 'var(--border-light-grey-color)', color: 'var(--font-dark-blue-color)'}")
      img(v-if="patientData?.photo", :src="url + patientData?.photo")
      span.text-2xl(v-else) {{patientAvatar}}

    template(v-if="!editMode")  
      .flex.gap-y-1.flex-col
        .flex.items-center
          span.text-xxl.font-bold.mr-3(
            :style="{color: 'var(--font-dark-blue-color)', 'line-height': '135%'}",
          ) {{patientName}}
 

        span.text-smm.label-color Добавлен в систему: 
          span.date-color {{ patientData?.gender }}
          span.date-color {{ patientData?.birth_date }}
        span.text-smm.label-color Спец отметки: 

    template(v-else)
      base-input(
            v-model="fullname"
            size="M",
            label="Фамилия"
          )
      base-input(
            v-model="fullname"
            size="M",
            label="Имя"
          )
      base-input(
        v-model="fullname"
        size="M",
        label="Отчество"
      )

  .flex.justify-between.pt-4
    .flex
      .menu-item.px-6.py-10px.cursor-pointer.text-base.whitespace-nowrap(
        v-for="item in menuItem",
        @click="selectItem(item)",
        :class="{'menu-item-active': item.component === modelValue}",
        :key="item.id",
        :id="item.id"
      ) {{item.title}}

</template>

<script>
import BaseInput from "@/components/base/BaseInput.vue";
import { v_model } from "@/shared/mixins/v-model";
import { column } from "@/pages/clients/utils/tableConfig.js";
import * as moment from "moment/moment";
import {
  headerMenuItem,
  routesDictionary,
} from "@/pages/newMedicalCard/utils/medicalConfig.js";

export default {
  name: "MedicalCardPatientForm",
  mixins: [v_model],
  data() {
    return {
      menuItem: headerMenuItem,
      routes: routesDictionary,
      editMode: true,
      fullname: "",
    };
  },
  components: {
    BaseInput,
  },
  computed: {
    routingHistory() {
      return this.$store.state.routingHistory;
    },
    url() {
      return this.$store.state.url;
    },
    medicalCardData() {
      return this.$store.state.medical.medicalCard;
    },
    patientData() {
      return this.medicalCardData?.person;
    },
    patientName() {
      let name = {
        lastName: this.ckeckName("last_name"),
        firstName: this.ckeckName("first_name"),
        patronymic: this.ckeckName("patronymic"),
      };
      return `${name.lastName} ${name.firstName} ${name.patronymic}`;
    },
    patientAvatar() {
      let checkedFirstName =
        this.patientData?.first_name !== null
          ? this.patientData?.first_name[0]
          : this.patientData?.last_name[1];
      return `${this.patientData?.last_name[0]}${checkedFirstName}`;
    },
    priority() {
      return column
        .find((elem) => elem.name === "priority")
        ?.settings.find((elem) => elem.priority === this.patientData?.priority);
    },
    createdDate() {
      return this.checkDate("created_at");
    },
    updatedDate() {
      return this.checkDate("updated_at");
    },
    allergiesList() {
      return this.patientData?.allergic?.map((elem) => ({
        name: elem?.name,
        title: elem?.title,
      }));
    },
  },
  methods: {
    selectItem(item) {
      this.value = item?.component;
    },
    ckeckName(field) {
      return this.patientData?.[field] ? this.patientData[field] : "";
    },
    checkDate(field) {
      return this.medicalCardData?.[field]
        ? moment.parseZone(this.medicalCardData?.[field]).format("DD.MM.YYYY")
        : "";
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
  },
};
</script>
<style scoped lang="sass">
.patient-info
  background-color: var(--default-white)
  min-height: 190px
.menu-item
  color: var(--font-grey-color)
  border-bottom: 1px solid transparent
.menu-item:first-child
  border-bottom-left-radius: 4px
.menu-item-active
  color: var(--font-dark-blue-color)
  border-bottom: 1px solid var(--font-dark-blue-color)
.q-btn
  font-weight: 500 !important
.q-btn :deep(.on-left)
  margin-right: 4px !important
.q-btn :deep(.q-btn-dropdown__arrow)
  margin-left: 0px !important
  opacity: 0.7
.text-grey
  color: var(--font-grey-color) !important
.q-breadcrumbs__el
  font-size: 12px
  line-height: 135%
  color: var(--font-grey-color)
.q-breadcrumbs :deep(.q-breadcrumbs__separator)
  margin: 10px 0 4px 8px !important
.label-color
  color: var(--font-grey-color)
.date-color
  color: var(--font-dark-blue-color)
.allergies-list
  max-height: 190px
  overflow-y: auto
  &::-webkit-scrollbar
    width: 4px
    background-color: var(--default-white)
  &::-webkit-scrollbar-track
    margin: 0
.allergies-item
  color: var(--font-dark-blue-color)
.allergies-item:hover
  background-color: var(--bg-light-grey)
  .icon-eye
    display: block
.icon-eye
  display: none
</style>
