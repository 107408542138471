<template lang="pug">
  .w-full
    .header.pl-3.pr-6.pt-14px.pb-10px.flex.items-center.justify-center
      q-btn(
        size="14px"
        color="dark"
        flat,
        :label="internalMonth",
        no-caps,
        padding="10px 12px"
      )
    .body.py-5.px-6
      .cell.flex.items-center.justify-center.cursor-pointer.rounded.text-sm(
        v-for="month in internalMonthsList",
        :key="month",
        :id="month",
        :class="calculateActiveCell(month)",
        @click="selectMonth(month)",
      ) {{ month[0].toUpperCase() + month.slice(1) }}
</template>

<script>
import * as moment from "moment/moment";
export default {
  name: "CalendarMonths",
  props: {
    internalDate: Object,
    internalMonth: String,
  },
  data() {
    return {
      internalMonthsList: null,
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.internalDate;
      },
      set(value) {
        this.$emit("update:internalDate", value);
      },
    },
  },
  methods: {
    calculateActiveCell(month) {
      return {
        "active-cell": this.internalValue.format("MMMM") === month,
      };
    },
    selectMonth(month) {
      this.internalValue = this.internalValue.clone().month(month);
    },
  },
  mounted() {
    this.internalMonthsList = [...Array(12)].map((_, index) =>
      moment().month(index).format("MMMM")
    );
  },
};
</script>

<style scoped lang="sass">
.header
  border-bottom: 1px solid var(--border-light-grey-color)
.body
  height: 298px
  display: grid
  grid-template-columns: repeat(3, 1fr)
  row-gap: 4px
  column-gap: 4px
.cell
  background-color:  var(--bg-light-grey)
  color: var(--font-dark-blue-color)
  &:hover
    background-color: var(--border-light-grey-color)
.active-cell
  background-color: var(--btn-blue-color)
  color: var(--default-white)
  &:hover
    background-color: var(--btn-blue-color)
</style>
