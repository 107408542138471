<template lang="pug">
  .w-full.border.rounded.border-grey.p-4.background.cursor-pointer()
    .flex.justify-between(:style="{'margin-bottom': '26px'}")
      .flex.flex-col.gap-y-1
        span.text-xl.font-bold.color-blue.line-height {{ `${protocolDate?.year} г.` }}
        .text-smm.color-grey.flex.items-center.gap-x-6px.line-height
          span.align-middle {{ protocolDate?.dayMonth }}
          .rounded-full.h-1.w-1.background-grey
          span.align-middle {{ `${time?.start} - ${time?.end}` }}
      .flex.items-center.justify-center.h-6.w-6.rounded(
        v-if="!noFillIndicator",
        :style="statusСolors"
      )
        img.teeth-icon(src="@/assets/icons/teeth.svg")
    .flex.items-center.gap-x-2
      base-avatar(:size="40")
        img.object-cover.h-full(
          v-if="protocolData.employee?.photo" 
          :src="protocolData.employee?.photo" 
          alt="avatar"
        )
        span.text-sm.color-blue(v-else) {{ avatar }}
      .flex.flex-col
        span.line-height.text-sm.color-blue.mb-2px {{ employeeName }}
        span.line-height.text-xxs.color-grey {{ protocolData.employee?.job_title}}
</template>

<script>
import BaseAvatar from "@/components/base/BaseAvatar.vue";
import * as moment from "moment/moment";
export default {
  name: "MedicalProtocolCard",
  components: { BaseAvatar },
  props: {
    protocolData: Object,
    noFillIndicator: Boolean,
  },
  computed: {
    avatar() {
      let firstName = this.protocolData?.employee?.first_name
        ? this.protocolData.employee?.first_name[0]
        : this.protocolData.employee?.last_name[1];
      return this.protocolData.employee?.last_name[0] + firstName;
    },
    employeeName() {
      let firstName = this.protocolData?.employee?.first_name
        ? ` ${this.protocolData.employee.first_name[0]}.`
        : "";
      let patronymic = this.protocolData?.employee?.patronymic
        ? ` ${this.protocolData.employee.patronymic[0]}.`
        : "";
      return this.protocolData.employee?.last_name + firstName + patronymic;
    },
    statusСolors() {
      switch (this.protocolData?.status) {
        case "filled":
          return {
            "background-color": "var(--bg-event-green-color)",
          };
        case "partially filled":
          return {
            "background-color": "var(--bg-event-orange-color)",
          };
        default:
          return {
            "background-color": "var(--border-red-color)",
          };
      }
    },
    protocolDate() {
      let date = moment
        .parseZone(this.protocolData?.start)
        .format("DD.MM.YYYY")
        .split(".");
      return {
        year: date[2],
        dayMonth: date[0] + "." + date[1],
      };
    },
    time() {
      return {
        start: moment.parseZone(this.protocolData?.start).format("HH:mm"),
        end: moment.parseZone(this.protocolData?.end).format("HH:mm"),
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.background
  background-color: var(--default-white)
.background:hover
  background-color: var(--bg-light-grey)
.color-grey
  color: var(--font-grey-color)
.background-grey
  background-color: var(--font-grey-color)
.border-grey
  border-color: var(--border-grey-color)
.color-blue
  color: var(--font-dark-blue-color)
.line-height
  line-height: 135%
.teeth-icon
  width: 16px
  height: 16px
</style>
