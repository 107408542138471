<template lang="pug">
  .wrapper.flex.flex-col.pt-6.dark-blue.font-medium
    .flex.flex-col.gap-y-4
      .flex.justify-between.w-full
        base-input.search(
          placeholder="Поиск",
          :width="422",
          iconLeft,
          size="M"
        )
          template(v-slot:iconLeft)
            q-icon(name="app:search", size="20px", style="color: var(--font-grey-color)")
        q-btn.ml-2(
          @click="sortPerson(patients)",
          :style="{width: '45px', height: '40px', border: '1px solid var(--gray-secondary)'}",
          padding="0px",
          :class="sortingClass"
        )
          calendar-sidebar-svg(name-svg="sort", :active="sort")
      .medcards-wrapper.flex.flex-col.overflow-auto.w-full
        .medcard.flex.items-center.cursor-pointer(
          v-for="patient in patients",
          @click="selectMedcard(patient)"
        )
          .name.flex.justify-between.w-full.items-center.h-14
            .info-block.flex.justify-between.gap-x-2
              img.h-10.w-10.object-cover.rounded-full(:src="patient.avatar")
              .flex.flex-col.gap-y-1
                .text-m {{trimPatientName(patient.last_name, patient.first_name, patient.patronymic)}}
                .grey-color.text-smm {{patient.birthday}}
            q-icon.ok-icon(name="app:ok", v-if="patient.check", size="24px")
    .footer.flex.gap-2
      base-button(type="secondary", label="Отменить", width="125px", @click="closeMedcards")
      base-button(
        width="132px",
        label="Сохранить",
        @click="saveMedcard(selectedMedcard)",
        :disabled="!selectedMedcard"
      )
</template>

<script>
import BaseInput from "@/components/base/BaseInput.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import { trimName } from "@/pages/newCalendar/utils/calendarFunctions.js";
import CalendarSidebarSvg from "@/pages/newCalendar/components/CalendarSidebarSvg.vue";

export default {
  name: "MedcardsModal",
  components: { BaseInput, BaseButton, CalendarSidebarSvg },
  props: { patients: Array, closeMedcards: Function, saveMedcard: Function },
  data() {
    return {
      trimPatientName: trimName,
      selectedMedcard: null,
      sort: false,
    };
  },
  computed: {
    sortingClass() {
      return this.sort
        ? {
            "sort-icon-active": true,
          }
        : {
            "sort-icon-default": true,
          };
    },
  },
  methods: {
    selectMedcard(patient) {
      this.selectedMedcard = patient;
      this.patients.map((e) => {
        e.check = false;
        if (e.id === patient.id) e.check = true;
      });
    },
    sortPerson(arr) {
      if (!this.sort) {
        this.sort = true;
        arr.sort((a, b) => (a.last_name > b.last_name ? 1 : -1));
      } else {
        this.sort = false;
        arr.sort((a, b) => (b.last_name > a.last_name ? 1 : -1));
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  width: 422px

.search :deep(path)
  fill: var(--font-grey-color)

.search :deep(.q-field__prepend)
  padding-right: 4px

.name
  margin: 4px 0px
  &:hover
    border-radius: 4px
    background: var(--gray-thirdly)

.medcard
  height: 64px
  margin-right: 14px
  border-bottom: 1px solid var(--gray-secondary)
  &:last-child
    border-bottom: none


.medcards-wrapper
  width: 440px
  max-height: 563px
  margin-right: -18px
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar-track:vertical
    margin-bottom: 14px

.info-block
  padding: 11px 0px

.footer
  border-top: 1px solid var(--gray-secondary)
  margin: 0px -32px -12px
  padding: 16px 32px 0px 32px

.sort-icon-default
  background-color: var(--bg-light-grey)
  color: var(--font-grey-color)
  border: 1px solid var(--gray-secondary)

.sort-icon-active
  background-color: var(--btn-blue-color)
  color: var(--default-white)

.grey-color
  color: var(--font-grey-color)

.ok-icon :deep(path)
  fill: var(--system-color-green)
</style>
