<template lang="pug">
.wrapper.flex.flex-col.w-full.bg-white.p-4
  .text-xxl.font-bold.pa-3
    .text Добрый день, %username%!
  .text.pt-3.cursor-pointer(@click="gotoCalendar") Расписание приемов
  .text.pt-3.cursor-pointer(@click="gotoMedicalCards") Медицинские карты

</template>

<script>
export default {
  name: "TheHome",
  methods: {
    gotoCalendar() {
      this.$router.push("/calendar");
    },
    gotoMedicalCards() {
      this.$router.push("/medcards");
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  overflow: auto
  border-top-left-radius: 4px
  border-top-right-radius: 4px
</style>
