<template lang="pug">
  .header.flex.w-full.rounded.justify-between.items-center.py-5.pl-6.pr-5
    .span.text-2xl.font-bold Карты здоровья
    base-button(
      width="206px",
      @click="() => {isOpenModal = true}",
    )
      q-icon.mr-2(size="20px" name="add")
      span Добавить запись
    health-state-create-modal(v-model="isOpenModal")
</template>

<script>
import HealthStateCreateModal from "@/pages/newMedicalCard/components/HealthState/HealthStateCreateModal.vue";
import BaseButton from "@/components/base/BaseButton.vue";

export default {
  name: "HealthStateHeader",
  components: {
    HealthStateCreateModal,
    BaseButton,
  },
  data() {
    return {
      isOpenModal: false,
    };
  },
};
</script>

<style lang="sass" scoped>
.header
  background-color: var(--default-white)
.add
  & .q-icon
    display: none
</style>
