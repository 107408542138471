<template lang="pug">
  .calendar-wrapper.bg-white
    component(
      v-bind:is="currentForm?.component",
      v-model:internal-date="internalDate",
      v-model:external-date="externalValue",
      :range="range",
      :start-year="startYear",
      :end-year="endYear",
      :range-count="rangeCount",
      :change-form="changeForm",
      :internal-month="internalMonth",
    )
    .footer.flex.justify-end.items-center.px-5.py-3
      q-btn(
        color="dark"
        flat,
        no-caps,
        label="Отменить",
        size="14px",
        padding="10px 12px",
        @click="cancelChanges"
      )
      q-btn(
        color="primary",
        flat,
        no-caps,
        :label="currentForm?.saveBtnLabel",
        size="14px",
        padding="10px 12px",
        @click="currentForm?.saveFunction"
      )
</template>

<script>
import * as moment from "moment/moment";
import CalendarDates from "@/components/base/Calendar/CalendarDates.vue";
import CalendarYears from "@/components/base/Calendar/CalendarYears.vue";
import CalendarMonths from "@/components/base/Calendar/CalendarMonths.vue";
export default {
  name: "BaseCalendar",
  components: { CalendarDates, CalendarMonths, CalendarYears },
  props: {
    modelValue: Object,
    range: Boolean,
    rangeCount: Number,
    startYear: {
      type: Number,
      default: 1970,
    },
    endYear: {
      type: Number,
      default: parseInt(moment().clone().add(1, "year").format("YYYY"), 10),
    },
    save: {
      type: Function,
      default: () => {},
    },
    cancel: {
      type: Function,
      default: () => {},
    },
    width: {
      type: String,
      default: "286px",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      internalDate: moment(),
      currentForm: null,
      forms: [
        {
          component: "CalendarDates",
          saveBtnLabel: "Сохранить",
          saveFunction: this.saveExternalChanges,
        },
        {
          component: "CalendarYears",
          saveBtnLabel: "Применить",
          saveFunction: this.applyInternalChanges,
        },
        {
          component: "CalendarMonths",
          saveBtnLabel: "Применить",
          saveFunction: this.applyInternalChanges,
        },
      ],
      externalValue: null,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    internalMonth() {
      let formattedMonth = this.internalDate?.format("MMMM");
      return `${
        formattedMonth[0].toUpperCase() + formattedMonth.slice(1)
      } ${this.internalDate?.format("YYYY")}`;
    },
  },
  methods: {
    changeForm(form) {
      this.currentForm = this.forms.find((elem) => elem.component === form);
    },
    changeDate() {
      if (this.value) {
        if (this.range) {
          this.internalDate = this.value?.from?.clone();
          this.externalValue = {
            from: this.value?.from?.clone(),
            to: this.value?.to?.clone(),
          };
        } else {
          this.internalDate = this.value?.clone();
          this.externalValue = this.value?.clone();
        }
      } else {
        if (this.range) {
          this.internalDate = moment();
          this.externalValue = {
            from: null,
            to: null,
          };
        } else {
          this.internalDate = moment();
          this.externalValue = null;
        }
      }
    },
    cancelChanges() {
      this.changeDate();
      this.changeForm("CalendarDates");
      this.cancel();
    },
    saveExternalChanges() {
      if (this.range)
        this.value = {
          from: this.externalValue?.from?.clone(),
          to: this.externalValue?.to?.clone(),
        };
      else this.value = this.externalValue?.clone();
      this.save();
    },
    applyInternalChanges() {
      switch (this.currentForm.component) {
        case "CalendarYears":
          this.changeForm("CalendarMonths");
          break;
        case "CalendarMonths":
          this.changeForm("CalendarDates");
          break;
      }
    },
  },
  mounted() {
    if (this.value) this.changeDate();
    this.currentForm = this.forms[0];
  },
};
</script>

<style scoped lang="sass">
.calendar-wrapper
  width: 286px
.footer
  border-top: 1px solid var(--border-light-grey-color)
</style>
