import { fetchWrapper } from "@/shared/fetchWrapper";
import { genderOptions } from "@/pages/newMedicalCard/utils/medicalConfig";
import { getConfidantObject } from "@/pages/newMedicalCard/utils/gettersObjects";
import toothFormula from "@/store/modules/toothFormula";

const state = () => ({
  medicalCard: {},
  confidantData: [],
  contactsData: {
    phones: [],
    emails: [],
    networks: [],
  },
  basicData: {
    personalData: {
      last_name: null,
      first_name: null,
      patronymic: null,
      gender: null,
      birth_date: null,
      photo: null,
    },
    registrationAddress: {
      region: null,
      city: null,
      street: null,
      house: null,
      flat: null,
    },
    residenceAddress: {
      region: null,
      city: null,
      street: null,
      house: null,
      flat: null,
    },
    benefit: {
      id: null,
      discount: null,
      name: null,
      photo: null,
    },
  },
  documents: {},
  allergies: [],
  avatar: "",
  benefitData: [],
  events: [],
  protocolsList: [
    {
      id: 1,
      employee: {
        last_name: "Захаров",
        first_name: "Алексей",
        patronymic: "Сергеевич",
        job_title: "Хирург",
        photo: "",
      },
      start: "2023-03-23T13:00:00+03:00",
      end: "2023-03-23T13:40:00+03:00",
      status: "filled",
    },
    {
      id: 2,
      employee: {
        last_name: "Лаврентьев",
        first_name: "Сергей",
        patronymic: "Анатольевич",
        job_title: "Терапевт",
        photo: "",
      },
      start: "2023-04-11T18:00:00+03:00",
      end: "2023-04-11T18:30:00+03:00",
      status: "partially filled",
    },
    {
      id: 3,
      employee: {
        last_name: "Захаров",
        first_name: "Алексей",
        patronymic: "Сергеевич",
        job_title: "Хирург",
        photo: "",
      },
      start: "2021-08-23T10:00:00+03:00",
      end: "2021-08-23T13:00:00+03:00",
      status: "filled",
    },
    {
      id: 4,
      employee: {
        last_name: "Щевьева",
        first_name: "Лариса",
        patronymic: "Николаевна",
        job_title: "Ортодонт",
        photo: "",
      },
      start: "2023-05-23T14:10:00+03:00",
      end: "2023-05-23T15:10:00+03:00",
      status: "",
    },
    {
      id: 5,
      employee: {
        last_name: "Захаров",
        first_name: "Алексей",
        patronymic: "Сергеевич",
        job_title: "Хирург",
        photo: "",
      },
      start: "2022-01-23T13:10:00+03:00",
      end: "2022-01-23T13:30:00+03:00",
      status: "filled",
    },
  ],
  protocolData: null,
  healthState: null,
});

const getters = {
  getBasicData(state, rootState) {
    let registrationAddress =
      state.medicalCard?.person?.addresses?.find(
        (el) => el.category === "REGISTRATION_ADDRESS"
      ) || {};
    let residenceAddress =
      state?.medicalCard?.person?.addresses?.find(
        (el) => el.category === "CURRENT_ADDRESS"
      ) || {};
    let person = state.medicalCard.person;
    return {
      personalData: {
        last_name: person?.last_name || "",
        first_name: person?.first_name || "",
        patronymic: person?.patronymic || "",
        gender:
          (person?.gender &&
            genderOptions.find((el) => el.id === person?.gender)) ||
          "",
        birth_date: person?.birth_date || "",
        photo: person?.photo
          ? {
              photo: rootState.getUrl + person.photo,
              file: null,
            }
          : {},
        id: person?.id,
      },
      registrationAddress: {
        region: registrationAddress?.region || "",
        city: registrationAddress?.city || "",
        street: registrationAddress?.street || "",
        house: registrationAddress?.house || "",
        flat: registrationAddress?.flat || "",
      },
      residenceAddress: {
        region: residenceAddress?.region || "",
        city: residenceAddress?.city || "",
        street: residenceAddress?.street || "",
        house: residenceAddress?.house || "",
        flat: residenceAddress?.flat || "",
      },
      benefit: {
        name: person?.benefit?.name,
        id: person?.benefit?.id,
        discount: person?.benefit?.discount,
        photo: person?.photo_benefit
          ? {
              photo: rootState.getUrl + person?.photo_benefit,
              file: null,
            }
          : {},
      },
    };
  },
  getDocumentsData(state, rootState) {
    const fields = [
      "passport",
      "insurance_number",
      "tax_identification_number",
      "OMS",
      "DMS",
    ];
    let result = {};
    fields.forEach((elem) => {
      const document = state.medicalCard?.person?.documents?.find(
        ({ category }) => category === elem
      );
      const isPassport = elem === "passport";
      const isPolicy = elem === "OMS" || elem === "DMS";
      result[elem] = {
        category: elem,
        id: document?.id || "",
        series: document?.series || (isPolicy || isPassport ? "" : "000"),
        number: document?.number || "",
        issued_by: document?.issued_by || (isPassport ? "" : "000"),
        issued_by_org_code: document?.issued_by_org_code || "",
        issued_at: document?.issued_at || "",
        attachments: document?.attachments?.length
          ? {
              photo: rootState.getUrl + document?.attachments?.[0],
              file: null,
            }
          : {},
      };
    });
    return result;
  },
  getContactsData(state) {
    let phones =
      state.medicalCard?.person?.contacts
        ?.filter((el) => el.category === "PHONE")
        ?.map((el) => {
          return { ...el };
        }) || [];
    let emails =
      state.medicalCard?.person?.contacts
        ?.filter((el) => el.category === "EMAIL")
        ?.map((el) => ({ ...el })) || [];
    let networks =
      state.medicalCard?.person?.contacts
        ?.filter((el) => el.category !== "PHONE" && el.category !== "EMAIL")
        ?.map((el) => ({ ...el })) || [];
    return {
      phones,
      emails,
      networks,
    };
  },
  getAllergiesData(state) {
    return [
      ...(state.medicalCard?.person?.allergic?.map((el) => ({ ...el })) || []),
    ];
  },
  getConfidantData(state) {
    return [
      ...(state.medicalCard?.confidant?.map((el) => getConfidantObject(el)) ||
        []),
    ];
  },
  getAvatar(state) {
    let lastName = state?.medicalCard?.person?.last_name;
    let firstName = state?.medicalCard?.person?.first_name;
    return lastName?.[0] + firstName?.[0];
  },
  getProtocolData() {
    return {
      complaints: ["Боль при приеме сладкой пищи"],
      anamnesis: [
        "Ребенок на боль в зубах не жаловался",
        "Ранее зубы лечили в ЛПУ",
      ],
      illnesses: ["Стоматит в 2022"],
      medications: ["Цитрамон", "Нафтизин"],
      inspection: ["Без особенностей", "Гематома на щеке", "Киста в глазу"],
      cavity: ["Кариес", "Отек десны", "2 искусственных зуба"],
      survey: [
        "Общий анализ крови",
        "Консультация узкого специалиста",
        "2 искусственных зуба",
      ],
      bite: "distal bite",
      hygieneIndex: 2,
      KPUIndex: 6.1,
      disease: {
        checked: false,
        text: "Пациенту 20 лет. Неделю назад металлокерамический мостовидный протез с опорами на зубы 4.3 и 4.6 зафиксирован на временный цемент. Во время ортопедического лечения интактные зубы 4.3 и 4.6 были препарированы под местной анестезией, после препарирования зубы были защищены временным пластмассовым мостовидным протезом, боли в это время пациент не ощущал. Ноющая боль появилась на второй день после фиксации металлокерамического зубного протеза на временный цемент, интенсивность боли постепенно нарастает, усиливается от холодного. Пациент точно указывает, что боль локализуется в области зуба 4.3",
      },
      preliminary: { text: "Средний кариес 36-го зуба (II класс по Блэку)" },
      thermometry: {
        cold: {
          value: 22,
          result: "Реакция нормальная",
        },
        heat: {
          value: 50,
          result: "Повышенная чувствительность",
        },
      },
      tonometry: {
        pulse: {
          value: 84,
        },
        pressure: {
          value: 120,
        },
      },
      toothFormula: {
        lacticBite: toothFormula.state.lacticBite,
        formula: JSON.parse(JSON.stringify(toothFormula.state.formula)),
      },
      data: {
        complaints: [
          "Боль при приеме сладкой пищи",
          "Жжение языка",
          "Кровоточивость десен",
          "Лечение у ортодонта не проводилось",
          "Ранее зубы лечили в ЛПУ",
          "Удаление зуба",
        ],
        files: [
          { name: "Документы", data: [] },
          { name: "Сканы", data: [] },
        ],
      },
      clinical: ["В00.09"],
    };
  },
  getInitProtocol() {
    return {
      complaints: [],
      anamnesis: [],
      illnesses: [],
      medications: [],
      inspection: [],
      cavity: [],
      survey: [],
      bite: null,
      hygieneIndex: null,
      KPUIndex: null,
      thermometry: {
        cold: {
          value: null,
          result: null,
        },
        heat: {
          value: null,
          result: null,
        },
      },
      tonometry: {
        pulse: {
          value: null,
        },
        pressure: {
          value: null,
        },
      },
      disease: { checked: false, text: null },
      preliminary: { text: null },
      toothFormula: {
        lacticBite: toothFormula.stateInit.lacticBite,
        formula: JSON.parse(JSON.stringify(toothFormula.stateInit.formula)),
      },
      data: {
        complaints: [],
        files: [
          { name: "Документы", data: [] },
          { name: "Сканы", data: [] },
        ],
      },
      clinical: [],
    };
  },
  getHealthStateData() {
    return [
      {
        id: 1,
        date: "2023-02-22",
        pictures: [],
        comment:
          "Пациент склонен к преувеличению фактов\nПациент отказывался заполнять карту\nТяжело идет на контакт",
      },
      {
        id: 2,
        date: "2022-02-22",
        pictures: [],
        comment:
          "Пациент склонен к преувеличению фактов\nПациент отказывался заполнять карту\nТяжело идет на контакт",
      },
      {
        id: 3,
        date: "2021-02-22",
        pictures: [],
        comment:
          "Пациент склонен к преувеличению фактов\nПациент отказывался заполнять карту\nТяжело идет на контакт",
      },
    ];
  },
};

const actions = {
  postCreateAddress(context, { id, address, category }) {
    fetchWrapper.post(`address`, {
      person_id: id,
      ...address,
      category: category,
    });
  },
  postUpdateAddress(context, { id, address, category }) {
    fetchWrapper.patch(`address/${id}`, {
      ...address,
      category: category,
    });
  },
  postCreateContact(context, { obj, id }) {
    fetchWrapper.post("contacts", {
      person_id: id,
      ...obj,
    });
  },
  postUpdateContact(context, { obj, id }) {
    fetchWrapper.patch(`contacts/${id}`, obj);
  },
  deleteContact(context, { id }) {
    fetchWrapper.del(`contacts/${id}`);
  },
  deleteItemData({ commit }, props) {
    commit("setDataWithouDeleted", props);
  },
  deleteProtocolFormData({ commit }, props) {
    commit("setDeletedProtocolForm", props);
  },
  addNewListData({ commit }, props) {
    commit("setNewListData", props);
  },
  getBenefitData({ commit }) {
    fetchWrapper.get("general/benefit/").then((res) => {
      commit("setBenefitData", res.results);
    });
  },
  getBenefitDataSearch({ commit }, text) {
    fetchWrapper.get(`general/benefit/?name=${text}`).then((res) => {
      commit("setBenefitData", res.results);
    });
  },
  createMedicalCard(ctx, { personId, successCallback }) {
    fetchWrapper
      .post("medical_cards", {
        person_id: personId,
        number: String(Math.floor(1 + Math.random() * (100000000 + 1 - 1))),
      })
      .then(() => {
        this.dispatch("getMedicalCardDataByPersonId", {
          personId,
          successCallback,
        });
      });
  },
  getMedicalCardDataByPersonId({ commit }, { personId, successCallback }) {
    fetchWrapper.get(`medical_cards/person/${personId}`).then((data) => {
      if (!data || data?.detail) {
        return this.dispatch("createMedicalCard", {
          personId,
          successCallback,
        });
      }
      commit("setMedicalCard", data);
      commit("setBasicData");
      commit("setContactsData");
      commit("setDocumentsData");
      commit("setAllergiesData");
      commit("setConfidantData");
      commit("setHealthStateData");
      commit("setEventsPersonData", personId);
      successCallback?.(data?.id);
    });
  },

  getMedicalDataById({ commit }, id) {
    fetchWrapper.get(`medical_cards/${id}`).then((data) => {
      if (!data) {
        return;
      }
      commit("setMedicalCard", data);
      commit("setBasicData");
      commit("setContactsData");
      commit("setDocumentsData");
      commit("setAllergiesData");
      commit("setConfidantData");
      commit("setHealthStateData");
      commit("setEventsPersonData", data?.person?.id);
    });
  },
  getPersonsFiltredList(value) {
    return fetchWrapper
      .get(`general/person/?last_name=${value}`)
      .then((res) => res.results);
  },
  returnInitData({ commit }) {
    commit("setBasicData");
    commit("setContactsData");
    commit("setAllergiesData");
    commit("setConfidantData");
  },
  removeMedicalCardData({ commit, rootState }, id) {
    fetchWrapper.del(`medical_card/medical_history/${id}/delete/`).then(() => {
      rootState.routingHistory.back();
      commit("setMedicalCard", {});
    });
  },
  createProtocol({ commit, rootGetters, state }, data) {
    data.employee = rootGetters.getUserData;
    data.id = state.protocolsList.length + 1;
    commit("setProtocolsList", data);
  },
  getProtocolData({ commit }, props) {
    commit("setProtocolData", props);
  },
  setMedicalCardData({ commit }, props) {
    commit("setMedicalCard", props);
  },
  setBasicData({ commit }, props) {
    commit("setBasicData", props);
  },
};

const mutations = {
  setDataWithouDeleted(state, props) {
    state[props.stateKey] = state[props.stateKey].filter(
      (item) => item.id !== props.itemId
    );
  },
  setDeletedProtocolForm(state, props) {
    state.protocolData[props.key] = this.getters.getInitProtocol[props.key];
  },
  setNewListData(state, props) {
    state[props.stateKey] = [...state[props.stateKey], props.data];
  },
  setBenefitData(state, data) {
    state.benefitData = data;
  },
  setMedicalCard(state, card) {
    state.medicalCard = card;
  },
  setBasicData(state) {
    state.basicData = { ...this.getters.getBasicData };
  },
  setContactsData(state) {
    state.contactsData = { ...this.getters.getContactsData };
  },
  setDocumentsData(state) {
    state.documents = { ...this.getters.getDocumentsData };
  },
  setAllergiesData(state) {
    state.allergies = this.getters.getAllergiesData;
  },
  setConfidantData(state) {
    state.confidantData = this.getters.getConfidantData;
  },
  setEventsPersonData(state, id) {
    fetchWrapper.get(`general/person/${id}/detail/`).then((res) => {
      state.events = res.events;
    });
  },
  setProtocolsList(state, data) {
    state.protocolsList = [...state.protocolsList, data];
  },
  setProtocolData(state, props) {
    if (props?.key) {
      state.protocolData[props.key] = {
        ...JSON.parse(JSON.stringify(this.getters.getProtocolData[props.key])),
      };
    } else {
      if (props.fillInspection) {
        state.protocolData = this.getters.getInitProtocol;
      } else {
        state.protocolData = this.getters.getProtocolData;
      }
    }
  },
  setHealthStateData(state) {
    state.healthState = JSON.parse(
      JSON.stringify(this.getters.getHealthStateData)
    );
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
