<template lang="pug">
  .cell.flex(
    :key="`${jawPosition}_${config.tooth_number}`"
    :style="{'width': '5%'}",
    :class="{'cursor-pointer': isEdit}"
    )
    .flex.h-full.w-full.justify-center.items-center.h-full.p-1(v-if="filtredTooths.includes(config.tooth_number)")
      .edit-cell.flex.h-full.w-full.rounded.justify-center.items-center(
        v-if="isEdit && rowKey !== 'tooth_number'"
        @click.stop="()=>{isEditCondition=!isEditCondition}",
        :class="{'open-edit': isEditCondition}"
        )
        q-icon(
          v-if="!isEditCondition",
          name="add",
          size="25px",
        )
        q-icon(
          v-else
          name="close",
          size="25px",
        )
      .flex.flex-col.gap-y-5px.items-center(
        v-if="rowKey === 'tooth_number'",
        )
        simple-tooth-svg(
          :opacity="value.dental_condition.general === 'О' ? 0.2 : 1",
          :type="config.type",
          :position="config?.position",
          :jaw="jawPosition"
          :filling="value.dental_condition.general === 'ИЗ'"
          )
        span.font-medium.text-smm {{ config.tooth_number }}
      .flex.flex-col.gap-y-1.items-center(
        v-if="rowKey === 'dental_condition' && !isEditCondition",
        :class="{'cell-info': isEdit}"
        )
        span.text-smm.font-medium {{getFilledConditions(value[rowKey]).map((el) => el.value).flat()[0]}}
        .conditions.flex.justify-center.items-center.w-27px.h-6.rounded.cursor-pointer(
          v-if="getFilledConditions(value[rowKey]).map((el) => el.value).flat().length-1 > 0",
          @click="()=>openConditionsInfo()",
          :class="{'active': isOpenConditionsInfo}"
          )
          span.text-xsx.font-medium {{`+${getFilledConditions(value[rowKey]).map((el) => el.value).flat().length - 1}`}}
          tooth-conditions-info(
            v-model="isOpenConditionsInfo",
            :conditions="getFilledConditions(value[rowKey]).map((el) => el.value).flat()"
            :config="conditionConfig"
            )
      span.text-smm.font-medium(
        v-if="rowKey === 'tooth_mobility' && !isEditCondition",
        :class="{'cell-info': isEdit}"
        ) {{ mobilityConfig.find((el) => el.id === value[rowKey])?.label || null }}
      q-menu(
        v-if="isEdit && rowKey === 'tooth_mobility'",
        v-model="isEditCondition",
        anchor="top left",
        :offset="[0,168]",
        )
        .flex.flex-col.p-2.w-40.gap-y-1
          .mobility-item.flex.justify-between.items-center.py-7px.pl-7px.pr-3.rounded.cursor-pointer(
            v-for="mobility in mobilityConfig",
            @click="()=>chooseMobility(mobility.id)"
            )
            span.text-smm.font-medium {{ `${mobility.label} степень` }}
            q-icon.check(
              name="check",
              size="12px"
              )
      q-menu(
        v-if="isEdit && rowKey === 'dental_condition'",
        v-model="isEditCondition",
        anchor="top left",
        self="bottom left",
        :offset="[20,8]",
        )
        dental-condition-form(
          :data="value.dental_condition"
          :cell-config="config",
          :form-config="formConfig",
          :jaw-position="jawPosition"
          )
</template>

<script>
import DentalConditionForm from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/DentalConditionForm.vue";
import SimpleToothSvg from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/SimpleToothSvg.vue";
import ToothConditionsInfo from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/ToothConditionsInfo.vue";
import { getFilledConditions } from "@/pages/newMedicalCard/utils/gettersObjects";
import {
  toothConditions,
  toothMobility,
} from "@/pages/newMedicalCard/utils/medicalConfig";
import { v_model } from "@/shared/mixins/v-model";

export default {
  name: "ToothFormulaCell",
  components: { SimpleToothSvg, ToothConditionsInfo, DentalConditionForm },
  data() {
    return {
      isEditCondition: false,
      isOpenConditionsInfo: false,
      mobilityConfig: toothMobility,
      conditionConfig: toothConditions,
      getFilledConditions,
    };
  },
  mixins: [v_model],
  props: {
    isEdit: Boolean,
    jawPosition: String,
    rowKey: String,
    config: Object,
    formConfig: Object,
    filtredTooths: Array,
  },
  watch: {
    isEdit() {
      this.isEditCondition = false;
    },
  },
  methods: {
    chooseMobility(id) {
      this.value.tooth_mobility = id;
      this.isEditCondition = false;
    },
    openConditionsInfo() {
      this.isOpenConditionsInfo = true;
    },
  },
};
</script>

<style lang="sass" scoped>
.cell
  border-right: 1px solid var(--border-light-grey-color)
  &:hover
    & .edit-cell
      display: flex
    & .cell-info
      display: none
  &:active
    & .cell-info
      display: none
  &:last-child
    border-right-width: 0
.conditions
  background-color: var(--bg-light-grey)
  &:hover
    background-color: var(--font-grey-color-0)
  &.active
    background-color: var(--btn-blue-color)
    color: var(--default-white)
.edit-cell
  background-color: var(--bg-light-grey)
  color: var(--font-grey-color)
  display: none
.open-edit
  display: flex !important
.mobility-item
  &:hover
    background-color: var(--bg-light-grey)
    & .check
      display: block
.check
  display: none
  color: var(--bg-status-green)
</style>
