<template lang="pug">
  medical-form-wrapper(
    :title="data.title",
    :is-edit="change",
    :open-edit="openCard",
    :cancel="closeCard",
    :tag="fillInspection || change",
    template
  )
    .disease-body.flex
      .flex.flex-col.gap-y-17px.w-full(v-if="fillInspection || change")
        .flex.-ml-2(v-if="data.key === 'disease'")
          q-checkbox.text-smm.font-medium(
            v-model="protocolData[data.state].checked",
            label="Без особенностей",
            size="36px"
          )
        .textarea.flex.w-full
          base-textarea.w-full(
            v-model="protocolData[data.state].text",
            type="textarea",
            resize="vertical",
            placeholder="Введите данные...",
            height="126px"
          )
      .font-medium.text-smm(v-else) {{protocolData[data.state]?.text}}
</template>

<script>
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import { mapState } from "vuex";
import BaseTextarea from "@/components/base/BaseTextarea.vue.vue";

export default {
  name: "DiseaseForm",
  components: { MedicalFormWrapper, BaseTextarea },
  props: {
    data: Object,
    fillInspection: Boolean,
  },
  data() {
    return { change: false };
  },
  computed: {
    ...mapState({
      protocolData: (state) => state.medical.protocolData,
    }),
  },
  methods: {
    openCard() {
      this.change = true;
    },
    closeCard() {
      this.change = false;
    },
  },
  watch: {
    fillInspection: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.change = false;
          this.protocolData[this.data.state].text = null;
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.disease-body
  background: var(--default-white)
  border-radius: 4px
  color: var(--font-dark-blue-color)

.q-checkbox :deep(.q-checkbox__bg)
  border-radius: 4px
  border: 1.5px solid var(--font-grey-color)
</style>
