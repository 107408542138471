import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import moment from "moment";
import "./assets/sass/tailwind.sass";
import "./assets/sass/variables.sass";
import { clientsServer } from "../server.js";
import { clickOutside } from "@/shared/clickOutside";
import store from "./store";
import { install } from "@dop/astra-ui";

const AstraUI = { install };

if (process.env.NODE_ENV === "development") {
  clientsServer({ environment: "development" });
}

moment.locale("ru");

createApp(App)
  .use(store)
  .use(AstraUI)
  .directive("click-outside", clickOutside)
  .use(router, moment)
  .mount("#app");
