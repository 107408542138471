import { mapState } from "vuex";
import * as moment from "moment/moment";
import CalendarBackground from "@/pages/newCalendar/components/CalendarBackground.vue";
import CalendarClockColumn from "@/pages/newCalendar/components/CalendarClockColumn.vue";
import {
  convertTime,
  verifyTime,
} from "@/pages/newCalendar/utils/calendarFunctions.js";

export const wrapperMixin = {
  props: {
    openSidebar: Boolean,
  },
  emits: [],
  components: {
    CalendarBackground,
    CalendarClockColumn,
  },
  data() {
    return {
      currentTime: "",
      isShownIndicator: true,
      timeCoil: [],
      timer: null,
      pixelsPerHour: 76,
      isCurrent: true,
    };
  },
  computed: {
    ...mapState({
      workingHours: (state) => state.calendar.workingHours,
    }),
    hours() {
      return convertTime(this.currentTime, 0, -6);
    },
    minutes() {
      return convertTime(this.currentTime, 3, -3);
    },
    hoursMinutes() {
      return this.currentTime.slice(0, -3);
    },
    pixelsPerMinute() {
      return this.pixelsPerHour / 60;
    },
    validateStartTime() {
      return verifyTime(this.workingHours.start);
    },
    validateEndTime() {
      return verifyTime(this.workingHours.end);
    },
    backgroundHeight() {
      return (
        (this.validateEndTime - this.validateStartTime) * this.pixelsPerHour
      );
    },
    lineIndicatorLocation() {
      return {
        top: `${this.calculateIndicatorLocation()}px`,
      };
    },
    circleIndicatorLocation() {
      return {
        top: `${this.calculateIndicatorLocation() + 58}px`,
      };
    },
  },
  methods: {
    changeCurrentTime() {
      this.currentTime = moment().format("HH:mm:ss");
    },
    changeTimeCoil() {
      this.timeCoil = this.timeCoil.map((elem) => {
        if (convertTime(elem, 0, -3) === this.hours) {
          return this.hoursMinutes;
        }
        return elem.slice(0, -3) + ":00";
      });
    },
    startTimer() {
      if (
        this.hours >= this.validateStartTime &&
        this.hours < this.validateEndTime
      ) {
        this.timer = setInterval(() => {
          this.changeCurrentTime();
          this.changeTimeCoil();
        }, 5000);
      }
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    calculateIndicatorLocation() {
      let newTime = this.currentTime
        .split(":")
        .map((elem) => parseInt(elem, 10));
      let result =
        (newTime[0] - this.validateStartTime) * this.pixelsPerHour +
        newTime[1] * this.pixelsPerMinute;
      if (result > this.backgroundHeight || result < 0) {
        this.isShownIndicator = false;
        return 0;
      }
      return result;
    },
    timeCoilInitialization() {
      this.timeCoil = [];
      for (let i = this.validateStartTime; i < this.validateEndTime; i++) {
        if (
          i === this.hours &&
          this.hours !== this.validateEndTime &&
          this.isCurrent
        ) {
          this.timeCoil.push(this.hoursMinutes);
        } else this.timeCoil.push(`${i}:00`);
      }
    },
  },
  watch: {
    currentTime() {
      if (
        this.hours === this.validateEndTime &&
        this.minutes > 0 &&
        this.timer
      ) {
        this.stopTimer();
        this.timeCoilInitialization();
      }
    },
  },
  beforeUnmount() {
    this.stopTimer();
  },
};
