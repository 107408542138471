<template lang="pug">
 .event-form.flex.flex-col.gap-y-6.pt-6.pb-7.px-8.fixed.right-6.bottom-6
    .flex.justify-between
      span.title.text-xl.font-bold Замена смен
      .flex.pt-2
        .icon-cancel.close-icon.tesxt-xs.cursor-pointer(@click="closeForm")
    .flex.flex-col.gap-y-6
      base-input(
        label="Дата",
        type="date",
        v-model="replacementSheet.date",
        size="M"
      )
      base-input(,
        type="text"
        v-model="replacementSheet.currentEmployee",
        label="Текущий сотрудник",
        outlined,
        size="M"
      )
      base-select(
        :items="ownersList",
        v-model="replacementSheet.replacementEmployee",
        label="Замена сотрудника",
        placeholder="Выберите сотрудника",
        size="M"
      )
      .time-wrapper.flex.justify-center.gap-x-6
        base-input(
          type="time",
          label="Начало",
          v-model="replacementSheet.start_time",
          size="M"
        )
        .flex.items-center.mt-4 —
        base-input(
          type="time",
          label="Конец",
          v-model="replacementSheet.end_time",
          size="M"
        )
      .flex.justify-center
        q-btn(
          label="Сохранить",
          color="primary",
          padding="8px 24px",
          no-caps,
          @click="changeShift(); closeForm()"
        )
</template>

<script>
import BaseSelect from "@/components/base/BaseSelect.vue";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "FormChangeShift",
  components: { BaseSelect, BaseInput },
  emits: ["update:model-value"],
  props: {
    employees: Array,
    serialized: Array,
    modelValue: Boolean,
    trimOwnerName: Function,
    changeShift: Function,
    replacementSheet: Object,
  },
  computed: {
    ownersList() {
      if (this.employees || this.serialized) {
        let filteredArray = [];
        this.employees.forEach((elem) => {
          filteredArray.push({
            id: elem.id,
            label: this.trimOwnerName(
              elem.last_name,
              elem.first_name,
              elem.patronymic
            ),
          });
        });
        this.serialized.forEach((elem) => {
          filteredArray.push({
            id: elem.id,
            label: this.trimOwnerName(
              elem.last_name,
              elem.first_name,
              elem.patronymic
            ),
          });
        });
        return filteredArray;
      }
      return [];
    },
  },
  methods: {
    closeForm() {
      this.$emit("update:model-value", false);
    },
  },
};
</script>

<style lang="sass" scoped>
.event-form
  height: fit-content
  width: 534px
  background-color: var(--default-white)
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25)
  border-radius: 4px
  z-index: 5
</style>
