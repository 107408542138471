<template lang="pug">
  .w-full
    .header.pl-3.pr-6.pt-14px.pb-10px.flex.items-center.justify-center
      q-btn(
        size="14px"
        color="dark"
        flat,
        :label="internalMonth",
        no-caps,
        padding="10px 12px"
      )
    .body.py-5.px-6
      .cell.flex.items-center.justify-center.cursor-pointer.py-3.rounded(
        v-for="year in internalYearsList",
        :key="year",
        :id="year",
        :class="calculateActiveCell(year), cellsClass",
        @click="selectYear(year)",
      ) {{ year }}
</template>

<script>
export default {
  name: "CalendarYears",
  props: {
    internalDate: Object,
    startYear: Number,
    endYear: Number,
    internalMonth: String,
  },
  computed: {
    internalValue: {
      get() {
        return this.internalDate;
      },
      set(value) {
        this.$emit("update:internalDate", value);
      },
    },
    cellsClass() {
      let remains = this.internalYearsList.length % 3;
      if (remains === 2)
        return {
          "cell-col-2": true,
        };
      if (remains === 1)
        return {
          "cell-col-3": true,
        };
      return {};
    },
    internalYearsList() {
      let diff = this.endYear - this.startYear,
        start = this.startYear;
      return [...Array(diff + 1)].map(() => start++);
    },
  },
  methods: {
    calculateActiveCell(year) {
      return {
        "active-cell": parseInt(this.internalValue.format("YYYY"), 10) === year,
      };
    },
    selectYear(year) {
      this.internalValue = this.internalValue.clone().year(year);
    },
    scrollToCurrentYear() {
      document
        .getElementById(parseInt(this.internalValue.format("YYYY"), 10))
        .scrollIntoView({ block: "center", behavior: "smooth" });
    },
  },
  mounted() {
    this.scrollToCurrentYear();
  },
};
</script>

<style scoped lang="sass">
.header
  border-bottom: 1px solid var(--border-light-grey-color)
.body
  height: 298px
  display: grid
  grid-template-columns: repeat(3, 1fr)
  row-gap: 4px
  column-gap: 4px
  overflow-y: auto
  &::-webkit-scrollbar
    width: 0
.cell
  background-color:  var(--bg-light-grey)
  color: var(--font-dark-blue-color)
  &:hover
    background-color: var(--border-light-grey-color)
.cell-col-2:first-child
  grid-column: 2
.cell-col-2:nth-child(2)
  grid-column: 3
.cell-col-3:first-child
  grid-column: 3
.active-cell
  background-color: var(--btn-blue-color)
  color: var(--default-white)
  &:hover
    background-color: var(--btn-blue-color)
</style>
