<template lang="pug">
  medical-form-wrapper(
    :title="data.title",
    :is-edit="change",
    :open-edit="openCard",
    :cancel="closeCard",
    :tag="fillInspection || change",
  )
    .protocol-body.flex.flex-col
      .flex.items-center.gap-x-11px(:class="{'open-header': change || fillInspection}")
      .flex.flex-col.gap-y-4(:class="{'changed': change || fillInspection}")
        .flex.flex-col.gap-y-1(:class="{'open-textarea': change || fillInspection}")
          .tag-wrap.flex.gap-x-1.gap-y-1
            .tag.flex.items-center.px-3.font-medium.text-smm(
              :class="{'pointer': fillInspection || change}"
              v-for="tag in protocolData[data.state].complaints", :key="tag.id"
            ) {{ tag }}
          q-input.input.px-3(v-if="change || fillInspection", type="textarea", autogrow, borderless, placeholder="Введите данные...")
        template(v-if="!(change || fillInspection)", v-for="file in protocolData[data.state].files")
          .wrap.flex.flex-col.gap-1.gap-y-3(v-if="file?.data?.length")
            .title.font-semibold.text-smm.mb-2 {{file.name}}
            .wrap.flex.gap-x-1.gap-y-1
              .flex.gap-x-2.items-center.pl-2.pr-4.py-2.gap-y-3(
                v-for="item in file.data"
                :class="{'photo-field': checkedName(file.name), 'scan-field': file.name === 'Сканы'}"
              )
                q-avatar(:size="checkedName(file.name) ? '24px' : '40px'", :rounded="!checkedName(file.name)")
                  img.h-10.w-10.object-cover(
                    :src="checkedName(file.name) ? data.iconDictionary[item.name.substr(item.name.lastIndexOf('.') + 1)] : item.file"
                  )
                .doc-text.font-medium.text-smm(v-if="checkedName(file.name)") {{item.name}}
                .flex.flex-col.gap-y-1(v-else)
                  .doc-text.font-medium.text-smm {{item.name.substr(0, item.name.lastIndexOf('.'))}}
                  .title.font-medium.text-xsx {{item.size + " Мб"}}
        .filter.flex.flex-col.gap-y-4.relative(v-if="change || fillInspection")
          .flex.gap-x-2
            base-input.search(
              placeholder="Поиск",
              :width="232",
              iconLeft,
              icon-left,
              size="M",
            )
              template(v-slot:iconLeft)
                q-icon.icon-grey(name="app:search", size="20px" )
            .button
              q-btn(
                :style="{width: '40px', height: '40px'}",
                padding="0"
              )
                q-icon.icon-grey(name="app:filter", size="24px")
            .button
              q-btn(
                :style="{width: '40px', height: '40px'}",
                padding="0"
              )
                q-icon.icon-grey(name="app:sort-number", size="24px")
          .field.flex.flex-col.overflow-y-scroll
            .checkbox.flex.flex-col(v-for="complaint in textSorting(data.results.tags)")
              .letter.flex.items-center.font-bold.justify-center {{ complaint.sym }}
              .line.flex.gap-x-4.h-9.items-center(v-for="name in complaint.array")
                q-checkbox(
                  dense,
                  v-model="protocolData[data.state].complaints",
                  :val="name",
                  :style="{border: 'none', width: '16px', height: '16px'}"
                )
                .name.flex.items-center.font-medium.text-smm {{ name }}
          .flex.h-10.w-10.absolute.right-4.bottom-4
            base-button(
              width="40px",
              @click="showModal = true",
            )
              q-icon.icon-white(name="app:plus", size="24px")
        base-modal(v-model="showModal", title="Создание тега", modal-padding)
          tag-creation-form(:close-modal="closeModal", :create-tag="createTag")
    .flex.gap-x-20.w-full(v-if="change || fillInspection")
      .flex.flex-col.gap-y-3.w-full(v-for="file in protocolData[data.state].files")
        .title.text-smm.font-semibold {{file.name}}
        .download.flex.relative.w-full
          base-input.w-full(
            @update:model-value="(e) => addNewFiles(e, file.name)",
            doc,
            type="file",
            id="upload",
            :accept="checkedName(file.name) ? '.xlsx, .xls, .doc, .docx, .pdf, .odt' : 'image/*'",
            multiple
            size="62px"
          )
          .text.flex.flex-col.items-center.justify-center.absolute.font-medium.text-smm.w-full
            span {{checkedName(file.name) ? 'Загрузите документ' : 'Загрузите скан документа'}}
              label.label.cursor-pointer(for="upload")  с компьютера
            span  или перетащите его в это поле
        .wrap.gap-1.flex(v-if="file?.data?.length")
          .flex.gap-x-2.items-center(
            v-for="item in file.data",
            :class="{'photo-field': checkedName(file.name), 'scan-field': file.name === 'Сканы'}"
          )
            q-avatar(:size="checkedName(file.name) ? '24px' : '40px'", :rounded="!checkedName(file.name)")
              img.h-10.w-10.object-cover(
                :src="checkedName(file.name) ? data.iconDictionary[item.name.substr(item.name.lastIndexOf('.') + 1)] : item.file"
              )
            .doc-text.font-medium.text-smm(v-if="checkedName(file.name)") {{item.name}}
            .flex.flex-col.gap-y-1(v-else)
              .doc-text.font-medium.text-smm {{item.name.substr(0, item.name.lastIndexOf('.'))}}
              .title.font-medium.text-xsx {{item.size + " Мб"}}
</template>

<script>
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import TagCreationForm from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/TagCreationForm.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseModal from "@/components/base/BaseModal.vue";
import { mapState } from "vuex";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "DataForm",
  components: {
    MedicalFormWrapper,
    BaseButton,
    BaseInput,
    TagCreationForm,
    BaseModal,
  },
  props: {
    abc: Array,
    data: Object,
    fillInspection: Boolean,

    inspection: Boolean,
  },
  data() {
    return {
      change: false,
      showModal: false,
    };
  },
  computed: {
    ...mapState({
      protocolData: (state) => state.medical.protocolData,
    }),
  },
  methods: {
    checkedName(e) {
      return e === "Документы" ? true : false;
    },
    addNewFiles(e, name) {
      return name === "Документы" ? this.addNewDoc(e) : this.addNewScan(e);
    },
    textSorting(arr) {
      let sortArr = [];
      this.abc.forEach((letter) => {
        arr.forEach((str) => {
          let finded = sortArr.find((obj) => obj.sym === letter);
          if (str[0] === letter) {
            if (!finded) {
              sortArr.push({
                sym: letter,
                array: [str],
              });
            } else if (finded.sym === letter) finded.array.push(str);
          }
        });
      });
      return sortArr;
    },
    openCard() {
      this.change = true;
    },
    closeCard() {
      this.change = false;
    },
    addNewDoc(arr) {
      let doc = [...arr];
      if (
        this.data.iconDictionary[
          doc[0]?.name.substr(doc[0].name.lastIndexOf(".") + 1)
        ]
      )
        this.protocolData[this.data.state].files.find(
          (e) => e.name === "Документы"
        ).data = [
          ...this.protocolData[this.data.state].files.find(
            (e) => e.name === "Документы"
          ).data,
          ...arr,
        ];
    },
    addNewScan(arr) {
      let scan = [...arr];
      if (scan[0].type.split("/")[0] !== "image") return;
      [...arr].forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.protocolData[this.data.state].files
            .find((e) => e.name === "Сканы")
            ?.data.push({
              file: e.target.result,
              name: file.name,
              size: (file.size / 1024 / 1024).toFixed(1),
            });
        };
        reader.readAsDataURL(file);
      });
    },
    closeModal() {
      this.showModal = false;
    },
    createTag(model) {
      this.showModal = false;
      this.protocolData[this.data.state].complaints.push(model.tag);
    },
  },
  watch: {
    fillInspection: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.change = false;
          this.protocolData[this.data.state].files.forEach(
            (e) => (e.data = [])
          );
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
#upload :deep(.q-field__native)
  cursor: pointer
  height: 100%
  padding: 0

#upload :deep(.q-field__control)
  height: 100%

.changed
  height: 300px
  column-gap: 16px
  flex-direction: row

.protocol-body
  background: var(--default-white)
  border-radius: 4px

.icon
  background: var(--bg-light-grey)

.open-header
  justify-content: space-between

.open-textarea
  width: 800px
  padding: 4px
  border: 1px solid var(--border-light-grey-color)
  border-radius: 4px

.tag
  height: 32px
  background: var(--bg-light-grey)
  border-radius: 4px
  color: var(--font-dark-blue-color)

.tag-wrap
  flex-wrap: wrap !important

.filter
  border: 1px solid var(--border-light-grey-color)
  border-radius: 4px
  padding: 16px 16px 0
  width: 360px

.button
  display: flex
  align-items: center
  width: 40px
  height: 40px
  justify-content: center
  cursor: pointer
  border-radius: 4px
  background: var(--bg-light-grey)

.field
  margin-right: -10px
  &::-webkit-scrollbar-track:vertical
    margin-bottom: 12px
  &::-webkit-scrollbar
    width: 4px

.checkbox
  width: 328px

.line
  border-bottom: 1px solid var(--bg-light-grey)
  min-height: 36px

.letter
  width: 18px
  height: 34px
  color: var(--font-grey-color)

.name
  width: 268px
  color: var(--font-dark-blue-color)

.q-checkbox :deep(.q-checkbox__bg)
  border-radius: 4px
  border: 1.5px solid var(--font-grey-color)

.q-checkbox :deep(.q-checkbox__inner:before)
  background: none !important

.download
  height: 62px
  background-color: var(--bg-light-grey)
  border-radius: 4px
  cursor: pointer

.text
  height: 62px
  cursor: pointer
  color: var(--font-grey-color)

.title
  color: var(--font-grey-color)

.label
  color: var(--btn-blue-color)

.doc-text
  color: var(--font-dark-blue-color)

.photo-field
  height: 32px
  background: var(--bg-light-grey)
  border-radius: 4px
  padding: 4px 8px
  width: fit-content

.wrap
  flex-wrap: wrap !important

.pointer
  cursor: pointer

.scan-field
  height: 56px
  background: var(--bg-light-grey)
  border-radius: 4px
  width: fit-content
  padding: 8px 16px 8px 8px

.search :deep(.q-field__prepend)
  padding-right: 6px

.input :deep(.q-field__native)
  font-weight: 500
  font-size: 14px
  line-height: 135%
  color: var(--font-dark-blue-color)
  padding: 4px 0
  &::placeholder
    color: var(--font-grey-color)
    opacity: 1

.icon-grey :deep(path)
  fill: var(--font-grey-color)

.icon-white :deep(path)
  fill: var(--default-white)
</style>
