<template lang="pug">
  .flex.flex-col.pl-6
    .row.flex.w-full.h-14(v-for="schedule in serialized", :key="schedule.id")
      //- q-btn(
      //-   flat,
      //-   class="edit",
      //-   icon="app:icon-edit",
      //-   size="12px",
      //-   no-caps,
      //-   padding="10px 13px",
      //-   @click="openForm(schedule)"
      //- )
      //-   q-menu(v-model="showForm")
      //-     form-change-shift(
      //-       v-model="showForm",
      //-       :replacement-sheet="replacementSheet",
      //-       :employees="employees",
      //-       :trim-owner-name="trimOwnerName",
      //-       :serialized="serialized",
      //-       :change-shift="changeShift"
      //-     )
      .name-employee.flex.items-center.gap-x-3
        .flex.w-9
          q-checkbox(
            v-model="selected",
            :val="schedule.id",
            :style="{border: 'none', sidth: '36px'}",
            size="32px"
          )
        .flex.items-center(:style="{minWidth: '357px', maxWidth: '357px'}")
          base-avatar.font-medium.text-sm(
            :size="36",
            :color="schedule.color",
            v-if="!schedule.photo"
          ) {{trimInitials(schedule.last_name, schedule.first_name)}}
          base-avatar(:size="36", v-else-if="schedule.photo")
            img.h-full.object-cover(:src="url + schedule.photo")
          .font-semibold.text-sm.pl-3(
            :style="{minWidth: '228px', maxWidth: '228px'}"
          ) {{schedule.last_name + " " + schedule.first_name + " " + schedule.patronymic}}
      .flex.overflow-hidden
        .cell.flex.flex-col.items-center.justify-center.m-1.cursor-pointer.transition(
          v-for="day in result",
          :key="day",
          :id="schedule.id + day",
          @click="choiceCell(day, schedule.id, schedule); clearSelect(selectEmployee); setActiveCell()",
          :class="choiceState(day, schedule.id), {'empty-cell': !choiceDay(day, schedule.id)}",
          :style="{backgroundColor: choiceDay(day, schedule.id) ? choiceColor(day, schedule) : ''}"
        )
          .flex(
            v-if="!showTime",
            :class="{'cell-work': choiceState(day, schedule.id)?.status}"
          ) {{choiceDay(day, schedule.id) ? choiceWorks(day, schedule) : '-'}}
          .flex.flex-col(
            v-else,
            :class="{'cell-work': choiceState(day, schedule.id)?.status, 'show-time': showTime}"
          )
            .cell-time.flex.justify-center(
              :style="{backgroundColor: choiceColor(day, schedule)}"
            ) {{choiceStartTime(day, schedule)}}
            .cell-time.flex.justify-center(
            ) {{choiceEndTime(day, schedule)}}
      //- q-draggable-rows(v-model="order")
      //-   q-draggable-row.row.flex.w-full.h-14(v-for="schedule in serialized", :key="schedule.id",
      //-     :id="id"
      //-     v-model="rows[id].depth"
      //-     :collapsed.sync="rows[id].collapsed"
      //-   )
</template>

<script>
import FormChangeShift from "@/pages/schedule/components/FormChangeShift.vue";
import BaseAvatar from "@/components/base/BaseAvatar";

export default {
  name: "ScheduleTableBody",
  components: { FormChangeShift, BaseAvatar },
  props: {
    serialized: Array,
    result: Array,
    scheduleList: Array,
    employees: Array,
    selected: Array,
    showTime: Boolean,
    trimOwnerName: Function,
    choiceCell: Function,
    clearSelect: Function,
    choiceState: Function,
    openForm: Function,
    changeShift: Function,
    setActiveCell: Function,
    replacementSheet: Object,
    selectEmployee: Object,
  },
  data() {
    return {
      showForm: false,
      statuses: [
        { work: "I", status: "I", color: "#B1F4C5" },
        { work: "II", status: "II", color: "#E2CDFE" },
        { work: "VACATION", status: "О", color: "#E7ECF0" },
        { work: "DAY_OFF", status: "В", color: "#CCE4FB" },
        { work: "PARTIAL", status: "Ч", color: "#FED1E9" },
        { work: "DUTY", status: "Д", color: "#FF00FF" },
      ],
    };
  },

  methods: {
    trimInitials(firstName, lastName) {
      let croppedFirstName = firstName !== null ? firstName[0] : "";
      let croppedLastName = lastName !== null ? lastName[0] : "";
      return croppedLastName + croppedFirstName;
    },
    choiceDay(day, employee) {
      let current = day.format("YYYY-MM-DD");
      return this.scheduleList.find(
        (e) => current === e.date && e.employee.id === employee
      )?.date;
    },
    choiceColor(day, employee) {
      let currentDay = day.format("YYYY-MM-DD");
      let res = employee.schedules.find((e) => e.date === currentDay)?.status;
      return this.statuses.find((e) => e.work === res)?.color;
    },
    choiceWorks(day, employee) {
      if (this.showTime) return;
      let currentDay = day.format("YYYY-MM-DD");
      return employee.schedules.find((e) => e.date === currentDay)?.status;
    },
    choiceStartTime(day, schedule) {
      let currentDay = day.format("YYYY-MM-DD");
      let start = schedule.schedules.find(
        (e) => e.date === currentDay && (e.status === "I" || e.status === "II")
      )?.start_time;
      return start;
    },
    choiceEndTime(day, schedule) {
      let currentDay = day.format("YYYY-MM-DD");
      let end = schedule.schedules.find(
        (e) => e.date === currentDay && (e.status === "I" || e.status === "II")
      )?.end_time;
      return end;
    },
  },
};
</script>

<style lang="sass" scoped>
.row
  border-bottom: 1.5px solid var(--border-light-grey-color-1)
  &:last-child
    border-bottom: none

.q-checkbox :deep(.q-checkbox__bg)
  border-radius: 4px
  border: 1px solid var(--font-grey-color)

.name-employee
  color: var(--font-dark-blue-color)

.cell
  height: 48px
  min-width: 74px
  max-width: 74px
  border-radius: 4px
  &:hover
    background-color: var(--border-light-grey-color-1)
  &:last-child
    border-right: none

.cell-work
  display: none

.empty-cell
  border: 1px solid #E7ECF0

.show-time
  text-align: center
  font-size: 12px

.cell-time
  width: 74px

.set-date
  background-color: var(--btn-blue-color-4) !important

.status
  background-color: var(--bg-color-status) !important

.status::after
  content: var(--text-status)

.set-template
  background-color: var(--bg-event-orange-color) !important

.set-template::after
  content: var(--text-status)
</style>
