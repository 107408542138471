<template lang="pug">
  medical-form-wrapper(
    id="base"
    title="Основные данные"
    :is-check-change="isCheckChange"
    :is-loading-data="isLoadingData"
    :is-edit="isEdit"
    :save="updateBasicData"
    :cancel="cancelEdit"
    :open-edit="openEdit"
    )
    q-form.form-wrap.gap-6.w-full(ref="formBasicData")
      .flex.flex-col.gap-2(v-for="data in configData")
        .font-semibold.text-sm.whitespace-nowrap {{data.dataLabel}}
        .flex.w-full.items-center.gap-4(v-for="field in data.fields")
          .label-field.font-sm.text-sm.whitespace-nowrap {{`${field.label} :`}}
          .flex.gap-3.items-center.h-10(v-if="field.type === 'avatar'")
            base-avatar(:size="40")
              img.avatar.object-cover(
                v-if="basic[data.dataKey][field.key]?.photo"
                :src="basic[data.dataKey][field.key].photo"
                alt="AV"
                )
              span(v-else) {{ avatar }}
            .replace-photo.font-medium.text-base.cursor-pointer(v-if="isEdit" @click="showModal = true") Заменить фото
            base-modal(v-model="showModal" title="Загрузить изображение")
              base-upload-photo(
                v-model="basic[data.dataKey][field.key]"
                :confirm-upload="confirmChangeAvatar"
              )
          .select(v-else-if="field.type === 'select'")
            base-select(
              readonly,
              v-model="basic[data.dataKey][field.key]"
              @update:model-value="checkChangeInput"
              :hide-dropdown-icon="!isEdit"
              :outlined="isEdit"
              :standout="!isEdit"
              :items="genderOptions"
              bg-color="#e9e9f6",
              width="100%",
              size="M"
            )
          base-input-date(
            v-else-if="field.type === 'date'",
            v-model="basic[data.dataKey][field.key]",
            size="M",
            width="100%",
            readonly,
            @update:model-value="checkChangeInput"
          )
          base-input(
            v-else,
            :readonly="field.disabled || !this.isEdit"
            v-model="basic[data.dataKey][field.key]"
            @update:model-value="checkChangeInput"
            :type="field.type"
            :name="field.key"
            width="100%",
            size="M",
            :rule="field.rules"            
          )
</template>

<script>
import BaseSelect from "@/components/base/BaseSelect.vue";
import BaseAvatar from "@/components/base/BaseAvatar.vue";
import BaseModal from "@/components/base/BaseModal.vue";
import BaseUploadPhoto from "@/components/base/BaseUploadPhoto.vue";
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import TheNotificationProvider from "@/components/Notifications/TheNotificationProvider.vue";
import { getFieldsNameUnvalidated } from "@/shared/utils/changesObjects";
import { addNotification } from "@/components/Notifications/notificationContext";
import { checkChangeData } from "@/shared/utils/changesObjects";
import {
  baseDataForm,
  genderOptions,
  errorMap,
} from "@/pages/newMedicalCard/utils/medicalConfig.js";
import { mapGetters, mapState } from "vuex";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseInputDate from "@/components/base/BaseInputDate.vue";
import { mapActions } from "vuex";
import { removeEmptyFields } from "@/shared/utils/changesObjects";

export default {
  name: "BasicDataForm",
  components: {
    MedicalFormWrapper,
    BaseInput,
    BaseAvatar,
    BaseSelect,
    BaseModal,
    BaseUploadPhoto,
    TheNotificationProvider,
    BaseInputDate,
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      isLoadingData: false,
      isCheckChange: false,
      genderOptions: genderOptions,
      configData: baseDataForm,
      errorMapBasic: errorMap,
    };
  },
  computed: {
    ...mapGetters({
      url: "getUrl",
      avatar: "getAvatar",
      initDataBasic: "getBasicData",
    }),
    ...mapState({
      basic: (state) => state.medical.basicData,
      isNew: (state) => state.medical.medicalCard.type === "new",
      medicalCard: (state) => state.medical.medicalCard,
      addresses: (state) => state.medical.medicalCard.person.addresses,
    }),
  },
  methods: {
    ...mapActions({
      createAddress: "postCreateAddress",
      updateAddress: "postUpdateAddress",
    }),
    updateBasicData() {
      const photoFormData = new FormData();
      if (
        this.basic.personalData.photo.file &&
        this.basic.personalData.photo.photo !==
          this.initDataBasic.personalData.photo.photo
      ) {
        photoFormData.append("photo", this.basic.personalData?.photo?.file[0]);
        console.log("photo_update");
      }
      Object.keys(this.basic).map((key) => {
        if (key === "registrationAddress")
          this.postAdresses(
            this.basic.registrationAddress,
            this.initDataBasic[key],
            "REGISTRATION_ADDRESS"
          );
        if (key === "residenceAddress")
          this.postAdresses(
            this.basic.residenceAddress,
            this.initDataBasic[key],
            "CURRENT_ADDRESS"
          );
      });
      this.isEdit = false;
    },
    postAdresses(updateData, initData, key) {
      let isInitDataEmpty = [...Object.keys(removeEmptyFields(initData))]
        .length;
      if (Object.keys(removeEmptyFields(updateData)).length) {
        if (!isInitDataEmpty)
          return this.createAddress({
            id: this.basic.personalData.id,
            address: updateData,
            category: key,
          });
        if (JSON.stringify(updateData) !== JSON.stringify(initData))
          return this.updateAddress({
            id: this.addresses.find(({ category }) => category === key).id,
            address: updateData,
            category: key,
          });
      }
    },
    checkChangeInput() {
      this.isCheckChange = checkChangeData(this.initDataBasic, this.basic);
    },
    confirmChangeAvatar() {
      this.showModal = false;
      this.isCheckChange = true;
    },
    cancelEdit() {
      this.$store.dispatch("returnInitData");
      this.isEdit = false;
      this.isCheckChange = false;
    },
    saveChange() {
      const form = this.$refs.formBasicData;
      form.validate().then((validate) => {
        if (validate) {
          this.updateBasicData();
          this.isLoadingData = false;
          this.isEdit = false;
          this.isCheckChange = false;
        } else {
          getFieldsNameUnvalidated(form).forEach((errorKey) => {
            addNotification(
              this.errorMapBasic[errorKey].title,
              this.errorMapBasic[errorKey].title,
              this.errorMapBasic[errorKey].error,
              "error",
              5000
            );
          });
        }
      });
    },
    openEdit() {
      this.isEdit = true;
    },
  },
  mounted() {
    this.isNew && this.openEdit();
  },
};
</script>

<style lang="sass" scoped>
.select
  width: 100%
.avatar
  height: 100%
  border-radius: 50%
.replace-photo
  color: var(--btn-blue-color)
.form-wrap
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: repeat(1, 1fr)
  @media(max-width: 1440px)
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(2, 1fr)
.label-field
  min-width: 110px
  color: var(--font-grey-color)
</style>
