<template lang="pug">
  medical-form-wrapper(
    :title="data.title",
    :is-edit="isEdit",
    :open-edit="openEdit",
    :cancel="cancelEdit",
    :no-top-buttons="noTopButtons"
     )
    .flex.flex-col.gap-y-4
      .flex.gap-x-7px.rounded.py-7px.px-3.w-fit(:style="{'backgroundColor': isEdit ? 'var(--default-white)': 'var(--bg-light-grey)'}")
        q-checkbox(
          v-model="protocol[data.state].lacticBite",
          dense,
          size="38px",
          checked-icon="check_box",
          unchecked-icon="check_box_outline_blank",
          :disable="!isEdit",
          :color="isEdit ? 'primary' : 'grey'"
          )
        span.font-medium.text-smm Молочный прикус
      .flex.flex-col
        tooth-formula-table(
          v-for="config in data.formulaConfig",
          :filtred-tooths="filtredTooths"
          :key="`table_${config.name}`", 
          :config="config", 
          :data="protocol[data.state]", 
          :form-config="data.conditionsForm",
          :is-edit="isEdit",
          :view-type="isShowSecondView",
          )
      formula-filter(
        v-model="filtredTooths",
        :data="protocol[data.state]",
        )
    .toogle.flex.flex-toogle.h-10.p-1.rounded.absolute
      q-btn.w-12.h-full.rounded(
        size="12px",
        :style="{'backgroundColor': !isShowSecondView ? 'var(--btn-blue-color)' : 'var(--bg-light-grey)'}",
        @click="()=>{ isShowSecondView=false }",
      )
        q-icon.tooth(:class="{'tooth-active': !isShowSecondView}", name="app:tooth-outline", size="24px")
      q-btn.w-12.h-full.rounded(
        size="12px",
        :style="{'backgroundColor': isShowSecondView ? 'var(--btn-blue-color)' : 'var(--bg-light-grey)'}",
        @click="()=>{isShowSecondView=true }",
      )
        q-icon.tooth(:class="{'tooth-active': isShowSecondView}", name="app:tooth", size="22px")
</template>

<script>
import FormulaFilter from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/FormulaFilter.vue";
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import ToothFormulaTable from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/ToothFormulaTable.vue";
import teeth from "@/assets/icons/teeth.svg";
import teeth_2 from "@/assets/icons/teeth-2.svg";
import { mapState } from "vuex";

export default {
  name: "ToothFormulaForm",
  components: { MedicalFormWrapper, ToothFormulaTable, FormulaFilter },
  data() {
    return {
      teeth,
      teeth_2,
      filtredTooths: [],
      isEdit: false,
      component: false,
      isShowSecondView: false,
    };
  },
  props: {
    isOutsideEdit: Boolean,
    noTopButtons: Boolean,
    data: Object,
    fillInspection: Boolean,
  },
  computed: {
    ...mapState({
      protocol: (state) => state.medical.protocolData,
    }),
  },
  methods: {
    openEdit() {
      this.isEdit = true;
      this.isShowSecondView = false;
    },
    cancelEdit() {
      this.isEdit = false;
      this.$emit("updateIsEdit", false);
    },
  },
  mounted() {
    this.filtredTooths = Object.keys(this.protocol[this.data.state].formula);
  },
  watch: {
    isOutsideEdit: {
      immediate: true,
      handler(value) {
        this.isEdit = value;
      },
    },
    fillInspection: {
      immediate: true,
      handler(value) {
        if (value) {
          this.isEdit = true;
        } else {
          this.isEdit = false;
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.toogle
  background-color: var(--bg-light-grey)
  top: 16px
  right: 24px

.tooth :deep(path)
  fill: var(--font-grey-color)

.tooth-active :deep(path)
  fill: var(--default-white)
</style>
