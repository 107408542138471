<template lang="pug">
  .flex.w-full.my-4(
    :class="{'schedule-select': selectEmployee.label}"
  )
    .edit.flex.items-center.justify-center.h-11.w-11(v-if="selectEmployee.label")
      .flex.icon-edit
    .flex.justify-center.items-center(
      :class="{'select-name': selectEmployee.label}",
      :style="{color: 'var(--btn-blue-color)'}"
    )
      // TODO: какая-то странная хуйня с v-model, передается пропса
      base-select(
        v-if="!selectEmployee.label",
        :items="ownersList",
        v-model="selectEmployee",
        placeholder="Добавить сотрудника",
        size="M"
      )
      .flex.justify-center(v-else) {{selectEmployee.label}}
    .row.flex
      .cell.flex.flex-col.items-center.justify-center.w-11.h-11.cursor-pointer(
        v-if="selectEmployee.label",
        v-for="day in result",
        :key="day",
        :id="selectEmployee.id + day",
        @click="choiceCell(day, selectEmployee.id)",
        :class="choiceState(day, selectEmployee.id)",
        :style="{width: `calc(100% / ${result.length})`}"
      )
</template>

<script>
import BaseSelect from "@/components/base/BaseSelect.vue";

export default {
  name: "ScheduleTableSelect",
  components: { BaseSelect },
  props: {
    result: Array,
    employees: Array,
    choiceCell: Function,
    choiceState: Function,
    trimOwnerName: Function,
    selectEmployee: Object,
  },
  computed: {
    ownersList() {
      if (this.employees) {
        let filteredArray = [];
        this.employees.forEach((elem) => {
          filteredArray.push({
            id: elem.id,
            label: this.trimOwnerName(
              elem.last_name,
              elem.first_name,
              elem.patronymic
            ),
          });
        });
        return filteredArray;
      }
      return [];
    },
  },
};
</script>

<style lang="sass" scoped>
.edit
  border-bottom: 1.5px solid var(--border-light-grey-color-1)
  border-left: 1.5px solid var(--border-light-grey-color-1)
  border-right: 1.5px solid var(--border-light-grey-color-1)

.schedule-select
  border-right: 8px solid var(--bg-ligth-blue-color)
  border-top: 1.5px solid var(--border-light-grey-color-1)

.select-name
  width: 25%
  border-right: 1.5px solid var(--border-light-grey-color-1)
  border-bottom: 1.5px solid var(--border-light-grey-color-1)

.row
  width: calc(75% - 44px)

.cell
  border-right: 1.5px solid var(--border-light-grey-color-1)
  border-bottom: 1.5px solid var(--border-light-grey-color-1)
  &:hover
    background-color: var(--border-light-grey-color-1)
  &:last-child
    border-right: none

.status
  background-color: var(--bg-color-status) !important

.status::after
  content: var(--text-status)

.set-date
  background-color: var(--btn-blue-color-4) !important

.set-template
  background-color: var(--bg-event-orange-color) !important

.set-template::after
  content: var(--text-status)
</style>
