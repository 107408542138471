<template lang="pug">
  q-dialog(
    v-model="value",
    ref="dialog"
    )
    .flex.justify-center.items-center.absolut.w-screen.h-screen.pointer-events-none
      .wrapper.flex.relative.rounded-2xl(:style="{color: 'var(--default-white)'}")
        .flex.absolute(:style="{color: 'var(--default-white)'}")
        img.rounded-2xl.w-full.h-full(:src="picture?.photo || initPicture?.photo")
        q-btn.btn.right(
          @click="closeModal"
          round,
          padding="15px"
          )
          q-icon(name="app:cancel" size="18px")
        q-btn.btn.right.print(
          round,
          padding="12px"
          )
          q-icon(name="print" size="24px")
        q-btn.btn.right.download(
          round,
          padding="13px 14px 15px 14px"
          )
          q-icon(name="app:download" size="20px")
        q-btn.btn.right.arrow(
          @click="() => switchPictures('next')",
          v-if="indexPicture !== listPictures.length-1",
          round,
          padding="12px 11px 12px 13px"
          )
          q-icon(name="navigate_next" size="24px")
        q-btn.btn.left.arrow(
          @click="() => switchPictures()",
          v-if="indexPicture !== 0",
          round,
          padding="12px 13px 12px 11px"
          )
          q-icon.rotate-180(name="navigate_next" size="24px")          
</template>

<script>
import { v_model } from "@/shared/mixins/v-model";
import BaseModal from "@/components/base/BaseModal.vue";

export default {
  name: "BaseModalShowingPicture",
  components: { BaseModal },
  mixins: [v_model],
  data() {
    return {
      indexPicture: 0,
      picture: {},
    };
  },
  props: {
    initPicture: Object,
    listPictures: Array,
  },
  methods: {
    closeModal() {
      this.value = false;
    },

    switchPictures(direction) {
      if (direction === "next") {
        this.indexPicture += 1;
      } else {
        this.indexPicture -= 1;
      }
      this.picture = this.listPictures[this.indexPicture];
    },
  },
  updated() {
    this.listPictures?.forEach((el, index) => {
      if (el.id === this.initPicture?.id) {
        this.indexPicture = index;
      }
    });
    this.picture = this.initPicture;
    const backdropEl = this.$refs.dialog?.contentEl?.previousSibling;
    if (!backdropEl) return;
    if (!this.hideOverlay) {
      backdropEl.style.background = "rgba(37, 40, 80, 0.2)";
      backdropEl.style.backdropFilter = "blur(4px)";
    } else backdropEl.style.background = "rgba(0,0,0,0)";
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  height: 785px
  width: 640px
  box-shadow: 4px 4px 8px rgba(9, 10, 21, 0.1), -4px -4px 8px rgba(9, 10, 21, 0.1)
.btn
  background: var(--font-dark-blue-color-2)
  position: absolute
.right
  right: -64px
.left
  left: -64px
.print
  top: 72px
.download
  top: 128px
.arrow
  top: 344px
</style>
