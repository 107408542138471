export const searchListConfig = [
  {
    title: "Медкарта",
    minWidth: "140px",
  },
  {
    title: "ФИО",
    width: "100%",
    minWidth: "350px",
  },
  {
    title: "Дата рождения",
    minWidth: "220px",
  },
  {
    title: "Телефон",
    minWidth: "220px",
  },
];
