import {
  ruleLengthValue,
  ruleEmailValue,
  ruleMaxLength,
  ruleNotValue,
  ruleDateValue,
  ruleOptionalFields,
} from "@/shared/utils/rulesInputs";
import pdfIcon from "@/assets/icons/pdf.svg";
import wordIcon from "@/assets/icons/word.svg";
import exelIcon from "@/assets/icons/exel.svg";

export const baseDataForm = [
  {
    dataLabel: "Личные данные",
    dataKey: "personalData",
    fields: [
      {
        key: "last_name",
        label: "Фамилия",
        type: "text",
        disabled: true,
      },
      {
        key: "first_name",
        label: "Имя",
        type: "text",
        disabled: true,
      },
      {
        key: "patronymic",
        label: "Отчество",
        type: "text",
        disabled: true,
      },
      {
        key: "gender",
        label: "Пол",
        type: "select",
        disabled: true,
      },
      {
        key: "birth_date",
        label: "Дата рождения",
        type: "date",
        disabled: true,
      },
      {
        key: "photo",
        label: "Фото",
        type: "avatar",
        disabled: true,
      },
    ],
  },
  {
    dataLabel: "Адрес регистрации",
    dataKey: "registrationAddress",
    fields: [
      {
        key: "region",
        label: "Регион",
        type: "text",
        rules: [(val) => ruleNotValue(val)],
        error: "Поле 'Регион' должны быть заполнены",
      },
      {
        key: "city",
        label: "Город",
        type: "text",
        rules: [(val) => ruleNotValue(val)],
        error: "Поле 'Город' должны быть заполнены",
      },
      {
        key: "street",
        label: "Улица",
        type: "text",
        rules: [(val) => ruleNotValue(val)],
        error: "Поле 'Улица' должны быть заполнены",
      },
      {
        key: "house",
        label: "Дом",
        type: "text",
        rules: [(val) => ruleNotValue(val)],
        error:
          "Номер дома должен начинатся не с нуля и только с цифробуквенного символа",
      },
      {
        key: "flat",
        label: "Квартира",
        type: "text",
        rules: [(val) => !/^[\W0]/.test(val)],
        error:
          "Номер квартиры должен начинатся не с нуля и только с цифробуквенного символа",
      },
    ],
  },
  {
    dataLabel: "Адрес проживания",
    dataKey: "residenceAddress",
    fields: [
      {
        key: "region",
        label: "Регион",
        type: "text",
      },
      {
        key: "city",
        label: "Город",
        type: "text",
      },
      {
        key: "street",
        label: "Улица",
        type: "text",
      },
      {
        key: "house",
        label: "Дом",
        type: "text",
        rules: [(val) => !/^[\W0]/.test(val)],
        error:
          "Номер дома должен начинатся не с нуля и только с цифробуквенного символа",
      },
      {
        key: "flat",
        label: "Квартира",
        type: "text",
        rules: [(val) => !/^[\W0]/.test(val)],
        error:
          "Номер квартиры должен начинатся не с нуля и только с цифробуквенного символа",
      },
    ],
  },
];

export const errorMap = {
  house: {
    title: "Дом",
    error:
      "Номер дома должен начинатся не с нуля и только с цифробуквенного символа",
  },
  flat: {
    title: "Квартира",
    error:
      "Номер квартиры должен начинатся не с нуля и только с цифробуквенного символа",
  },
};

export const mapNetworks = {
  VK: {
    id: "VK",
    title: "VK",
    icon: "app:vk",
    network: "VK",
  },
  TELEGRAM: {
    id: "TELEGRAM",
    title: "Telegram",
    icon: "app:tg",
    network: "TELEGRAM",
  },
  GMAIL: {
    id: "GMAIL",
    title: "Gmail",
    icon: "app:google",
    network: "GMAIL",
  },
  SLACK: {
    id: "SLACK",
    title: "Slack",
    icon: "app:slack",
    network: "SLACK",
    markerLink: "slack",
  },
  DISCORD: {
    id: "DISCORD",
    title: "Discord",
    icon: "app:discord",
    network: "DISCORD",
  },
  VIBER: {
    id: "VIBER",
    title: "Viber",
    icon: "wifi_calling_3",
    network: "VIBER",
  },
  WHATS_APP: {
    id: "WHATS_APP",
    title: "WhatsApp",
    icon: "call",
    network: "WHATS_APP",
  },
};

export const contactsDataForm = {
  phones: {
    title: "Телефон",
    fieldName: "Телефон",
    error:
      "Не корректно введен номер телефона. Номер телефона должен состоять из 10 цифр (без учета +7)",
    category: "PHONE",
    rules: [(val) => ruleLengthValue(val, 18)],
    inputMask: "+7 (###) ###-##-##",
  },
  emails: {
    title: "Почта",
    fieldName: "Email",
    error:
      "Не корректно введен адрес почты. Почта должна содержать локальное имя, знак @ и имя домена",
    rules: [(val) => ruleEmailValue(val)],
    category: "EMAIL",
  },
  networks: {
    title: "Социальные сети",
    fieldName: "Соцсеть",
    error: "Отсутсвует ссылка соцсети",
    rules: [(val) => ruleNotValue(val)],
    category: "TELEGRAM",
  },
};

export const allergiesConfig = {
  name: {
    title: "Аллерген",
    rules: [(val) => ruleMaxLength(val, 100)],
    error:
      "Некорректно введен аллерген. Значение должно быть больше 1 и меньше 50 символов",
  },
  title: {
    title: "Проявление аллергии",
    rules: [(val) => ruleMaxLength(val, 100)],
    error:
      "Некорректно введено проявление аллергии. Значение должно быть больше 1 и меньше 100 символов",
  },
};

export const confidantConfig = [
  {
    dataLabel: "Общая информация",
    fields: [
      {
        key: "last_name",
        label: "Фамилия",
        error: "Поля с ФИО должны быть заполнены",
        rules: [(val) => ruleNotValue(val)],
      },
      {
        key: "first_name",
        label: "Имя",
        error: "Поля с ФИО должны быть заполнены",
        rules: [(val) => ruleNotValue(val)],
      },
      {
        key: "patronymic",
        label: "Отчество",
        error: "Поля с ФИО должны быть заполнены",
        rules: [(val) => ruleNotValue(val)],
      },
    ],
  },
  {
    dataLabel: "Контакты",
    fields: [
      {
        key: "phone",
        label: "Телефон",
        mask: "+7 (###) ###-##-##",
        error:
          "Не корректно введен номер телефона. Номер телефона должен состоять из 10 цифр (без учета +7)",
        rules: [(val) => ruleLengthValue(val, 18)],
      },
      {
        key: "email",
        label: "Почта",
        error:
          "Не корректно введен адрес почты. Почта должна содержать локальное имя, знак @ и имя домена",
        rules: [(val) => ruleEmailValue(val)],
      },
      {
        key: "networks",
        label: "Соцсеть",
        rules: [(val) => ruleNotValue(val)],
      },
    ],
  },
  {
    dataLabel: "Дополнения",
    fields: [
      {
        key: "note",
        label: "Информация",
        type: "textarea",
        autogrow: true,
        placeholder: "Нет информации",
      },
    ],
  },
];

export const genderOptions = [
  {
    id: "MALE",
    label: "Мужской",
  },
  {
    id: "WOMEN",
    label: "Женский",
  },
];

export const headerMenuItem = [
  {
    title: "Общая информация",
    id: "basic",
    component: "MedicalBaseInfo",
  },
  {
    title: "Журнал посещений",
    id: "session-log",
    component: "SessionLog",
  },
  {
    title: "Планы лечения",
    id: "treatment-plans",
    component: "TreatmentPlans",
  },
  {
    title: "Статистика",
    id: "statistics",
    component: "Statistics",
  },
  {
    title: "История контактов",
    id: "contact-history",
    component: "ContactHistory",
  },
];

export const baseInfoMenu = [
  {
    title: "Основные данные",
    id: "basic-data",
    component: "MedicalBasicDataWrapper",
  },
  {
    title: "ППО",
    hint: "Протоколы первичного осмотра",
    id: "protocols",
    component: "MedicalProtocolsWrapper",
  },
  {
    title: "Доверенное лицо",
    id: "confidant",
    component: "MedicalConfidantWrapper",
  },
  {
    title: "Аллергии",
    id: "allergies",
    component: "MedicalAllergiesWrapper",
  },
  {
    title: "Состояние здоровья",
    id: "health-status",
    component: "MedicalHealthStateWrapper",
  },
  {
    title: "История болезней",
    id: "medical-history",
    component: "MedicalHistory",
  },
  {
    title: "Зубные формулы",
    id: "dental-formula",
    component: "MedicalDentalFormulasWrapper",
  },
];

export const baseInsuranceForm = [
  {
    insuranceLabel: "ОМС",
    insuranceKey: "OMS",
    fields: [
      {
        key: "series",
        label: "Серия",
        type: "text",
        rules: (val) => ruleNotValue(val),
        errorMessage: {
          title: "Ошибка валидации",
          message: "Серия полиса ОМС не заполнена",
        },
      },
      {
        key: "number",
        label: "Номер",
        type: "text",
        rules: (val) => ruleNotValue(val),
        errorMessage: {
          title: "Ошибка валидации",
          message: "Номер полиса ОМС не заполнен",
        },
      },
      {
        key: "attachments",
        label: "Фото ОМС",
        type: "photo",
      },
    ],
  },
  {
    insuranceLabel: "ДМС",
    insuranceKey: "DMS",
    fields: [
      {
        key: "series",
        label: "Серия",
        type: "text",
        rules: (val) => ruleNotValue(val),
        errorMessage: {
          title: "Ошибка валидации",
          message: "Серия полиса ДМС не заполнена",
        },
      },
      {
        key: "number",
        label: "Номер",
        type: "text",
        rules: (val) => ruleNotValue(val),
        errorMessage: {
          title: "Ошибка валидации",
          message: "Номер полиса ДМС не заполнен",
        },
      },
      {
        key: "attachments",
        label: "Фото ДМС",
        type: "photo",
      },
    ],
  },
  {
    insuranceLabel: "Категория льготы",
    insuranceKey: "benefit",
    discount: "discount",
    fields: [
      {
        key: "name",
        label: "Категория",
        type: "select",
      },
      {
        key: "photo",
        label: "Документы",
        type: "photo",
      },
    ],
  },
];
export const routesDictionary = {
  "/clients": "Клиенты",
  "/calendar": "Календарь",
  "/medical-card": "Медкарта",
};

export const documentForm = [
  {
    dataLabel: "Паспорт",
    dataKey: "passport",
    dataClass: "passport",
    fields: [
      {
        key: "series",
        label: "Серия",
        type: "text",
        inputMask: "####",
        errorMessage: {
          title: "Ошибка валидации",
          message: "Серия паспорта менее 4 цифр или не заполнена",
        },
        rules: (val) => ruleLengthValue(val, 4),
      },
      {
        key: "number",
        label: "Номер",
        type: "text",
        inputMask: "######",
        errorMessage: {
          title: "Ошибка валидации",
          message: "Номер паспорта менее 6 цифр или не заполнен",
        },
        rules: (val) => ruleLengthValue(val, 6),
      },
      {
        key: "issued_by",
        label: "Кем выдан",
        type: "text",
        errorMessage: {
          title: "Ошибка валидации",
          message: "Поле 'Кем выдан' не заполнено",
        },
        rules: (val) => ruleNotValue(val),
      },
      {
        key: "issued_by_org_code",
        label: "Код подраздел.",
        type: "text",
        inputMask: "###-###",
        errorMessage: {
          title: "Ошибка валидации",
          message: "Код подразделения менее 6 цифр или не заполнен",
        },
        rules: (val) => ruleLengthValue(val, 7),
      },
      {
        key: "issued_at",
        label: "Дата выдачи",
        type: "date",
        rules: (val) => ruleDateValue(val),
        errorMessage: {
          title: "Ошибка валидации",
          message: "Дата выдачи паспорта позднее текущего дня или не заполнена",
        },
      },
      {
        key: "attachments",
        label: "Фото паспорта",
        type: "photo",
      },
    ],
  },
  {
    dataLabel: "СНИЛС",
    dataKey: "insurance_number",
    dataClass: "insurance",
    fields: [
      {
        key: "number",
        label: "Номер СНИЛС",
        type: "text",
        inputMask: "###-###-### ##",
        errorMessage: {
          title: "Ошибка валидации",
          message: "СНИЛС содержит менее 11 цифр",
        },
        rules: (val, id) => ruleOptionalFields(val, 14, id),
      },
      {
        key: "attachments",
        label: "Фото СНИЛС",
        type: "photo",
      },
    ],
  },
  {
    dataLabel: "ИНН",
    dataKey: "tax_identification_number",
    dataClass: "tax",
    fields: [
      {
        key: "number",
        label: "Номер ИНН",
        type: "text",
        inputMask: "############",
        errorMessage: {
          title: "Ошибка валидации",
          message: "ИНН содержит менее 12 цифр",
        },
        rules: (val, id) => ruleOptionalFields(val, 12, id),
      },
      {
        key: "attachments",
        label: "Фото ИНН",
        type: "photo",
      },
    ],
  },
];

export const mapEventStatus = {
  COMPLETED: {
    title: "Принят",
    color: "#55CD76",
  },
  CANCELED: {
    title: "Отказ",
    color: "#FF6F6F",
  },
  RECEPTION: {
    title: "На приеме",
    color: "#FFC75B",
  },
  PLANNED: {
    title: "Не принят",
    color: "#9294A7",
  },
};

export const toothConditions = {
  К: "Кариес",
  П: "Пульпит",
  Пт: "Периодонтит",
  Д: "Дефект",
  Пл: "Пломба",
  ИК: "Искусственная коронка",
  ИЗ: "Искусственный зуб",
  В: "Вкладка",
  И: "Имплант",
  КЗ: "Корень зуба",
  О: "Отсутствующий зуб",
};

export const toothMobility = [
  { id: 1, label: "I" },
  { id: 2, label: "II" },
  { id: 3, label: "III" },
  { id: 4, label: "IV" },
];

export const formulaFilterMap = [
  ["Все", "К", "П"],
  ["Д", "Пл", "ИК"],
  ["ИЗ", "В", "И"],
  ["КЗ", "О", "Пт"],
];

export const protocolForms = [
  {
    title: "Жалобы",
    component: "MedicalFormTag",
    key: "complaints",
    state: "complaints",
    results: {
      tags: [
        "Боль при приеме сладкой пищи",
        "Жжение языка",
        "Кровоточивость десен",
        "Лечение у ортодонта не проводилось",
        "Ранее зубы лечили в ЛПУ",
        "Удаление зуба",
      ],
    },
  },
  {
    title: "Аллергологический анамнез",
    component: "MedicalFormTag",
    key: "anamnesis",
    state: "anamnesis",
    results: {
      tags: [
        "Ребенок на боль в зубах не жаловался",
        "Ранее зубы лечили в ЛПУ",
        "Лечение у ортодонта не проводилось",
        "Налет появился год назад",
        "Ребенок отказывается от еды",
      ],
    },
  },
  {
    title: "Перенесенные и сопутствующие заболевания",
    component: "MedicalFormTag",
    key: "illnesses",
    state: "illnesses",
    results: {
      tags: [
        "Стоматит в 2022",
        "Абсцесс Броди",
        "Абсцесс мягких тканей",
        "Абсцесс печени",
        "Авитаминоз",
      ],
    },
  },
  {
    title: "Принимаемые лекарственные препараты",
    component: "MedicalFormTag",
    key: "medications",
    state: "medications",
    results: {
      tags: [
        "Цитрамон",
        "Нафтизин",
        "А-Пар ",
        "А-Церумен",
        "Абаджио",
        "Багомед",
        "Багомед плюс",
      ],
    },
  },
  {
    title: "Термометрия (по показаниям)",
    component: "MainFactorsForm",
    key: "thermometry",
    state: "thermometry",
    config: {
      cold: {
        step: 1,
        title: "Реакция на холод",
        label: "Температура",
        shadowText: "º",
        max: 25,
        min: -40,
        color: "var(--font-cold-color)",
        bg: "var(--bg-cold-color)",
        icon: "ac_unit",
        colorWarning: (val) =>
          thermometryResultsMap.find((el) => el.value === val)?.colorWarning,
      },
      heat: {
        step: 1,
        title: "Реакция на тепло",
        label: "Температура",
        shadowText: "º",
        max: 100,
        min: 45,
        color: "var(--font-heat-color)",
        bg: "var(--bg-heat-color)",
        icon: "local_fire_department",
        colorWarning: (val) =>
          thermometryResultsMap.find((el) => el.value === val)?.colorWarning,
      },
    },
  },
  {
    title: "Тонометрия, ЧСС (по показаниям)",
    component: "MainFactorsForm",
    key: "tonometry",
    state: "tonometry",
    config: {
      pulse: {
        step: 1,
        label: "Пульс",
        shadowText: "уд/мин",
        max: 140,
        min: 30,
        colorWarning: (val) => {
          if (!val) return val;
          if (val >= 80 && val < 110) {
            return "var(--bg-yellow-warning)";
          }
          if ((0 <= val && val <= 60) || val >= 110) {
            return "var(--border-red-color)";
          }
        },
      },
      pressure: {
        step: 1,
        label: "Артериальное давление",
        shadowText: "мм.рт.ст.",
        max: 180,
        min: 40,
        colorWarning: (val) => {
          if (!val) return val;
          if ((val >= 130 && val < 140) || (val >= 100 && val < 110)) {
            return "var(--bg-yellow-warning)";
          }
          if (val >= 140 || val < 100) {
            return "var(--border-red-color)";
          }
        },
      },
    },
  },
  {
    title: "Развитие настоящего заболевания",
    component: "DiseaseForm",
    key: "disease",
    state: "disease",
    results: {
      checked: false,
      text: "Пациенту 20 лет. Неделю назад металлокерамический мостовидный протез с опорами на зубы 4.3 и 4.6 зафиксирован на временный цемент. Во время ортопедического лечения интактные зубы 4.3 и 4.6 были препарированы под местной анестезией, после препарирования зубы были защищены временным пластмассовым мостовидным протезом, боли в это время пациент не ощущал. Ноющая боль появилась на второй день после фиксации металлокерамического зубного протеза на временный цемент, интенсивность боли постепенно нарастает, усиливается от холодного. Пациент точно указывает, что боль локализуется в области зуба 4.3",
    },
  },
  {
    title: "Внешний осмотр",
    component: "MedicalFormTag",
    key: "inspection",
    state: "inspection",
    results: {
      tags: [
        "Без особенностей",
        "Гематома на щеке",
        "Киста в глазу",
        "Красные пятна на языке",
        "Фингал под глазом",
      ],
    },
  },
  {
    title: "Осмотр полости рта",
    component: "MedicalFormTag",
    key: "cavity",
    state: "cavity",
    results: {
      tags: [
        "Кариес",
        "Отек десны",
        "2 искусственных зуба",
        "Без особенностей",
        "Громадная язва",
        "Кариес",
      ],
    },
  },
  {
    title: "Зубная формула",
    component: "ToothFormulaForm",
    key: "toothFormula",
    state: "toothFormula",
    conditionsForm: {
      conditions: {
        general: {
          options: (() => {
            let arrayOptions = ["ИЗ", "И", "О", "П", "ИК", "В"];
            return arrayOptions.map((item) => ({
              value: item,
              label: toothConditions[item],
            }));
          })(),
          label: "Общее",
        },
        crown: {
          options: (() => {
            let arrayOptions = ["К", "П", "Д", "Пл"];
            return arrayOptions.map((item) => ({
              value: item,
              label: toothConditions[item],
            }));
          })(),
          label: "Коронка",
          partsTooth: {
            central: {
              label: "Центральная часть",
            },
            upper: {
              label: "Верхняя часть",
            },
            lower: {
              label: "Нижняя часть",
            },
            left: {
              label: "Левая часть",
            },
            right: {
              label: "Правая часть",
            },
          },
        },
        root: {
          options: (() => {
            let arrayOptions = ["К", "П", "Д", "Пл", "КЗ", "Пт"];
            return arrayOptions.map((item) => ({
              value: item,
              label: toothConditions[item],
            }));
          })(),
          label: "Корень",
          partsTooth: {
            central: {
              label: "Центральная часть",
            },
            left: {
              label: "Левая часть",
            },
            right: {
              label: "Правая часть",
            },
          },
        },
      },
    },
    formulaConfig: [
      {
        name: "upper",
        rowMap: [
          {
            label: "Подвижность зубов",
            key: "tooth_mobility",
          },
          {
            label: "Cостояние зубов",
            key: "dental_condition",
          },
          {
            label: "Верхняя челюсть",
            key: "tooth_number",
          },
        ],
        columns: [
          {
            type: "molars",
            tooth_number: "18",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "molars",
            tooth_number: "17",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "molars",
            tooth_number: "16",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "left",
            type: "pre-molars",
            tooth_number: "15",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "left",
            type: "pre-molars",
            tooth_number: "14",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "left",
            type: "fangs",
            tooth_number: "13",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "incisors",
            tooth_number: "12",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "incisors",
            tooth_number: "11",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "incisors",
            tooth_number: "21",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "incisors",
            tooth_number: "22",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "right",
            type: "fangs",
            tooth_number: "23",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "right",
            type: "pre-molars",
            tooth_number: "24",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "right",
            type: "pre-molars",
            tooth_number: "25",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "molars",
            tooth_number: "26",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "molars",
            tooth_number: "27",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "molars",
            tooth_number: "28",
            dental_condition: "",
            tooth_mobility: "",
          },
        ],
      },
      {
        name: "lower",
        rowMap: [
          {
            label: "Нижняя челюсть",
            key: "tooth_number",
          },
          {
            label: "Cостояние зубов",
            key: "dental_condition",
          },
          {
            label: "Подвижность зубов",
            key: "tooth_mobility",
          },
        ],
        columns: [
          {
            type: "molars",
            tooth_number: "48",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "molars",
            tooth_number: "47",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "molars",
            tooth_number: "46",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "left",
            type: "pre-molars",
            tooth_number: "45",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "left",
            type: "pre-molars",
            tooth_number: "44",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "left",
            type: "fangs",
            tooth_number: "43",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "incisors",
            tooth_number: "42",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "incisors",
            tooth_number: "41",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "incisors",
            tooth_number: "31",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "incisors",
            tooth_number: "32",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "right",
            type: "fangs",
            tooth_number: "33",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "right",
            type: "pre-molars",
            tooth_number: "34",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            position: "right",
            type: "pre-molars",
            tooth_number: "35",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "molars",
            tooth_number: "36",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "molars",
            tooth_number: "37",
            dental_condition: "",
            tooth_mobility: "",
          },
          {
            type: "molars",
            tooth_number: "38",
            dental_condition: "",
            tooth_mobility: "",
          },
        ],
      },
    ],
  },
  {
    title: "Прикус",
    component: "IndexForm",
    key: "bite",
    state: "bite",
    content: {
      reference: `Прикус считается неправильным при аномалиях в расположении 
        зубов и нарушениях окклюзии, в результате которых нарушаются функции 
        зубочелюстной системы. Все виды нарушения прикуса в большей или меньшей 
        степени приводят к разрушению и потере зубов, нарушениям ВНЧС 
        (височно-нижнечелюстного сустава), а также асимметрии лица.`,
      whitespace: false,
      list: [
        {
          label: "Ортогнатический прикус",
          readOnlyLabel: "Нормальный – Ортогнатический",
          value: "ortognatic bite",
          reference: `Ортогнатический пркус – при смыкании зубы верхней челюсти 
            контактируют с одноименными нижними. Идеальным считается расположение 
            зубов, при котором верхние резцы перекрывают нижние на 1/3`,
        },
        {
          label: "Дистальный прикус",
          readOnlyLabel: "Дистальный",
          value: "distal bite",
          reference: `Дистальный прикус – верхняя челюсть выдается вперед. Верхние резцы
            могут быть наклонены вперед, к губам, или назад, внутрь ротовой полости.
            Часто между передними зубами верхней и нижней челюстей отсутствует
            контакт, образуется сагиттальная щель и дизокклюзия (отсутствие смыкания).
            Такой вид прикуса зубов визуально проявляется в скошенном профиле и верхней
            губе, нависающей над нижней; может формироваться «второй подбородок».`,
        },
        {
          label: "Мезиальный прикус",
          readOnlyLabel: "Мезиальный",
          value: "mesial bite",
          reference: `Мезиальный прикус – нижняя челюсть выдается вперед. Здесь 
            так же может наблюдаться сагиттальная щель и отсутствовать окклюзия, 
            а может быть лишь незначительное перекрытие верхних зубов нижними. 
            Внешними признаками мезиального прикуса являются выдвинутая нижняя 
            челюсть и массивный подбородок; нижняя губа, как правило, увеличена.`,
        },
        {
          label: "Глубокий прикус",
          readOnlyLabel: "Глубокий",
          value: "deep bite",
          reference: `Глубокий прикус – нижние резцы скрыты верхними на 50% 
            или больше. В зависимости от стадии, при глубоком виде прикуса у 
            взрослых может полностью отсутствовать окклюзия и развиваться воспаление 
            тканей верхнего неба из-за травмирования нижними зубами. При глубоком прикусе 
            распространена так называемая десневая улыбка, нижняя треть лица выглядит 
            негармонично маленькой, а подбородок скошенным.`,
        },
        {
          label: "Открытый прикус",
          readOnlyLabel: "Открытый",
          value: "open bite",
          reference: `Открытый прикус – при смыкании челюстей контакта между 
            верхними и нижними зубами нет. Такой вид прикуса вызывает значительные 
            нарушения жевательных и речевых функций; лицевые мышцы обычно 
            перенапряжены, а нижняя часть лица удлинена.`,
        },
        {
          label: "Перекрестный прикус",
          readOnlyLabel: "Перекрестный",
          value: "cross bite",
          reference: `Перекрестный прикус – асимметрия челюстей по вертикали; 
            при смыкании наблюдается пересечение зубных рядов. Развивается при 
            недоразвитии одной из челюстей и приводит к заметной асимметрии лица.`,
        },
      ],
    },
  },
  {
    title: "Индекс гигиены",
    component: "IndexForm",
    key: "hygieneIndex",
    state: "hygieneIndex",
    content: {
      reference: `0 — отсутствие зубного налета, камня;
      1  — налет покрывает треть поверхности зуба, зубной камень локализуется над деснами;
      2 — налет поражает до 2/3 зуба, отмечается поддесневой камень;
      3 — налет покрывает более 2/3 поверхности, поддесневой камень поражает шейки зубов.`,
      whitespace: true,
      list: [
        {
          label: "0",
          value: 0,
        },
        {
          label: "1",
          value: 1,
        },
        {
          label: "2",
          value: 2,
          icon: true,
          color: "var(--bg-event-orange-color)",
        },
        {
          label: "3",
          value: 3,
          icon: true,
          color: "var(--border-red-color)",
        },
      ],
    },
  },
  {
    title: "Индекс КПУ",
    component: "IndexForm",
    key: "KPUIndex",
    state: "KPUIndex",
    content: {
      reference: `Индекс КПУ в зависимости от стоматологических проблем:
        от 0 до 2 – слабая интенсивность;
        от 3 до 5 – средняя;
        от 6 до 10 – высокая.`,
      whitespace: true,
    },
    rules: [(val) => val <= 10 && val >= 0],
    checkRangeColor: (val) => {
      let roundedVal = parseInt(val);
      if (roundedVal >= 3 && roundedVal <= 5)
        return "var(--bg-event-orange-color)";
      if (roundedVal >= 6 && roundedVal <= 10) return "var(--border-red-color)";
    },
  },
  {
    title: "Предварительный диагноз",
    component: "DiseaseForm",
    key: "preliminary",
    state: "preliminary",
    results: {
      text: "Средний кариес 36-го зуба (II класс по Блэку)",
    },
  },
  {
    title: "План обследования",
    component: "MedicalFormTag",
    key: "survey",
    state: "survey",
    results: {
      tags: [
        "Общий анализ крови",
        "Консультация узкого специалиста",
        "2 искусственных зуба",
        "Биохимический анализ крови",
      ],
    },
  },
  {
    title: "Данные обследования",
    component: "DataForm",
    key: "data",
    state: "data",
    results: {
      tags: [
        "Ранее зубы лечили в ЛПУ",
        "Жжение языка",
        "Кровоточивость десен",
        "Лечение у ортодонта не проводилось",
        "Боль при приеме сладкой пищи",
        "Удаление зуба",
        "Кровоточивость языка",
      ],
    },
    iconDictionary: {
      doc: wordIcon,
      docx: wordIcon,
      xls: exelIcon,
      xlsx: exelIcon,
      odt: wordIcon,
      pdf: pdfIcon,
    },
  },
  {
    title: "Клинический диагноз",
    component: "ClinicalForm",
    key: "clinical",
    state: "clinical",
    results: {
      diseases: [
        { label: "В00.09", value: "Простой герпес" },
        {
          label: "В00.10",
          value: "Простой герпес лица",
        },
        {
          label: "В00.11",
          value: "Простой герпес губы",
        },
        {
          label: "В00.2Х",
          value: "Герпетический гингивостоматит",
        },
        {
          label: "В02.20",
          value: "Постгерпетическая невралгия тройничного нерва",
        },
        {
          label: "В02.21",
          value: "Постгерпетическая невралгия других черепных нервов",
        },
        {
          label: "В02.22",
          value: "Постгерпетическая невралгия двойного нерва",
        },
        {
          label: "В02.23",
          value: "Простой герпес нижней губы",
        },
      ],
    },
  },
];

export const thermometryResultsMap = [
  {
    value: "Нет реакции",
    colorWarning: "var(--border-red-color)",
  },
  {
    value: "Слабая реакция",
    colorWarning: "var(--bg-yellow-warning)",
  },
  {
    value: "Реакция нормальная",
  },
  {
    value: "Повышенная чувствительность",
    colorWarning: "var(--bg-yellow-warning)",
  },
  {
    value: "Непереносимость",
    colorWarning: "var(--border-red-color)",
  },
];

export const sizesDetailedSvg = {
  general: {
    native: {
      molars: {
        width: 37,
        height: 56,
      },
      "pre-molars": {
        width: 38,
        height: 59,
      },
      incisors: {
        width: 43,
        height: 63,
      },
      fangs: {
        width: 38,
        height: 59,
      },
    },
    artificial: {
      width: 43,
      height: 63,
    },
  },
  crown: {
    native: {
      width: 41,
      height: 40,
    },
    artificial: {
      width: 20,
      height: 20,
    },
  },
};
