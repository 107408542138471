<template lang="pug">
  .flex.w-full
    .flex.w-full.flex-col.gap-y-2(v-if="getProtocol")
      .header.h-fit.flex.w-full.py-5.pr-5.pl-6.rounded.justify-between.items-center
        span.text-xxl.font-bold {{`Зубная формула ${moment(getProtocol.start).format("YYYY")} года`}}
        .flex.gap-x-2
          q-btn.button(
            icon="app:edit",
            size="14px",
            padding="0",
            @click="openEdit",
          )
          q-btn.button(
            icon="print",
            size="20px",
            padding="0"
          )
          q-btn.button(
            icon="app:basket",
            size="14px",
            padding="0",
            @click="deleteFormula",
          )
      .wrapper-form.rounded.relative
        tooth-formula-form(
          :data="formulaConfig",
          no-top-buttons,
          :is-outside-edit="isEdit"
          @updateIsEdit="updateIsEdit",
          )
        .protocol-link.flex.absolute.gap-x-2.items-center.cursor-pointer(@click="goToProtocol")
          span.font-medium Перейти к протоколу первичного осмотра
          q-icon(
            name="east",
            size="24px"
            )
    .flex.w-full.h-full.items-center.justify-center(v-else)
      span.text-xxl.font-bold(:style="{color: 'var(--font-grey-color)'}") Выберите осмотр
</template>

<script>
import ToothFormulaForm from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/ToothFormulaForm.vue";
import moment from "moment";
import { protocolForms } from "@/pages/newMedicalCard/utils/medicalConfig.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "FormulasPageWrapper",
  components: { ToothFormulaForm },
  data() {
    return {
      moment,
      formulaConfig: protocolForms.find((el) => el.key === "toothFormula"),
      isEdit: false,
    };
  },
  props: {
    goToProtocol: Function,
    protocolId: Number,
  },
  computed: {
    ...mapState({
      protocolsList: (state) => state.medical.protocolsList,
    }),
    getProtocol() {
      return this.protocolsList.find((el) => el.id === this.protocolId);
    },
  },
  methods: {
    ...mapActions({
      deleteProtocolFormData: "deleteProtocolFormData",
    }),
    updateIsEdit(edit) {
      this.isEdit = edit;
    },
    openEdit() {
      this.isEdit = true;
    },
    deleteFormula() {
      this.deleteProtocolFormData({ key: "toothFormula" });
    },
  },
};
</script>

<style lang="sass" scoped>
.protocol-link
  bottom: 34px
  right: 24px
  color: var(--btn-blue-color)
  &:hover
    opacity: 0.7
.wrapper-form
  height: 606px
  background-color: var(--default-white)
.header
  background-color: var(--default-white)
.button
  width: 40px
  height: 40px
  border-radius: 4px
  background: var(--bg-light-grey)
  color: var(--font-grey-color)
</style>
