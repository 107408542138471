<template lang="pug">
  .sidebar-wrapper.h-full.rounded.px-2.py-6.flex.flex-col.gap-1
    .menu-item.text-base.px-4.py-3.rounded.cursor-pointer.whitespace-nowrap(
      v-for="item in menuItem",
      :key="item.id",
      :id="item.id",
      @click="selectItem(item)",
      :title="item.hint || item.title"
      :class="{'menu-item-active': item.component === modelValue}"
    ) {{item.title}}
</template>

<script>
import { v_model } from "@/shared/mixins/v-model";
import { baseInfoMenu } from "@/pages/newMedicalCard/utils/medicalConfig.js";
export default {
  name: "MedicalSidebar",
  mixins: [v_model],
  data() {
    return {
      menuItem: baseInfoMenu,
    };
  },
  updated() {
    this.$store.dispatch("returnInitData");
  },
  methods: {
    selectItem(item) {
      this.value = item?.component;
    },
  },
};
</script>
<style lang="sass" scoped>
.sidebar-wrapper
  min-width: 200px
  min-height: 350px
  background-color: var(--default-white)
.menu-item
  color: var(--font-grey-color)
.menu-item-active, .menu-item:hover
  color: var(--btn-blue-color)
  background-color: var(--bg-ligth-blue-color)
</style>
