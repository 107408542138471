<template lang="pug">
  q-menu(
    anchor="bottom left",
    :offset="[160,5]",
    @before-hide="closePopup"
    )
    .flex.flex-col.p-4.gap-15px.text-smm.font-medium
      .general.w-full.flex.justify-start.pb-15px
        span.opacity-60(:style="{'color': 'var(--font-dark-blue-color)'}") {{`${conditions[0]} – ${config[conditions[0]]}`}}
      span(v-for="condition in conditions.slice(1)") {{`${condition} – ${config[condition]}`}}
</template>

<script>
import { v_model } from "@/shared/mixins/v-model";

export default {
  name: "ToothConditionsInfo",
  props: {
    config: Object,
    conditions: Array,
    modelValue: Boolean,
    hideEvent: Function,
  },
  mixins: [v_model],
  methods: {
    closePopup() {
      this.value = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.general
  border-bottom: 1px solid var(--border-light-grey-color)
</style>
