<template lang="pug">
  .wrapper.flex.font-medium.flex-col.text-smm
    .header.flex.flex-col.gap-y-6
      .grey-color.text-smm.font-semibol {{"Медицинская карта: " + data.medicalNumber}}
      .flex.gap-x-4.w-full
        img.h-20.w-20.rounded-full(:src="data?.avatar")
        .flex.items-center.justify-between.w-full
          .info.flex.flex-col.gap-y-2
            .text-5xl.font-bold {{data.last_name + " " + data.first_name + " " + data.patronymic}}
            .flex.flex-col.text-m
              span {{data.birthday}}
              .flex.gap-x-2.items-center
                span Приоритет:
                .white-color.flex.grey-color.rounded.items-center.px-3.h-6.w-fit(
                  :class="selectedPriority"
                ) {{selectedPriorityName}}
          .circle.flex.rounded-full.items-center.justify-center(
            :style="{background: choiceFill(data.fill)}"
          )
            .flex.flex-col.items-center
              .text-5xl.font-bold {{data.fill}}
              .grey-color.text-xsx Заполнено
    .body.flex.px-8.py-6.flex-col.gap-y-4
      .flex.gap-x-4.items-center
        .title.flex.text-m.grey-color Соцсети:
        .wrap.flex.gap-1
          .network.flex.h-7.items-center.gap-x-2.cursor-pointer(
            v-for="network in data.networks"
          )
            q-icon.blue-fill(:name="network.icon", size="24px")
            span {{network.name}}
      .flex.gap-x-4.items-center
        .title.flex.text-m.grey-color Телефон:
        .flex {{data?.phone}}
      .flex.gap-x-4.items-center
        .title.flex.text-m.grey-color Email:
        .flex {{data?.email}}
      .flex.gap-x-4.items-center
        .title.flex.text-m.grey-color Страхование:
        .flex.gap-x-2.items-center
          span {{data?.company}}
          .policy.flex.items-center.gap-x-2.pl-3.pr-2.h-7
            span {{data?.policy}}
            q-icon.cursor-pointer.grey-fill(
              @click="copyValue(data.policy)",
              name="app:copy", size="18px"
            )
      .flex.gap-x-4.items-center
        .title.flex.text-m.grey-color Особые отметки:
        .wrap.flex.gap-x-1.gap-y-1
          .flex.h-7.w-fit.items-center.px-3.rounded(
            v-for="mark in data.marks", :style="{background: mark.color}"
          ) {{mark.name}}
    .footer.flex
</template>

<script>
export default {
  name: "MedcardModal",
  props: {
    data: Object,
  },
  data() {
    return {
      priorities: [
        { id: 0, color: "none-priority", name: "Без приоритета" },
        { id: 1, color: "red-color", name: "Высокий" },
        { id: 2, color: "yellow-color", name: "Средний" },
        { id: 3, color: "green-color", name: "Низкий" },
      ],
      information: [
        { title: "Соцсети: ", data: [] },
        { title: "Телефон: ", data: [] },
        { title: "Email: ", data: [] },
        { title: "Страхование: ", data: [] },
        { title: "Особые отметки: ", data: [] },
      ],
    };
  },
  computed: {
    selectedPriority() {
      return this.priorities.find((e) => e.id === this.data.priority).color;
    },
    selectedPriorityName() {
      return this.priorities.find((e) => e.id === this.data.priority).name;
    },
  },
  methods: {
    choiceFill(fill) {
      return `radial-gradient(closest-side, white 45px, transparent 80% 100%), conic-gradient(var(--aqua-color) ${fill}, var(--gray-secondary) 0%)`;
    },
    copyValue(policy) {
      navigator.clipboard.writeText(policy);
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  width: 744px
  color: var(--font-dark-blue-color)

.header
  border-bottom: 1px solid var(--gray-secondary)
  padding: 16px 32px 25px 33px

.info
  max-width: 424px

.circle
  width: 100px
  height: 100px

.title
  width: 132px

.policy
  border-radius: 4px
  border: 1px solid var(--gray-scondary)
  background: var(--gray-thirdly)

.network
  padding: 8px 8px 8px 2px
  border-radius: 28px
  border: 1px solid var(--gray-secondary)
  &:hover
    background: var(--gray-secondary)

.wrap
  flex-wrap: wrap !important

.none-priority
  background: var(--font-grey-color)

.red-color
  background: var(--system-color-red)

.yellow-color
  background: var(--system-color-yellow)

.green-color
  background: var(--system-color-green)

.grey-color
  color: var(--font-grey-color)

.white-color
  color: var(--default-white)

.grey-fill :deep(path)
  fill: var(--font-grey-color)

.blue-fill :deep(path)
  fill: var(--btn-blue-color)
</style>
