<template lang="pug">
  .flex.h-full.w-full.relative.overflow-hidden
    .left-col.flex.flex-col.items-center.justify-center
      .logo-wrapper.absolute.left-12.top-12
        img(:src="logo")
      .login-panel.flex.flex-col.justify-center.gap-y-10
        .flex.flex-col.gap-y-2
          .flex.not-italic.font-bold.text-5xl.letter-spacing Добро пожаловать!
          .flex.not-italic.font-medium.text-base(
            :style="{color: 'var(--font-grey-color)'}"
          ) Войдите в аккаунт, чтобы начать пользоваться сервисом «Астра».
        .flex.flex-col.gap-y-5
          .flex.flex-col.gap-y-px
            base-input.blue-color(
              :error="authError"
              v-model="user.username",
              placeholder="+7 (915) 224–21–31",
              label="Номер телефона",
              size="L"
              @keypress.enter="login"
            )
              template(#error) Этого телефона нет в системе
          .flex.flex-col.gap-y-px
            base-input.blue-color(
              :error="authError"
              v-model="user.password",
              :type="passwordInputType",
              placeholder="Введите ваш пароль",
              label="Пароль",
              size="L"
              @keypress.enter="login"
            )
              .flex.items-center.cursor-pointer(@click="changePasswordInputType", v-if="user.password")
                q-icon(name="app:eye" v-if="showPassword")
                q-icon(name="app:eye-open" v-else)
              template(#error) Неверный логин или пароль
          .flex.items-center.-ml-2
            q-checkbox(v-model="rememberMe", type="checkbox")
              .font-medium.base Запомнить меня
        base-button(
          :disable="canPressLoginButton",
          label="Войти в аккаунт",
          size="L",
          @click="login",
        )
      .absolute.left-12.bottom-12.text-smm.font-medium 2023 © Астра
    .right-col.flex.items-center.justify-center.relative
</template>

<script>
import { fetchWrapper } from "@/shared/fetchWrapper";
import logo from "@/assets/images/logoMark.svg";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "TheLogin",
  components: { BaseInput, BaseButton },
  data() {
    return {
      showPassword: false,
      logo,
      authorized: true,
      user: {},
      rememberMe: false,
      authError: false,
    };
  },
  computed: {
    passwordInputType() {
      return !this.showPassword ? "password" : "text";
    },
    canPressLoginButton() {
      return !(this.user.username && this.user.password);
    },
  },
  methods: {
    changePasswordInputType() {
      this.showPassword = !this.showPassword;
    },
    login() {
      fetchWrapper
        .post("auth/login", {
          user_name: this.user.username,
          password: this.user.password,
        })
        .then((data) => {
          if (!data) this.authError = true;
          else this.$router.push("/");
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.left-col
  background-color: var(--default-white)
  color: var(--font-dark-blue-color)
  height: 100%
  width: calc(1920px - 1108px)

.logo-wrapper
  width: 90px
  height: 75px

.login-panel
  width: 444px
  height: 422px

.letter-spacing
  letter-spacing: 0.02em

.right-col
  background-color: var(--font-dark-blue-color)
  width: calc(100vw - 812px)
  background-image: url("@/assets/images/loginBG.svg")
  background-size: cover
  background-repeat: no-repeat
  background-position: center

.red-color
  color: var(--border-red-color)

.q-btn
  height: 48px

.blue-color :deep(.label)
  color: var(--font-dark-blue-color)
</style>
