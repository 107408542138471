<template lang="pug">
  .flex.w-full.gap-x-10px
    .filter-block.w-full.flex-col.flex.rounded(
      v-for="filter in formulaFilterMap"
      )
      .flex.block-filter.h-30px(v-for="value in filter")
        .border-right.width-cell.flex.justify-center.items-center
          q-checkbox(
            dense,
            v-model="filterFurmula",
            @update:model-value="() => updateFilter(value)",
            :val="value",
            checked-icon="check_box",
            unchecked-icon="check_box_outline_blank",
          )
        .border-right.width-cell.flex.justify-center.items-center
          span.text-smm.font-medium(
            v-if="value !== 'Все'"
            :style="{'color': 'var(--font-grey-color)'}"
            ) {{value}}
        .border-right.flex.items-center.pl-3.w-full(:style="{'minWidth': '190px'}")
          span.text-smm.font-medium {{toothConditions[value] || value}}
        .width-cell.flex.w-8.justify-center.items-center
          span.text-smm.font-medium {{getCountConditions[value] && getCountConditions[value].length || "-"}}
</template>

<script>
import {
  toothConditions,
  formulaFilterMap,
} from "@/pages/newMedicalCard/utils/medicalConfig";
import { getFilledConditions } from "@/pages/newMedicalCard/utils/gettersObjects";
import { v_model } from "@/shared/mixins/v-model";

export default {
  name: "FormulaFilter",
  data() {
    return {
      toothConditions,
      formulaFilterMap,
      getFilledConditions,
      filterFurmula: ["Все"],
    };
  },
  props: {
    data: Object,
  },
  mixins: [v_model],
  methods: {
    updateFilter(value) {
      if (value === "Все" || !this.filterFurmula.length) {
        this.filterFurmula = ["Все"];
        this.value = [...Object.keys(this.data.formula)];
      } else {
        this.filterFurmula = [
          ...this.filterFurmula.filter((item) => item !== "Все"),
        ];
        this.value = this.filterFurmula
          .map((key) => this.getCountConditions[key])
          .flat();
      }
    },
  },
  computed: {
    getCountConditions() {
      let conditions = {};
      Object.keys(this.toothConditions).forEach((key) => {
        conditions[key] = [];
      });
      Object.keys(this.data.formula).forEach((key) => {
        let firstConditions = getFilledConditions(
          this.data.formula[key].dental_condition
        )
          .map((item) => item.value)
          .flat()[0];
        if (firstConditions) {
          conditions[firstConditions] = [...conditions[firstConditions], key];
        }
      });
      return conditions;
    },
  },
};
</script>

<style lang="sass" scoped>
.filter-block
  border: 1px solid var(--border-light-grey-color)
.border-right
  border-right: 1px solid var(--border-light-grey-color)
.width-cell
  min-width: 32px
</style>
