export function convertTime(str, startIndex, endIndex) {
  return parseInt(str?.slice(startIndex, endIndex), 10);
}

export function trimName(lastName, firstName, patronymic) {
  let checkedFirstName = firstName ? firstName?.[0] + ". " : "";
  let checkedPatronymic = patronymic ? patronymic?.[0] + "." : "";
  return `${lastName} ${checkedFirstName}${checkedPatronymic}`;
}

export function verifyTime(dayTime) {
  let timeArray = dayTime.split(":").map((elem) => parseInt(elem, 10));
  let newTime = timeArray[1] >= 30 ? timeArray[0] + 1 : timeArray[0];
  return newTime;
}

export function capitalizeFirstChar(str) {
  return str[0]?.toUpperCase() + str?.slice(1);
}
