const stateInit = {
  lacticBite: false,
  formula: (() => {
    let initObj = {};
    let arrayTooth = [
      18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47,
      46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,
    ];
    arrayTooth.forEach((number) => {
      initObj[number] = {
        dental_condition: {
          general: null,
          crown: {
            central: [],
            upper: [],
            lower: [],
            left: [],
            right: [],
          },
          root: {
            central: [],
            left: [],
            right: [],
          },
        },
        tooth_mobility: null,
      };
    });
    return initObj;
  })(),
};

const state = {
  lacticBite: true,
  formula: {
    ...JSON.parse(JSON.stringify(stateInit.formula)),
    13: {
      dental_condition: {
        general: "О",
        crown: { ...stateInit.formula["13"].dental_condition.crown },
        root: { ...stateInit.formula["13"].dental_condition.root },
      },
      tooth_mobility: 1,
    },
    12: {
      dental_condition: {
        general: "О",
        crown: { ...stateInit.formula["12"].dental_condition.crown },
        root: { ...stateInit.formula["12"].dental_condition.root },
      },
      tooth_mobility: null,
    },
    11: {
      dental_condition: {
        general: "ИЗ",
        crown: {
          ...stateInit.formula["11"].dental_condition.crown,
          central: ["К"],
        },
        root: { ...stateInit.formula["11"].dental_condition.root },
      },
      tooth_mobility: null,
    },
    25: {
      dental_condition: {
        general: "ИЗ",
        crown: { ...stateInit.formula["25"].dental_condition.crown },
        root: { ...stateInit.formula["25"].dental_condition.root },
      },
      tooth_mobility: 3,
    },
    43: {
      dental_condition: {
        general: "О",
        crown: { ...stateInit.formula["43"].dental_condition.crown },
        root: { ...stateInit.formula["43"].dental_condition.root },
      },
      tooth_mobility: 3,
    },
    42: {
      dental_condition: {
        general: "О",
        crown: { ...stateInit.formula["42"].dental_condition.crown },
        root: { ...stateInit.formula["42"].dental_condition.root },
      },
      tooth_mobility: null,
    },
    41: {
      dental_condition: {
        general: "ИЗ",
        crown: { ...stateInit.formula["41"].dental_condition.crown },
        root: { ...stateInit.formula["41"].dental_condition.root, left: ["П"] },
      },
      tooth_mobility: null,
    },
    35: {
      dental_condition: {
        general: "ИЗ",
        crown: { ...stateInit.formula["35"].dental_condition.crown },
        root: { ...stateInit.formula["35"].dental_condition.root },
      },
      tooth_mobility: null,
    },
  },
};

export default {
  stateInit,
  state,
};
