<template lang="pug">
  q-btn(
    :label="miniSize || round ? '' : label",
    no-caps, 
    :class="btnClass"
    :style="{width: miniSize ? '40px' : width, 'min-width': width, ...btnHeight}",
    :round="miniSize || round",
    :padding="computedPadding",
    :disable="disable"
  )
    q-icon(v-if="miniSize", name="app:ok", size="20px")
    slot(v-else)
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    type: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "M",
    },
    width: {
      type: String,
      default: "auto",
    },
    round: Boolean,
    label: String,
    disable: Boolean,
    noPadding: Boolean,
    padding: String,
  },
  computed: {
    btnClass() {
      return {
        [this.type]: true,
      };
    },
    btnHeight() {
      if (this.size === "L")
        return {
          height: "48px",
          "min-height": "48px",
        };
      if (this.size === "S")
        return {
          height: "32px",
          "min-height": "32px",
        };
      return {
        height: "40px",
        "min-height": "40px",
      };
    },
    miniSize() {
      return this.type === "mini";
    },
    computedPadding() {
      if (this.miniSize) return "2px 0 0 0";
      if (this.noPadding) return "0";
      if (this.padding) return this.padding;
      return "";
    },
  },
};
</script>

<style scoped lang="sass">
.primary
  color: white
  background-color: var(--btn-blue-color)
  &:hover
    background-color: var(--btn-blue-color-hover)
  &:active
    background-color: var(--font-dark-blue-color)
  &:disabled, &[disabled]
    opacity: 1 !important
    background-color: var(--btn-blue-color-disabled)

.secondary
  color: var(--btn-blue-color)
  border: 1.5px solid var(--btn-blue-sec-border-color)
  background-color: white
  &:hover
    background-color: var(--btn-blue-sec-color-hover)
  &:active
    background-color: var(--btn-blue-sec-color-active)
  &:disabled, &[disabled]
    opacity: 1 !important
    color: var(--btn-blue-sec-color-disabled)
    background-color: white
    border: 1.5px solid var(--btn-blue-sec-border-color-disabled)

.remove
  color: var(--border-red-color)
  border: 1.5px solid var(--btn-red-border-color)
  background-color: white
  &:hover
    background-color: var(--btn-red-color-hover)
  &:active
    background-color: var(--btn-red-color-active)
  &:disabled, &[disabled]
    opacity: 1 !important
    color: var(--btn-red-color-disabled)
    background-color: white
    border: 1.5px solid var(--btn-red-border-color-disabled)

.mini
  color: var(--btn-blue-color)
  border: 1.5px solid var(--btn-mini-color)
  background-color: white
  &:hover
    border: none
    background-color: var(--btn-mini-color)
  &:active
    border: none
    background-color: var(--btn-mini-color-active)
    color: var(--bg-status-green)

.grey
  color: var(--font-dark-blue-color)
  border: 1.5px solid var(--gray-secondary)
  background-color: var(--gray-thirdly)
  font-weight: 500
  font-size: 14px
  line-height: 19px
  &:hover
    background-color: var(--btn-blue-sec-color-hover)
  &:active
    background-color: var(--btn-blue-sec-color-active)
  &:disabled, &[disabled]
    opacity: 1 !important
    color: var(--btn-blue-sec-color-disabled)
    background-color: white
    border: 1.5px solid var(--btn-blue-sec-border-color-disabled)


.q-btn :deep(.q-focus-helper)
  display: none
.q-btn :deep(.q-ripple)
  display: none
</style>
