<template lang="pug">
  medcard-form-wrapper(v-if="patient", title="Пациент на приеме", title-link="Медкарта")
    .flex.flex-col.h-full
      .header.flex.px-6.py-5.justify-between
        .flex.text-base.font-medium.gap-x-3
          img.h-14.w-14.object-cover.rounded-full(:src="patient?.avatar")
          .flex.flex-col.justify-center.gap-y-1
            .font-bold.text-xll {{patient.last_name + " " + patient.first_name + " " + patient.patronymic}}
            .grey-color.text-smm {{patient?.birthday}}
        .flex.flex-col.gap-y-1.text-smm.font-medium
          .flex.items-center.gap-x-2
            .priority.flex(:class="choiceClass(patient.priority)")
            .text-smm(:class="choiceColor(patient.priority)") {{choicePriority(patient.priority)}}
          .grey-color.text-smm Приоритет
      .flex.h-full
        .data.flex.w-full.h-full.flex-col.px-6.py-4.gap-y-6.overflow-hidden(v-for="item in data")
          .text-m.font-bold {{item.title}}
          .flex.flex-col.text-smm.overflow-hidden.relative.gap-y-1
            .grey-color {{item.firstLabel}}
            .insurance.flex.font-medium {{item.firstDescription}}
          .flex.flex-col.text-smm.gap-y-1
            .grey-color {{item.secondLabel}}
            .font-medium {{item.secondDescription}}
</template>

<script>
import MedcardFormWrapper from "@/pages/medcards/components/MedcardFormWrapper.vue";
import { patientList } from "@/pages/newCalendar/utils/calendarConfig";
import * as moment from "moment";

export default {
  name: "CurrentPatientForm",
  components: { MedcardFormWrapper },
  data() {
    return {
      patient: patientList
        .find(({ label }) => label === "Пациенты")
        .data.find(
          (e) =>
            e.time.from <= moment().format("HH:MM") &&
            e.time.to >= moment().format("HH:MM") &&
            e.reception === moment().format("DD.MM.YYYY")
        ),
      prioritys: [
        { name: "Без приоритета", id: 0, class: "none-priority" },
        { name: "Высокий", id: 1, class: "high-priority" },
        { name: "Средний", id: 2, class: "middle-priority" },
        { name: "Низский", id: 3, class: "low-priority" },
      ],
      data: [
        { title: "Запись", firstLabel: "Дата", secondLabel: "Время" },
        { title: "Данные", firstLabel: "Телефон", secondLabel: "Email" },
        { title: "Страхование", firstLabel: "Компания", secondLabel: "Полис" },
      ],
    };
  },
  methods: {
    choiceClass(priority) {
      return this.prioritys.find(({ id }) => id === priority).class;
    },
    choicePriority(priority) {
      return this.prioritys.find(({ id }) => id === priority).name;
    },
    choiceColor(priority) {
      return {
        "none-priority": !priority,
        "red-color": priority === 1,
        "blue-color": priority === 2,
        "green-color": priority === 3,
      };
    },
  },
  watch: {
    patient: {
      deep: true,
      immediate: true,
      handler() {
        this.data[0].firstDescription = moment().format("DD MMMM");
        this.data[0].secondDescription =
          this.patient?.time?.from + "-" + this.patient?.time?.to;

        this.data[1].firstDescription = this.patient?.phone;
        this.data[1].secondDescription = this.patient?.email;

        this.data[2].firstDescription = this.patient?.company;
        this.data[2].secondDescription = this.patient?.policy;
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.header
  border-bottom: 1px solid var(--gray-secondary)

.priority
  height: 8px
  width: 8px
  border-radius: 50%

.data
  border-right: 1px solid var(--gray-scondary)
  &:first-child
    max-width: 154px
  &:last-child
    @media (max-width: 1820px)
      max-width: 282px
    border-right: none
    max-width: 214px
    .insurance
      width: 209px
      overflow: hidden
      &:after
        content: ""
        text-align: right
        position: absolute
        bottom: 0
        right: 0
        width: 72px
        height: 24px
        background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.24) 50.73%, rgba(255, 255, 255, 0.00) 100%)
        pointer-events: none
      @media (max-width: 1820px)
        width: 234px

.high-priority
  background: var(--system-color-red)

.middle-priority
  background: var(--btn-blue-color)

.low-priority
  background: var(--system-color-green)

.none-priority
  border: 1.5px solid var(--font-grey-color)

.grey-color
  color: var(--font-grey-color)

.red-color
  color: var(--system-color-red)

.blue-color
  color: var(--btn-blue-color)

.green-color
  color: var(--system-color-green)
</style>
