<template lang="pug">
  .patient-info.pt-4.pr-6.flex.flex-col.justify-between.rounded.font-medium.text-m
    .ml-6.flex.justify-between
      q-breadcrumbs
        template(v-slot:separator)
          q-icon.rotate-180(
            size="12px",
            name="app:long-arrow",
            color="grey",
          )
        q-breadcrumbs-el(:label="routes[routingHistory.state.back]")
        q-breadcrumbs-el(:label="`Медкарта #${medicalCardData?.number}`")
      q-btn(
        flat,
        text-color="grey",
        no-caps,
        padding="6px"
        size="md"
        @click="changeShownRemoveModal(true)",
      )
        q-icon(left, name="app:basket", size="16px")
        span Удалить медкарту
    .ml-6.flex.gap-x-4
      q-avatar(size="80px", :style="{'background-color': 'var(--border-light-grey-color)', color: 'var(--font-dark-blue-color)'}")
        img(v-if="patientData?.photo", :src="url + patientData?.photo")
        span.text-2xl(v-else) {{patientAvatar}}
      .flex.gap-y-1.flex-col
        .flex.items-center
          span.text-xxl.font-bold.mr-3(
            :style="{color: 'var(--font-dark-blue-color)', 'line-height': '135%'}",
          ) {{patientName}}
          q-chip(
            v-if="priority?.text",
            square,
            text-color="white",
            :style="{height: '20px', background: priority?.color, padding: '2px 8px'}",
            :label="priority?.text"
          )
          q-btn-dropdown(
            v-if="allergiesList?.length > 0"
            :style="{background: 'var(--border-red-color)', height: '20px'}",
            text-color="white",
            :label="`+${allergiesList?.length}`"
            icon="pan_tool"
            size="10px",
            padding="2px 8px",
            dropdown-icon="expand_more",
            menu-anchor="bottom left",
            menu-self="top start",
          )
            .p-4.w-56.flex.justify-between
              q-list.allergies-list.w-full(id="allergiesList")
                q-item.allergies-item.text-smm.rounded(
                  v-close-popup,
                  v-for="allergy in allergiesList",
                  :style="{padding: '0 8px', 'min-height': '32px'}"
                )
                  q-item-section.cursor-pointer {{allergy.name}}
                  .m-auto.label-color.icon-eye
                    q-icon(name="visibility", size="16px")
        span.text-smm.label-color Добавлен в систему: 
          span.date-color {{ createdDate }}
        span.text-smm.label-color Последнее обновление: 
          span.date-color {{ updatedDate }}
    .flex.justify-between.pt-4
      .flex
        .menu-item.px-6.py-10px.cursor-pointer.text-base.whitespace-nowrap(
          v-for="item in menuItem",
          @click="selectItem(item)",
          :class="{'menu-item-active': item.component === modelValue}",
          :key="item.id",
          :id="item.id"
        ) {{item.title}}
      q-btn-dropdown(
        color="primary",
        icon="print",
        label="Печатать",
        dropdown-icon="expand_more",
        flat,
        size="md"
        no-caps,
        padding="2px 4px"
        :style="{height: '32px'}"
      )
</template>

<script>
import { v_model } from "@/shared/mixins/v-model";
import { column } from "@/pages/clients/utils/tableConfig.js";
import * as moment from "moment/moment";
import {
  headerMenuItem,
  routesDictionary,
} from "@/pages/newMedicalCard/utils/medicalConfig.js";
export default {
  name: "MedicalHeader",
  mixins: [v_model],
  props: {
    changeShownRemoveModal: Function,
  },
  data() {
    return {
      menuItem: headerMenuItem,
      routes: routesDictionary,
    };
  },
  computed: {
    routingHistory() {
      return this.$store.state.routingHistory;
    },
    url() {
      return this.$store.state.url;
    },
    medicalCardData() {
      return this.$store.state.medical.medicalCard;
    },
    patientData() {
      return this.medicalCardData?.person;
    },
    patientName() {
      let name = {
        lastName: this.ckeckName("last_name"),
        firstName: this.ckeckName("first_name"),
        patronymic: this.ckeckName("patronymic"),
      };
      return `${name.lastName} ${name.firstName} ${name.patronymic}`;
    },
    patientAvatar() {
      let checkedFirstName =
        this.patientData?.first_name !== null
          ? this.patientData?.first_name[0]
          : this.patientData?.last_name[1];
      return `${this.patientData?.last_name[0]}${checkedFirstName}`;
    },
    priority() {
      return column
        .find((elem) => elem.name === "priority")
        ?.settings.find((elem) => elem.priority === this.patientData?.priority);
    },
    createdDate() {
      return this.checkDate("created_at");
    },
    updatedDate() {
      return this.checkDate("updated_at");
    },
    allergiesList() {
      return this.patientData?.allergic?.map((elem) => ({
        name: elem?.name,
        title: elem?.title,
      }));
    },
  },
  methods: {
    selectItem(item) {
      this.value = item?.component;
    },
    ckeckName(field) {
      return this.patientData?.[field] ? this.patientData[field] : "";
    },
    checkDate(field) {
      return this.medicalCardData?.[field]
        ? moment.parseZone(this.medicalCardData?.[field]).format("DD.MM.YYYY")
        : "";
    },
  },
};
</script>
<style scoped lang="sass">
.patient-info

  background-color: var(--default-white)

  min-height: 190px
.menu-item
  color: var(--font-grey-color)
  border-bottom: 1px solid transparent
.menu-item:first-child
  border-bottom-left-radius: 4px
.menu-item-active
  color: var(--font-dark-blue-color)
  border-bottom: 1px solid var(--font-dark-blue-color)
.q-btn
  font-weight: 500 !important
.q-btn :deep(.on-left)
  margin-right: 4px !important
.q-btn :deep(.q-btn-dropdown__arrow)
  margin-left: 0px !important
  opacity: 0.7
.text-grey
  color: var(--font-grey-color) !important
.q-breadcrumbs__el
  font-size: 12px
  line-height: 135%
  color: var(--font-grey-color)
.q-breadcrumbs :deep(.q-breadcrumbs__separator)
  margin: 10px 0 4px 8px !important
.label-color
  color: var(--font-grey-color)
.date-color
  color: var(--font-dark-blue-color)
.allergies-list
  max-height: 190px
  overflow-y: auto
  &::-webkit-scrollbar
    width: 4px
    background-color: var(--default-white)
  &::-webkit-scrollbar-track
    margin: 0
.allergies-item
  color: var(--font-dark-blue-color)
.allergies-item:hover
  background-color: var(--bg-light-grey)
  .icon-eye
    display: block
.icon-eye
  display: none
</style>
