<template lang="pug">
  .modal
    .text.mt-4.mb-10.text-base Вы действительно хотите удалить медкарту?
    .flex.gap-x-3
      base-button(
        type="secondary",
        label="Отменить",
        width="126px"
        @click="changeShownRemoveModal(false)"
      )
      base-button(
        type="remove",
        label="Удалить",
        width="126px",
        @click="removeMedicalCard"
      )
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
export default {
  name: "MedicalRemoveModal",
  props: {
    changeShownRemoveModal: Function,
  },
  components: { BaseButton },
  data() {
    return {};
  },
  methods: {
    removeMedicalCard() {
      this.changeShownRemoveModal(false);
      this.$store.dispatch("removeMedicalCardData", this.$route.params.id);
    },
  },
};
</script>
<style scoped lang="sass">
.modal
  width: 350px
.text
  color: var(--font-grey-color)
</style>
