<template lang="pug">
  medical-form-wrapper(
    :title="`Доверенное лицо ${index+1}`" 
    titleDelete="Удалить контакт" 
    :delete-item="deleteConfidant"
    :is-check-change="isCheckChange" 
    :is-loading-data="isLoadingData" 
    :is-edit="isEdit"
    :save="saveChange" 
    :cancel="cancelEdit" 
    :open-edit="openEdit"
    )
    q-form.form-wrap.gap-24.w-full(ref="formConfidant")
      .flex.flex-col.gap-4(v-for="data in configData")
        .font-semibold.text-sm.whitespace-nowrap {{data.dataLabel}}
        .flex.w-full.h-full.justify-between.gap-4(v-for="field in data.fields")
          .label-field.font-sm.text-sm.whitespace-nowrap.mt-10px {{`${field.label} :`}}
          .input-wrapper.items-center.h-fit.gap-10px
            .w-full.flex.gap-x-1.gap-y-2(v-if="field.key === 'networks'" :style="{'flex-wrap': 'wrap !important'}")
              .flex.rounded-full.cursor-pointer.h-fit.items-center.gap-10px.py-1.pl-1.pr-2(
                v-for="network in confidant.networks"
                @click="() => copyLinkNetwork(network.username)"
                style="background-color: var(--bg-light-grey)"
              )
                q-icon.icon(:name="mapNetworks[network.category].icon" :class="{old: network.id, new: !network.id}")
                span.text-smm.font-medium {{ mapNetworks[network.category].title || network.username }}
              .flex.relative
                q-btn(
                  v-if="isEdit"
                  icon="add",
                  size="17px",
                  class="add-network"
                  @click.stop
                  round,
                  dense,
                  no-caps,
                  padding="3px"
                )
                  q-menu(v-model="isOpenPopupAdding", anchor="bottom left", self="top right")
                    .menu-wrapper.flex.flex-col.gap-y-3.p-4
                      base-adding-network(
                        :items="Object.values(mapNetworks)",
                        :network="newNetwork",
                        :rule-input="ruleInputNetwork"
                      )
                      .w-fit
                        base-button(
                          label="Добавить",
                          @click="saveNetwork",
                          width="126px"
                        )
            .flex.w-full.relative(v-else)
              q-menu.h-fit(
                v-if="field.key === 'last_name'",
                fit,
                v-model="isOpenListConfidant",
                :style="{'max-height': '160px', 'position': 'fixed !important'}",
                no-focus,
                anchor="bottom right",
                self="top right"
              )
                .flex.flex-col.w-full.h-full.overflow-y-auto
                  .confidant-item.flex.h-10.items-center.cursor-pointer.text-base.px-2(
                    v-for="person in listPersons" 
                    :id="person.id"
                    @click="(e) => choiceConfidant(e)"
                  ) {{ `${person.last_name || ""} ${person.first_name || ""} ${person.patronymic || ""}` }}
              base-textarea.w-full(
                v-if="field.type === 'textarea'",
                :readonly="!isEdit",
                v-model="confidant[field.key].value",
                @update:model-value="(val) => checkChangeInput(val, field.key)",
                :mask="field.mask",
                :type="field.type",
                :name="field.key",
                :placeholder="field.placeholder",
                height="96px"
              )
              base-input.w-full(
                v-else,
                :readonly="!isEdit",
                v-model="confidant[field.key].value",
                @update:model-value="(val) => checkChangeInput(val, field.key)",
                :mask="field.mask",
                :type="field.type",
                :name="field.key",
                :placeholder="field.placeholder",
                :rule="field.rules",
                size="M"
              )
</template>

<script>
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import BaseSelectNetworks from "@/components/base/BaseSelectNetworks.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BasePopup from "@/components/base/BasePopup.vue";
import BaseAddingNetwork from "@/components/base/BaseAddingNetwork.vue";
import BaseSelect from "@/components/base/BaseSelect.vue";
import TheNotificationProvider from "@/components/Notifications/TheNotificationProvider.vue";
import { getFieldsNameUnvalidated } from "@/shared/utils/changesObjects";
import { getRequestConfidant } from "@/shared/utils/wrapperRequestChangeData";
import { addNotification } from "@/components/Notifications/notificationContext";
import { ruleNotValue } from "@/shared/utils/rulesInputs";
import { checkChangeData } from "@/shared/utils/changesObjects";
import { getConfidantObject } from "@/pages/newMedicalCard/utils/gettersObjects";
import { mapState, mapGetters } from "vuex";
import {
  confidantConfig,
  mapNetworks,
} from "@/pages/newMedicalCard/utils/medicalConfig";
import { fetchWrapper } from "@/shared/fetchWrapper";
import BaseTextarea from "@/components/base/BaseTextarea.vue.vue";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "ConfidantForm",
  components: {
    MedicalFormWrapper,
    BaseInput,
    BaseSelectNetworks,
    BaseSelect,
    BaseButton,
    BasePopup,
    BaseAddingNetwork,
    TheNotificationProvider,
    BaseTextarea,
  },
  data() {
    return {
      newNetwork: {
        category: mapNetworks["TELEGRAM"],
        username: "",
      },
      isEdit: false,
      isLoadingData: false,
      isCheckChange: false,
      isOpenPopupAdding: false,
      isOpenListConfidant: false,
      ruleInputNetwork: [(val) => ruleNotValue(val)],
      configData: confidantConfig,
      mapNetworks: mapNetworks,
      listPersons: [],
      initPerson: null,
    };
  },
  props: {
    confidant: {},
    index: Number,
  },
  computed: {
    ...mapGetters({
      initConfidant: "getConfidantData",
    }),
    ...mapState({
      confidants: (state) => state.medical.confidantData,
    }),
  },
  methods: {
    choiceConfidant(e) {
      fetchWrapper
        .get(`general/person/${e.currentTarget.id}/detail/`)
        .then((res) => {
          this.confidants[this.index] = getConfidantObject(res);
          this.initPerson = res;
          this.isOpenListConfidant = false;
        });
    },
    saveNetwork() {
      if (this.newNetwork.username) {
        this.confidants[this.index].networks.push({
          category: this.newNetwork.category.id,
          username: this.newNetwork.username,
        });
        this.isOpenPopupAdding = false;
        this.isCheckChange = true;
        this.newNetwork = {
          category: mapNetworks["TELEGRAM"],
          username: "",
        };
      } else {
        addNotification(
          "Соцсеть",
          "Соцсеть",
          "Отсутсвует ссылка на соцсеть",
          "error",
          5000
        );
      }
    },
    checkChangeInput(val, key) {
      if (key === "last_name") {
        if (val.length > 0) {
          this.$store.dispatch("getPersonsFiltredList", val).then((res) => {
            if (res.length > 0) {
              this.listPersons = res;
              this.isOpenListConfidant = true;
            } else {
              this.listPersons = [];
              this.isOpenListConfidant = false;
            }
          });
        } else {
          this.isOpenListConfidant = false;
          this.listPersons = [];
        }
      }
      this.isCheckChange = checkChangeData(
        this.initConfidant[this.index],
        this.confidant
      );
    },
    returnInitData() {
      const initData = this.initConfidant.find(
        (el) => el.id === this.confidant?.id
      );
      this.confidants[this.index] = initData || getConfidantObject();
    },
    saveChange() {
      const initConfidantData = this.initConfidant[this.index];
      const form = this.$refs.formConfidant;
      form.validate().then((validate) => {
        if (validate) {
          getRequestConfidant(
            this.confidant,
            initConfidantData,
            this.initPerson,
            this.$route.params.id
          ).then(() => {
            this.$store.dispatch("getMedicalCardData");
            this.isLoadingData = false;
            this.isEdit = false;
            this.isCheckChange = false;
          });
        } else {
          const config = this.configData.map((el) => el.fields).flat();
          getFieldsNameUnvalidated(form).forEach((errorKey) => {
            const configField = config.find((el) => el.key === errorKey);
            addNotification(
              configField.label,
              configField.label,
              configField.error,
              "error",
              5000
            );
          });
        }
      });
    },
    cancelEdit() {
      this.returnInitData();
      this.isEdit = false;
      this.isCheckChange = false;
    },
    openEdit() {
      this.isEdit = true;
    },
    deleteConfidant() {
      if (this.confidant.id) {
        fetchWrapper
          .del(
            `medical_card/medical_history/${this.$route.params.id}/delete_confidant/`,
            {
              confidants: [
                {
                  person: {
                    id: this.confidant.id,
                  },
                },
              ],
            }
          )
          .then(() => {
            this.$store.dispatch("getMedicalCardData");
          });
      } else {
        this.confidants.splice(this.index, 1);
      }
    },
    copyLinkNetwork(value) {
      navigator.clipboard.writeText(value);
    },
  },
};
</script>

<style lang="sass" scoped>
.form-wrap
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: repeat(1, 1fr)
  @media(max-width: 1900px)
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(2, 1fr)
  @media(max-width: 1320px)
    grid-template-columns: repeat(1, 1fr)
    grid-template-rows: repeat(3, 1fr)
.label-field
  color: var(--font-grey-color)
.input-wrapper
  width: 302px
.confidant-item
  &:hover
    background-color: var(--bg-light-grey)
.add-network
  color: var(--font-dark-blue-color)
  background-color: var(--bg-light-grey)
  &:hover
    color: var(--btn-blue-color)
    opacity: 0.7
.icon
  &.old
    color: var(--btn-blue-color)
  &.new
    color: var(--icon-violet-color)
</style>
