import * as moment from "moment/moment";

export function ruleNotValue(val, text) {
  return !!val || text || false;
}

export function ruleLengthValue(val, minLength, text) {
  return (val && val.length === minLength) || text || false;
}

export function ruleOptionalFields(val, minLength, id, text) {
  return (!val && !id) || val.length === minLength || text || false;
}

export function ruleEmailValue(val, text) {
  const regExp = new RegExp(/^[^\s()<>@,;:/]+@\w[\w.-]+\.[a-z]{2,}$/i);
  return (val && val.length < 100 && regExp.test(val)) || text || false;
}

export function ruleMaxLength(val, maxLength, text) {
  return (!!val && val.length <= maxLength) || text || false;
}

export function ruleDateValue(val, text) {
  let date = moment(
    val && val?.length === 10 ? val.split(".").reverse().join("-") : null
  );
  return (
    (date.isValid() && !date.isAfter(moment().format("YYYY-MM-DD"))) ||
    text ||
    false
  );
}

export function ruleNumberInput(val, max, min, text) {
  return (val <= max && val >= min) || text || false;
}
