<template lang="pug">
  svg.hover(width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
    g(v-if="nameSvg === 'calendar'")
      path(d="M31 9.75C31.5523 9.75 32 10.1977 32 10.75V15H8V10.75C8 10.1977 8.44772 9.75 9 9.75H13.25V7.75C13.25 7.33579 13.5858 7 14 7C14.4142 7 14.75 7.33579 14.75 7.75V9.75H25.25V7.75C25.25 7.33579 25.5858 7 26 7C26.4142 7 26.75 7.33579 26.75 7.75V9.75H31Z" :fill="active ? '#4772f2' : '#bebfca'")
      path(opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M31 32H9C8.44772 32 8 31.5523 8 31V16.5H32V31C32 31.5523 31.5523 32 31 32ZM15 19H13C12.4477 19 12 19.4477 12 20V22C12 22.5523 12.4477 23 13 23H15C15.5523 23 16 22.5523 16 22V20C16 19.4477 15.5523 19 15 19ZM15 25H13C12.4477 25 12 25.4477 12 26V28C12 28.5523 12.4477 29 13 29H15C15.5523 29 16 28.5523 16 28V26C16 25.4477 15.5523 25 15 25ZM21 19H19C18.4477 19 18 19.4477 18 20V22C18 22.5523 18.4477 23 19 23H21C21.5523 23 22 22.5523 22 22V20C22 19.4477 21.5523 19 21 19ZM21 25H19C18.4477 25 18 25.4477 18 26V28C18 28.5523 18.4477 29 19 29H21C21.5523 29 22 28.5523 22 28V26C22 25.4477 21.5523 25 21 25ZM27 19H25C24.4477 19 24 19.4477 24 20V22C24 22.5523 24.4477 23 25 23H27C27.5523 23 28 22.5523 28 22V20C28 19.4477 27.5523 19 27 19ZM27 25H25C24.4477 25 24 25.4477 24 26V28C24 28.5523 24.4477 29 25 29H27C27.5523 29 28 28.5523 28 28V26C28 25.4477 27.5523 25 27 25Z" :fill="active ? '#4772f2' : '#bebfca'")
    g(v-if="nameSvg === 'clients'")
      g
        path(d="M24.4001 14.4734C24.4001 16.6089 22.6093 18.34 20.4001 18.34C18.191 18.34 16.4001 16.6089 16.4001 14.4734C16.4001 12.3379 18.191 10.6067 20.4001 10.6067C22.6093 10.6067 24.4001 12.3379 24.4001 14.4734Z" :fill="active ? '#4772f2' : '#bebfca'")
        path(d="M12.4001 24.9528C12.4001 21.5523 15.6478 19.0202 19.0858 19.7403C19.952 19.9217 20.8483 19.9217 21.7145 19.7403C25.1524 19.0202 28.4001 21.5523 28.4001 24.9528V27.62C28.4001 28.0471 28.042 28.3934 27.6001 28.3934H13.2001C12.7583 28.3934 12.4001 28.0471 12.4001 27.62V24.9528Z" :fill="active ? '#4772f2' : '#bebfca'")
        g(opacity="0.6")
          path(d="M31.2 15.6166C31.2 17.1022 29.9464 18.3065 28.4 18.3065C26.8536 18.3065 25.6 17.1022 25.6 15.6166C25.6 14.131 26.8536 12.9268 28.4 12.9268C29.9464 12.9268 31.2 14.131 31.2 15.6166Z" :fill="active ? '#4772f2' : '#bebfca'")
          path(d="M14.4 15.6166C14.4 17.1022 13.1464 18.3065 11.6 18.3065C10.0536 18.3065 8.8 17.1022 8.8 15.6166C8.8 14.131 10.0536 12.9268 11.6 12.9268C13.1464 12.9268 14.4 14.131 14.4 15.6166Z" :fill="active ? '#4772f2' : '#bebfca'")
          path(fill-rule="evenodd" clip-rule="evenodd" d="M12.52 19.2806C13.2055 19.1379 13.8801 19.1788 14.4945 19.3648C12.5441 20.4847 11.2 22.5391 11.2 24.9528V25.3001H6.56C6.25072 25.3001 6 25.0592 6 24.7621V22.9066C6 20.5411 8.27339 18.7797 10.68 19.2806C11.2863 19.4068 11.9137 19.4068 12.52 19.2806ZM26.0735 19.2376C28.1486 20.3243 29.6 22.4445 29.6 24.9528V25.3001H33.44C33.7493 25.3001 34 25.0592 34 24.7621V22.9066C34 20.5411 31.7266 18.7797 29.32 19.2806C28.7137 19.4068 28.0863 19.4068 27.48 19.2806C27.0002 19.1807 26.5257 19.1708 26.0735 19.2376Z" :fill="active ? '#4772f2' : '#bebfca'")
    g(v-if="nameSvg === 'settings'")
      path(:fill="active ? '#4772f2' : '#bebfca'" opacity="0.6" d="m20.93202,15.9808l0.0537,0.0933l0.7464,-0.3152c1.3952,-0.5891 3.0106,-0.046 3.7666,1.2662l1.4158,2.4579c0.0964,0.1672 0.175,0.3404 0.2362,0.517l0.7131,0c0.7569,0 1.3951,-0.5638 1.4886,-1.3149l0.0572,-0.4606c0.0358,-0.2873 0.226,-0.5281 0.4757,-0.6745c0.2563,-0.1502 0.568,-0.1912 0.8413,-0.0748l0.3903,0.1662c0.6983,0.2974 1.5087,0.0263 1.8875,-0.6313l0.6179,-1.0726c0.3623,-0.629 0.2011,-1.4292 -0.3765,-1.8688l-0.5924,-0.4509c-0.1152,-0.0876 -0.1561,-0.2372 -0.1561,-0.3819l0,-0.4725c0,-0.1462 0.0546,-0.2908 0.1709,-0.3793l0.5552,-0.4225c0.6004,-0.4569 0.768,-1.2887 0.3914,-1.9424l-0.595,-1.03282c-0.378,-0.65613 -1.1857,-0.92767 -1.8833,-0.6331l-0.4164,0.17583c-0.2694,0.11377 -0.5763,0.0737 -0.8257,-0.07911c-0.2504,-0.15291 -0.4496,-0.39166 -0.4858,-0.68281l-0.0562,-0.45233c-0.0935,-0.75105 -0.7317,-1.31486 -1.4886,-1.31486l-1.1808,0c-0.7569,0 -1.3952,0.56381 -1.4886,1.31486l-0.0573,0.46066c-0.0357,0.28728 -0.2259,0.52807 -0.4757,0.67448c-0.2563,0.1503 -0.568,0.19175 -0.8418,0.07614l-0.4094,-0.17286c-0.6975,-0.29457 -1.5052,-0.02303 -1.8832,0.6331l-0.595,1.03282c-0.3766,0.6537 -0.209,1.4855 0.3913,1.9424l0.5552,0.4225c0.1163,0.0885 0.171,0.2331 0.171,0.3793l0,0.4725c0,0.1466 -0.0549,0.2916 -0.1715,0.3804l-0.5547,0.4221c-0.6003,0.4569 -0.7679,1.2887 -0.3913,1.9424zm6.3762,-0.5308c0.6733,0 1.2479,-0.2392 1.7239,-0.7175c0.4759,-0.4783 0.7139,-1.0558 0.7139,-1.7325c0,-0.6767 -0.238,-1.2542 -0.7139,-1.7325c-0.476,-0.4783 -1.0506,-0.7175 -1.7239,-0.7175c-0.6849,0 -1.2625,0.2392 -1.7329,0.7175c-0.4699,0.4783 -0.7049,1.0558 -0.7049,1.7325c0,0.6767 0.235,1.2542 0.7049,1.7325c0.4704,0.4783 1.048,0.7175 1.7329,0.7175z" clip-rule="evenodd" fill-rule="evenodd")
      g
        path(transform="rotate(120 15.5172 24.5958)" :fill="active ? '#4772f2' : '#bebfca'" d="m5.28083,29.36488l0.08668,0.14927l1.20485,-0.5043c2.25215,-0.94252 4.85974,-0.0736 6.08008,2.02584l2.2854,3.93248c0.15561,0.26751 0.28249,0.54462 0.38128,0.82717l1.15109,0c1.2218,0 2.25198,-0.90204 2.40291,-2.10376l0.09233,-0.73693c0.05779,-0.45966 0.36481,-0.84493 0.76788,-1.07916c0.41372,-0.24031 0.91687,-0.30591 1.35803,-0.11968l0.63003,0.26591c1.1272,0.47582 2.43536,0.04208 3.04682,-1.01004l0.99742,-1.71609c0.58483,-1.00636 0.32462,-2.28663 -0.60775,-2.98996l-0.95626,-0.72141c-0.18596,-0.14015 -0.25198,-0.3795 -0.25198,-0.61102l0,-0.75597c0,-0.23391 0.08814,-0.46526 0.27587,-0.60686l0.89621,-0.67597c0.96917,-0.73101 1.23971,-2.06184 0.6318,-3.10772l-0.96045,-1.65245c-0.61017,-1.04977 -1.91397,-1.48421 -3.04004,-1.01292l-0.67216,0.28132c-0.43487,0.18202 -0.93027,0.11792 -1.33285,-0.12657c-0.4042,-0.24465 -0.72575,-0.62663 -0.78418,-1.09245l-0.09072,-0.7237c-0.15093,-1.20163 -1.18112,-2.10369 -2.40291,-2.10369l-1.90606,0c-1.2218,0 -2.25215,0.90206 -2.40291,2.10369l-0.09249,0.73703c-0.05763,0.45963 -0.36465,0.84488 -0.76788,1.07913c-0.41372,0.24047 -0.91687,0.30679 -1.35884,0.12182l-0.66086,-0.27656c-1.12591,-0.47129 -2.42971,-0.03685 -3.03988,1.01292l-0.96045,1.65245c-0.60791,1.04588 -0.33737,2.37671 0.63164,3.10772l0.89621,0.67597c0.18773,0.14159 0.27603,0.37295 0.27603,0.60686l0,0.75597c0,0.23455 -0.08862,0.46654 -0.27684,0.60862l-0.8954,0.67533c-0.96901,0.73101 -1.23955,2.06184 -0.63164,3.10772l-0.00001,-0.00001zm10.29253,-0.84925c1.08685,0 2.01437,-0.3827 2.78274,-1.14795c0.7682,-0.76525 1.15238,-1.68921 1.15238,-2.77189c0,-1.08268 -0.38418,-2.00664 -1.15238,-2.77189c-0.76836,-0.76525 -1.69589,-1.14795 -2.78274,-1.14795c-1.10557,0 -2.03794,0.3827 -2.79726,1.14795c-0.75852,0.76525 -1.13786,1.68921 -1.13786,2.77189c0,1.08268 0.37934,2.00664 1.13786,2.77189c0.75932,0.76525 1.69169,1.14795 2.79726,1.14795z" clip-rule="evenodd" fill-rule="evenodd")
        path(:fill="active ? '#4772f2' : '#bebfca'" d="m5.27928,29.28626l0.08668,0.14927l1.20485,-0.5043c2.25214,-0.94252 4.85974,-0.0736 6.08008,2.02584l2.2854,3.93248c0.15561,0.26751 0.28249,0.54462 0.38128,0.82717l1.15109,0c1.2218,0 2.25198,-0.90204 2.40291,-2.10376l0.09234,-0.73693c0.05778,-0.45966 0.36481,-0.84492 0.76788,-1.07915c0.41372,-0.24031 0.91687,-0.30591 1.35803,-0.11968l0.63003,0.26591c1.1272,0.47582 2.43536,0.04208 3.04682,-1.01004l0.99742,-1.71609c0.58483,-1.00636 0.32462,-2.28663 -0.60775,-2.98996l-0.95626,-0.72141c-0.18596,-0.14016 -0.25198,-0.37951 -0.25198,-0.61102l0,-0.75597c0,-0.23391 0.08814,-0.46526 0.27587,-0.60685l0.89621,-0.67598c0.96917,-0.73101 1.23971,-2.06184 0.6318,-3.10771l-0.96045,-1.65245c-0.61017,-1.04977 -1.91397,-1.48421 -3.04004,-1.01292l-0.67216,0.28132c-0.43487,0.18202 -0.93027,0.11791 -1.33285,-0.12657c-0.4042,-0.24465 -0.72575,-0.62664 -0.78419,-1.09246l-0.09072,-0.7237c-0.15093,-1.20163 -1.18111,-2.10369 -2.40291,-2.10369l-1.90606,0c-1.22179,0 -2.25214,0.90206 -2.40291,2.10369l-0.0925,0.73703c-0.05762,0.45963 -0.36465,0.84488 -0.76788,1.07913c-0.41372,0.24047 -0.91687,0.30678 -1.35884,0.12181l-0.66086,-0.27656c-1.12591,-0.47129 -2.4297,-0.03685 -3.03988,1.01292l-0.96045,1.65245c-0.60791,1.04587 -0.33737,2.3767 0.63164,3.10771l0.89621,0.67598c0.18773,0.14159 0.27603,0.37294 0.27603,0.60685l0,0.75597c0,0.23455 -0.08862,0.46654 -0.27684,0.60862l-0.8954,0.67533c-0.96901,0.73101 -1.23955,2.06184 -0.63164,3.10772zm10.29253,-0.84925c1.08684,0 2.01437,-0.38271 2.78273,-1.14795c0.7682,-0.76525 1.15239,-1.68922 1.15239,-2.77189c0,-1.08268 -0.38419,-2.00664 -1.15239,-2.77189c-0.76836,-0.76525 -1.69589,-1.14796 -2.78273,-1.14796c-1.10558,0 -2.03794,0.38271 -2.79727,1.14796c-0.75852,0.76525 -1.13786,1.68921 -1.13786,2.77189c0,1.08267 0.37934,2.00664 1.13786,2.77189c0.75933,0.76524 1.69169,1.14795 2.79727,1.14795z" clip-rule="evenodd" fill-rule="evenodd")
    g(v-if="nameSvg === 'home'")
      path(d="M20 5L20.5303 4.46967C20.3897 4.32902 20.1989 4.25 20 4.25C19.8011 4.25 19.6103 4.32902 19.4697 4.46967L20 5ZM8.33331 16.6667L7.80298 16.1363C7.66233 16.277 7.58331 16.4678 7.58331 16.6667L8.33331 16.6667ZM31.6666 16.6667H32.4166C32.4166 16.4678 32.3376 16.277 32.197 16.1363L31.6666 16.6667ZM31.6667 33.3333V34.0833C31.8656 34.0833 32.0563 34.0043 32.197 33.8637C32.3376 33.723 32.4167 33.5322 32.4167 33.3333H31.6667ZM8.33332 33.3333H7.58332C7.58332 33.7475 7.91911 34.0833 8.33332 34.0833V33.3333ZM19.4697 4.46967L7.80298 16.1363L8.86364 17.197L20.5303 5.53033L19.4697 4.46967ZM32.197 16.1363L20.5303 4.46967L19.4697 5.53033L31.1363 17.197L32.197 16.1363ZM30.9166 16.6667L30.9167 33.3333H32.4167L32.4166 16.6667L30.9166 16.6667ZM31.6667 32.5833H8.33332V34.0833H31.6667V32.5833ZM9.08332 33.3333L9.08331 16.6667L7.58331 16.6667L7.58332 33.3333H9.08332Z" :fill="active ? '#4772f2' : '#bebfca'")
    g(v-if="nameSvg === 'medcards'")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M8.5 20V31C8.5 32.1046 9.39543 33 10.5 33H29.5C30.6046 33 31.5 32.1046 31.5 31V22.9032C31.5 21.7987 30.6046 20.9032 29.5 20.9032H20.0299C19.5184 20.9032 19.0263 20.7073 18.6549 20.3556L16.7451 18.5476C16.3737 18.196 15.8816 18 15.3701 18H10.5C9.39543 18 8.5 18.8954 8.5 20ZM19.5 25V26H18.5C17.9477 26 17.5 26.4477 17.5 27C17.5 27.5523 17.9477 28 18.5 28H19.5V29C19.5 29.5523 19.9477 30 20.5 30C21.0523 30 21.5 29.5523 21.5 29V28H22.5C23.0523 28 23.5 27.5523 23.5 27C23.5 26.4477 23.0523 26 22.5 26H21.5V25C21.5 24.4477 21.0523 24 20.5 24C19.9477 24 19.5 24.4477 19.5 25Z" :fill="active ? '#4772f2' : '#bebfca'")
      path(opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M10.5 9L10.5 16.5H15.3701C16.2652 16.5 17.1263 16.8429 17.7763 17.4583L19.6861 19.2663C19.779 19.3542 19.902 19.4032 20.0299 19.4032H29.5L29.5 11.7986C29.5 11.2859 29.3031 10.7928 28.95 10.4211L26.2914 7.6225C25.9138 7.22504 25.3896 7 24.8414 7H12.5C11.3954 7 10.5 7.89543 10.5 9ZM12.75 10C12.75 9.58579 13.0858 9.25 13.5 9.25H24.5C24.9142 9.25 25.25 9.58579 25.25 10C25.25 10.4142 24.9142 10.75 24.5 10.75H13.5C13.0858 10.75 12.75 10.4142 12.75 10ZM12.75 13.5C12.75 13.0858 13.0858 12.75 13.5 12.75H26.5C26.9142 12.75 27.25 13.0858 27.25 13.5C27.25 13.9142 26.9142 14.25 26.5 14.25H13.5C13.0858 14.25 12.75 13.9142 12.75 13.5Z" :fill="active ? '#4772f2' : '#bebfca'")
</template>

<script>
export default {
  name: "SidebarButtonSvg",
  props: {
    nameSvg: { type: String, default: "calendar" },
    active: Boolean,
  },
};
</script>
