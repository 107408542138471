<template lang="pug">
  .base-info-wrapper.w-full.flex.gap-x-2.flex-1.font-medium.text-m
    //- medical-sidebar(v-model="currentMenuItem")
    component(
      v-if="currentMenuItem",
      v-bind="{...componentProps, updateCurrentItem:updateCurrentItem, clearProps:clearProps}"
      :is="currentMenuItem",
      @update-current-item="updateCurrentItem"
    )
</template>

<script>
import MedicalSidebar from "@/pages/newMedicalCard/components/MedicalSidebar.vue";
import MedicalBasicDataWrapper from "@/pages/newMedicalCard/components/MedicalBasicDataWrapper.vue";
import MedicalAllergiesWrapper from "@/pages/newMedicalCard/components/MedicalAllergiesWrapper.vue";
import MedicalConfidantWrapper from "@/pages/newMedicalCard/components/MedicalConfidantWrapper.vue";
import MedicalHealthStateWrapper from "@/pages/newMedicalCard/components/MedicalHealthStateWrapper.vue";
import MedicalProtocolsWrapper from "@/pages/newMedicalCard/components/InitialInspectionProtocol/MedicalProtocolsWrapper.vue";
import MedicalDentalFormulasWrapper from "@/pages/newMedicalCard/components/MedicalDentalFormulasWrapper.vue";
export default {
  name: "MedicalBaseInfo",
  components: {
    MedicalSidebar,
    MedicalBasicDataWrapper,
    MedicalAllergiesWrapper,
    MedicalConfidantWrapper,
    MedicalHealthStateWrapper,
    MedicalProtocolsWrapper,
    MedicalDentalFormulasWrapper,
  },
  data() {
    return {
      componentProps: {},
      currentMenuItem: "MedicalBasicDataWrapper",
    };
  },
  methods: {
    updateCurrentItem(item, props) {
      this.currentMenuItem = item;
      this.componentProps = { ...props };
    },
    clearProps() {
      this.componentProps = {};
    },
  },
};
</script>
<style lang="sass" scoped>
.base-info-wrapper
  height: 80.4%
  max-height: calc(100% - 190px - 8px)
  color: var(--font-dark-blue-color)
</style>
