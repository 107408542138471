<template lang="pug">
.w-full.fit.row.no-wrap.justify-evenly
  .col-grow.pr-2
    medical-card-patient-form
    medical-base-info.mt-2 
  .h-full
    the-right-menu
</template>

<script>
import MedicalHeader from "@/pages/newMedicalCard/components/MedicalHeader.vue";
import MedicalRecords from "@/pages/newMedicalCard/components/MedicalRecords.vue";
import MedicalBaseInfo from "@/pages/newMedicalCard/components/MedicalBaseInfo.vue";
import BaseModal from "@/components/base/BaseModal.vue";
import MedicalRemoveModal from "@/pages/newMedicalCard/components/MedicalRemoveModal.vue";
import { mapState } from "vuex";
import TheRightMenu from "@/components/TheRightMenu.vue";
import MedicalCardPatientForm from "@/pages/newMedicalCard/components/MedicalCardPatientForm.vue";

export default {
  name: "TheMedicalCard",
  components: {
    MedicalHeader,
    MedicalRecords,
    MedicalBaseInfo,
    BaseModal,
    MedicalRemoveModal,
    TheRightMenu,
    MedicalCardPatientForm,
  },
  data() {
    return {
      currentMenuItem: "MedicalBaseInfo",
      isShownRemoveModal: false,
    };
  },
  methods: {
    changeShownRemoveModal(value) {
      this.isShownRemoveModal = value;
    },
  },
  computed: mapState({
    medical: (state) => state.medical.medicalCard,
  }),
  created() {
    this.$store.dispatch("getMedicalDataById", this.$route.params.id);
  },
};
</script>

<style lang="sass" scoped></style>
