<template lang="pug">
  .schedule.flex-1.flex.flex-col.relative(
    :style="{width: openSidebar ? 'calc(100vw - 320px)' : 'calc(100vw - 160px)'}"
  )
    calendar-header.w-full.mb-1(v-model="displayType")
    .schedule-body.h-full.bg-white.w-full(:class="{rounded: !expandedDisplayType}")
      .column-wrapper.flex.ml-20(:style="columnWrapperWidth")
        calendar-column(
          v-for="date in dateRange",
          :key="date",
          :date="date",
          :style="columnHeight",
          :expanded-display-type="expandedDisplayType",
          :day-start-time="validateStartTime"
          :day-end-time="validateEndTime"
        )
      .flex.relative(
        :style="expandedDisplayType ? backgroundWrapperWidth : {}",
        :class="{'border-bottom': expandedDisplayType}"
      )
        .time-coil-wrapper.left-0.-mt-16
          calendar-clock-column(
            :time-coil="timeCoil",
            :current-time="currentTime",
            :is-current="isCurrent",
            :day-end-time="validateEndTime"
          )
          .time-circle-indicator.left-74px.h-3.w-3.rounded-full.absolute(
            v-if="isShownIndicator",
            :style="circleIndicatorLocation"
          )
        span.time-line-indicator.block.left-20.absolute(
          v-if="isShownIndicator",
          :style="lineIndicatorLocation"
        )
        calendar-background.flex-1
    .w-full.h-3.bg-white.footer(v-if="expandedDisplayType")
</template>

<script>
import CalendarHeader from "@/pages/newCalendar/components/doctorCalendar/CalendarHeader";
import CalendarColumn from "@/pages/newCalendar/components/doctorCalendar/CalendarColumn.vue";
import { mapState } from "vuex";
import * as moment from "moment/moment";
import { wrapperMixin } from "@/pages/newCalendar/mixins/wrapperMixin.js";
export default {
  name: "CalendarWrapper",
  components: {
    CalendarHeader,
    CalendarColumn,
  },
  props: {
    openSidebar: Boolean,
  },
  mixins: [wrapperMixin],
  data() {
    return {
      displayType: "expanded",
    };
  },
  computed: {
    ...mapState({
      selectedDates: (state) => state.calendar.selectedDates,
    }),
    expandedDisplayType() {
      return this.displayType === "expanded";
    },
    backgroundWrapperWidth() {
      return {
        width: `${380 * this.dateRange.length + 80}px`,
      };
    },
    dateRange() {
      let diff = this.selectedDates?.to.diff(this.selectedDates?.from, "days");
      let range = [];
      for (let i = 0; i <= diff; i++) {
        range.push(this.selectedDates?.from.clone().add(i, "day"));
      }
      return range;
    },
    columnHeight() {
      return this.expandedDisplayType
        ? {
            height: `${this.timeCoil.length * this.pixelsPerHour + 60}px`,
            width: "380px",
          }
        : {
            height: `${this.timeCoil.length * this.pixelsPerHour + 96}px`,
            width: this.openSidebar
              ? `calc((100vw - 320px - 80px) / ${this.dateRange.length})`
              : `calc((100vw - 160px - 80px) / ${this.dateRange.length})`,
          };
    },
    columnWrapperWidth() {
      return this.expandedDisplayType
        ? {
            width: `${380 * this.dateRange.length}px`,
          }
        : {
            width: this.openSidebar
              ? "calc(100vw - 320px - 80px)"
              : "calc(100vw - 160px - 80px)",
          };
    },
  },
  watch: {
    selectedDates: {
      deep: true,
      handler(newValue) {
        this.isCurrent =
          newValue?.from.isSame(moment().clone().startOf("week"), "day") &&
          newValue?.to.isSame(moment().clone().endOf("week"), "day");
        this.isShownIndicator = this.isCurrent;
        if (this.timer) {
          this.stopTimer();
          this.timeCoilInitialization();
        }
        if (this.isCurrent) {
          this.changeCurrentTime();
          this.timeCoilInitialization();
          this.startTimer();
        }
      },
    },
  },
  mounted() {
    this.changeCurrentTime();
    this.timeCoilInitialization();
  },
};
</script>

<style lang="sass" scoped>
@import "@/pages/newCalendar/sass/wrapperStyle.sass"
</style>
