<template lang="pug">
  svg(
    v-if="partTooth === 'crown'",
    :width="getSizeSvg.width",
    :height="getSizeSvg.height",
    :viewBox="getSizeSvg.viewBox",
    fill="none",
    xmlns="http://www.w3.org/2000/svg"
  )
    g(clip-path="url(#clip0_4088_279519)")
      g(v-if="view === 'artificial'")
        path(
          d="M16.7175 16.7175C15.8354 17.5997 14.7881 18.2994 13.6355 18.7769C12.4829 19.2543 11.2476 19.5 10 19.5C8.75244 19.5 7.5171 19.2543 6.36451 18.7769L6.17316 19.2388L6.36451 18.7769C5.21191 18.2994 4.16464 17.5997 3.28249 16.7175C2.40033 15.8354 1.70056 14.7881 1.22314 13.6355C0.745725 12.4829 0.5 11.2476 0.5 10C0.5 8.75244 0.745725 7.5171 1.22314 6.36451C1.70056 5.21191 2.40033 4.16464 3.28249 3.28249C4.16464 2.40033 5.21192 1.70056 6.36451 1.22314C7.5171 0.745725 8.75244 0.5 10 0.5C11.2476 0.5 12.4829 0.745725 13.6355 1.22314C14.7881 1.70056 15.8354 2.40033 16.7175 3.28249C17.5997 4.16464 18.2994 5.21191 18.7769 6.36451C19.2543 7.5171 19.5 8.75244 19.5 10C19.5 11.2476 19.2543 12.4829 18.7769 13.6355C18.2994 14.7881 17.5997 15.8354 16.7175 16.7175Z",
          stroke="#252850"
          )
        circle(
          cx="10",
          cy="10",
          r="7.5",
          fill="#D3D4DC"
          )
      g(v-else)
        g(v-if="data.general === 'О'")
          path(
            d="M31.1435 31.1174C29.5183 32.7413 27.5889 34.0294 25.4654 34.9082C23.342 35.787 21.066 36.2394 18.7676 36.2394C16.4691 36.2394 14.1932 35.787 12.0697 34.9082C9.94627 34.0294 8.01687 32.7413 6.39168 31.1174C4.7665 29.4936 3.47735 27.5659 2.59783 25.4443C1.71831 23.3227 1.26562 21.0488 1.26562 18.7525C1.26562 16.4561 1.7183 14.1823 2.59783 12.0607C3.47735 9.93912 4.7665 8.01138 6.39169 6.38756C8.01687 4.76373 9.94628 3.47562 12.0697 2.59679C14.1932 1.71796 16.4691 1.26562 18.7676 1.26562C21.066 1.26563 23.342 1.71796 25.4654 2.59679C27.5889 3.47562 29.5183 4.76373 31.1435 6.38756C32.7687 8.01138 34.0578 9.93912 34.9374 12.0607C35.8169 14.1823 36.2696 16.4561 36.2696 18.7525C36.2696 21.0488 35.8169 23.3227 34.9374 25.4443C34.0578 27.5659 32.7687 29.4936 31.1435 31.1174Z",
            stroke="#9294A7"
            )
        g(v-else-if="['ИЗ','В','ИК'].includes(data.general)" transform="translate(2)")
          path(d="M31.4796 31.4713C29.8079 33.1415 27.8234 34.4664 25.6393 35.3704C23.4552 36.2743 21.1143 36.7395 18.7503 36.7395C16.3862 36.7395 14.0453 36.2743 11.8612 35.3704C9.6771 34.4665 7.69258 33.1415 6.02095 31.4713C4.34931 29.8011 3.02329 27.8182 2.11861 25.636C1.21393 23.4537 0.748291 21.1147 0.748291 18.7527C0.748291 16.3906 1.21393 14.0517 2.11861 11.8694C3.02329 9.68713 4.34931 7.70427 6.02095 6.03404C7.69258 4.3638 9.67711 3.0389 11.8612 2.13498C14.0453 1.23105 16.3862 0.765808 18.7503 0.765808C21.1143 0.765809 23.4552 1.23105 25.6393 2.13498C27.8234 3.0389 29.8079 4.3638 31.4796 6.03404C33.1512 7.70427 34.4772 9.68713 35.3819 11.8694C36.2866 14.0517 36.7522 16.3906 36.7522 18.7527C36.7522 21.1147 36.2866 23.4537 35.3819 25.636C34.4772 27.8182 33.1512 29.8011 31.4796 31.4713Z" fill="#252850")
          mask(id="mask0_4460_279697" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="37" height="37")
            path(d="M31.4796 31.4711C29.8079 33.1414 27.8234 34.4663 25.6393 35.3702C23.4552 36.2741 21.1143 36.7394 18.7503 36.7394C16.3862 36.7394 14.0453 36.2741 11.8612 35.3702C9.6771 34.4663 7.69258 33.1414 6.02095 31.4711C4.34931 29.8009 3.02329 27.818 2.11861 25.6358C1.21393 23.4535 0.748291 21.1146 0.748291 18.7525C0.748291 16.3904 1.21393 14.0515 2.11861 11.8692C3.02329 9.68695 4.34931 7.70409 6.02095 6.03386C7.69258 4.36362 9.67711 3.03872 11.8612 2.13479C14.0453 1.23087 16.3862 0.765625 18.7503 0.765625C21.1143 0.765625 23.4552 1.23087 25.6393 2.13479C27.8234 3.03872 29.8079 4.36362 31.4796 6.03386C33.1512 7.70409 34.4772 9.68695 35.3819 11.8692C36.2866 14.0515 36.7522 16.3904 36.7522 18.7525C36.7522 21.1146 36.2866 23.4535 35.3819 25.6358C34.4772 27.818 33.1512 29.8009 31.4796 31.4711Z" fill="#252850")
          g(mask="url(#mask0_4460_279697)")
            rect(x="20.9553" y="48.1551" width="2" height="49" transform="rotate(-150 20.9553 48.1551)" fill="white" fill-opacity="0.3")
            rect(x="17.9912" y="45.2891" width="2" height="48" transform="rotate(-150 17.9912 45.2891)" fill="white" fill-opacity="0.3")
            rect(x="14.0271" y="44.1551" width="2" height="49" transform="rotate(-150 14.0271 44.1551)" fill="white" fill-opacity="0.3")
            rect(x="11.063" y="41.2891" width="2" height="48" transform="rotate(-150 11.063 41.2891)" fill="white" fill-opacity="0.3")
            rect(x="7.59888" y="39.2891" width="2" height="48" transform="rotate(-150 7.59888 39.2891)" fill="white" fill-opacity="0.3")
            rect(x="4.13477" y="37.2891" width="2" height="48" transform="rotate(-150 4.13477 37.2891)" fill="white" fill-opacity="0.3")
            rect(x="0.670654" y="35.2891" width="2" height="48" transform="rotate(-150 0.670654 35.2891)" fill="white" fill-opacity="0.3")
            rect(x="-2.79346" y="33.2891" width="2" height="48" transform="rotate(-150 -2.79346 33.2891)" fill="white" fill-opacity="0.3")
            rect(x="-6.25757" y="31.2891" width="2" height="48" transform="rotate(-150 -6.25757 31.2891)" fill="white" fill-opacity="0.3")
            path(d="M31.1262 31.1174C29.501 32.7413 27.5716 34.0294 25.4481 34.9082C23.3246 35.787 21.0487 36.2394 18.7503 36.2394C16.4518 36.2394 14.1759 35.787 12.0524 34.9082C9.92894 34.0294 7.99954 32.7413 6.37435 31.1174C4.74916 29.4936 3.46001 27.5659 2.58049 25.4443C1.70097 23.3227 1.24829 21.0488 1.24829 18.7525C1.24829 16.4561 1.70097 14.1823 2.58049 12.0607C3.46001 9.93912 4.74916 8.01138 6.37435 6.38756C7.99954 4.76373 9.92894 3.47562 12.0524 2.59679C14.1759 1.71796 16.4518 1.26562 18.7503 1.26562C21.0487 1.26563 23.3246 1.71796 25.4481 2.59679C27.5716 3.47562 29.501 4.76373 31.1262 6.38756C32.7514 8.01138 34.0405 9.93912 34.92 12.0607C35.7995 14.1823 36.2522 16.4561 36.2522 18.7525C36.2522 21.0488 35.7995 23.3227 34.92 25.4443C34.0405 27.5659 32.7514 29.4936 31.1262 31.1174Z" stroke="#252850")
        g(v-else)
          g
            path(
              d="M26.1322 26.1118C22.6171 29.624 16.918 29.624 13.4029 26.1118M13.3926 26.1015C9.89147 22.5922 9.89126 16.9137 13.392 13.4041M13.4029 13.3932C16.918 9.88102 22.6171 9.88102 26.1322 13.3932M26.1431 13.4041C29.6439 16.9137 29.6437 22.5922 26.1425 26.1015M25.7788 25.7581C22.459 29.0752 17.0762 29.0752 13.7563 25.7581C10.4367 22.4413 10.4367 17.0637 13.7563 13.7469C17.0762 10.4298 22.459 10.4298 25.7788 13.7469C29.0985 17.0637 29.0985 22.4413 25.7788 25.7581ZM7.75075 32.4661L13.4121 26.8095C17.0188 30.0581 22.5164 30.0581 26.1231 26.8095L31.7844 32.4661C30.2369 33.9263 28.4346 35.0932 26.4654 35.9082C24.342 36.787 22.066 37.2394 19.7676 37.2394C17.4691 37.2394 15.1932 36.787 13.0697 35.9082C11.1006 35.0932 9.2983 33.9263 7.75075 32.4661ZM32.4919 31.7593L26.8305 26.1027C30.0813 22.4989 30.0813 17.0061 26.8305 13.4023L32.4919 7.74571C33.9536 9.29209 35.1216 11.093 35.9374 13.0607C36.8169 15.1823 37.2696 17.4561 37.2696 19.7525C37.2696 22.0488 36.8169 24.3227 35.9374 26.4443C35.1216 28.412 33.9536 30.2129 32.4919 31.7593ZM26.1231 12.6955C22.5164 9.44691 17.0188 9.44691 13.4121 12.6955L7.75075 7.03892C9.2983 5.57869 11.1006 4.41176 13.0697 3.59679C15.1932 2.71796 17.4691 2.26562 19.7676 2.26562C22.066 2.26563 24.342 2.71796 26.4654 3.59679C28.4346 4.41176 30.2369 5.57869 31.7844 7.03892L26.1231 12.6955ZM12.7047 26.1027L7.04333 31.7593C5.58161 30.2129 4.41354 28.412 3.59783 26.4443C2.71831 24.3227 2.26562 22.0488 2.26562 19.7525C2.26562 17.4561 2.7183 15.1823 3.59783 13.0607C4.41354 11.093 5.58161 9.29209 7.04333 7.74571L12.7047 13.4023C9.45391 17.0061 9.45391 22.4989 12.7047 26.1027Z",
              stroke="#252850"
            )
            path(
              @click="(el) => clickPart(el.target.id)",
              :id="editable && 'crown_lower'",
              :class="{'edit-part': editable}",
              d="M29.5234 31.7754C29.802 32.0662 29.7555 32.5373 29.4182 32.7575C26.5287 34.6441 23.1246 36.0162 19.6995 35.9394C16.2673 35.8624 12.8886 34.7337 10.0788 32.7673C9.75567 32.5412 9.72375 32.0808 10.0001 31.7996L13.1122 28.6337C13.3445 28.3973 13.7156 28.3737 13.9901 28.5594C15.7771 29.7687 17.5777 30.0976 19.8296 30.1481C22.0882 30.1988 23.8151 29.7034 25.6461 28.5283C25.9184 28.3536 26.2784 28.3877 26.5021 28.6212L29.5234 31.7754Z",
              :fill="data.crown.lower.fill"
            )
            path(
              @click="(el) => clickPart(el.target.id)",
              :id="editable && 'crown_upper'",
              :class="{'edit-part': editable}",
              d="M10.02 7.83259C9.73773 7.54247 9.78271 7.06817 10.1218 6.84692C13.0102 4.96209 16.4123 3.59159 19.8354 3.66837C23.2653 3.74529 26.6418 4.87262 29.4506 6.83662C29.7757 7.06395 29.8058 7.52786 29.5252 7.80822L26.2828 11.048C26.0499 11.2806 25.6816 11.3026 25.4106 11.1157C23.6415 9.89566 21.9585 9.51017 19.7053 9.45964C17.4456 9.40896 15.8222 9.95753 14.0063 11.1421C13.737 11.3177 13.3792 11.2851 13.155 11.0547L10.02 7.83259Z",
              :fill="data.crown.upper.fill"
            )
            path(
              @click="(el) => clickPart(el.target.id)",
              :id="editable && 'crown_right'",
              :class="{'edit-part': editable}",
              d="M31.759 10.0672C32.0484 9.7804 32.5272 9.82331 32.75 10.1644C34.6342 13.049 36.0037 16.4456 35.9269 19.8633C35.8499 23.2903 34.7217 26.6639 32.756 29.4704C32.5285 29.7952 32.0642 29.8253 31.7836 29.5449L28.5411 26.3052C28.3083 26.0725 28.2862 25.7045 28.4733 25.4337C29.6944 23.6662 30.0802 21.9845 30.1308 19.7333C30.1815 17.4738 29.6311 15.9223 28.4438 14.1173C28.2686 13.8509 28.2992 13.4965 28.5257 13.2719L31.759 10.0672Z",
              :fill="data.crown.right.fill"
            )
            path(
              @click="(el) => clickPart(el.target.id)",
              :id="editable && 'crown_left'",
              :class="{'edit-part': editable}",
              d="M7.84119 10.0499C7.55039 9.77014 7.07763 9.81602 6.85683 10.1537C4.96938 13.0404 3.59672 16.4408 3.67359 19.8623C3.75057 23.2893 4.87885 26.663 6.8445 29.4694C7.07202 29.7943 7.53632 29.8243 7.81691 29.544L11.0594 26.3042C11.2922 26.0716 11.3143 25.7036 11.1272 25.4328C9.90611 23.6652 9.52029 21.9836 9.46972 19.7323C9.41901 17.4751 9.92564 15.7802 11.1039 13.9551C11.2789 13.684 11.2454 13.3252 11.0129 13.1015L7.84119 10.0499Z",
              :fill="data.crown.left.fill"
            )
            ellipse(
              @click="(el) => clickPart(el.target.id)",
              :id="editable && 'crown_central'",
              :class="{'edit-part': editable}",
              cx="19.7675",
              cy="19.7948",
              rx="7.0722",
              ry="7.06627",
              :fill="data.crown.central.fill"
            )
            circle(
              v-if="data.crown.central.injury",
              cx="2.89014",
              cy="2" r="2",
              fill="#252850",
              transform="translate(16.8 17.6)"
              )
            circle(
              v-if="data.crown.left.injury",
              cx="2.89014",
              cy="2" r="2",
              fill="#252850",
              transform="translate(3.7 17.6)"
              )
            circle(
              v-if="data.crown.right.injury",
              cx="2.89014",
              cy="2",
              r="2",
              fill="#252850",
              transform="translate(30 17.6)"
              )
            circle(
              v-if="data.crown.upper.injury",
              cx="2.89014",
              cy="2" r="2",
              fill="#252850",
              transform="translate(16.8 4.6)"
              )
            circle(
              v-if="data.crown.lower.injury",
              cx="2.89014",
              cy="2",
              r="2",
              fill="#252850",
              transform="translate(16.8 30.9)"
              )
  svg(
    v-if="partTooth === 'general'",
    :class="{'rotate-180': rotateSvg}",
    :width="getSizeSvg.width",
    :height="getSizeSvg.height",
    :viewBox="getSizeSvg.viewBox",
    fill="none",
    xmlns="http://www.w3.org/2000/svg"
    )
    molars-tooth(
      v-if="toothConfig.type === 'molars'",
      :view="view",
      :clickPart="clickPart",
      :editable="editable",
      :data="data"
      )
    pre-molars-fangs-tooth(
      v-if="toothConfig.type === 'pre-molars' || toothConfig.type === 'fangs'",
      :view="view",
      :clickPart="clickPart",
      :editable="editable",
      :data="data"
      )
    incors-tooth(
      v-if="toothConfig.type === 'incisors'",
      :view="view",
      :clickPart="clickPart",
      :editable="editable",
      :data="data"
      )
</template>

<script>
import { sizesDetailedSvg } from "@/pages/newMedicalCard/utils/medicalConfig";
import MolarsTooth from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/teeth/MolarsTooth.vue";
import PreMolarsFangsTooth from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/teeth/PreMolarsFangsTooth.vue";
import IncorsTooth from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/teeth/IncorsTooth.vue";
import { getFilledConditions } from "@/pages/newMedicalCard/utils/gettersObjects";

export default {
  name: "SimpleToothSvg",
  components: { MolarsTooth, PreMolarsFangsTooth, IncorsTooth },
  data() {
    return {
      sizesDetailedSvg,
      getFilledConditions,
    };
  },
  props: {
    partTooth: String,
    toothConfig: Object,
    jawPosition: String,
    clickPart: {
      default: () => {},
      type: Function,
    },
    view: {
      default: "native",
      type: String,
    },
    editable: Boolean,
    oversized: {
      default: 0,
      type: Number,
    },
    data: Object,
  },
  computed: {
    rotateSvg() {
      if (this.view === "artificial") {
        if (this.jawPosition === "lower") {
          return true;
        }
      } else {
        if (this.jawPosition === "upper") {
          return true;
        }
      }
      return false;
    },
    getSizeSvg() {
      let size = this.sizesDetailedSvg[this.partTooth][this.view];
      if (this.partTooth === "general" && this.view === "native") {
        size = size[this.toothConfig?.type];
      }
      let resultSize = {
        width: size.width + this.oversized,
        height: size.height + this.oversized,
        viewBox: `0 0 ${size.width} ${size.height}`,
      };
      if (
        this.partTooth === "general" &&
        ["ИК", "ИЗ"].includes(this.data.general)
      ) {
        resultSize.width += 20;
      }
      return resultSize;
    },
  },
};
</script>

<style lang="sass" scoped>
.edit-part
  cursor: pointer
  &:hover
    fill: #F3CED0
</style>
