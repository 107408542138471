<template lang="pug">
  .w-full
    .header.pl-3.pr-6.pt-14px.pb-10px.flex.items-center.justify-between
      q-btn(
        size="14px"
        color="dark"
        flat,
        :label="internalMonth"
        no-caps,
        padding="10px 12px"
        @click="switchForm"
      )
      .arrows.flex.items-center.justify-center.gap-x-6
        q-btn(
          flat,
          size="10px",
          dense,
          icon="arrow_back_ios_new",
          text-color="black",
          round,
          @click="previousMonth"
        )
        q-btn(
          flat,
          icon="arrow_forward_ios",
          size="10px",
          text-color="black",
          dense,
          round,
          @click="nextMonth"
        )
    .week.py-4.px-5.grid.grid-rows-1.grid-cols-7
      .flex.items-center.justify-center.week-item.text-xsx.py-1(v-for="day of week", :key="day") {{ day }}
    .body.pb-3.px-5
      .flex.items-center.justify-center.cursor-pointer.w-full.h-full(
        v-for="date of internalDatesList",
        @click="selectDate(date)",
        :key="date",
        :style="calculateRangeStyle(date)",
      )
        .flex.items-center.justify-center.rounded-full.w-full.h-full(
          :class="calculateCellClasses(date)",
        ) {{ date.format("D") }}
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CalendarDates",
  props: {
    internalDate: Object,
    externalDate: Object,
    range: Boolean,
    rangeCount: Number,
    internalMonth: String,
    changeForm: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      week: ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"],
    };
  },
  computed: {
    ...mapState({
      today: (state) => state.calendar.currentDate,
    }),
    internalValue: {
      get() {
        return this.internalDate;
      },
      set(value) {
        this.$emit("update:internalDate", value);
      },
    },
    externalValue: {
      get() {
        return this.externalDate;
      },
      set(value) {
        this.$emit("update:externalDate", value);
      },
    },
    internalStartDate() {
      return this.internalValue
        .clone()
        .startOf("month")
        .startOf("week")
        .subtract(1, "day");
    },
    internalDatesList() {
      return [...Array(42)].map(() =>
        this.internalStartDate.add(1, "day").clone()
      );
    },
  },
  methods: {
    switchForm() {
      this.changeForm("CalendarYears");
    },
    previousMonth() {
      this.internalValue = this.internalValue.clone().subtract(1, "month");
    },
    nextMonth() {
      this.internalValue = this.internalValue.clone().add(1, "month");
    },
    calculateCellClasses(date) {
      if (this.defineDateRange(date))
        return {
          "date-active": true,
        };
      if (
        this.calculateRangeStyle(date) &&
        Object.keys(this.calculateRangeStyle(date) === 1)
      )
        return {
          "text-white": true,
          "date-range": true,
        };
      if (date.isSame(this.today, "day"))
        return {
          "date-today": true,
        };
      if (date.isSame(this.internalDate, "month"))
        return {
          "date-month": true,
        };
      return {
        "date-other": true,
      };
    },
    calculateRangeStyle(date) {
      if (
        this.range &&
        this.externalValue?.from &&
        this.externalValue?.to &&
        !this.externalValue?.from.isSame(
          this.externalValue?.to?.format("YYYY-MM-DD")
        )
      ) {
        if (date.isSame(this.externalValue?.from?.format("YYYY-MM-DD")))
          return {
            "border-top-left-radius": "9999px",
            "border-bottom-left-radius": "9999px",
            "background-color": "var(--btn-blue-color-5)",
          };
        if (date.isSame(this.externalValue?.to?.format("YYYY-MM-DD")))
          return {
            "border-top-right-radius": "9999px",
            "border-bottom-right-radius": "9999px",
            "background-color": "var(--btn-blue-color-5)",
          };
        if (
          date.isAfter(this.externalValue?.from) &&
          date.isBefore(this.externalValue?.to)
        )
          return {
            "background-color": "var(--btn-blue-color-5)",
          };
      }
    },
    selectDate(date) {
      if (!this.range) {
        this.externalValue = date.clone();
        return;
      }
      if (this.rangeCount) {
        this.externalValue.from = date;
        this.externalValue.to = this.externalValue.from
          .clone()
          .add(this.rangeCount - 1, "day");
        return;
      }
      if (this.externalValue?.to) {
        this.externalValue.from = date;
        this.externalValue.to = null;
        return;
      }
      if (date.isAfter(this.externalValue?.from)) {
        this.externalValue.to = date;
        return;
      }
      this.externalValue.to = this.externalValue.from;
      this.externalValue.from = date;
      return;
    },
    defineDateRange(date) {
      if (!this.range) {
        return date.isSame(this.externalValue?.format("YYYY-MM-DD"));
      }
      return (
        date.isSame(this.externalValue?.from?.format("YYYY-MM-DD")) ||
        date.isSame(this.externalValue?.to?.format("YYYY-MM-DD"))
      );
    },
  },
};
</script>

<style scoped lang="sass">
.header
  border-bottom: 1px solid var(--border-light-grey-color)
.week-item
  color: var(--font-grey-color)
  &:nth-last-child(-n+2)
    color: var(--border-red-color)
.date-active
  color: var(--default-white),
  background-color: var(--btn-blue-color)
  &:hover
    opacity: 0.5
.date-other
  color: var(--font-grey-color)
  &:hover
    background-color: var(--border-light-grey-color)
.date-month
  color: var(--font-black-color)
  &:hover
    background-color: var(--border-light-grey-color)
.date-today:hover, .date-range:hover
  background-color: var(--btn-blue-color-2)
.date-today
  border: 1px dashed var(--btn-blue-color)
  color: var(--btn-blue-color)
.body
  display: grid
  grid-template-columns: repeat(7, 35px)
  grid-template-rows: repeat(6, 35px)
  row-gap: 4px
.month:hover
  background-color: var(--bg-light-grey)
</style>
