<template lang="pug">
  .wrapper-documents.flex.flex-col.flex-auto.h-full.gap-y-8
    .flex.flex-col.gap-y-6
      span.title-info.text-base.font-bold Паспортные данные
      .grid.grid-cols-2.gap-x-4.gap-y-6
        base-input(
          v-model="form.series_number",
          mask="#### ######",
          placeholder="0000 000000",
          label="Серия и номер",
          size="M"
        )
        base-input(
          v-model="form.issued_by_org",
          placeholder="Точно как в паспорте",
          label="Кем выдан",
          size="M"
        )
        base-input(
          v-model="form.issued_by_org_code",
          mask="###-###",
          placeholder="000–000",
          label="Код подразделения",
          size="M"
        )
        base-input-date(
          v-model="form.issued_by_date",
          placeholder="Дата",
          label="Дата выдачи",
          size="M"
        )
    .flex.flex-col.gap-y-6
      span.title-info.text-base.font-bold СНИЛС и ИНН
      .grid.grid-cols-2.gap-x-4.gap-y-6
        base-input(
          v-model="form.snils",
          mask="###-###-### ##",
          placeholder="000–000–000 00",
          label="Номер СНИЛС",
          disabled,
          size="M"
        )
        base-input(
          v-model="form.inn",
          placeholder="000000000000",
          mask="############",
          label="Номер ИНН",
          disabled,
          size="M"
        )
</template>

<script>
import BaseInput from "@/components/base/BaseInput.vue";
import BaseInputDate from "@/components/base/BaseInputDate.vue";

export default {
  name: "FormCreateIdentityDocuments",
  components: { BaseInput, BaseInputDate },
  emits: ["change"],
  data() {
    return {
      form: {
        series_number: "",
        issued_by_org: "",
        issued_by_org_code: "",
        issued_by_date: "",
        snils: "",
        inn: "",
      },
    };
  },
  watch: {
    form(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper-documents
  height: 338px
  overflow-y: auto
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar-track
    background-color: rgba(211, 212, 220, 0.5)
    border-radius: 8px
  &::-webkit-scrollbar-thumb
    border-radius: 8px
    background-color: var(--btn-blue-color)

.title-info
  color: var(--font-dark-blue-color)

.input-date
  border: 1.5px solid var(--border-light-grey-color)
</style>
