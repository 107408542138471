<template lang="pug">
  .header-wrapper.flex.w-full.justify-between.p-4
    .flex.items-center.justify-center.gap-x-2
      base-input.search(
        :width="438",
        size="M",
        placeholder="ФИО Пациента",
        icon-left,
        v-model="filterString"
      )
        template(#iconLeft)
          q-icon.search-icon(name="app:search", size="20px")
    base-button(@click="showCreateModal = true")
      q-icon.plus.mr-2(name="app:plus", size="18px")
      span Новая медкарта
    base-modal(v-model="showCreateModal", title="Создать медицинскую карту", modal-padding)
      patient-creation-form(@close="handleClosePatientForm")
</template>

<script>
import BaseInput from "@/components/base/BaseInput.vue";
import BaseSelect from "@/components/base/BaseSelect.vue";
import BaseButton from "@/components/base/BaseButton.vue";

import debounce from "lodash/debounce";
import BaseModal from "@/components/base/BaseModal.vue";
import PatientCreationForm from "@/components/PatientCreationForm.vue";
import { mapActions } from "vuex";
export default {
  name: "MedicalCardSearchHeader",
  components: {
    PatientCreationForm,
    BaseModal,
    BaseInput,
    BaseSelect,
    BaseButton,
  },
  emits: ["search"],
  data() {
    return {
      filterString: "",
      showCreateModal: false,
    };
  },
  methods: {
    handleClosePatientForm(val) {
      this.showCreateModal = false;
      if (!val) return;
      this.getMedicalCardData({
        personId: val.id,
        successCallback: (id) => this.$router.push("medical-card/" + id),
      });
    },
    ...mapActions({
      getMedicalCardData: "getMedicalCardDataByPersonId",
    }),
  },
  watch: {
    filterString: debounce(function (value) {
      this.$emit("search", value);
    }, 300),
  },
};
</script>

<style lang="sass" scoped>
.header-wrapper
  height: 72px
  border-bottom: 1px solid var(--gray-secondary)

.search-icon :deep(path)
  fill: var(--font-grey-color)

.search :deep(.q-field__prepend)
  padding-right: 12px


.plus :deep(path)
  fill: var(--default-white)

.grey-color
  color: var(--font-grey-color)

.item
  border-bottom: 1px solid var(--gray-secondary)
  &:last-child
    border-bottom: none
  &:hover
    background-color: var(--gray-thirdly)

span
  cursor: default
</style>
