<template lang="pug">
  .medcards-wrapper.flex.flex-col.gap-y-2.h-full
    medical-card-search
</template>

<script>
import MedicalCardSearch from "./components/MedicalCardSearch.vue";
import TablePatients from "./components/TablePatients.vue";

export default {
  name: "TheMedcards",
  components: { MedicalCardSearch, TablePatients },
};
</script>

<style lang="sass">
.medcards-wrapper
  width: calc(100vw - 72px)
  overflow: hidden
</style>
