<template lang="pug">
  .table-header.flex.w-full.pr-2.pl-6
    q-checkbox(v-model="selectedAll", @click="select", size="32px")
    .flex.items-center.pr-9.justify-between.px-4(
      :style="{minWidth: '357px', maxWidth: '357px'}"
    )
      .text.font-semibold.text-sm Сотрудники
      q-icon(
        name="app:icon-sort-number",
        size="12px",
        style="color: var(--font-dark-blue-color)"
      )
    .flex.pl-4
      .flex.items-center.justify-center.w-11(
        v-for="day in result",
        :class="{'column-color': changeColumnColor(day)}",
        :style="{width: '82px'}"
      )
        .text.flex.font-semibold.text-smm(
          :style="{color: changeColor(day)}"
        ) {{day.format("D") + "\xa0"}}
        .text.flex.font-semibold.text-smm(
          :style="{opacity: '0.5', color: changeColor(day)}"
        ) {{formatDay(day)}}
</template>

<script>
export default {
  name: "ScheduleTableHeader",
  props: {
    result: Array,
    selectedAll: Boolean,
    select: Function,
  },
  methods: {
    changeColumnColor(day) {
      return day.format("ddd") === "сб" || day.format("ddd") === "вс"
        ? true
        : false;
    },
    changeColor(day) {
      return day.format("ddd") === "сб" || day.format("ddd") === "вс"
        ? "var(--border-red-color)"
        : null;
    },
    formatDay(day) {
      let str = day.format("ddd");
      return str.charAt(0).toUpperCase() + str.slice(1) + ".";
    },
  },
};
</script>

<style lang="sass" scoped>
.q-checkbox :deep(.q-checkbox__bg)
  border-radius: 4px
  border: 1px solid var(--font-grey-color)

.text
  color: var(--font-grey-color)

.table-header
  overflow: hidden
  height: 36px
  border-bottom: 1.5px solid var(--border-light-grey-color-1)
  border-top-left-radius: 2px
  border-top-right-radius: 2px
  color: var(--btn-blue-color)

.column-color
  background-color: var(--bg-white-color-1)
</style>
