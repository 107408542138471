<template lang="pug">
  .flex.p-1
    .wrapper-tooth.flex.h-250px.w-28.flex-col.rounded.justify-around.items-center
      detailed-tooth-svg(
        part-tooth="general",
        :click-part="clickPart",
        :tooth-config="cellConfig",
        :jaw-position="jawPosition",
        :oversized="20",
        :view="data.general === 'И' ? 'artificial': 'native'",
        :data="getToothImageConfig(data)",
        editable
        )
      detailed-tooth-svg(
        part-tooth="crown",
        :click-part="clickPart",
        :oversized="10",
        :view="data.general === 'И' ? 'artificial': 'native'",
        :data="getToothImageConfig(data)",
        editable
        )
      span.text-base.font-medium {{cellConfig.tooth_number}}
    .flex.flex-col.pt-2.pl-4.pr-1.gap-y-2.w-350px.h-250px
      span.font-bold.text-m Состояние зуба
      .scroll.flex.flex-col.h-250px.overflow-y-auto.gap-y-1.pb-14px
        q-expansion-item.expansion-item.rounded.mr-2.w-318px(
          v-for="(type, key) in formConfig.conditions",
          v-model="isOpenExpansion[key]",
          :label="type.label",
          @click="() => partId = ''"
          @after-show="()=>jumpToItem()"
          )
          .general-expaction.flex.flex-col.pl-4(v-if="!type?.partsTooth")
            .condition-item.flex.gap-11px.items-center.h-10.w-full(
              v-for="option in type.options",
            )
              q-radio(
                v-model="data[key]",
                :val="option.value"
                @update:model-value="updateConditions"
                dense,
                checked-icon="check_box",
                unchecked-icon="check_box_outline_blank",
              )
              .condition-label.flex.gap-x-2.h-full.w-full.items-center.text-smm
                span.font-semibold {{ option.value }}
                span.font-medium {{ option.label }}
          .flex.flex-col.pl-4(v-else)
            .flex.flex-col.gap-y-3
              .part-item.flex.flex-col(v-for="(part, partKey) in type?.partsTooth")
                span.part-label.text-smm.font-medium(:ref="`${key}_${partKey}`") {{part.label}}
                .condition-item.flex.gap-11px.items-center.h-10.w-full.pr-4(v-for="option in type.options")
                  q-checkbox(
                    v-model="data[key][partKey]", 
                    :val="option.value",
                    @update:model-value="updateConditions"
                    checked-icon="check_box",
                    unchecked-icon="check_box_outline_blank",
                    dense,
                    )
                  .condition-label.flex.gap-x-2.h-full.w-full.items-center.text-smm
                    span.font-semibold {{ option.value }}
                    span.font-medium {{ option.label }}
    q-menu(
      v-model="isOpenInfoSelected",
      anchor="top right",
      no-parent-event,
      :offset="[4,0]"
    )
      .h-258px.flex.flex-col.pl-4.pr-2.py-2.gap-y-3
        .flex.gap-x-2.text-m.font-bold
          span Выбранное
          span(:style="{'color': 'var(--font-grey-color)'}") {{listSelected.map((el) => el.value).flat().length}}
        .scroll.flex.flex-col.gap-y-3.h-full.pr-2
          .flex.flex-col.gap-y-2.text-smm.font-medium(v-for="condition in listSelected")
            .flex.gap-x-6px.items-center.whitespace-nowrap
              span.part-label {{formConfig.conditions[condition.type].label}}
              .light-grey-background.flex.w-1.h-1.rounded(v-if="condition.part")
              span.part-label(v-if="condition.part") {{formConfig.conditions[condition.type].partsTooth[condition.part].label}}
            .flex.flex-col.gap-y-1
              .grey-background.flex.px-3.py-7px.rounded.w-fit.whitespace-nowrap(
                v-for="value in [condition.value].flat()"
                ) {{`${value} – ${toothConditions[value]}`}}
</template>

<script>
import {
  getFilledConditions,
  getToothImageConfig,
} from "@/pages/newMedicalCard/utils/gettersObjects";
import { toothConditions } from "@/pages/newMedicalCard/utils/medicalConfig";
import DetailedToothSvg from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/DetailedToothSvg.vue";

export default {
  name: "DentalConditionForm",
  components: { DetailedToothSvg },
  data() {
    return {
      partId: "",
      isOpenInfoSelected: false,
      listSelected: [],
      isOpenExpansion: {
        general: false,
        root: false,
        crown: false,
      },
      getFilledConditions,
      getToothImageConfig,
      toothConditions,
    };
  },
  props: {
    data: Object,
    cellConfig: Object,
    formConfig: Object,
    jawPosition: {
      default: "upper",
      type: String,
    },
  },
  methods: {
    clickPart(id) {
      this.partId = id;
      let partGeneralKey = id.split("_")[0];
      if (this.isOpenExpansion[partGeneralKey]) {
        this.$refs[id][0].scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      } else {
        Object.keys(this.isOpenExpansion).forEach((key) => {
          if (key === partGeneralKey) {
            this.isOpenExpansion[key] = true;
          } else {
            this.isOpenExpansion[key] = false;
          }
        });
      }
    },
    jumpToItem() {
      if (this.partId) {
        this.$refs[this.partId][0].scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    },
    updateConditions() {
      let arraySelected = [...this.getFilledConditions(this.data)];
      if (arraySelected.length) {
        this.listSelected = [...arraySelected];
        this.isOpenInfoSelected = true;
      } else {
        this.isOpenInfoSelected = false;
      }
    },
  },
  created() {
    this.updateConditions();
  },
};
</script>

<style lang="sass" scoped>
.wrapper-tooth
  border: 1px solid var(--border-light-grey-color)
.expansion-item
  border: 1px solid var(--bg-light-grey)
  & .general-expaction
    & .condition-item:last-child
      & .condition-label
        border-bottom: none
  & .part-item:last-child
    & .condition-item:last-child
      & .condition-label
        border-bottom: none
.part-label
  color: var(--font-grey-color)
.light-grey-background
  background-color: var(--font-grey-color)
.grey-background
  background-color: var(--bg-light-grey)
.condition-label
  border-bottom: 1px solid var(--bg-light-grey)
.scroll
  &::-webkit-scrollbar
      width: 4px
</style>
