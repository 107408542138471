<template lang="pug">
.flex
  .col
    base-select.search(
      width="100%",
      size="M",
      placeholder="Найти пациента",
      icon-left,
      hide-dropdown-icon,
      use-input
      :input-debounce="500"
      :items="candidates",
      custom-option,
      :filter-fn="filterFn",
      :popup-content-style="{height: candidates?.length > 3 ? '216px' : ''}"
      v-model="value"
      ref="selectRef"
      @blur="pasteFullName"
    )
      template(v-slot:iconLeft)
        q-icon.search-icon(name="app:search", size="20px")
      template(v-slot:customOption="{props, data}")
        .w-full.item.px-4.py-2.flex.gap-x-3.cursor-pointer(
          v-bind="props"
        )
          .flex.flex-col.gap-y-1
            .text-dark.text-sm.font-medium {{ data.last_name + ' ' + data.first_name + ' ' + data.patronymic }}
      template(v-slot:beforeOptions)
        .h-10.w-full.px-4.pt-3.pb-2.flex.items-center
          span.text-m.grey-color {{message}}
            span.text-m.primary-color.cursor-pointer.pl-1(@click="createPerson") Создать
      template(v-slot:noOption)
        .h-10.w-full.px-4.pt-3.pb-2.flex.items-center
          span.text-m.grey-color {{message}}
            span.text-m.primary-color.cursor-pointer.pl-1(@click="createPerson") Создать
</template>

<script>
import BaseInput from "./BaseInput.vue";
import { fetchWrapper } from "@/shared/fetchWrapper";
import { v_model } from "@/shared/mixins/v-model";
import BaseSelect from "@/components/base/BaseSelect.vue";
export default {
  name: "BaseInputWithSearch",
  components: {
    BaseInput,
    BaseSelect,
  },
  mixins: [v_model],
  emits: ["create-person"],
  data() {
    return {
      fullName: "",
      message: "",
      showMessageBar: true,
      candidates: [],
      person: null,
    };
  },
  computed: {
    pickedFullName() {
      if (!this.value.first_name) return "";
      return (
        this.value.last_name +
        " " +
        this.value.first_name +
        " " +
        this.value.patronymic
      );
    },
  },
  watch: {
    pickedFullName() {
      this.pasteFullName();
    },
  },
  methods: {
    createPerson() {
      this.$refs?.selectRef?.hidePopup();
      this.$emit("create-person");
    },
    filterFn(val, update) {
      fetchWrapper.get(`persons/?full_name=${val}`).then((result) => {
        update(() => {
          this.candidates = result;
          this.showMessageBar = true;
          this.message = `Нужный ${val} не найден?`;
        });
      });
    },
    pasteFullName() {
      this.$nextTick(() => {
        this.$refs?.selectRef?.updateInputValue(this.pickedFullName, true);
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.search-icon :deep(path)
  fill: var(--font-grey-color)

.search :deep(.q-field__prepend)
  padding-right: 4px

.plus :deep(path)
  fill: var(--default-white)

.grey-color
  color: var(--font-grey-color)

.primary-color
  color: var(--btn-blue-color)

.item
  border-bottom: 1px solid var(--gray-secondary)
  &:last-child
    border-bottom: none
  &:hover
    background-color: var(--gray-thirdly)
</style>
