<template lang="pug">
  .calendar-header-wrapper.w-full.flex.items-center.p-4.justify-between
    base-input.search(
      iconLeft,
      :width="280",
      size="M",
      placeholder="Найти ...",
      icon-left
    )
      template(v-slot:iconLeft)
        q-icon.search-icon(name="app:search", size="20px")
    .flex.gap-x-4.items-center.justify-center
      q-btn(
        color="secondary",
        round,
        size="14px",
        dense,
        icon="arrow_back_ios_new",
        text-color="primary",
        padding="2px 11px 2px 8px",
        @click="previousWeek"
      )
      base-input.search(
        size="M"
        :width="386",
        v-model="currentWeek",
      )
        .h-5.w-5.flex.items-center.justify-center.ml-3
          q-icon.calendar-icon.text.cursor-pointer(
            :name="calendarVisibility ? 'app:cancel-mini' : 'app:calendar'",
            size="20px",
          )
            q-menu(
              :style="{'margin-top': '8px !important'}"
              v-model="calendarVisibility",
              transition-show="scale",
              transition-hide="scale"
              self="top middle",
              :offset="[160, 8]"
            )
              base-calendar(
                v-model="dates",
                range,
                :range-count="7",
                :save="saveDatesChange",
                :start-year="2000"
              )
      q-btn(
        color="secondary",
        icon="arrow_forward_ios",
        round,
        size="14px",
        text-color="primary",
        dense,
        @click="nextWeek"
      ) 
    .h-10.p-1.flex.items-center.justify-between.bg-secondary.rounded.text-grey-color.ml-52.border
      q-btn-toggle(
        v-model="value",
        :options="displayTypesList",
        no-caps,
        padding="0px"
      )
        template(v-slot:one)
          .w-12.h-8.flex.items-center.justify-center.rounded(
            :class="{'active-toggle': value === 'expanded'}"
          )
            date-switcher-svg(name-svg="expanded", :active="value === 'expanded'")
        template(v-slot:two)
          .w-12.h-8.flex.items-center.justify-center.rounded(
            :class="{'active-toggle': value === 'collapsed'}"
          )
            date-switcher-svg(name-svg="compressed", :active="value === 'collapsed'")
</template>

<script>
import { v_model } from "@/shared/mixins/v-model";
import DateSwitcherSvg from "@/pages/newCalendar/components/CalendarDateSwitcherSvg.vue";
import { headerMixin } from "@/pages/newCalendar/mixins/headerMixin.js";
export default {
  name: "CalendarHeader",
  mixins: [v_model, headerMixin],
  components: { DateSwitcherSvg },
  data() {
    return {
      displayTypesList: [
        {
          slot: "one",
          value: "expanded",
        },
        {
          slot: "two",
          value: "collapsed",
        },
      ],
      dates: {
        from: null,
        to: null,
      },
    };
  },
  computed: {
    currentWeek() {
      return `${this.dates?.from?.format(
        "D MMMM YYYY"
      )} — ${this.dates?.to?.format("D MMMM YYYY")}`;
    },
  },
  methods: {
    previousWeek() {
      const diff = this.dates?.to?.diff(this.dates?.from, "days") + 1;
      this.dates.from = this.dates.from.clone().subtract(diff, "day");
      this.dates.to = this.dates.to.clone().subtract(diff, "day");
    },
    nextWeek() {
      const diff = this.dates?.to?.diff(this.dates?.from, "days") + 1;
      this.dates.from = this.dates.from.clone().add(diff, "day");
      this.dates.to = this.dates.to.clone().add(diff, "day");
    },
    initializeDates() {
      this.dates = {
        from: this.selectedDates.from.clone(),
        to: this.selectedDates.to.clone(),
      };
    },
  },
  watch: {
    dates: {
      deep: true,
      handler(val) {
        if (
          !this.val?.from.isSame(this.selectedDates?.from) &&
          !this.val?.to.isSame(this.selectedDates?.to)
        )
          this.changeSelectedDates(val);
      },
    },
    selectedDates: {
      deep: true,
      immediate: true,
      handler(val) {
        if (
          !val?.from.isSame(this.dates?.from) ||
          !val?.to.isSame(this.dates?.to)
        )
          this.initializeDates();
      },
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/pages/newCalendar/sass/headerStyle.sass"
</style>
<style lang="sass">
.q-field--outlined.q-field--readonly .q-field__control:before
  border-style: solid !important
</style>
