<template lang="pug">
  .flex.flex-col.gap-y-2.wrapper.h-full
    allergies-form
</template>

<script>
import AllergiesForm from "@/pages/newMedicalCard/components/AllergiesForms/AllergiesForm.vue";

export default {
  name: "MedicalAlergiesWrapper",
  components: { AllergiesForm },
};
</script>
<style lang="sass" scoped>
.wrapper
  width: 83.2%
  @media (max-width: 600px)
    width: fit-content
  &::-webkit-scrollbar
    width: 0
</style>
