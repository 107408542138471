<template lang="pug">
  .flex.flex-col
    .line-wrapper
      .line.flex.items-center(
        v-for="hour in timeCount",
        :key="hour"
      )
        .middle-line
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CalendarBackground",
  computed: {
    ...mapState({
      workingHours: (state) => state.calendar.workingHours,
    }),
    timeCount() {
      return (
        this.workingHours.end.split(":")[0] -
        this.workingHours.start.split(":")[0]
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.line
  border-top: 1px solid var(--border-light-grey-color)
  height: 76px

.middle-line
  border-top: 1px dashed var(--border-light-grey-color)
  width: 100%
</style>
