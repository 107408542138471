import { createRouter, createWebHashHistory } from "vue-router";
import TheCalendar from "@/pages/newCalendar/TheCalendar";
import TheSettings from "@/pages/settings/TheSettings";
import TheMedcards from "@/pages/medcards/TheMedcards";
import TheLogin from "@/pages/login/TheLogin";
import LoggedInLayout from "@/components/LoggedInLayout";
import TheMedicalCard from "@/pages/newMedicalCard/TheMedicalCard";
import { fetchWrapper } from "@/shared/fetchWrapper";
import TheHome from "@/pages/home/TheHome";

const ifNotAuthenticated = async (to, from, next) => {
  const data = await fetchWrapper.get("users/me");
  if (!data) return next();
  return next("/");
};

const ifAuthenticated = async (to, from, next) => {
  const data = await fetchWrapper.get("users/me");
  if (!data) return next("/login");

  return next();
};

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/login",
      component: TheLogin,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/",
      component: LoggedInLayout,
      beforeEnter: ifAuthenticated,

      children: [
        { path: "", redirect: "home" },
        { path: "home", component: TheHome },
        { path: "calendar", component: TheCalendar },
        { path: "medcards", component: TheMedcards },
        { path: "settings", component: TheSettings },
        { path: "medical-card/:id", component: TheMedicalCard },
      ],
    },
  ],
});
