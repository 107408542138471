<template lang="pug">
  .wrapper-form.h-full
    .flex.flex-col.gap-y-6
      base-input(
        v-model="form.full_address",
        placeholder="Введите адрес целиком",
        label="Полный адрес",
        size="M"
      )
      .flex.items-center.justify-center.relative
        .line.absolute
        span.text-sm.separator.px-2 или
      .grid.grid-cols-2.gap-y-6.gap-x-4
        base-input(
          v-model="form.city",
          label="Город",
          placeholder="Введите город",
          disabled,
          size="M"
        )
        base-input(
          v-model="form.region",
          label="Область",
          placeholder="Введите область",
          disabled,
          size="M"
        )
        base-input(
          v-model="form.street",
          label="Улица",
          placeholder="Введите улицу",
          disabled,
          size="M"
        )
        base-input(
          v-model="form.house",
          label="Дом",
          placeholder="Номер дома",
          disabled,
          size="M"
        )
        base-input(
          v-model="form.flat",
          label="Квартира",
          placeholder="Номер квартиры",
          disabled,
          size="M"
        )
        base-input(
          v-model="form.zip_code",
          label="Индекс",
          mask="######",
          placeholder="000000",
          disabled,
          size="M"
        )
</template>

<script>
import BaseCustomSelect from "@/components/base/BaseCustomSelect";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "FormCreateAddresses",
  components: { BaseInput, BaseCustomSelect },
  emits: ["change"],
  data() {
    return {
      form: {
        full_address: "",
        city: "",
        region: "",
        street: "",
        house: "",
        flat: "",
        zip_code: "",
      },
    };
  },
  watch: {
    form(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper-form
  height: 380px
  width: 570px
  overflow-y: auto
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar-track
    background-color: rgba(211, 212, 220, 0.5)
    border-radius: 8px
  &::-webkit-scrollbar-thumb
    border-radius: 8px
    background-color: var(--btn-blue-color)

.line
  width: 570px
  height: 1px
  background-color: var(--border-light-grey-color)
  z-index: -1

.separator
  color: var(--font-dark-blue-color)
  background-color: var(--default-white)
</style>
