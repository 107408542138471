<template lang="pug">
  medical-form-wrapper(
    id="contacts"
    title="Контакты"
    :is-check-change="isCheckChange"
    :is-loading-data="isLoadingData"
    :is-edit="isEdit"
    :save="saveChange"
    :cancel="cancelEdit"
    :open-edit="openEdit"
    )
    q-form.form-wrap.gap-6.w-full(ref="formContacts")
      .flex.flex-col.gap-2(v-for="(contact, key) in contacts")
        .font-semibold.text-sm.whitespace-nowrap {{configData[key].title}}
        .flex.w-full.items-center.gap-4(v-for="(data, index) in contact")
          .label-field.font-sm.text-sm.whitespace-nowrap {{`${configData[key].fieldName} ${index+1} :`}}
          .w-full.items-center.flex.h-10
            .flex.w-full(v-if="key === 'networks'")
              .flex.rounded-full.cursor-pointer.items-center.gap-10px.py-1.pl-1.pr-2(
                v-if="data.id || data.filled"
                @click="() => copyLinkNetwork(data.value)"
                style="background-color: var(--bg-light-grey)"
              )
                q-icon.icon(:name="mapNetworks[data.category].icon" :class="{old: data.id, new: data.filled}")
                span.text-sm.font-medium {{ mapNetworks[data.category].title || data.value }}
              .flex.gap-10px.w-full(v-else v-click-outside="() => checkNetworksField(index)" :key="key+index")
                base-select-networks(
                  :list-data="Object.values(mapNetworks)"
                  :option-data="data.category"
                  :choose-option="(e) => chooseNetwork(e, index)"
                  :style-border="true"
                  :size-input="50"
                  )
                base-input(
                  v-model="data.value",
                  :name="key",
                  :rule="configData[key].rules",
                  size="M",
                  width="100%"
                )
            base-input(
              v-else,
              :readonly="!isEdit",
              v-model="data.value",
              :name="key",
              :mask="configData[key].inputMask",
              :rule="configData[key].rules",
              @update:model-value="checkChangeInput",
              size="M",
              width="100%"
            )
            q-icon.delete-icon.ml-2.cursor-pointer(
              v-if="isEdit"
              @click="() => deleteContact(key, index)"
              name="app:cancel-mini",
              size="24px"
            )
        q-btn.ml-2px(
          v-if="isEdit",
          color="primary",
          flat,
          :style="{'font-weight': 500, width: '76px', height: '40px'}",
          no-caps,
          padding="0",
          @click="(e) => addNewContact(e, key)"
          label="Добавить"
        )
</template>

<script>
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import BaseSelectNetworks from "@/components/base/BaseSelectNetworks.vue";
import { mapNetworks } from "@/pages/newMedicalCard/utils/medicalConfig";
import TheNotificationProvider from "@/components/Notifications/TheNotificationProvider.vue";
import { addNotification } from "@/components/Notifications/notificationContext";
import {
  checkChangeData,
  getFieldsNameUnvalidated,
} from "@/shared/utils/changesObjects";
import { contactsDataForm } from "@/pages/newMedicalCard/utils/medicalConfig";
import { mapState, mapGetters } from "vuex";
import BaseInput from "@/components/base/BaseInput.vue";
import { mapActions } from "vuex";

export default {
  name: "ContactsForm",
  components: {
    MedicalFormWrapper,
    BaseInput,
    BaseSelectNetworks,
    TheNotificationProvider,
  },
  data() {
    return {
      isEdit: false,
      isLoadingData: false,
      isCheckChange: false,
      configData: contactsDataForm,
      mapNetworks: mapNetworks,
    };
  },
  computed: {
    ...mapGetters({
      initContactsData: "getContactsData",
    }),
    ...mapState({
      contacts: (state) => state.medical.contactsData,
      personId: (state) => state.medical.medicalCard.person.id,
      initAllContacts: (state) => state.medical.medicalCard.person.contacts,
    }),
  },
  methods: {
    ...mapActions({
      createContact: "postCreateContact",
      updateContact: "postUpdateContact",
      delContacts: "deleteContact",
    }),
    checkNetworksField(index) {
      if (this.contacts.networks[index].category)
        this.contacts.networks[index].filled = true;
    },
    openEdit() {
      this.isEdit = true;
    },
    cancelEdit() {
      this.$store.dispatch("returnInitData");
      this.isEdit = false;
      this.isCheckChange = false;
    },
    updateContacts() {
      this.isLoadingData = true;
      const allNewContacts = [
        ...this.contacts.phones,
        ...this.contacts.emails,
        ...this.contacts.networks,
      ];
      const notEmptyContacts = allNewContacts.filter((el) => el.category);
      return this.changeContacts(this.initAllContacts, notEmptyContacts);
    },
    changeContacts(initData, notEmptyContacts) {
      const deleteRequests = initData
        .filter((el) => !notEmptyContacts.find((data) => data.id === el.id))
        .map((el) => this.delContacts({ id: el.id }));
      const requests = notEmptyContacts.map((el) => {
        const requestObj = {
          category: el.category,
          value: el.value,
        };
        delete requestObj.id;
        if (!el?.id)
          return this.createContact({ obj: requestObj, id: this.personId });
        if (
          el?.id &&
          checkChangeData(
            initData.find((obj) => obj.id === el.id),
            el
          )
        )
          return this.updateContact({ obj: requestObj, id: el.id });
      });
      return requests.concat(deleteRequests);
    },
    saveChange() {
      const form = this.$refs.formContacts;
      form.validate().then((validate) => {
        if (validate) {
          this.updateContacts();
          this.isLoadingData = false;
          this.isEdit = false;
          this.isCheckChange = false;
        } else {
          getFieldsNameUnvalidated(form).forEach((errorKey) => {
            addNotification(
              this.configData[errorKey].title,
              this.configData[errorKey].title,
              this.configData[errorKey].error,
              "error",
              5000
            );
          });
        }
      });
    },
    checkChangeInput() {
      this.isCheckChange = checkChangeData(
        this.initContactsData,
        this.contacts
      );
    },
    chooseNetwork(e, index) {
      this.contacts.networks[index].category = e.currentTarget.id;
    },
    addNewContact(e, key) {
      if (e.pointerType) {
        if (key === "networks") {
          this.contacts[key].push({
            filled: false,
            category: this.configData[key]?.category,
          });
        } else {
          this.contacts[key].push({
            category: this.configData[key]?.category,
          });
        }
      }
      this.checkChangeInput();
    },
    deleteContact(key, index) {
      this.contacts[key]?.splice(index, 1);
      this.checkChangeInput();
    },
    copyLinkNetwork(value) {
      navigator.clipboard.writeText(value);
    },
  },
};
</script>

<style lang="sass" scoped>
.icon
  &.old
    color: var(--btn-blue-color)
  &.new
    color: var(--icon-violet-color)
.form-wrap
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: repeat(1, 1fr)
  @media(max-width: 1440px)
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(2, 1fr)
.network-field
  background-color: var(--bg-light-grey)
.label-field
  min-width: 110px
  color: var(--font-grey-color)
.delete-icon :deep(path)
  fill: var(--font-grey-color)
.on-left
  margin-right: 10px
.q-btn :deep(.q-ripple)
  display: none
</style>
