<template lang="pug">
  .flex.flex-col
    .flex.flex-col.justify-between.py-6(:style="{height: '194px'}")
      .flex.flex-col(class="gap-y-1.5")
        .label.font-semibold.text-smm Категория
        .select.flex.items-center.justify-between.cursor-pointer.py-2.px-4.rounded(
          :class="{'open-select': isOpen}",
          @click="openMenu",
          v-click-outside="closeMenu"
        ) {{model.category ? model.category : "Выберите категорию"}}
          .flex.duration-100(:class="{'open-menu': isOpen}")
            q-icon(name="app:down-arrow")
        q-menu(
          v-if="isOpen",
          :style="{rowGap: '6px'}",
          style="margin-top: 4px !important",
          fit,
          anchor="bottom left",
          self="top left"
        )          
          .tag.item.flex.py-2.px-4.items-center.cursor-pointer.w-full.gap-x-2(
            @click="addCategory"
          )
            span Добавить категорию
          .menu-wrapper.flex.flex-col.h-40.overflow-y-auto
            .options.flex(v-for="item in options")
              .item.flex.py-2.px-4.items-center.cursor-pointer(
                @click="selected(item.label)"
              ) {{item.label}}
      .flex.flex-col(class="gap-x-1.5")
        base-input(
          v-model="model.tag",
          label="Текст тега",
          placeholder="Введите тег",
          size="M"
        )
    .buttons-group.flex
      base-button(
        type="secondary",
        width="146px"
        label="Отменить",
        padding="0",
        @click="closeModal"
      )
      base-button(
        width="146px",
        label="Создать",
        :disable="!(model.category && model.tag)",
        @click="createTag(model)",
      )
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "TagCreationForm",
  components: { BaseInput, BaseButton },
  props: {
    closeModal: Function,
    createTag: Function,
  },
  data() {
    return {
      model: { category: null, tag: "" },
      options: [
        { label: "Какая-то категория 1" },
        { label: "Какая-то категория 2" },
        { label: "Какая-то категория 3" },
        { label: "Какая-то категория 4" },
        { label: "Какая-то категория 5" },
        { label: "Какая-то категория 6" },
      ],
      isOpen: false,
    };
  },
  methods: {
    closeMenu() {
      this.isOpen = false;
    },
    addCategory() {
      this.options.push({
        label: `Какая-то категория ${this.options.length + 1}`,
      });
      this.model.category = this.options.at(-1)?.label;
    },
    openMenu() {
      this.isOpen = !this.isOpen;
    },
    selected(label) {
      this.model.category = label;
      this.isOpen = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.label
  color: var(--font-grey-color)

.select
  border: 1px solid var(--border-light-grey-color)
  transition: border-color 0.36s cubic-bezier(0.4, 0, 0.2, 1)
  width: 436px
  &:hover
    border: 1px solid rgba(0, 0, 0, 1)

.open-menu
  transform: rotate(180deg)

.open-select
  border: 1.5px solid var(--btn-blue-color)
  &:hover
    border: 1.5px solid var(--btn-blue-color)

.menu-wrapper
  margin-right: 5px
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar-track:vertical
    margin-top: 23px

.options
  border-bottom: 1px solid var(--border-light-grey-color)
  width: 419px
  &:last-child
    border-bottom: none

.item
  width: 419px
  &:hover
    background: var(--bg-hover-row-table)

.tag
  border-top: 1px solid var(--border-light-grey-color)
  color: var(--btn-blue-color)

.buttons-group
  padding: 16px 168px 0px 32px
  border-top: 1px solid var(--border-light-grey-color)
  margin-left: -32px
  margin-right: -32px
  column-gap: 7px
</style>
