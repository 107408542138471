<template lang="pug">
  .header-wrapper.relative.flex.justify-center.box-border.py-2.pl-4_75px.pr-6
    .flex.items-center.box-border.cursor-pointer.mr-auto
      img.logo-img.mr-29_25px(
        src="@/assets/images/logo.svg",
        alt="Logo",
        @click="redirectHomePage"
      )
    .flex.ml-auto.items-center.gap-x-4

      q-btn(
        flat,
        dense,
        :style="{'min-width': '40px', 'min-height': '40px'}",
        round,
      )
        q-icon.bell(name="app:bell", size="24px")
      .flex.justify-centflexer.items-center.relative
        q-btn(
          @click="openPopup",
          flat,
          padding="0",
          style="color: var(--font-grey-color)"
        )
          base-avatar(:size="36", :color="userData?.color", v-if="!userData?.avatar") {{changeName}}
          base-avatar(:size="32", v-else)
            img.h-full.object-cover(:src="url + userData?.avatar", alt="Avatar")
          .h-6.w-6.flex.items-center.justify-center.ml-1.pt-1
            q-icon.arrow(name="app:down-arrow", size="24px")
          q-menu(
            :offset="[60, 6]"
          )
            .menu.p-4
              .text-popup.flex.cursor-pointer.pl-2(@click="logout", v-close-popup) Выйти
</template>

<script>
import img from "@/assets/images/avatar.svg";
import chargePersonAvatar from "@/assets/images/charge-person-avatar.svg";
import HeaderInputs from "./HeaderInputs.vue";
import BaseAvatar from "@/components/base/BaseAvatar";
import BasePopup from "@/components/base/BasePopup.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import { fetchWrapper } from "@/shared/fetchWrapper";

export default {
  name: "TheHeader",
  components: {
    HeaderInputs,
    BaseAvatar,
    BasePopup,
    BaseButton,
  },
  props: {
    url: String,
    closeForm: Function,
    openForm: Function,
    isOpenForm: Boolean,
    setUpdatedClients: Function,
    writeCreatedClientId: Function,
  },
  data() {
    return {
      avatarSrc: img,
      chargePersonInfo: {
        avatarSrc: chargePersonAvatar,
        name: "Гордеев Николай Степанович",
      },
      showPopup: false,
      userData: {},
    };
  },
  computed: {
    changeName() {
      return `${this.userData?.last_name || ""} ${
        this.userData?.first_name || ""
      } ${this.userData?.patronymic || ""}`;
    },
    isOpenFormInternal: {
      get() {
        return this.isOpenForm;
      },
      set(val) {
        if (!val) this.closeForm();
        else this.openForm();
      },
    },
  },
  methods: {
    redirectHomePage() {
      this.$router.push("/");
    },
    async logout() {
      await fetchWrapper.post("auth/logout");
      this.$router.go("/login");
    },
    openPopup() {
      this.showPopup = !this.showPopup;
    },
  },
  async mounted() {
    this.userData = await fetchWrapper.get("users/me");
    this.$store.dispatch("setUserData", this.userData);
  },
};
</script>

<style lang="sass" scoped>
.header-buttons
  outline: none
  border: none
  background-color: transparent
  color: var(--font-dark-blue-color)

.header-wrapper
  width: 100%
  height: 56px
  background-color: var(--default-white)
  position: relative
  z-index: 11

.logo-img
  height: 32px
  width: 70px

.icon-down-arrow
  width: 24px
  height: 24px

.icon-bell
  width: 24px
  height: 24px
  color: var(--font-dark-blue-color)

.charge-person-container
  position: absolute

.text-popup
  color: var(--btn-blue-color)
  &:hover
    opacity: 0.8

.menu
  width: 160px
  max-width: 160px !important
  border-radius: 4px
  background-color: var(--default-white)
.on-right
  margin-left: 8px
.q-btn :deep(.q-ripple)
  display: none
.q-btn :deep(.q-focus-helper)
  display: none

.add
  &:deep(path)
    fill: var(--btn-blue-color)

.add-btn
  border: none

.bell :deep(path), .arrow :deep(path)
  fill: var(--font-dark-blue-color)
</style>
