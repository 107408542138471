<template lang="pug">
  .flex.gap-x-10
    .flex.flex-col.gap-y-4.text-smm
      //.flex.gap-x-3.items-center
      //  .text.font-semibold Статус:
      //  .flex.gap-x-1
      //    .input.flex.items-center.pl-4.gap-x-1.rounded-md
      //      img(:src="currentStatus.icon")
      //      .dark-blue.font-medium {{currentStatus.name}}
      //    q-btn.change.flex.w-7.h-7.rounded-md(dense, padding="4px 4px")
      //      q-icon.icon(name="app:folder", size="20px")
      //      q-menu(:offset="[140, 8]")
      //        .flex.p-2.flex-col
      //          .status.flex.items-center.gap-x-1.font-medium.text-smm.rounded.h-7(
      //            v-for="status in statuses",
      //            @click="choiceStatus(status)",
      //            v-close-popup
      //          )
      //            img(:src="status.icon")
      //            span {{status.name}}
      .flex.gap-x-3.items-center
        .text.font-semibold Дата:
        .flex.gap-x-1
          .input.flex.items-center.pl-4.rounded-md
            .dark-blue.font-medium {{currentDate.format("DD MMMM YYYY")}}
          q-btn.change.flex.w-7.h-7.rounded-md(dense, padding="4px 4px")
            q-icon.icon(name="app:calendar", size="20px")
            q-menu(
              transition-show="scale",
              transition-hide="scale",
              self="top middle",
              :offset="[118, 14]",
            )
              base-calendar(v-model="currentDate")
      .flex.gap-x-3.items-center
        .text.font-semibold Время:
        .flex.gap-x-1
          base-input.input.no-border(size="XS", mask="##:## - ##:##", v-model="times", :error="!!errors?.['start'] || !!errors?.['end']")
    .flex.h-14.gap-x-3.items-center.text-smm
      .text.font-semibold Медкарта:
      .flex.gap-x-1
        .name.flex.rounded-md.flex-col(@click="showMedcard")
          .flex.items-center.px-4.py-2.gap-x-2(:class="{'medcard': patient}")
            .photo.flex.h-10.w-10.items-center.justify-center
              img.w-10.h-10.rounded-full(v-if="patient", :src="patient.avatar")
              q-icon.icon(v-else, name="app:noname", size="24px")
            .flex.flex-col.font-medium.gap-y-2px
              .dark-blue {{patient ? trimPatientName(patient.last_name, patient.first_name, patient.patronymic) : "Имя Фамилия"}}
              .grey-color.text-xsx.rounded-md {{patient ? patient.birthday : "Дата"}}
          .indicator-default.flex.w-full.h-1
            .indicator.flex.h-1(:style="{width: patient ? patient.fill : '0%'}")
        base-modal(v-model="isShowMedcard", none-padding)
          medcard-modal(:data="patient")
        .change.flex.items-center.rounded-md
          q-btn(dense, padding="18px 4px", @click="isShowMedcards = true")
            q-icon.icon(name="app:folder", size="20px")
          base-modal(v-model="isShowMedcards", title="Медкарты", modal-padding)
            medcards-modal(
              :save-medcard="saveMedcard",
              :close-medcards="closeModalMedcards",
              :patients="patients"
            )
</template>

<script>
import noname from "@/assets/icons/noname.svg";
import BaseCalendar from "@/components/base/Calendar/BaseCalendar.vue";
import BaseModal from "@/components/base/BaseModal.vue";
import BaseTimeModal from "@/components/base/BaseTimeModal.vue";
import MedcardsModal from "./MedcardsModal.vue";
import { patientList } from "@/pages/newCalendar/utils/calendarConfig.js";
import { trimName } from "@/pages/newCalendar/utils/calendarFunctions.js";
import * as moment from "moment/moment";
import BaseInput from "@/components/base/BaseInput.vue";
import { v_model } from "@/shared/mixins/v-model";
import MedcardModal from "./MedcardModal.vue";

export default {
  name: "HeaderRecordForm",
  components: {
    BaseInput,
    BaseCalendar,
    BaseModal,
    BaseTimeModal,
    MedcardsModal,
    MedcardModal,
  },
  mixins: [v_model],
  props: {
    currentStatus: Object,
    statuses: Array,
    choiceStatus: Function,
    errors: Object,
  },
  data() {
    return {
      noname,
      currentDate: moment().clone(),
      isShowTime: false,
      isShowMedcards: false,
      isShowMedcard: false,
      patients: patientList.find(({ id }) => id === 2).data,
      times: "",
      data: [
        {
          start: "8:00",
          end: "8:30",
        },
        {
          start: "11:30",
          end: "12:00",
        },
      ],
      patient: null,
      trimPatientName: trimName,
    };
  },
  computed: {
    returnData() {
      const times = this.validateTimeString(this.times);

      return {
        start: this.currentDate
          .clone()
          .hours(times.start.hours())
          .minutes(times.start.minutes()),
        end: this.currentDate
          .clone()
          .hours(times.end.hours())
          .minutes(times.end.minutes()),
      };
    },
  },
  methods: {
    fixedFill(fill) {
      return 100 - fill.slice(0, -1) + "%";
    },
    closeModalMedcards() {
      this.isShowMedcards = false;
    },
    saveMedcard(medcard) {
      this.isShowMedcards = false;
      this.patient = medcard;
    },
    showMedcard() {
      if (this.patient) this.isShowMedcard = true;
    },
    validateTimeString(value = "") {
      const [start = "", end = ""] = value.split(" - ");
      this.validateTime(start);
      return {
        end: this.validateTime(end),
        start: this.validateTime(start),
      };
    },
    validateTime(value = "") {
      const [h, m = "00"] = value.split(":");
      return moment(`${h}:${m}`, "HH:mm");
    },
  },
  watch: {
    returnData(val) {
      this.value = val;
    },
  },
};
</script>

<style lang="sass" scoped>
.input
  width: 200px
  border: 1px solid var(--gray-secondary)
  & :deep(input)
    min-width: 50px
    --text-size: 14px
  & :deep(.q-field__control)
    padding-left: 1rem

.icon :deep(path)
  fill: var(--font-grey-color)

.dark-blue
  color: var(--font-dark-blue-color)
  min-width: 50px

.change
  background: var(--bg-light-grey)
  color: var(--font-grey-color)
  border: 1px solid var(--gray-secondary)

.status
  width: 160px
  height: 40px
  padding: 8px 10px
  &:hover
    background: var(--bg-light-grey)
    cursor: pointer

.text
  color: var(--font-grey-color)
  width: 76px

.medcard
  cursor: pointer
  &:hover
    background: var(--gray-secondary)

.name
  background: var(--default-white)
  width: 200px
  border: 1px solid var(--gray-secondary)

.photo
  background: var(--border-light-grey-color)
  border-radius: 50%

.indicator-default
  background: var(--gray-secondary)
  border-end-end-radius: 4px

.indicator
  background: var(--aqua-color)
  border-end-start-radius: 4px

.grey-color
  color: var(--font-grey-color)
</style>
