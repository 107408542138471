<template lang="pug">
  .form-wrapper.relative.h-fit.rounded.w-full
    transition(name="medical-form", mode="out-in")
      .loader.absolute.z-10.flex.items-center.justify-center.w-full.h-full.rounded(v-if="isLoadingData")
        base-loader
    q-btn.absolute.top-4.right-6(
      @click="deleteItem",
      v-if="!titleDelete && deleteItem",
      :style="{backgroundColor: 'var(--bg-light-grey)', color: 'var(--font-grey-color)',}",
      padding="10px",
      )
      q-icon(
        name="app:basket",
        size="20px",
        )
    .flex.flex-col.h-full.gap-25px.p-6
      .flex.w-full.justify-between(v-if="!noTopButtons")
        .flex.w-fit.gap-11px.items-center(v-if="!tag")
          span.font-bold.text-base.whitespace-nowrap {{title}}
          q-icon.edit-button.text-lg.cursor-pointer(
            name="app:edit",
            v-if="!isEdit && !noEditBtn",
            @click="openEdit",
            size="20px",
            )
        .flex.w-full.gap-11px.items-center.justify-between(v-else)
          span.font-bold.text-base.whitespace-nowrap {{title}}
          .flex.items-center.h-5(
            v-if="!template",
            :style="{color: 'var(--btn-blue-color)'}"
            )
            base-button(type="secondary", width="142px")
              q-icon.sample.mr-2(name="app:sample", size="20px")
              span.text-smm.font-medium Шаблоны
        .flex.w-fit.gap-10px.items-center.delete-button.cursor-pointer(
          v-if="!isEdit && titleDelete && deleteItem",
          @click="deleteItem"
          )
          q-icon(name="app:basket" size="20px")
          span.text-smm.font-medium {{ titleDelete }}
      slot
      .flex.h-fit.w-fit.gap-2(v-if="isEdit && buttonsPresence")
        base-button(
          type="secondary"
          label="Отменить",
          width="126px",
          @click="cancel"
        )
        base-button(
          v-if="isCheckChange",
          width="222px",
          label="Сохранить изменения",
          @click="save",
        )
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseLoader from "@/components/Loader/BaseLoader.vue";
import layers from "@/assets/icons/layers.svg";
export default {
  name: "MedicalFormWrapper",
  components: { BaseButton, BaseLoader },
  props: {
    tag: {
      type: Boolean,
      default: false,
    },
    template: Boolean,
    title: String,
    titleDelete: String,
    noEditBtn: Boolean,
    noTopButtons: Boolean,
    cancel: {
      type: Function,
      default: () => {},
    },
    save: {
      type: Function,
      default: () => {},
    },
    openEdit: {
      type: Function,
      default: () => {},
    },
    deleteItem: Function,
    isCheckChange: {
      type: Boolean,
      default: false,
    },
    isLoadingData: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    buttonsPresence: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      layers,
    };
  },
};
</script>

<style lang="sass" scoped>
.form-wrapper
  background-color: var(--default-white)
.edit-button
  & > :deep(svg > path)
    stroke: var(--btn-blue-color)
  color: var(--btn-blue-color)
  &:hover
    color: var(--btn-blue-color-hover)
.delete-button
  color: var(--font-grey-color)
  &:hover
    opacity: 0.7
.loader
  background-color: var(--default-white)
.medical-form-enter-from
  opacity: 0
  transform: translateY(0px)
  pointer-events: none
.medical-form-leave-to
  opacity: 0
  transform: translateY(0px)
  pointer-events: none
.medical-form-leave-active
  transition: 0.3s ease
.medical-form-move
  transition: 0.3s ease
.sample :deep(path)
  fill: var(--btn-blue-color)
</style>
