<template lang="pug">
  base-input-container.gap-y-2(:label="label", :style="{width: width + 'px', ...paddingVariable}")
    q-input.input(
      v-model="value",
      :name="name",
      :multiple="multiple",
      :input-style="{resize: resize, 'font-input': true, height: height}",
      :placeholder="placeholder",
      outlined,
      type="textarea",
      :readonly="readonly",
      :disable="disabled",
      :rules="rule",
      :lazy-rules="lazyRule",
      :item-aligned="itemAligned",
      no-error-icon,
      :autogrow="autogrow",
      :accept="accept",
      :debounce="debounce",
      :shadow-text="shadowText",
      :autofocus="autofocus",
      hide-bottom-space
    )
</template>

<script>
import BaseInputContainer from "@/components/base/BaseInputContainer.vue";

export default {
  name: "BaseInput",
  components: { BaseInputContainer },
  props: {
    multiple: Boolean,
    autofocus: Boolean,
    autogrow: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "",
    },
    resize: {
      type: String,
      default: "none",
    },
    shadowText: String,
    mask: String,
    debounce: [String, Number],
    width: Number,
    rule: Array,
    lazyRule: [Boolean, String],
    itemAligned: Boolean,
    modelValue: [String, Date, Number],
    placeholder: String,
    disabled: Boolean,
    label: String,
    readonly: Boolean,
    name: String,
    height: String,
    padding: {
      type: String,
      default: "12px 16px",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    paddingVariable() {
      return {
        "--padding": this.padding,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.font-input
  font-feature-settings: 'pnum' on, 'lnum' on

.input :deep(.q-field__native)
  font-weight: 500
  font-size: 16px
  line-height: normal
  color: var(--font-dark-blue-color)
  padding: var(--padding)
  &::placeholder
    color: var(--font-grey-color)
    opacity: 1

.input :deep(.q-field__control)
  color: var(--font-dark-blue-color)
  padding: 0 0
  &:before
    border-color: var(--border-light-grey-color) !important
    transition: none
  &:hover:before
    border-color: var(--font-grey-color) !important
  &:after
    border-width: 1px !important
    transition: none
    transform: none !important

.q-field--disabled :deep(.q-field__control > div)
  opacity: 1 !important

.q-field--outlined.q-field--disabled :deep(.q-field__native)
  color: var(--font-grey-color)

.q-field--outlined.q-field--disabled :deep(.q-field__control)
  background: var(--bg-light-grey) !important

.q-field--outlined.q-field--readonly :deep(.q-field__control)
  background: var(--bg-light-grey) !important
  &:before
    border-color: var(--bg-light-grey) !important
    transition: none
  &:hover:before
    border-color: var(--bg-light-grey) !important

.q-field--outlined.q-field--readonly :deep(.q-field__native)
  cursor: default

.q-field--error :deep(.q-field__bottom)
  padding: 4px 0 0 0
  font-weight: 500
  font-size: 12px
  line-height: 135% !important
</style>
