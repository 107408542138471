<template lang="pug">
  .preview-wrapper.bg-white
    .header.flex.mb-6
      .title.font-bold.text-xl.color-dark-blue Запись на {{ date }}, {{ time }}
      .icons.flex.items-center.gap-x-3.-mt-5.-mr-4.p-1
        q-icon.cursor-pointer.header-icon(name="app:dots", size="24px", color="dark")
        q-icon.cursor-pointer.header-icon(name="app:cancel", size="24px", color="dark", @click="hidePreview")
    .body.gap-y-4.text.text-sm
      .flex.items-center.gap-x-3(v-for="field in previewConfig", :key="field?.title")
        .field.color-grey.flex.items-center.justify-start.font-semibold {{ field?.title }}:
        .info.flex-1.rounded.h-full.py-2.px-4.flex.items-center.gap-x-1.color-dark-blue(v-if="field?.data")
          //img.h-5.w-5(:src="field?.icon ? this[field?.icon] : ''", v-if="field?.icon")
          span {{ field?.data ? this[field?.data] : "" }}
        .flex.gap-x-1.flex-1(v-if="field?.title === 'Пациент'")
          .nameplate.rounded.px-4.pt-2.pb-1.flex.gap-x-2.info.flex-1.items-center
            q-avatar(size="40px")
              img(:src="memberData?.photo ? memberData.photo : defaultImg")
            .flex.flex-col.gap-y-2px
              span.color-dark-blue {{ trimName(memberData?.last_name, memberData?.first_name, memberData?.patronymic) }}
              span.color-grey {{ birthday }}
          q-btn.flex-1(
            color="primary",
            no-caps,
            label="Медкарта",
            :style="{'font-weight': 500}"
            size="14px",
            @click="openMedicalCard(memberData.id)"
          )
            q-icon.arrow.ml-1(name="app:long-arrow", size="20px")
        .flex.gap-x-1.flex-1.h-full(v-if="field?.title === 'Услуги'")
          //.rounded.h-full.services.flex.flex-wrap.gap-1.p-1
          //  .py-2.px-4.color-dark-blue.rounded.h-7.relative(
          //    v-for="chip in services.list",
          //    :key="chip?.title",
          //    :id="chip?.title",
          //    :class="chipClass(chip?.title)"
          //    :style="{'background-color': chip?.color}"
          //  )
          //    span {{chip?.title}}
          //    .gradient.h-7.w-11.absolute.right-0(
          //      :id="chip?.title+'gradient'",
          //      :style="{background: `linear-gradient(270deg, ${chip?.color} 0%, rgba(255, 255, 255, 0.00) 100%)`}"
          //    )
          //  .info.color-dark-blue.rounded.h-7.px-4(v-if="services.remains > 0") +{{ services.remains }}
          //.rounded.info.color-grey.h-full.cost.flex.items-center.justify-center {{ services.cost }} ₽

</template>

<script>
import * as moment from "moment/moment";
import { mapState } from "vuex";
import { trimName } from "@/pages/newCalendar/utils/calendarFunctions.js";
import { mapActions } from "vuex";
import defaultImg from "@/assets/images/person.png";
import {
  recordPreviewConfig,
  statuses,
} from "@/pages/newCalendar/utils/calendarConfig.js";
export default {
  name: "CalendarRecordPreview",
  props: {
    previewVisibility: Boolean,
  },
  data() {
    return {
      defaultImg,
      previewConfig: recordPreviewConfig,
      trimName: trimName,
    };
  },
  computed: {
    internalPreview: {
      get() {
        return this.previewVisibility;
      },
      set(value) {
        this.$emit("update:previewVisibility", value);
      },
    },
    ...mapState({
      selectedRecordId: (state) => state.calendar.selectedRecordId,
      events: (state) => state.calendar.events || [],
    }),
    record() {
      return this.events?.find((elem) => elem.id === this.selectedRecordId);
    },
    start() {
      return moment.parseZone(this.record?.start);
    },
    end() {
      return moment.parseZone(this.record?.end);
    },
    date() {
      return this.start?.format("D MMMM YYYY");
    },
    time() {
      return this.start?.format("HH:mm") + " – " + this.end?.format("HH:mm");
    },
    statusLabel() {
      return statuses[0].data.find((elem) => elem.value === this.record?.status)
        ?.label;
    },
    statusIcon() {
      return statuses[0].data.find((elem) => elem.value === this.record?.status)
        ?.icon;
    },
    memberData() {
      return this.record?.person;
    },
    birthday() {
      return moment(this.memberData?.birth_date)?.format("MM.DD.YYYY");
    },
    // services() {
    //   let croppedServices = JSON.parse(JSON.stringify(this.record?.services));
    //   let remains = this.record?.services?.length - 3;
    //   if (remains > 0) croppedServices.length = 3;
    //   else remains = 0;
    //   return {
    //     list: croppedServices,
    //     remains: remains,
    //     cost: this.record?.services?.reduce((acc, curr) => acc + curr.cost, 0),
    //   };
    // },
  },
  methods: {
    openMedicalCard(id) {
      this.getMedicalCardData({
        personId: id,
        successCallback: (id) => this.$router.push("medical-card/" + id),
      });
    },
    hidePreview() {
      this.internalPreview = false;
    },
    chipClass(id) {
      this.$nextTick(() => {
        let chipRef = document.getElementById(id);
        if (chipRef?.offsetWidth > 200) {
          chipRef.classList.add("overflow");
          let gradient = document.getElementById(id + "gradient");
          gradient.style.display = "block";
        }
      });
    },
    ...mapActions({
      getMedicalCardData: "getMedicalCardDataByPersonId",
    }),
  },
};
</script>

<style lang="sass" scoped>
.header
  gap: 0 93px
.color-dark-blue
  color: var(--font-dark-blue-color)
.color-grey
  color: var(--font-grey-color)
.body
  display: grid
  grid-template-rows: repeat(3, 28px) 56px 68px
.field
  width: 76px
.info
  background-color: var(--bg-light-grey)
.nameplate
  border-bottom: 4px solid var(--bg-aqua-blue)
.arrow
  transform: rotate(180deg)
  :deep(path)
    fill: var(--default-white)
.cost
  width: 82px
.services
  border: 1px solid var(--bg-light-grey)
  flex-wrap: wrap !important
  width: 322px
  & > div
    display: flex
    align-items: center
    justify-content: center
.overflow
  width: 200px
  justify-content: start !important
  overflow: hidden
  white-space: nowrap
.gradient
  display: none
.header-icon :deep(path)
  fill: var(--font-dark-blue-color)
</style>
