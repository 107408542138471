<template lang="pug">
  .wrapper-bar.flex.flex-col.gap-y-4.p-5.justify-between
    .flex.w-full.justify-around.gap-x-4
      .time-wrapper.flex.justify-between.p-7
        base-input.items-center(
          type="time",
          v-model="times.start_time",
          label="Начало",
          size="M"
        )
        .flex.items-center.mt-6 —
        base-input.items-center(
          type="time",
          v-model="times.end_time",
          label="Конец",
          size="M"
        )
      .status-wrapper.flex.flex-col.gap-y-3.p-3
        .flex.justify-center.items-center.h-10
          span.font-semibold Статусы
        .flex.justify-around.gap-x-4(class="py-2.5")
          q-btn(
            v-for="item in buttons",
            :key="item",
            @click="choiceSchedule(item.class)",
            :label="item.name",
            :class="item.class",
            text-color="white",
            no-caps,
            style="width: 200px"
          )
      .graph-template.flex.flex-col.items-center.gap-y-4
        base-select.w-52(
          :items="templateList",
          v-model="template.item",
          placeholder="Выберите шаблон"
          label="Шаблоны графиков",
          size="M"
        )
    .flex.justify-center.gap-x-6.h-10
      q-btn(
        label="Сохранить",
        color="primary",
        style="width: 144px",
        padding="4px 24px",
        @click="createSchedules(); clearCell()",
        no-caps
      )
      q-btn(
        label="Удалить",
        style="background-color: var(--bg-event-red-color); width: 144px",
        text-color="white",
        padding="4px 24px",
        @click="deleteSchedule",
        no-caps
      )
</template>

<script>
import BaseSelect from "@/components/base/BaseSelect.vue";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "ScheduleBar",
  components: {
    BaseInput,
    BaseSelect,
  },
  props: {
    buttons: Array,
    template: Object,
    times: Object,
    createSchedules: Function,
    deleteSchedule: Function,
    clearCell: Function,
  },
  data() {
    return {
      templateList: [
        { label: "2/2", name: "2/2", symbol: "'2/2'" },
        { label: "Дежурство", name: "DUTY", symbol: "'Д'" },
        { label: "Смена", name: "FirstEvenSecondOdd", symbol: "'С'" },
        { label: "-", name: "None", symbol: false },
      ],
    };
  },
  methods: {
    choiceSchedule(item) {
      this.buttons.forEach((e) => (e.active = false));
      this.buttons.find((e) => e.class === item).active = true;
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper-bar
  height: 224px
  border: 1.5px solid var(--border-light-grey-color-1)
  border-radius: 4px
  width: calc(100vw - 136px)

.time-wrapper
  min-width: 320px
  height: 120px
  border: 1.5px solid var(--border-light-grey-color-1)
  border-radius: 4px

.status-wrapper
  min-width: 540px
  height: 120px
  border: 1.5px solid var(--border-light-grey-color-1)
  border-radius: 4px

.button-work
  background: #55CD76
  &:active
    background-color: #55CD76
    opacity: 1
    border: none
  &:hover
    background-color: #55CD76
    opacity: 0.8
    border: none

.button-vacation
  background: var(--btn-blue-color-2)
  opacity: 1
  &:active
    background: var(--btn-blue-color-2)
    opacity: 1
    border: none
  &:hover
    background: var(--btn-blue-color-2)
    opacity: 0.8
    border: none

.button-free
  background: #9294A7
  &:active
    background-color: #9294A7
    opacity: 1
    border: none
  &:hover
    background-color: #9294A7
    opacity: 0.8
    border: none

.graph-template
  width: 218px
  height: 120px
</style>
