<template lang="pug">
  .flex.flex-col.gap-y-6.pt-6.dark-blue
    .table-time.flex.p-4
      .top-block.flex.flex-col.-mr-4(v-for="time in timeCoil")
        .timeline.flex.text-smm.font-medium {{time.hour}}
        .side.flex.p-1.h-9
          q-btn.left-btn(:class="{'default-btn': !checkStartTime(time.hour)}", size="9px", padding="0 8px")
            q-icon.icon(v-if="checkStartTime(time.hour)", name="app:lock", size="20px")
            q-icon.icon-grey(v-else, name="app:plus", size="20px")
          q-btn.right-btn(:class="{'default-btn': !checkEndTime(time.hour)}", size="4px", padding="0 4px")
            q-icon.icon(v-if="checkEndTime(time.hour  )", name="app:lock", size="20px")
            q-icon.icon-grey(v-else, name="app:plus", size="20px")
    .flex.justify-between.items-center
      .flex.gap-2
        base-button(
          type="secondary",
          width="108px",
          label="Отмена",
          @click="closeModalTime",
        )
        base-button(
          width="132px",
          label="Сохранить",
          @click="closeModalTime"
        )
      .dark-blue.font-bold.text-6xl {{`${times.from} - ${times.to}`}}
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";

export default {
  name: "BaseTimeModal",
  props: { times: Object, closeModalTime: Function, data: Array },
  components: { BaseButton },
  data() {
    return { select: false };
  },
  computed: {
    validateStartTime() {
      return this.verifyTime("8:00");
    },
    validateEndTime() {
      return this.verifyTime("19:00");
    },
    timeCoil() {
      let time = [];
      for (let i = this.validateStartTime; i <= this.validateEndTime; i++) {
        time.push({ hour: `${i}:00`, check: false });
      }
      return time;
    },
  },
  methods: {
    verifyTime(dayTime) {
      let timeArray = dayTime.split(":").map((elem) => parseInt(elem, 10));
      let newTime = timeArray[1] > 30 ? timeArray[0] + 1 : timeArray[0];
      return newTime;
    },
    checkStartTime(time) {
      return this.data.find((e) => e.start === time || e.end === time)
        ? true
        : false;
    },
    checkEndTime(time) {
      let subtime = time.substr(0, 2) + ":30";
      return this.data.find((e) => e.end === subtime || e.start === subtime)
        ? true
        : false;
    },
  },
};
</script>

<style lang="sass" scoped>
.table-time
  width: 463px
  height: 174px
  border-radius: 6px
  flex-wrap: wrap !important
  border: 1px solid var(--gray-secondary)

.dark-blue
  color: var(--font-dark-blue-color)

.side
  border-left: 1px solid var(--font-dark-blue-color)
  margin-left: 16px
  margin-bottom: 24px
  height: 36px

.left-btn
  width: 29px
  border-radius: 6px 1px 1px 6px
  border: 1px solid var(--gray-secondary)

.right-btn
  width: 29px
  border-radius: 1px 6px 6px 1px
  border: 1px solid var(--gray-secondary)

.icon :deep(path)
  fill: var(--system-color-red)

.icon-grey :deep(path)
  fill: var(--font-grey-color)

.default-btn
  background: var(--gray-thirdly)
</style>
