<template lang="pug">
  .calendar-header-wrapper.w-full.flex.items-center.p-4.justify-between
    base-input.search(
      iconLeft,
      :width="280",
      size="M",
      placeholder="Найти ...",
      icon-left
    )
      template(v-slot:iconLeft)
        q-icon.search-icon(name="app:search", size="20px")
    .flex.gap-x-4.items-center.justify-center
      q-btn(
        color="secondary",
        round,
        size="14px",
        dense,
        icon="arrow_back_ios_new",
        text-color="primary",
        padding="2px 11px 2px 8px",
        @click="previousDate",
      )
      base-input.search(
        size="M"
        :width="332",
        v-model="currentDate",
        :shadow-text="inputShadowText"
      )
        .h-5.w-5.flex.items-center.justify-center.ml-3
          q-icon.calendar-icon.text.cursor-pointer(
            :name="calendarVisibility ? 'app:cancel-mini' : 'app:calendar'",
            size="20px",
          )
            q-menu(
              :style="{'margin-top': '8px !important'}"
              v-model="calendarVisibility",
              transition-show="scale",
              transition-hide="scale"
              self="top middle",
              :offset="[130, 8]"
            )
              base-calendar(
                v-model="date",
                :save="saveDatesChange",
                :start-year="2000"
              )
      q-btn(
        color="secondary",
        icon="arrow_forward_ios",
        round,
        size="14px",
        text-color="primary",
        dense,
        @click="nextDate"
      )
    .h-10.p-1.flex.items-center.justify-between.bg-secondary.rounded.text-grey-color.ml-52.border
      q-btn-toggle(
        v-model="value",
        :options="displayTypesList",
        no-caps,
        padding="0px 12px"
      )
</template>

<script>
import { v_model } from "@/shared/mixins/v-model";
import { headerMixin } from "@/pages/newCalendar/mixins/headerMixin.js";
import { capitalizeFirstChar } from "@/pages/newCalendar/utils/calendarFunctions.js";
export default {
  name: "CalendarHeader",
  mixins: [v_model, headerMixin],
  data() {
    return {
      displayTypesList: [
        {
          value: "day",
          label: "День",
        },
        {
          value: "week",
          label: "Неделя",
        },
      ],
      date: null,
    };
  },
  computed: {
    currentDate() {
      return this.date?.format("D MMMM YYYY");
    },
    inputShadowText() {
      return this.date?.isValid()
        ? capitalizeFirstChar(this.date?.format("dddd"))
        : "";
    },
  },
  methods: {
    previousDate() {
      this.date = this.date?.clone()?.subtract(1, "day");
    },
    nextDate() {
      this.date = this.date?.clone()?.add(1, "day");
    },
    initializeDates() {
      this.date = this.selectedDates.from.clone();
    },
  },
  watch: {
    date: {
      deep: true,
      handler(val) {
        if (!val?.isSame(this.selectedDates?.from, "day"))
          this.changeSelectedDates({
            from: val,
            to: val?.clone()?.endOf("day"),
          });
      },
    },
    selectedDates: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val?.from.isSame(this.date, "day")) this.initializeDates();
      },
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/pages/newCalendar/sass/headerStyle.sass"
.q-btn-toggle :deep(span)
  font-weight: 500
.q-btn-toggle :deep(.q-btn-item)
  height: 32px
</style>
<style lang="sass">
.q-field--outlined.q-field--readonly .q-field__control:before
  border-style: solid !important
</style>
