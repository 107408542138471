<template lang="pug">
  .flex.gap-x-2.w-full
    medical-protocols-list(:open-inspection="openInspection", no-fill-indicator)
    formulas-page-wrapper(:protocol-id="selectedProtocol", :go-to-protocol="goToProtocol")
</template>

<script>
import MedicalProtocolsList from "@/pages/newMedicalCard/components/InitialInspectionProtocol/MedicalProtocolsList.vue";
import FormulasPageWrapper from "@/pages/newMedicalCard/components/DentalFormulas/FormulasPageWrapper.vue";
import { mapActions } from "vuex";

export default {
  name: "MedicalDentalFormulasWrapper",
  components: { MedicalProtocolsList, FormulasPageWrapper },
  data() {
    return {
      selectedProtocol: null,
    };
  },
  props: {
    updateCurrentItem: Function,
  },
  methods: {
    ...mapActions({
      getProtocolData: "getProtocolData",
    }),
    openInspection(id) {
      this.selectedProtocol = id;
      this.getProtocolData({
        fillInspection: false,
      });
    },
    goToProtocol() {
      this.updateCurrentItem("MedicalProtocolsWrapper", { openProtocol: true });
    },
  },
};
</script>
