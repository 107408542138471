<template lang="pug">
  base-input-container.gap-y-2(:label="label", :style="{width: width + 'px', ...sizeVariable }")
    q-input.input(
      v-model="value",
      :name="name",
      :class="{'font-input': true}",
      :input-style="{ resize: resize}",
      :placeholder="placeholder",
      outlined,
      :readonly="readonly",
      :disable="disabled",
      :rules="[checkTime]",
      :lazy-rules="lazyRule",
      :item-aligned="itemAligned",
      mask="time",
      :accept="accept",
      :debounce="debounce",
      hide-bottom-space,
      no-error-icon
    )
      template(v-slot:append, v-if="!readonly && !disabled")
        q-icon(
          name="app:time",
          class="cursor-pointer",
          size="19px"
        )
          q-popup-proxy(cover, transition-show="scale", transition-hide="scale")
            q-time(v-model="value", mask="HH:mm")
              .flex.items-center.justify-end
                q-btn(
                  v-close-popup,
                  label="Закрыть",
                  color="primary",
                  flat,
                  no-caps
                )
</template>

<script>
import BaseInputContainer from "@/components/base/BaseInputContainer.vue";

export default {
  name: "BaseInputTime",
  components: { BaseInputContainer },
  props: {
    accept: {
      type: String,
      default: "",
    },
    resize: {
      type: String,
      default: "none",
    },
    debounce: [String, Number],
    width: Number,
    rule: Array,
    lazyRule: [Boolean, String],
    itemAligned: Boolean,
    modelValue: String,
    placeholder: String,
    disabled: Boolean,
    label: String,
    readonly: Boolean,
    iconLeft: Boolean,
    name: String,
    size: String,
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue ? this.modelValue : null;
      },
      set(value) {
        this.$emit("update:modelValue", this.checkTime(value) ? value : null);
      },
    },
    sizeVariable() {
      switch (this.size) {
        case "XS":
          return {
            "--input-height": "28px",
            "--text-size": "12px",
            "--line-height": "135%",
            "--px": "0 8px",
            "--py": "10px 0",
          };
        case "S":
          return {
            "--input-height": "32px",
            "--text-size": "12px",
            "--line-height": "135%",
            "--px": "0 8px",
            "--py": "10px 0",
          };
        case "M":
          return {
            "--input-height": "40px",
            "--text-size": "16px",
            "--line-height": "normal",
            "--px": "0 16px",
            "--py": "8px 0",
          };
        case "L":
          return {
            "--input-height": "48px",
            "--text-size": "16px",
            "--line-height": "normal",
            "--px": "0 16px",
            "--py": "8px 0",
          };
        default:
          return {
            "--input-height": "56px",
            "--text-size": "16px",
            "--line-height": "normal",
            "--px": "0 16px",
            "--py": "8px 0",
          };
      }
    },
  },
  methods: {
    checkTime(val) {
      return (
        !!val &&
        val.length === 5 &&
        val?.slice(0, 2) < 24 &&
        val?.slice(-2) < 60
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.font-input
  font-feature-settings: 'pnum' on, 'lnum' on

.input :deep(.q-field__native)
  font-weight: 500
  font-size: var(--text-size)
  line-height: var(--line-height)
  color: var(--font-dark-blue-color)
  padding: var(--py)
  &::placeholder
    color: var(--font-grey-color)
    opacity: 1

.input :deep(.q-field__control)
  height: var(--input-height) !important
  color: var(--font-dark-blue-color)
  padding: var(--px)
  &:before
    border-color: var(--border-light-grey-color) !important
    transition: none
  &:hover:before
    border-color: var(--font-grey-color) !important
  &:after
    border-width: 1px !important
    transition: none
    transform: none !important

.q-field--disabled :deep(.q-field__control > div)
  opacity: 1 !important

.q-field--outlined.q-field--disabled :deep(.q-field__native)
  color: var(--font-grey-color)

.q-field--outlined.q-field--disabled :deep(.q-field__control)
  background: var(--bg-light-grey) !important

.q-field--outlined.q-field--readonly :deep(.q-field__control)
  background: var(--bg-light-grey) !important
  &:before
    border-color: var(--bg-light-grey) !important
    transition: none
  &:hover:before
    border-color: var(--bg-light-grey) !important

.q-field--outlined.q-field--readonly :deep(.q-field__native)
  cursor: default

.q-field--error :deep(.q-field__bottom)
  padding: 4px 0 0 0
  font-weight: 500
  font-size: 12px
  line-height: 135% !important

.input :deep(.q-field__marginal)
  height: auto !important
</style>
