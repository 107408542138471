<template lang="pug">
  .w-full.flex
    calendar-sidebar(v-if="!isOpen", :open-sidebar="openSidebar", :create-form="createForm")
    calendar-open-sidebar(v-else, :open-sidebar="openSidebar", :create-form="createForm")
    component.ml-2(
      v-bind:is="calendarComponent",
      :open-sidebar="isOpen",
    )
    base-modal(v-model="isShowForm", title="Создание записи", modal-padding)
      create-event-form(:close-form="closeForm")
    base-modal(v-model="previewVisibility", :hideHeader="true", :modalPadding="true")
      calendar-record-preview(v-model:preview-visibility="previewVisibility")
</template>

<script>
import CalendarSidebar from "@/pages/newCalendar/components/CalendarSidebar";
import CalendarOpenSidebar from "@/pages/newCalendar/components/CalendarOpenSidebar";
import DoctorCalendarWrapper from "@/pages/newCalendar/components/doctorCalendar/CalendarWrapper";
import ManagerCalendarWrapper from "@/pages/newCalendar/components/managerCalendar/CalendarWrapper";
import CreateEventForm from "@/pages/newCalendar/components/CreateEventForm";
import * as moment from "moment/moment";
import BaseModal from "@/components/base/BaseModal.vue";
import CalendarRecordPreview from "./components/CalendarRecordPreview.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "TheCalendar",
  components: {
    CalendarSidebar,
    CalendarOpenSidebar,
    DoctorCalendarWrapper,
    ManagerCalendarWrapper,
    CreateEventForm,
    BaseModal,
    CalendarRecordPreview,
  },
  data() {
    return {
      isOpen: false,
      currentDate: moment(),
      isShowForm: false,
      previewVisibility: false,
    };
  },
  computed: {
    ...mapState({
      selectedRecordId: (state) => state.calendar.selectedRecordId,
      selectedDates: (state) => state.calendar.selectedDates,
      calendarShape: (state) => state.calendarShape,
    }),
    calendarComponent() {
      return this.calendarShape === "doctor"
        ? "doctor-calendar-wrapper"
        : "manager-calendar-wrapper";
    },
  },
  methods: {
    openSidebar() {
      this.isOpen = !this.isOpen;
    },
    createForm() {
      this.isShowForm = true;
    },
    closeForm() {
      this.isShowForm = false;
    },
    ...mapActions({
      changeSelectedRecordId: "changeSelectedRecordId",
      changeSelectedDates: "changeSelectedDates",
      getEvents: "getEvents",
    }),
    convertDate(date) {
      return moment(date?.split(".")?.reverse()?.join("-"));
    },
  },
  watch: {
    selectedRecordId(value) {
      if (value) this.previewVisibility = !!value;
    },
    previewVisibility(value) {
      if (!value) this.changeSelectedRecordId(null);
    },
    "$route.query": {
      immediate: true,
      deep: true,
      handler(value) {
        if (this.$route.path === "/calendar") {
          let dateType = this.calendarShape === "doctor" ? "week" : "day";
          if (!value?.start || !value?.end) {
            this.changeSelectedDates({
              from: moment().clone().startOf(dateType),
              to: moment().clone().endOf(dateType),
            });
            this.$router.replace({
              query: {
                start: this.selectedDates?.from?.format("DD.MM.YYYY"),
                end: this.selectedDates?.to?.format("DD.MM.YYYY"),
              },
            });
          } else if (
            value?.start !== this.selectedDates?.from?.format("DD.MM.YYYY") ||
            value?.end !== this.selectedDates?.to?.format("DD.MM.YYYY")
          ) {
            this.changeSelectedDates({
              from: this.convertDate(value?.start).clone().startOf("day"),
              to: this.convertDate(value?.end).clone().endOf("day"),
            });
          }
        }
      },
    },
    selectedDates: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (
          !newVal?.from?.isSame(oldVal?.from) ||
          !newVal?.to?.isSame(oldVal?.to)
        ) {
          this.getEvents();
          if (
            this.$route.query?.start !== newVal?.from?.format("DD.MM.YYYY") &&
            this.$route.query?.end !== newVal?.to?.format("DD.MM.YYYY")
          ) {
            this.$router.replace({
              query: {
                start: newVal?.from?.format("DD.MM.YYYY"),
                end: newVal?.to?.format("DD.MM.YYYY"),
              },
            });
          }
        }
      },
    },
  },
  beforeUnmount() {
    this.$route.query = {};
  },
};
</script>

<style lang="sass" scoped>
.close
  opacity: 0
  width: 0px
  height: 0
  transition: opacity 0.2s, width 0.3s

.active
  opacity: 1
  width: 232px
  height: 100%
  transition: opacity 0.5s, width 0.3s
</style>
