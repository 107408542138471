<template lang="pug">
  q-btn(
    style="width: 48px; height: 48px"
    :class="checkActive",
    :href="path",
    :id="id",
    @click="(e) => changeStylePage(e)"
    rounded
    padding="0px"
  )
    sidebar-button-svg(:name-svg="icon", :active="active")
</template>

<script>
import SidebarButtonSvg from "@/components/SidebarButtonSvg.vue";
export default {
  name: "ButtonSidebar",
  components: { SidebarButtonSvg },
  props: {
    path: String,
    id: String,
    changeStylePage: Function,
    active: Boolean,
    icon: String,
  },
  computed: {
    checkActive() {
      return this.active ? "active button" : "button";
    },
  },
};
</script>

<style lang="sass" scoped>
.button
  background-color: transparent
.active
  background-color: var(--bg-light-blue-color)
  color: var(--btn-blue-color)
.button:hover
  background-color: var(--bg-light-blue-color)
  color: var(--btn-blue-color)
  .hover :deep(path)
    fill: #9294A7
.q-btn :deep(.q-focus-helper)
  display: none
.q-btn :deep(.q-ripple)
  display: none
</style>
