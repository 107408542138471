<template lang="pug">
  medical-form-wrapper(
    title="Аллергии"
    :is-check-change="isCheckChange" 
    :is-loading-data="isLoadingData" 
    :is-edit="isEdit"
    :save="saveChange" 
    :cancel="cancelEdit" 
    :open-edit="openEdit"
    )
    q-form.flex.flex-col.gap-19px.w-full(ref="formAllergies")
      .flex.flex-col.gap-4.w-full
        .flex.items-center.w-full.justify-between(v-for="(allergy, index) in allergies")
          .w-32.justify-start
            .label-field.font-sm.text-sm.whitespace-nowrap {{ `Аллерген ${index+1}:` }}
          .flex.min-w-64.gap-2.w-full.items-center
            base-input(
              :readonly="!isEdit" 
              v-model="allergy.name"
              @update:model-value="checkChangeInput"
              name="name"
              :rule="configData.name.rules"
              :width="350"
              placeholder="Аллерген"
              size="M"
            )
            base-input.w-full(
              :readonly="!isEdit" 
              v-model="allergy.title"
              @update:model-value="checkChangeInput"
              name="title"
              :rule="configData.title.rules"
              placeholder="Проявление аллергии",
              size="M"
            )
            .delete-contact.icon-cancel.text-xxs.cursor-pointer(
              v-if="isEdit" 
              @click="() => deleteAllergy(index)"
            )
    q-btn.ml-2px(
      v-if="isEdit",
      color="primary",
      flat,
      :style="{'font-weight': 500, width: '104px', height: '24px'}",
      no-caps,
      padding="0",
      @click="(e) => addNewAllergy(e)" 
    )
      q-icon.icon(name="app:plus", size="24px", left)
      span Добавить
</template>

<script>
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import TheNotificationProvider from "@/components/Notifications/TheNotificationProvider.vue";
import { getFieldsNameUnvalidated } from "@/shared/utils/changesObjects";
import { addNotification } from "@/components/Notifications/notificationContext";
import { checkChangeData } from "@/shared/utils/changesObjects";
import { allergiesConfig } from "@/pages/newMedicalCard/utils/medicalConfig";
import { mapState, mapGetters } from "vuex";
import { getRequestArrayData } from "@/shared/utils/wrapperRequestChangeData";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "AllergiesForm",
  components: {
    MedicalFormWrapper,
    BaseInput,
    TheNotificationProvider,
  },
  data() {
    return {
      isEdit: false,
      isLoadingData: false,
      isCheckChange: false,
      configData: allergiesConfig,
    };
  },
  computed: {
    ...mapState({
      allergies: (state) => state.medical.allergies,
      personId: (state) => state.medical.medicalCard.person.id,
    }),
    ...mapGetters({
      initAllergies: "getAllergiesData",
    }),
  },
  methods: {
    updateAllergies() {
      const notEmptyAllergies = this.allergies.filter(
        (el) => el.name && el.title
      );
      return getRequestArrayData(
        this.initAllergies,
        notEmptyAllergies,
        "person",
        this.personId,
        "general",
        "allergic"
      );
    },
    saveChange() {
      const form = this.$refs.formAllergies;
      form.validate().then((res) => {
        if (res) {
          this.isLoadingData = true;
          Promise.allSettled(this.updateAllergies())
            .then(() => this.$store.dispatch("getMedicalCardData"))
            .then(() => {
              this.isLoadingData = false;
              this.isEdit = false;
              this.isCheckChange = false;
            });
        } else {
          getFieldsNameUnvalidated(form).forEach((errorKey) => {
            addNotification(
              this.configData[errorKey].title,
              this.configData[errorKey].title,
              this.configData[errorKey].error,
              "error",
              5000
            );
          });
        }
      });
    },
    cancelEdit() {
      this.$store.dispatch("returnInitData");
      this.isEdit = false;
      this.isCheckChange = false;
    },
    openEdit() {
      this.isEdit = true;
    },
    checkChangeInput() {
      this.isCheckChange = checkChangeData(this.initAllergies, this.allergies);
    },
    addNewAllergy(e) {
      if (e.pointerType) {
        this.allergies.push({
          name: null,
          title: null,
        });
      }
      this.checkChangeInput();
    },
    deleteAllergy(index) {
      this.allergies.splice(index, 1);
      this.checkChangeInput();
    },
  },
};
</script>

<style lang="sass" scoped>
.delete-contact
  color: var(--font-grey-color)
.label-field
  color: var(--font-grey-color)
.on-left
  margin-right: 4px
.q-btn :deep(.q-ripple)
  display: none
.icon :deep(path)
  fill: var(--btn-blue-color)
</style>
