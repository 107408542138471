<template lang="pug">
  .calendar-clock-column.flex.flex-col.items-end.pb-5.px-3
    span.text-base(
      v-for="hour in timeCoil",
      :key="hour",
      :class="currentHourStyle(hour)"
    ) {{ hour }}
</template>

<script>
import { convertTime } from "@/pages/newCalendar/utils/calendarFunctions.js";
export default {
  name: "CalendarClockColumn",
  props: {
    timeCoil: Array,
    currentTime: String,
    dayEndTime: Number,
    isCurrent: Boolean,
  },
  computed: {
    currentHour() {
      return convertTime(this.currentTime, 0, -6);
    },
    currentMinute() {
      return convertTime(this.currentTime, 3, -3);
    },
    isEndDay() {
      return this.dayEndTime === this.currentHour && this.currentMinute > 0;
    },
  },
  methods: {
    currentHourStyle(elem) {
      if (
        convertTime(elem, 0, 3) === this.currentHour &&
        !this.isEndDay &&
        this.isCurrent
      ) {
        return {
          "current-time": true,
          "font-bold": true,
        };
      }
      return {
        "current-time": false,
        "font-medium": true,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.current-time
  color: var(--bg-event-red-color)
.calendar-clock-column
  gap: 57px
  width: 80px
  height: 100%
  color: var(--font-dark-blue-color)
  background-color: var(--default-white)
</style>
