<template lang="pug">
  .flex.flex-col.gap-y-2.wrapper.h-full.w-full
    basic-data-form
    contacts-form
    documents-form
    insurance-form
</template>

<script>
import BasicDataForm from "@/pages/newMedicalCard/components/BasicDataForms/BasicDataForm.vue";
import DocumentsForm from "@/pages/newMedicalCard/components/BasicDataForms/DocumentsForm.vue";
import InsuranceForm from "@/pages/newMedicalCard/components/BasicDataForms/InsuranceForm.vue";
import ContactsForm from "@/pages/newMedicalCard/components/BasicDataForms/ContactsForm.vue";

export default {
  name: "MedicalBasicDataWrapper",
  components: { BasicDataForm, DocumentsForm, InsuranceForm, ContactsForm },
};
</script>
<style lang="sass" scoped>
.wrapper
  flex: 1
  overflow-y: auto
  min-width: 560px
  min-height: 350px
  @media (max-width: 600px)
    width: fit-content
</style>
