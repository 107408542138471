<template lang="pug">
  .list-wrapper.h-full.flex.gap-y-1.flex-col
    .background.h-72px.flex.w-full.justify-between.items-center.p-4.rounded
      span.text-2xl.font-bold Осмотры
      base-button(
        width="40px",
        @click="changeShownCreateModal(true)",
        v-if="createInspection"
      )
        q-icon.plus-icon(name="app:big-plus", size="24px")
    .p-4.rounded.background.list.flex.flex-col.gap-y-6
      .flex
        base-select(
          :style="{flex: 1}",
          :items="sortingYears",
          v-model="sortingYear",
          clearable,
          size="M"
        )
        q-btn.ml-2(
          icon="app:sort-number",
          :style="{width: '40px', height: '40px'}",
          padding="0",
          :class="sortingClass",
          @click="invertSorting"
        )
      .flex.flex-col.gap-y-2
        medical-protocol-card(
          v-for="protocol in sortingProtocols", :key="protocol.id",
          :protocol-data="protocol",
          :no-fill-indicator="noFillIndicator",
          @click="() => openInspection(protocol.id)"
        )
    base-modal(
      v-model="showModal",
      title="Создание осмотра"
    )
      medical-protocol-create-modal(
        :change-shown-create-modal="changeShownCreateModal",
        :create-inspection="createInspection"
      )
</template>

<script>
import BaseSelect from "@/components/base/BaseSelect.vue";
import BaseAvatar from "@/components/base/BaseAvatar.vue";
import MedicalProtocolCard from "@/pages/newMedicalCard/components/InitialInspectionProtocol/MedicalProtocolCard.vue";
import BaseModal from "@/components/base/BaseModal.vue";
import MedicalProtocolCreateModal from "@/pages/newMedicalCard/components/InitialInspectionProtocol/MedicalProtocolCreateModal.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import { mapState } from "vuex";
export default {
  name: "MedicalProtocolsList",
  components: {
    BaseSelect,
    BaseAvatar,
    MedicalProtocolCard,
    BaseModal,
    MedicalProtocolCreateModal,
    BaseButton,
  },
  props: {
    openInspection: Function,
    createInspection: Function,
    noFillIndicator: Boolean,
  },
  data() {
    return {
      sorting: false,
      sortingYear: "",
      activeProtocolId: 1,
      showModal: false,
    };
  },
  computed: {
    ...mapState({
      protocolsList: (state) => state.medical.protocolsList,
    }),
    sortingClass() {
      return this.sorting
        ? {
            "sort-icon-active": true,
          }
        : {
            "sort-icon-default": true,
          };
    },
    sortingYears() {
      if (!this.protocolsList) return [];
      let years = this.protocolsList
        .map((elem) => {
          return elem?.start.slice(0, 4);
        })
        .sort((first, second) => second - first);
      return this.sorting ? [...new Set(years.reverse())] : [...new Set(years)];
    },
    sortingProtocols() {
      if (!this.protocolsList) return [];
      let protocols = [];
      this.protocolsList.forEach((elem) =>
        protocols.push({
          id: elem.id,
          employee: {
            last_name: elem.employee?.last_name,
            first_name: elem.employee?.first_name,
            patronymic: elem.employee?.patronymic,
            job_title: elem.employee?.job_title,
            photo: elem.employee?.photo,
          },
          start: elem.start,
          end: elem.end,
          status: elem.status,
        })
      );
      if (this.sortingYear) {
        protocols = protocols.filter(
          (elem) => elem.start.slice(0, 4) === this.sortingYear
        );
      }
      let sorted = protocols.sort((first, second) => {
        let firstDate = first?.start.split("T")[0];
        let secondDate = second?.start.split("T")[0];
        if (firstDate > secondDate) return 1;
        if (firstDate < secondDate) return -1;
        return 0;
      });
      if (this.sorting) return sorted;
      return sorted.reverse();
    },
  },
  methods: {
    invertSorting() {
      this.sorting = !this.sorting;
    },
    changeShownCreateModal(value) {
      this.showModal = value;
    },
  },
};
</script>

<style lang="sass" scoped>
.list-wrapper
  width: 19.6%
.list
  height: calc(100% - 76px)
  overflow-y: auto
  &::-webkit-scrollbar
    width: 0
.background
  background-color: var(--default-white)
.sort-icon-default
  background-color: var(--bg-light-grey)
  color: var(--font-grey-color)
.sort-icon-active
  background-color: var(--btn-blue-color)
  color: var(--default-white)
.color-grey
  color: var(--font-grey-color)
.plus-icon :deep(path)
  fill: white
</style>
