<template lang="pug">
  medical-form-wrapper(
    :title="data.title", 
    :is-edit="isEdit", 
    :open-edit="openEdit",
    :buttonsPresence="!fillInspection", 
    :cancel="cancelEdit"
    )
    .thermometry-wrapper.grid.gap-x-20.gap-y-5
      .flex.flex-col.gap-y-4.items-start(v-if="isEdit" v-for="(dataValue, key) in protocol[data.key]")
        span.font-semibold.text-smm(v-if="data.key === 'thermometry'") {{ data.config[key].title }}
        base-input-number.w-full(
          v-model="dataValue.value",
          :step="data.config[key].step",
          :min="data.config[key].min",
          :max="data.config[key].max",
          reverse-value
          :label="data.config[key].label",
          :shadow-text="data.config[key].shadowText",
          :placeholder="`0${data.config[key].shadowText}`",
          size="M"
        )
        .flex.flex-col.gap-y-2.w-full(v-if="data.key === 'thermometry'")
          base-input.w-full(
            v-model="dataValue.result",
            label="Результат",
            size="M",
            placeholder="Введите диагноз...",
          )
          .results-wrapper.flex.flex-col.rounded.w-full.px-4.py-2
            .result.flex.w-full.justify-between.items-center.h-9(v-for="result in thermometryMap")
              .flex.gap-x-4
                q-radio(
                  v-model="dataValue.result",
                  :val="result.value",
                  :label="result.value",
                  size="40px",
                  dense,
                  checked-icon="check_box",
                  unchecked-icon="check_box_outline_blank",
                  )
              .alert-icon.flex.justify-center.items-center.rounded.w-6.h-6(v-if="result.colorWarning")
                q-icon(size="13px" name="warning_amber")
      .flex.flex-col.gap-y-2.items-start(
        v-else,
        :class="{'gap-y-4': data.key === 'tonometry'}"
        )
        .flex.flex-col.gap-y-3(v-for="(dataValue, key) in protocol[data.key]")
          .label.font-semibold.text-smm(v-if="data.key === 'tonometry'") {{ data.config[key].label }}
          .flex.gap-x-1.h-8(v-if="dataValue.value")
            .flex.h-full.w-20.justify-center.items-center.gap-x-1.rounded(
              v-if="data.key === 'thermometry'",
              :style="{'background-color': data.config[key].bg, 'color': data.config[key].color}"
              )
              q-icon(size="18px", :name="data.config[key].icon")
              span.text-smm.font-medium {{ `${dataValue.value}º` }}
            .flex.items-center.h-full.px-3.font-medium.text-smm.rounded(
              v-if="dataValue.result || (data.key === 'tonometry' && dataValue.value)", 
              :style="{'background-color': 'var(--bg-light-grey)'}") {{ dataValue?.result || `${dataValue.value} ${data.config[key].shadowText}` }}
            .flex.items-center.justify-center.rounded.w-8.h-full(
              v-if="data.config[key].colorWarning(dataValue?.result || dataValue.value)", 
              :style="{'background-color': data.config[key].colorWarning(dataValue.result || dataValue.value), 'color': 'white'}")
              q-icon(size="20px" name="warning_amber")
  </template>

<script>
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import BaseInputNumber from "@/components/base/BaseInputNumber.vue";
import { thermometryResultsMap } from "@/pages/newMedicalCard/utils/medicalConfig";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "ThermometryForm",
  components: { MedicalFormWrapper, BaseInput, BaseInputNumber },
  props: {
    data: Object,
    fillInspection: Boolean,
  },
  data() {
    return {
      isEdit: false,
      thermometryMap: thermometryResultsMap,
    };
  },
  computed: {
    ...mapState({
      protocol: (state) => state.medical.protocolData,
    }),
  },
  methods: {
    ...mapActions({
      getProtocolData: "getProtocolData",
    }),
    openEdit() {
      this.isEdit = true;
    },
    cancelEdit() {
      this.getProtocolData({
        key: this.data.key,
      });
      this.isEdit = false;
    },
  },
  watch: {
    fillInspection: {
      immediate: true,
      handler(value) {
        if (value) {
          this.isEdit = true;
        } else {
          this.isEdit = false;
        }
      },
    },
  },
  mounted() {
    if (this.fillInspection) {
      this.isEdit = true;
    }
  },
};
</script>

<style lang="sass" scoped>
.thermometry-wrapper
  grid-template-columns: repeat(2, 1fr),
  grid-template-rows: 1fr
  @media(max-width: 1176px)
    grid-template-columns: 1fr
    grid-template-rows: repeat(2, 1fr)
.results-wrapper
  border: 1px solid var(--border-light-grey-color)
.alert-icon
  background-color: var(--bg-light-grey)
  color: var(--font-grey-color)
.result
  border-bottom: 1px solid var(--bg-light-grey)
  &:last-child
    border: none
.label
  color: var(--font-grey-color)
</style>
