<template lang="pug">
  base-modal(v-model="value", title="Добавление записи" modal-padding)
    .form-wrapper.flex.flex-col.gap-y-6.justify-start.mt-1
      span.text-smm.font-medium.title-inputs {{`Дата: ${dataEntry.date}`}}
      .flex.flex-col.gap-y-4.w-full
        .flex.flex-col.gap-y-6px.justify-start.w-full
          span.text-smm.font-semibold.title-inputs Карта здоровья
          base-download(
            v-model="dataEntry.pictures",
            multiple,
            title-type="снимок",
            :returned-fields="['photo', 'id', 'name', 'size']"
          )
          .grey-border.rounded-md.flex.w-full.py-2.pr-4.pl-2.justify-between.items-center(v-for="picture in dataEntry.pictures")
            .flex.items-center
              img.grey-border.rounded.w-8.h-8.mr-3(:src="picture.photo")
              span.name.text-base.font-normal.mr-2.whitespace-pre-wrap.color-dark {{picture.name}}
              span.text-smm.font-normal.whitespace-nowrap(:style="{color: 'var(--font-dark-grey-color)'}") {{`${picture.size} МБ`}}
            q-icon.delete-img.cursor-pointer(
              @click="() => deleteImg(picture.id)",
              name="app:cancel", 
              size="15px",
              )
        .flex.flex-col.gap-y-6px.justify-start.w-full
          span.text-smm.font-semibold.title-inputs Комментарий врача
          base-textarea.w-full(
            v-model="dataEntry.comment",
            placeholder="Введите описание"
            height="92px"
          )
      .flex.gap-x-2.text-smm
        base-button(
          width="126px",
          type="secondary",
          @click="closeModal",
          label="Отменить"
        )
        base-button(
          width="132px",
          label="Сохранить",
          @click="createNewEntry",
        )
</template>

<script>
import BaseModal from "@/components/base/BaseModal.vue";
import BaseDownload from "@/components/base/BaseDownload.vue";
import { mapActions } from "vuex";
import { v_model } from "@/shared/mixins/v-model";
import moment from "moment";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseTextarea from "@/components/base/BaseTextarea.vue.vue";

export default {
  name: "HealthStateCreateModal",
  components: {
    BaseModal,
    BaseDownload,
    BaseButton,
    BaseTextarea,
  },
  mixins: [v_model],
  data() {
    return {
      dataEntry: {},
    };
  },
  methods: {
    ...mapActions({
      addNewListData: "addNewListData",
    }),
    deleteImg(id) {
      this.dataEntry.pictures = this.dataEntry.pictures.filter(
        (img) => img.id !== id
      );
    },
    closeModal() {
      this.value = false;
    },
    createNewEntry() {
      this.addNewListData({
        stateKey: "healthState",
        data: { ...this.dataEntry, id: Math.random },
      });
      this.value = false;
    },
  },
  computed: {
    getData() {
      return {
        date: moment().format("YYYY-MM-DD"),
        pictures: [],
        comment: "",
      };
    },
  },
  mounted() {
    this.dataEntry = JSON.parse(JSON.stringify(this.getData));
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (!this.value) {
          this.dataEntry = JSON.parse(JSON.stringify(this.getData));
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.color-dark
  color: var(--font-dark-blue-color)
.name
  max-width: 270px
.delete-img
  color: var(--font-grey-color)
  &:hover
    opacity: 0.7
.title-inputs
  color: var(--font-grey-color)
.form-wrapper
  width: 422px
.grey-border
  border: 1px solid var(--border-light-grey-color)
</style>
