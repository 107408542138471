import * as moment from "moment/moment";
import { fetchWrapper } from "@/shared/fetchWrapper";
moment.locale("ru");
const state = () => ({
  currentDate: moment(),
  workingHours: {
    start: "8:00",
    end: "19:00",
  },
  selectedDates: {
    from: moment().clone().startOf("week"),
    to: moment().clone().endOf("week"),
  },
  selectedRecordId: null,
  events: [],
});

const getters = {};

const actions = {
  changeSelectedDates({ commit }, dates) {
    commit("setSelectedDates", dates);
  },
  changeSelectedRecordId({ commit }, id) {
    commit("setSelectedRecordId", id);
  },
  getEvents({ commit, state }) {
    const start = state.selectedDates.from.format("YYYY-MM-DDTHH:mm:ss");
    const end = state.selectedDates.to.format("YYYY-MM-DDTHH:mm:ss");
    fetchWrapper.get(`events?start=${start}&end=${end}`).then((data) => {
      if (Array.isArray(data)) {
        commit("setEvents", data);
      }
    });
  },
};

const mutations = {
  setSelectedDates(state, data) {
    state.selectedDates = { ...data };
  },
  setSelectedRecordId(state, id) {
    state.selectedRecordId = id;
  },
  setEvents(state, events) {
    state.events = events;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
