<template lang="pug">
  medcard-form-wrapper.relative(
    title="Пациенты на сегодня",
    :quantity="patientsData.length",
    :link="redirectCalendar",
    title-link="В календарь"
  )
    .form-wrapper.flex.flex-col.overflow-y-auto(
      @scroll="scrollTo",
      id="scroll",
      :class="{'default-form': patientsData.length <= 3}"
    )
      .patient.flex.items-center.p-4.justify-between(
        v-for="(patient, index) in patientsData",
        :class="{'default-patient': patientsData.length <= 3}",
        @click="changeView(patient.id)"
      )
        .flex.text-base.font-medium.gap-x-3
          img.h-11.w-11.object-cover.rounded-full(:src="patient.avatar")
          .flex.flex-col.justify-center.gap-y-1
            .name.flex.relative {{trimName(patient.last_name, patient.first_name, patient.patronymic)}}
            .grey-color.text-smm {{trimBirthday(patient.birthday)}}
        .flex.flex-col.justify-center.w-full(:style="{width: '140px'}")
          .text-m.font-bold {{patient.time.from + " - " + patient.time.to}}
          .grey-color.font-medium.text-smm(
            :class="{'red-color': !index}"
            ) {{index ? "Время посещения" : "Скоро прием" }}
        .flex
          q-btn.btn(
            icon="app:medcard",
            size="18px",
            dense,
            padding="8px",
            style="color: var(--font-grey-color); background: var(--bg-light-grey)"
          )
      .gradient-name.flex.absolute(v-if="isGradient")
</template>

<script>
import * as moment from "moment";
import MedcardFormWrapper from "@/pages/medcards/components/MedcardFormWrapper.vue";
import { patientList } from "@/pages/newCalendar/utils/calendarConfig";

export default {
  name: "TodaysPatientsForm",
  components: { MedcardFormWrapper },
  data() {
    return {
      patientsData: patientList
        .find(({ label }) => label === "Пациенты")
        .data.sort((a, b) => {
          if (a.time.from > b.time.from) return 1;
          if (a.time.from < b.time.from) return -1;
          return 0;
        })
        .filter(
          (e) =>
            e.reception === moment().format("DD.MM.YYYY") &&
            e.time.from > moment().format("HH:MM")
        ),
      isGradient: false,
    };
  },
  methods: {
    trimBirthday(birthday) {
      return moment(birthday, "DD MM YYYY").format("DD MMMM YYYY") + " г.";
    },
    trimName(last, first, patronymic) {
      let fullName = last + " " + first + " " + patronymic;
      if (fullName.length > 30) return fullName.slice(0, 30);
      return fullName;
    },
    changeView(id) {
      let view = [];
      this.patientsData.map((e) => {
        if (e.id === id) {
          e.view = true;
          view.push(e);
          e.viewDate = moment().format("DD.MM.YYYY");
        }
      });
      localStorage.view = JSON.stringify(view);
    },
    redirectCalendar() {
      this.$router.push("/calendar");
    },
    scrollTo(event) {
      if (
        Math.abs(
          event.currentTarget.scrollHeight -
            event.currentTarget.clientHeight -
            event.currentTarget.scrollTop
        ) < 1
      )
        return (this.isGradient = false);
      this.isGradient = true;
    },
    checkScroll() {
      let elem = document.getElementById("scroll");
      if (Math.abs(elem.scrollHeight - elem.clientHeight - elem.scrollTop))
        this.isGradient = true;
    },
  },
  mounted() {
    this.checkScroll();
  },
};
</script>

<style lang="sass" scoped>
.form-wrapper
  margin-right: 8px
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar-track:vertical
    margin-top: 16px
    margin-bottom: 16px

.name
  width: 272px
  &:after
    content: ""
    text-align: right
    position: absolute
    bottom: 0
    right: 0
    width: 72px
    height: 24px
    background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.24) 50.73%, rgba(255, 255, 255, 0.00) 100%)
    pointer-events: none
  @media (max-width: 1820px)
    width: 340px

.patient
  border-bottom: 1px solid var(--gray-secondary)
  border-right: 1px solid var(--gray-secondary)
  margin-right: 8px
  height: 77px
  &:last-child
    border-bottom: none

.default-patient
  margin-right: 0px
  border-right: none
  &:last-child
    border-bottom: 1px solid var(--gray-secondary)

.default-form
  margin-right: 0px
  &:last-child
    border-bottom: none

.gradient-name
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)
  width: 580px
  height: 43px
  bottom: 0
  left: 0

.btn
  border: 1px solid var(--gray-secondary)
  color: var(--font-grey-color)
  background: var(--bg-light-grey)

.grey-color
  color: var(--font-grey-color)

.red-color
  color: var(--system-color-red)
</style>
