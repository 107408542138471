<template lang="pug">
  medical-form-wrapper(
    :title="data.title",
    :is-edit="isEdit",
    :cancel="cancelEdit",
    :open-edit="() => changeEdit(true)",
    :buttonsPresence="!fillInspection"
  )
    .index-wrapper.w-full(v-if="isEdit")
      .rounded.border.px-4.py-2(v-if="data.content?.list")
        .flex.flex-col
          .py-2.radio-item.flex.justify-between.items-center(v-for="item in data.content?.list")
            q-radio(
              v-model="modelValue",
              dense,
              :val="item?.value ? item.value : item",
              checked-icon="check_box",
              unchecked-icon="check_box_outline_blank"
            )
              span.text-sm.line-height.blue-color {{ item?.label ? item.label : item }}
            .alert-icon.flex.justify-center.items-center.rounded.w-6.h-6(v-if="item?.icon")
              q-icon(name="warning_amber", size="13")
      base-input-number(
        v-else,
        v-model="modelValue",
        :step="0.1",
        :max="10",
        :min="0"
        size="M"
      )
      .rounded.grey-background.py-3.px-4.blue-color
        span.font-bold.text-base.line-height Справка
        .text-sm.line-height.mt-2(:class="{'whitespace-pre-line': data.content?.whitespace}") {{ reference }}
    .w-full.flex.gap-x-1(v-if="!isEdit")
      .w-full.flex.gap-x-1(v-if="modelValue")
        .text-sm.line-height.blue-color.py-6px.px-3.grey-background.rounded {{ displayedValue }}
        .flex.rounded.w-8.h-8.justify-center.items-center.text-xl(:style="checkIconColor")
          q-icon(name="warning_amber", color="white")
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import { mapState } from "vuex";
import BaseInputNumber from "@/components/base/BaseInputNumber.vue";

export default {
  name: "IndexForm",
  components: {
    BaseButton,
    MedicalFormWrapper,
    BaseInputNumber,
  },
  props: {
    data: Object,
    fillInspection: Boolean,
  },
  data() {
    return {
      isEdit: false,
      modelValue: null,
    };
  },
  computed: {
    ...mapState({
      protocolData: (state) => state.medical.protocolData,
    }),
    state() {
      return this.protocolData[this.data.state];
    },
    findedListItem() {
      if (this.data.content?.list && this.modelValue)
        return this.data.content?.list.find(
          (elem) => elem.value === this.modelValue
        );
      return null;
    },
    reference() {
      if (this.findedListItem && this.findedListItem?.reference)
        return this.findedListItem?.reference;
      return this.data.content?.reference;
    },
    displayedValue() {
      if (this.findedListItem)
        return this.findedListItem?.readOnlyLabel
          ? this.findedListItem?.readOnlyLabel
          : this.findedListItem?.label;
      return this.modelValue;
    },
    checkIconColor() {
      if (this.findedListItem)
        return {
          "background-color": this.findedListItem?.color,
        };
      return {
        "background-color": this.data?.checkRangeColor(this.modelValue),
      };
    },
  },
  methods: {
    changeEdit(value) {
      this.isEdit = value;
    },
    cancelEdit() {
      this.modelValue = this.state;
      this.changeEdit(false);
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        if (this.fillInspection) this.protocolData[this.data.state] = value;
      },
    },
    state: {
      immediate: true,
      handler(value) {
        if (!this.fillInspection) this.modelValue = value;
      },
    },
    fillInspection: {
      immediate: true,
      handler(value) {
        if (value) {
          this.isEdit = true;
          this.modelValue = null;
        } else {
          this.isEdit = false;
          this.modelValue = this.state;
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.index-wrapper
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-template-rows: repeat(1, 1fr)
  column-gap: 80px
  @media(max-width: 1320px)
    grid-template-columns: repeat(1, 1fr)
    grid-template-rows: repeat(2, 1fr)
    row-gap: 20px

.border
  border: 1px solid var(--border-light-grey-color)

.line-height
  line-height: 135%

.blue-color
  color: var(--font-dark-blue-color)

.grey-background
  background-color: var(--bg-light-grey)

.radio-item
  border-bottom: 1px solid var(--bg-light-grey)
.radio-item:last-child
  border-bottom: none

.alert-icon
  background-color: var(--bg-light-grey)
  color: var(--font-grey-color)
</style>
