<template lang="pug">
  .flex.flex-col.items-center.justify-center(
    :style="{padding: '153px 370px'}"
  )
    .avatar-wrapper.flex.relative
      base-input(
        circle,
        type="file",
        id="image-upload",
        accept="image/.jpg,.jpeg,.png, .jpg",
        class="image-field",
        @change="(e)=>previewImage(e)",
        @click="compressed"
      )
      .avatar.flex.absolute.items-center.gap-x-6
        img.avatar.object-cover(for="image-upload", :src="image" )
        base-button(
          width="48px",
          @click="confirm",
          size="L",
          round,
          padding="2px 0 0 0"
        )
          q-icon.icon(name="app:ok", size="40px")
</template>

<script>
import addImageIcon from "@/assets/icons/photo.svg";
import { v_model } from "@/shared/mixins/v-model";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "BaseModalUploadPhoto",
  components: { BaseInput, addImageIcon, BaseButton },
  data() {
    return {
      image: addImageIcon,
      imageFile: null,
    };
  },
  mixins: [v_model],
  props: {
    confirmUpload: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    compressed() {
      const compressImage = async (file, { quality = 1, type = file.type }) => {
        const imageBitmap = await createImageBitmap(file);
        const canvas = document.createElement("canvas");
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(imageBitmap, 0, 0);

        const blob = await new Promise((resolve) =>
          canvas.toBlob(resolve, type, quality)
        );

        return new File([blob], file.name, {
          type: blob.type,
        });
      };

      const inputFile = document.querySelector(".image-field");
      inputFile.addEventListener("change", async (e) => {
        const { files } = e.target;
        if (!files.length) return;
        const dataTransfer = new DataTransfer();

        for (const file of files) {
          if (!file.type.startsWith("image")) {
            dataTransfer.items.add(file);
            continue;
          }
          const compressedFile = await compressImage(file, {
            quality: 0.5,
            type: "image/jpeg",
          });
          dataTransfer.items.add(compressedFile);
        }
        this.imageData = [...dataTransfer.files];
      });
    },
    previewImage(event) {
      let picture = event.target.files;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(picture[0]);
      this.imageData = [...event.target.files];
    },
    confirm() {
      this.value.file = this.imageData;
      this.value.photo = this.image;
      this.confirmUpload();
    },
  },
  created() {
    if (this.value?.photo) {
      this.image = this.value.photo;
    }
  },
};
</script>

<style lang="sass" scoped>
.avatar-wrapper
  min-width: 400px
  min-height: 400px
  border-radius: 50%
.avatar
  min-width: 400px
  min-height: 400px
  height: 100%
  border-radius: 50%

.icon :deep(path)
  fill: var(--default-white)
</style>
