<template lang="pug">
  .wrapper.flex.gap-x-2
    medical-protocols-list(:open-inspection="openInspection", :create-inspection="createInspection")
    medical-protocols-inspection(:inspection="inspection", :fill-inspection="fillInspection")
</template>

<script>
import MedicalProtocolsList from "@/pages/newMedicalCard/components/InitialInspectionProtocol/MedicalProtocolsList.vue";
import MedicalProtocolsInspection from "@/pages/newMedicalCard/components/InitialInspectionProtocol/MedicalProtocolsInspection.vue";
import { mapActions } from "vuex";
export default {
  name: "MedicalProtocolsWrapper",
  components: { MedicalProtocolsList, MedicalProtocolsInspection },
  data() {
    return {
      inspection: false,
      fillInspection: false,
    };
  },
  props: {
    openProtocol: Boolean,
    clearProps: Function,
  },
  methods: {
    ...mapActions({
      getProtocolData: "getProtocolData",
    }),
    openInspection() {
      this.inspection = true;
      this.fillInspection = false;
      this.getProtocolData({
        fillInspection: false,
      });
    },
    createInspection() {
      this.fillInspection = true;
      this.inspection = false;
      this.getProtocolData({
        fillInspection: true,
      });
    },
  },
  watch: {
    openProtocol: {
      immediate: true,
      handler(value) {
        this.inspection = value;
      },
    },
  },
  unmounted() {
    this.clearProps();
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  flex: 1
  overflow-y: auto
</style>
