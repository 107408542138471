<template lang="pug">
  .medical-wrapper.rounded.h-full
    list-header(@search="getMedcards")
    list-contents(
      :medicalCards="medicalCards",
      :data-status="dataStatus"
    )
</template>

<script>
import ListHeader from "@/pages/medcards/components/MedicalCardSearchHeader.vue";
import ListContents from "@/pages/medcards/components/MedicalCardSearchList.vue";
import { fetchWrapper } from "@/shared/fetchWrapper";
import nothingChooseImg from "@/assets/images/nothing-choose.svg";
import nothingSearchImg from "@/assets/images/nothing-search.svg";
export default {
  name: "MedicalCardSearch",
  components: { ListHeader, ListContents },
  data() {
    return {
      medicalCards: [],
      dataStatus: {
        title: "no data",
        message: "Введите данные Пациента для поиска",
        img: nothingChooseImg,
      },
    };
  },
  methods: {
    async getMedcards(value) {
      if (!value) {
        this.dataStatus = {
          title: "no data",
          message: "Введите ФИО Пациента",
          img: nothingChooseImg,
        };
        this.medicalCards = [];
        return;
      }

      const data = await fetchWrapper.post("persons/search", {
        full_name: value,
      });
      if (data?.length === 0) {
        this.medicalCard = [];
        this.dataStatus = {
          title: "not found",
          message: `По запросу «${value}» Пациентов не найдено.
            Переформулируйте запрос и попробуйте снова`,
          img: nothingSearchImg,
        };
        return;
      }
      this.medicalCards = data;
      this.dataStatus = {
        title: "found",
      };
      return;
    },
  },
};
</script>

<style lang="sass" scoped>
.medical-wrapper
  background-color: var(--default-white)
  display: flex
  flex-direction: column
  overflow: hidden
</style>
