<template lang="pug">
  .schedule.flex-col(:style="themeCell")
    .schedule-header.flex.justify-between.py-4.px-6
      base-input(
        placeholder="Поиск",
        size="M"
      )
        .flex.items-center.cursor-pointer
          q-icon(
            name="app:icon-search",
            size="18px",
            style="color: var(--font-dark-blue-color)"
          )
      base-button(width="188px")
        q-icon(name="app:plus", size="12px", left)
        span Создать смену
    schedule-table-header(
      :result="result",
      :selected-all="selectedAll",
      :select="select"
    )
    schedule-table-body(
      :serialized="serialized",
      :selected="selected",
      :trim-owner-name="trimOwnerName",
      :result="result",
      :choice-cell="choiceCell",
      :clear-select="clearSelect",
      :select-employee="selectEmployee",
      :choice-state="choiceState",
      :show-time="showTime",
      :schedule-list="scheduleList",
      :open-form="openForm",
      :replacement-sheet="replacementSheet",
      :employees="employees",
      :change-shift="changeShift"
      :set-active-cell="setActiveCell"
    )
</template>

<script>
import * as moment from "moment";
import ScheduleTableHeader from "@/pages/schedule/components/ScheduleTableHeader.vue";
import ScheduleTableBody from "@/pages/schedule/components/ScheduleTableBody.vue";
import ScheduleTableSelect from "@/pages/schedule/components/ScheduleTableSelect.vue";
import ScheduleBar from "@/pages/schedule/components/ScheduleBar.vue";
import BaseSelect from "@/components/base/BaseSelect.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseButton from "@/components/base/BaseButton.vue";

export default {
  name: "ScheduleTable",
  components: {
    ScheduleTableHeader,
    ScheduleTableBody,
    ScheduleTableSelect,
    ScheduleBar,
    BaseInput,
    BaseSelect,
    BaseButton,
  },
  props: {
    scheduleList: Array,
    serialized: Array,
    buttons: Array,
    employees: Array,
    showTime: Boolean,
    activeCell: Boolean,
    clearSelect: Function,
    trimOwnerName: Function,
    openForm: Function,
    changeShift: Function,
    setActiveCell: Function,
    template: Object,
    selectEmployee: Object,
    startMonth: Object,
    replacementSheet: Object,
    times: Object,
    createSchedules: Function,
    deleteSchedule: Function,
  },
  data() {
    return {
      days: "",
      result: [],
      employee: [],
      dateInterval: {
        id: "",
        start: "",
        end: "",
        text: "",
      },
      activeButton: null,
      choiceMonth: null,
      selected: [],
      selectedAll: false,
    };
  },
  computed: {
    themeCell() {
      this.setActiveButton();
      return {
        "--bg-color-status": this.activeButton?.color,
        "--text-status": !this.template.item?.symbol
          ? this.activeButton?.text
          : this.template.item?.symbol,
      };
    },
  },
  methods: {
    select() {
      this.selected = [];
      if (!this.selectedAll) {
        for (let i in this.serialized) {
          this.selected.push(this.serialized[i].id);
        }
        this.selectedAll = true;
      } else this.selectedAll = false;
    },
    setActiveButton() {
      this.activeButton = this.buttons.find((e) => e.active);
    },
    setActiveTemplate() {
      return (this.buttons.find((e) => e.work === "WORKS").active = true);
    },
    clearCell() {
      for (let i in this.dateInterval) {
        this.dateInterval[i] = "";
      }
    },
    choiceCell(day, id, schedule) {
      let formatTime = day.format("YYYY-MM-DD");

      if (this.showTime) return;
      if (this.dateInterval.id !== id || !this.activeCell) this.clearCell();

      if (!this.dateInterval.start) {
        this.dateInterval.start = formatTime;
        this.dateInterval.id = id;
      } else if (day.isAfter(this.dateInterval.start)) {
        this.dateInterval.end = formatTime;
      } else {
        this.dateInterval.start = formatTime;
      }
      this.$emit("schedule-employee", this.dateInterval, id);
      let insideSchedules = schedule?.schedules.filter(
        (el) =>
          (el.date > this.dateInterval.start &&
            el.date < this.dateInterval.end) ||
          el.date === this.dateInterval.start ||
          el.date === this.dateInterval.end
      );
      if (!this.selectEmployee.label && !!insideSchedules)
        this.rangeSchdedules(schedule, insideSchedules, id);
    },
    rangeSchdedules(schedule, insideSchedules, id) {
      insideSchedules = schedule.schedules.filter(
        (el) =>
          (el.date > this.dateInterval.start &&
            el.date < this.dateInterval.end) ||
          el.date === this.dateInterval.start ||
          el.date === this.dateInterval.end
      );
      if (insideSchedules.length > 0) insideSchedules[0].employeeId = id;

      let keys = Object.keys(insideSchedules);
      let outsideSchedules = [];
      let firstDate = insideSchedules[keys[0]]?.date;
      let lastDate = insideSchedules[keys.length - 1]?.date;

      if (this.dateInterval.start < firstDate) {
        outsideSchedules.push({
          start_date: this.dateInterval.start,
          end_date: moment(firstDate).subtract(1, "d").format("YYYY-MM-DD"),
        });
      }
      if (this.dateInterval.end > lastDate)
        outsideSchedules.push({
          start_date: moment(lastDate).add(1, "d").format("YYYY-MM-DD"),
          end_date: this.dateInterval.end,
        });
      this.$emit("schedules", insideSchedules, outsideSchedules);
    },
    choiceState(day, id) {
      let formatDay = day?.format("YYYY-MM-DD");
      let resStart =
        this.dateInterval.start &&
        formatDay === this.dateInterval.start &&
        this.dateInterval.id === id;

      let resEnd =
        this.dateInterval.end &&
        formatDay === this.dateInterval.end &&
        this.dateInterval.id === id;

      let resMiddle =
        day?.isBefore(this.dateInterval.end) &&
        this.dateInterval.id === id &&
        day?.isAfter(this.dateInterval.start);

      if (resStart || resMiddle || resEnd) {
        return {
          "set-template":
            !!this.template.item?.symbol && this.setActiveTemplate(),
          status: !!this.activeButton,
          "set-date": !this.activeButton && this.activeCell,
        };
      }
    },
    changeDays() {
      this.days = moment(this.choiceMonth, "YYYY-MM-DD").daysInMonth();
    },
    pushMonth() {
      this.result = [];
      this.result.push(this.startMonth);
      for (let i = 2; i <= this.days; i++) {
        this.result.push(this.startMonth.clone().add(i - 1, "d"));
      }
    },
  },
  watch: {
    startMonth: {
      immediate: true,
      handler(newMonth) {
        if (newMonth) {
          this.choiceMonth = newMonth.format("YYYY-MM-DD");
          this.changeDays();
          this.pushMonth();
        }
      },
    },
  },
  mounted() {
    this.changeDays();
    this.pushMonth();
  },
};
</script>

<style lang="sass" scoped>
.schedule
  border-top: 1.5px solid var(--border-light-grey-color-1)
  border-radius: 4px
  min-height: 87px
  // width: calc(100vw - 136px)
  background-color: var(--default-white)

.schedule-header
  height: 72px

.show-time
  text-align: center
  font-size: 12px

.modal-wrapper
  border: 1.5px solid var(--border-light-grey-color-1)
  border-radius: 4px
  width: 400px
  height: 500px

.on-left
  margin-right: 10px
</style>
