export function removeEmptyFields(obj) {
  let resultObj = {};
  Object.keys(obj)
    .filter(
      (key) => obj[key] !== undefined && obj[key] !== null && obj[key] !== ""
    )
    .forEach((key) => {
      resultObj[key] = obj[key];
    });
  return resultObj;
}

export function getObjectChangeField(objInit, objUpdate) {
  const resultObj = {};
  Object.keys(objUpdate).forEach((key) => {
    if (objUpdate[key] !== objInit[key]) {
      resultObj[key] = objUpdate[key];
    }
  });
  return resultObj;
}

export function checkChangeData(dataInit, dataChange) {
  return JSON.stringify(dataInit) !== JSON.stringify(dataChange);
}

export function getFieldsNameUnvalidated(form) {
  const unvalidatedFields = form
    .getValidationComponents()
    .filter((el) => !el.validate());
  return [...new Set(unvalidatedFields.map((field) => field.name))];
}
