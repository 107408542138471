<template lang="pug">
  .flex.flex-col.pt-6.gap-y-4(:style="{maxWidth: '682px'}")
    base-input-full-name(v-model="patientData" )
    .flex.flex-col.flex-auto.l.gap-y-8
      .flex
        button.title-info.px-6.py-2.cursor-pointer.w-full.text-sm(
          v-for="form in forms",
          @click="selectForm(form.component)",
          :class="{active: form.component === currentForm}",
          :key="form.id",
          :id="form.id",
          :disabled="form.disabled"
        ) {{form.title}}
      component(
        v-bind:is="currentForm",
        v-model="patientData"
      )
  .footer.flex.gap-2
    base-button(type="secondary", label="Отменить", width="126px", @click="$emit('close')")
    base-button(width="168px", label="Создать", @click="createPatient")
</template>

<script>
import { v_model } from "@/shared/mixins/v-model";
import FormCreateBasicInfo from "@/pages/clients/components/FormCreateBasicInfo.vue";
import FormCreateIdentityDocuments from "@/pages/clients/components/FormCreateIdentityDocuments.vue";
import FormCreateAddresses from "@/pages/clients/components/FormCreateAddresses.vue";
import FormCreateAttachments from "@/pages/clients/components/FormCreateAttachments.vue";
import BaseInputFullName from "@/components/base/BaseInputFullName.vue";
import HeaderRecordForm from "../pages/newCalendar/components/HeaderRecordForm.vue";
import BaseCalendar from "@/components/base/Calendar/BaseCalendar.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseModal from "@/components/base/BaseModal.vue";
import ServicesModal from "../pages/newCalendar/components/ServicesModal.vue";
import { fetchWrapper } from "@/shared/fetchWrapper";

const forms = [
  {
    title: "Основное",
    id: "basic",
    component: "form-create-basic-info",
  },
  {
    title: "ДУЛ",
    id: "doc",
    component: "form-create-identity-documents",
    disabled: true,
  },
  {
    title: "Адрес",
    id: "address",
    component: "form-create-addresses",
    disabled: true,
  },
  {
    title: "Дополнительное",
    id: "additional",
    component: "form-create-attachments",
    disabled: true,
  },
];

export default {
  name: "PatientCreationForm",
  components: {
    FormCreateBasicInfo,
    FormCreateIdentityDocuments,
    FormCreateAddresses,
    FormCreateAttachments,
    BaseInputFullName,
    BaseCalendar,
    HeaderRecordForm,
    BaseButton,
    BaseModal,
    ServicesModal,
  },
  emits: ["close"],
  mixins: [v_model],
  data() {
    return {
      patientData: {
        full_name: "",
        phone: "",
        birth_date: "",
      },
      currentForm: "form-create-basic-info",
      forms,
    };
  },
  computed: {
    nameObject() {
      const nameArr = this.patientData?.full_name?.split(" ");
      return {
        last_name: nameArr?.[0] || "",
        first_name: nameArr?.[1] || "",
        patronymic: nameArr?.[2] || "",
      };
    },
  },
  methods: {
    async createPatient() {
      const body = {
        ...this.nameObject,
      };
      if (this.patientData.phone) {
        body.contacts = [
          {
            category: "PHONE",
            value: this.patientData.phone,
          },
        ];
      }
      if (this.patientData.birth_date)
        body.birth_date = this.patientData.birth_date;
      const data = await fetchWrapper.post("persons/", body);
      this.$emit("close", data);
    },
    handleChangePatientData(val) {
      this.patientData = { ...this.patientData, ...val };
    },
    selectForm(componentName) {
      this.currentForm = this.forms.find(
        (elem) => elem.component === componentName
      )?.component;
    },
  },
};
</script>

<style lang="sass" scoped>
.dark-blue
  color: var(--font-dark-blue-color)
  min-width: 50px

.text
  color: var(--font-grey-color)
  width: 76px

.change
  background: var(--bg-light-grey)
  color: var(--font-grey-color)

.services
  background: var(--bg-light-grey)
  color: var(--font-grey-color)
  width: 560px

.service
  display: -webkit-box
  overflow: hidden

.gradient
  width: 10%
  height: 28px
  right: 0

.other
  width: 46px
  background: var(--bg-light-grey)

.price
  background: var(--bg-light-grey)
  width: 82px
  color: var(--font-grey-color)

.title-info
  color: var(--font-grey-color)
  border-bottom: 1.5px solid var(--font-grey-color)
  &:hover
    color: var(--btn-blue-color)
    border-bottom: 1.5px solid var(--btn-blue-color)
  &.active
    color: var(--btn-blue-color)
    border-bottom: 1.5px solid var(--btn-blue-color)

.footer
  border-top: 1px solid var(--border-light-grey-color)
  margin: 40px -32px 0px
  padding: 16px 32px 0px 32px

.icon :deep(path)
  fill: var(--font-grey-color)
</style>
