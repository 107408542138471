<template lang="pug">
.sidebar.h-full.rounded.flex.flex-col.justify-between.pt-4.px-2
  .flex.flex-col.gap-y-4
    q-btn(
      style="width: 48px; height: 48px",
      id="print",
      rounded,
      padding="0px",
      icon="print",
      text-color="blue",
      size="xl"
    )
</template>

<script>
import BaseButtonSidebar from "@/components/base/BaseSidebarButton";
export default {
  name: "TheRightMenu",
  components: { BaseButtonSidebar },
};
</script>

<style lang="sass" scoped>
.sidebar
  max-width: 64px
  min-width: 64px
  background-color: var(--default-white)

.button:hover
  background-color: var(--bg-light-blue-color)
  color: var(--btn-blue-color)
  .hover :deep(path)
    fill: #9294A7
</style>
