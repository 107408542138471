<template lang="pug">
  .header.flex
    .flex.items-center.justify-between.py-3.pl-5.pr-6.h-14.w-full
      .flex.gap-x-4.items-center
        .flex.gap-x-2
          q-btn.w-8.h-8(
            @click="previousHandler",
            style="background: var(--bg-grey-color); color: var(--font-dark-blue-color)",
            round,
            size="14px",
            dense
          )
            q-icon(name="arrow_back_ios", right)
          q-btn.w-8.h-8(
            @click="nextHandler",
            style="background: var(--bg-grey-color); color: var(--font-dark-blue-color)",
            icon="arrow_forward_ios",
            round,
            size="14px",
            dense
          )
        .flex(:style="{width: '180px'}")
          base-input.relative(
            :placeholder="dateString",
            size="M",
            :width="180"
          )
            q-icon(
              size="20px",
              name="app:calendar",
              class="cursor-pointer",
              style="color: var(--font-dark-blue-color)"
            )
      .toogle.flex
        q-btn(
          label="Время",
          size="12px",
          class="button"
          :text-color="showTime ? 'white' : null",
          :style="{background: showTime ? 'var(--bg-aqua-blue)' : null}",
          @click="changeShowTime"
        )
        q-btn(
          label="Статус",
          size="12px",
          class="button",
          :text-color="showTime ? null : 'white'",
          :style="{background: showTime ? null : 'var(--bg-aqua-blue)'}",
          @click="changeShowStatus"
        )
</template>

<script>
import * as moment from "moment";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "ScheduleHeader",
  components: { BaseInput, BaseButton },
  props: {
    showTime: Boolean,
    startMonth: Object,
    changeShowTime: Function,
    changeShowStatus: Function,
  },
  data() {
    return {
      isCurrentMonth: true,
    };
  },
  computed: {
    dateString() {
      return this.startMonth
        .format("MMMM YYYY")
        .split(" ")
        .map((elem, index) => {
          if (index === 0) return elem[0].toUpperCase() + elem.slice(1);
          return elem;
        })
        .join(" ");
    },
  },
  methods: {
    previousHandler() {
      this.$emit("switch-previous-month");
    },
    nextHandler() {
      this.$emit("switch-next-month");
    },
  },
  watch: {
    startMonth() {
      this.isCurrentMonth =
        this.startMonth.format("YYYY-MM-DD") ===
        moment().date(1).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="sass" scoped>
.header
  height: 56px
  border-radius: 4px
  background-color: var(--default-white)

.font-input
  font-feature-settings: 'pnum' on, 'lnum' on

.toogle
  background-color: #eff1f4
  border-radius: 4px
  width: 186px
  height: 40px
  padding: 4px

.button
  width: 89px
</style>
