<template lang="pug">
  medical-form-wrapper(
    :title="`${moment(value?.date).format('YYYY')} год`",
    :is-edit="isEdit",
    :open-edit="openEdit",
    :cancel="cancelEdit",
    :delete-item="deleteCard",
    :save="saveСhanges",
    :is-check-change="isCheckChange"
    )
    .flex.w-full.gap-x-4.relative
      span.absolute.bottom-0.right-0.text-smm.font-medium(
        v-if="!isEdit",
        :style="{color: 'var(--font-grey-color)'}"
        ) {{moment(value?.date).format('DD.MM.YYYY')}}
      base-modal-showing-picture(
        v-model="isShowsPicture",
        :init-picture="value.pictures.find((el) => el.id === pictureId)",
        :list-pictures="value.pictures"
        )
      .flex.flex-col.gap-y-2.p-2.rounded.h-fit(
        v-if="value.pictures.length || isEdit",
        :style="{backgroundColor: 'var(--bg-light-grey)'}",
        )
        .pictures.gap-2(
          v-if="value.pictures.length"
          :class="{'edit': isEdit, 'no-edit': !isEdit, 'one-picture': value.pictures.length === 1}"
          )
          .photo.flex.relative(
            v-for="(picture, index) in value.pictures",
            )
            .photo-viewing.flex.absolute.rounded.cursor-pointer.w-full.h-full.items-center.justify-center(
              v-if="!isEdit",
              @click="() => showPicture(picture.id)"
              )
              img(src="@/assets/icons/whiteEye.svg" alt="eye")
            img.h-full.w-full.rounded.object-cover(:src="picture.photo")
            q-btn.absolute(
              v-if="isEdit",
              @click="() => deletePicture(picture.id)",
              round,
              dense,
              size="16px",
              padding="4px 4px 4px 4.5px",
              :style="{backgroundColor: 'var(--border-red-color)', top: '-8px', right: '-8px'}"
            )
              q-icon(
                name="app:cancel",
                size="8px",
                :style="{color: 'var(--default-white)'}"
                )
        base-download(
          v-if="isEdit",
          v-model="value.pictures",
          multiple,
          with-icon,
          size-icon="18px",
          :returned-fields="['photo', 'id', 'name', 'size']"
          :width="216"
          :height="92"
        )
      .flex.w-full.flex-col.gap-y-2
        .flex.font-semibold.text-sm(:style="{color: 'var(--font-grey-color)'}") Комментарий врача
        base-textarea.w-full(
          v-if="isEdit",
          v-model="value.comment",
          size="126px",
          height="126px"
        )
        .flex.flex-col.w-full.pl-10px(v-else)
          .flex(v-for="comment in getListComment")
            .flex.gap-x-10px.w-full(v-if="comment")
              .flex.w-1.h-1.rounded-full.mt-7px(
                :style="{backgroundColor: 'var(--font-dark-blue-color)'}")
              span.w-full.font-medium {{comment}}
</template>

<script>
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import BaseDownload from "@/components/base/BaseDownload.vue";
import BaseModalShowingPicture from "@/components/base/BaseModalShowingPicture.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { v_model } from "@/shared/mixins/v-model";
import BaseTextarea from "@/components/base/BaseTextarea.vue.vue";

export default {
  name: "HealthStateForm",
  components: {
    MedicalFormWrapper,
    BaseModalShowingPicture,
    BaseDownload,
    BaseTextarea,
  },
  data() {
    return {
      moment,
      isEdit: false,
      isCheckChange: true,
      isShowsPicture: false,
      pictureId: "",
      initData: {},
    };
  },
  mixins: [v_model],
  computed: {
    ...mapGetters({
      getHealthStateData: "getHealthStateData",
    }),
    getListComment() {
      return this.value.comment.split("\n");
    },
  },
  watch: {
    isEdit: {
      immediate: true,
      handler() {
        if (this.isEdit) {
          this.initData = JSON.parse(JSON.stringify(this.value));
        }
      },
    },
  },
  methods: {
    ...mapActions({
      deleteItemData: "deleteItemData",
      returnInitData: "returnInitData",
    }),
    showPicture(id) {
      this.pictureId = id;
      this.isShowsPicture = true;
    },
    deleteCard() {
      this.deleteItemData({ stateKey: "healthState", itemId: this.value.id });
    },
    deletePicture(id) {
      this.value.pictures = this.value.pictures.filter((el) => el.id !== id);
    },
    addNewFiles(e) {
      Object.values(e.target.files).forEach((file) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.value.pictures.push({
            photo: e.target.result,
            file: file,
            id: Math.random(),
          });
        };
        reader.readAsDataURL(file);
      });
      e.target.value = null;
    },
    openEdit() {
      this.isEdit = true;
    },
    cancelEdit() {
      this.value = this.initData;
      this.isEdit = false;
    },
    saveСhanges() {
      this.isEdit = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.pictures
  display: grid
  &.no-edit
    grid-template-columns: repeat(2, 104px)
    grid-auto-rows: 104px
    &.one-picture
      grid-template-columns: repeat(1, 104px)
  &.edit
    grid-template-columns: repeat(4, 40px)
    grid-auto-rows: 40px
.photo-viewing
  display: none
  background-color: var(--font-dark-blue-color-1)
  color: var(--default-white)
.photo
  &:hover .photo-viewing
    display: flex
.download
  min-height: 93px
  width: 216px
  border: 1px dashed var(--font-grey-color)
  background-color: var(--default-white)
  &:hover .q-icon
    opacity: 0.6
  & .q-icon
    color: var(--btn-blue-color)
.comments
  border: 1px solid var(--border-light-grey-color)
  padding: 3px 8px 6px 16px
</style>
