<template lang="pug">
  .protocols-wrapper.h-full.flex.flex-col.gap-y-2
    .flex.font-bold.text-6xl.items-center.h-full.justify-center(
      v-if="!inspection && !fillInspection"
      :style="{color: 'var(--font-grey-color)'}"
    ) Выберите осмотр
    .flex.flex-col.gap-y-2(v-else)
      .protocol.flex.justify-between.p-6(v-for="elem in list")
        .flex.font-bold.text-6xl {{elem.label}}
        .flex.gap-x-4
          .button
            q-btn.btn(
              icon="app:printer",
              size="20px",
              :style="{width: '40px', height: '40px', color: 'var(--font-grey-color)'}",
              padding="0"
            )
          .button
            q-btn.btn(
              icon="app:basket",
              size="20px",
              :style="{width: '40px', height: '40px', color: 'var(--font-grey-color)'}",
              padding="0"
            )
      div(v-for="form in protocolForms", :key="form.key", v-if="inspection || fillInspection")  
        component(v-bind:is="form.component", :data="form", :fill-inspection="fillInspection", :abc="abc")
      .protocol.flex.justify-between.p-6(v-if="fillInspection")
        .flex.h-fit.w-fit.gap-2
          base-button(label="Отменить", type="secondary", width="126px")
          base-button(label="Сохранить", width="132px")
        .flex.items-center.gap-x-3(:style="{color: 'var(--font-grey-color)'}")
          .flex.font-semibold.text-7xl {{ percent + "%" }}
          .flex.font-medium.text-smm.w-20 заполнение осмотра
</template>

<script>
import MedicalFormTag from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/MedicalFormTag.vue";
import DataForm from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/DataForm.vue";
import DiseaseForm from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/DiseaseForm.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import IndexForm from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/IndexForm.vue";
import MainFactorsForm from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/MainFactorsForm.vue";
import ToothFormulaForm from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/ToothFormulaForm.vue";
import { protocolForms } from "@/pages/newMedicalCard/utils/medicalConfig.js";
import ClinicalForm from "./Forms/ClinicalForm.vue";
import { mapState } from "vuex";
import { removeEmptyFields } from "@/shared/utils/changesObjects";
import toothFormula from "@/store/modules/toothFormula";

export default {
  name: "MedicalProtocolsInspection",
  components: {
    MedicalFormTag,
    DataForm,
    DiseaseForm,
    ClinicalForm,
    BaseButton,
    IndexForm,
    MainFactorsForm,
    ToothFormulaForm,
  },
  props: { inspection: Boolean, fillInspection: Boolean },
  data() {
    return {
      initToothFormula: toothFormula.stateInit,
      percent: 0,
      protocolForms: protocolForms,
      list: [
        {
          id: 1,
          label: "Первичный осмотр 2023 года",
        },
      ],
      abc: [
        "А",
        "Б",
        "В",
        "Г",
        "Д",
        "Е",
        "Ё",
        "Ж",
        "З",
        "И",
        "Й",
        "К",
        "Л",
        "М",
        "Н",
        "О",
        "П",
        "Р",
        "С",
        "Т",
        "У",
        "Ф",
        "Х",
        "Ц",
        "Ч",
        "Ш",
        "Щ",
        "Ы",
        "Э",
        "Ю",
        "Я",
      ],
    };
  },
  computed: {
    ...mapState({
      protocolData: (state) => state.medical.protocolData,
    }),
  },
  methods: {
    setPercent(data, sum) {
      switch (data) {
        case "KPUIndex":
        case "bite":
        case "hygieneIndex":
          if (this.protocolData[data]) sum += 1;
          break;
        case "anamnesis":
        case "cavity":
        case "clinical":
        case "complaints":
        case "illnesses":
        case "inspection":
        case "medications":
        case "survey":
          if (this.protocolData[data].length) sum += 1;
          break;
        case "data": {
          if (this.protocolData[data].complaints.length) sum += 0.5;
          this.protocolData[data].files.forEach((e) => {
            if (e.data.length) sum += 0.25;
          });
          break;
        }
        case "disease":
        case "preliminary":
          if (this.protocolData[data].text || this.protocolData[data].change)
            sum += 1;
          break;
        case "thermometry": {
          let obj = removeEmptyFields(this.protocolData[data]);
          for (let el in obj) {
            if (obj[el].result) sum += 0.25;
            if (obj[el].value) sum += 0.25;
          }
          break;
        }
        case "tonometry": {
          let obj = removeEmptyFields(this.protocolData[data]);
          for (let el in obj) if (obj[el].value) sum += 0.5;
          break;
        }
        case "toothFormula":
          if (
            JSON.stringify(this.initToothFormula) !==
            JSON.stringify(this.protocolData[data])
          )
            sum += 1;
          break;
      }
      return sum;
    },
    interestСalculation() {
      let sum = 0;
      for (let data in this.protocolData) sum = this.setPercent(data, sum);
      this.percent = Math.round((100 * Math.round(sum)) / 17);
    },
  },
  watch: {
    protocolData: {
      deep: true,
      immediate: true,
      handler() {
        this.interestСalculation();
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.protocols-wrapper
  flex: 1
  overflow-y: auto
  &::-webkit-scrollbar
    width: 0

.protocol
  background: var(--default-white)
  width: 100%
  height: 80px
  border-radius: 4px

.button
  display: flex
  align-items: center
  width: 40px
  height: 40px
  justify-content: center
  cursor: pointer
  border-radius: 4px
  background: var(--bg-light-grey)

.btn :deep(path)
  fill: var(--font-grey-color)
</style>
