<template lang="pug">
.flex.flex-col.pt-6.gap-y-4(:style="{maxWidth: '682px'}")
  header-record-form(
    :current-status="currentStatus",
    :statuses="patientData.statuses",
    :choice-status="choiceStatus",
    v-model="time",
  )
  base-input-with-search(v-model="patient", @create-person="createPerson")
  .flex.flex-col.flex-auto.l.gap-y-8
    .wrapper-info.h-full
      .grid.grid-cols-2.gap-x-4.gap-y-6
        base-input(
          :disabled="!!patient.id"
          v-model="phone",
          placeholder="+7 (915) 644–92–23",
          mask="+7 (###) ###-##-##",
          label="Номер телефона",
          size="M",
          important
        )
        base-input-date(
          :disabled="!!patient.id"
          v-model="birth_date",
          size="M",
          important,
          label="Дата рождения",
          placeholder="Дата рождения"
        )

  .footer.flex.gap-2
    base-button(type="secondary", label="Отменить", width="126px", @click="closeForm")
    base-button(width="168px", label="Создать запись", @click="createEvent")
  base-modal(v-model="showCreateModal", title="Создать пациента", modal-padding)
    patient-creation-form(@close="handleClosePatientForm")
</template>

<script>
import BaseInputWithSearch from "@/components/base/BaseInputWithSearch.vue";
import { patientData } from "@/pages/newCalendar/utils/calendarConfig.js";
import HeaderRecordForm from "./HeaderRecordForm.vue";
import BaseCalendar from "@/components/base/Calendar/BaseCalendar.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseInputDate from "@/components/base/BaseInputDate.vue";
import { fetchWrapper } from "@/shared/fetchWrapper";
import { mapActions } from "vuex";
import PatientCreationForm from "@/components/PatientCreationForm.vue";
import BaseModal from "@/components/base/BaseModal.vue";
import { addNotification } from "@/components/Notifications/notificationContext";
import { errors } from "@/shared/errors";

export default {
  name: "CreateEventForm",
  components: {
    BaseCalendar,
    HeaderRecordForm,
    BaseButton,
    BaseInputWithSearch,
    BaseInput,
    BaseInputDate,
    PatientCreationForm,
    BaseModal,
  },
  props: { isShowForm: Boolean, closeForm: Function },
  data() {
    return {
      patient: {},
      phone: "",
      birth_date: "",
      time: {},
      patientData: patientData,
      currentStatus: patientData.statuses.find((e) => e.name === "Не принят"),
      showCreateModal: false,
      errors: {},
    };
  },
  computed: {
    primaryNumber() {
      return this.patient?.contacts?.find((e) => e.category === "PHONE")?.value;
    },
  },
  methods: {
    ...mapActions({
      getEvents: "getEvents",
    }),
    createPerson() {
      this.showCreateModal = true;
    },

    choiceStatus(e) {
      this.currentStatus = e;
    },
    handleClosePatientForm(val) {
      this.showCreateModal = false;
      if (!val) return;
      this.patient = val;
    },
    async createEvent() {
      const event = await fetchWrapper.post("events", {
        start: this.time.start.toISOString(),
        end: this.time.end.toISOString(),
        person: {
          id: this.patient.id,
        },
        medic_id: this.$store.getters.getUserData.id,
      });
      if (event?.id) {
        await this.getEvents();
        this.closeForm();
      }
      if (event?.code) {
        this.errors = event?.fields;
        addNotification(new Date(), "Ошибка", errors[event?.type], "error");
      }
    },
  },
  mounted() {},
  watch: {
    patient(val) {
      if (val.birth_date) {
        this.birth_date = val.birth_date;
      }
      if (this.primaryNumber) {
        this.phone = this.primaryNumber;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.dark-blue
  color: var(--font-dark-blue-color)
  min-width: 50px

.text
  color: var(--font-grey-color)
  width: 76px

.change
  background: var(--bg-light-grey)
  color: var(--font-grey-color)

.services
  background: var(--bg-light-grey)
  color: var(--font-grey-color)
  width: 560px

.service
  display: -webkit-box
  overflow: hidden

.gradient
  width: 10%
  height: 28px
  right: 0

.other-serivices
  width: 474px
  padding: 4px
  border: 1px solid var(--border-light-grey-color)
  background: var(--default-white)

.other
  width: 46px
  background: var(--bg-light-grey)

.price
  background: var(--bg-light-grey)
  width: 82px
  color: var(--font-grey-color)

.title-info
  color: var(--font-grey-color)
  border-bottom: 1.5px solid var(--font-grey-color)
  &:hover
    color: var(--btn-blue-color)
    border-bottom: 1.5px solid var(--btn-blue-color)
  &.active
    color: var(--btn-blue-color)
    border-bottom: 1.5px solid var(--btn-blue-color)

.footer
  border-top: 1px solid var(--border-light-grey-color)
  margin: 40px -32px 0px
  padding: 16px 32px 0px 32px
</style>
