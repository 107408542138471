<template lang="pug">
  .sidebar-wrapper.flex.flex-col.gap-y-px
    .form.flex.items-center.justify-end.py-4.pr-4.rounded-t
      q-btn.rotate-180.button-open(
        @click="openSidebar(); hideSidebar()",
        dense,
        padding="8px",
        style="color: var(--font-grey-color); background: var(--bg-light-grey)"
      )
        img(:src="arrow")
    .form.flex.p-4
      base-button(width="100%", @click="createForm")
        q-icon.plus(name="app:plus", size="24px", left)
        span Создать запись
    .flex.flex-col.gap-y-px
      q-expansion-item.expansion.font-bold.text-xm(
        expand-icon="app:down-arrow",
        v-for="(elem, index) in patientList",
        expand-separator,
        :label="elem.label",
        @click="choiceForm(elem.choice, index)",
        :class="{'patient': elem.label === 'Пациенты'}"
      )
        .select.flex.flex-col.font-medium.text-smm.px-2(
          v-if="elem.label !== 'Пациенты'",
          v-for="item in elem.data"
        )
          .status.flex.items-center.gap-x-1.px-2
            q-icon(
              v-if="elem.label === 'Первичная медкарта'"
              :name="item.icon",
              size="20px",
              :class="choiceClass(item.id, 'medcard')"
            )
            .priority.rounded-full.flex.mr-1.5px(
              v-if="elem.label === 'Статус приема'",
              :class="choiceClass(item.id, 'priority')"
            )
            span {{item.name}}
        .flex.flex-col.px-4.pt-6px.pb-0(:class="choiceState()", v-else)
          .flex.flex-col.gap-y-2
            .flex.h-8.w-full.items-center.justify-between
              base-input.input(
                v-model="foundPerson",
                @keyup.enter="searchPerson",
                @input="searchPerson",
                id="input",
                placeholder="Найти пациента...",
                :width="160"
                size="S",
                icon-left
              )
                template(v-slot:iconLeft)
                  q-icon.input-icon(name="app:search", size="16px")
              q-btn.ml-2.sort-icon(
                @click="sortPerson(elem.data)",
                :class="sortingClass",
                :style="{width: '32px', height: '32px'}",
                padding="0"
              )
                calendar-sidebar-svg(name-svg="sort", :active="sort")
            .flex.items-center.justify-between.font-medium.text-smm.h-55.py-2.cursor-pointer(
              @click="checkAll(elem.data)"
            )
              .flex.items-center.gap-x-2
                .all-icon.flex.items-center.justify-center
                  q-icon.icon-patients(name="app:patients", size="24px") 
                .flex.flex-col.font-medium
                  .text-smm Все
                  .grey-color.text.text-xsx {{ elem.data?.length + " пациентов" }}
              img(v-if="selectAll(elem.data)", :src="icon_ok")
          .person-wrapper.flex.flex-col(@scroll="scrollTo", id="persons")
            .person.items-center.flex.justify-between.py-2.cursor-pointer(
              v-for="(person, index) in choiceData(elem.data)",
              @click="checkPerson(index, elem.data)"
            )
              .flex.items-center.gap-x-2
                img.h-10.w-10.object-cover.rounded-full(:src="person.avatar")
                .flex.flex-col.font-medium
                  .text-smm {{trimOwnerName(person.last_name, person.first_name, person.patronymic)}}
                  .grey-color.text-xsx {{trimBirthday(person.birthday)}}
              img.h-6.w-6(v-if="person.check", :src="icon_ok")
            .gradient.flex.absolute(v-if="isGradient")
</template>

<script>
import arrow from "@/assets/icons/double_left_arrow.svg";
import icon_ok from "@/assets/icons/icon_ok.svg";
import sort_word from "@/assets/icons/sort_word.svg";
import BaseInput from "@/components/base/BaseInput.vue";
import { patientList } from "@/pages/newCalendar/utils/calendarConfig.js";
import { trimName } from "@/pages/newCalendar/utils/calendarFunctions.js";
import BaseButton from "@/components/base/BaseButton.vue";
import CalendarSidebarSvg from "@/pages/newCalendar/components/CalendarSidebarSvg.vue";
import * as moment from "moment";

export default {
  name: "CalendarOpenSidebar",
  props: { openSidebar: Function, createForm: Function },
  components: { BaseInput, BaseButton, CalendarSidebarSvg },
  data() {
    return {
      val: [],
      arrow,
      icon_ok,
      sort_word,
      foundPerson: "",
      sortData: [],
      patientList: patientList,
      sort: false,
      isGradient: true,
      trimOwnerName: trimName,
    };
  },
  computed: {
    sortingClass() {
      return this.sort
        ? {
            "sort-icon-active": true,
          }
        : {
            "sort-icon-default": true,
          };
    },
  },
  methods: {
    choiceClass(priority, name) {
      if (name === "priority")
        return {
          "none-priority": !priority,
          "red-color": priority === 1,
          "blue-color": priority === 2,
          "green-color": priority === 3,
        };
      return {
        medcard: !priority,
        "medcard-orange": priority === 1,
        "medcard-green": priority === 2,
      };
    },
    trimBirthday(birthday) {
      return moment(birthday, "DD MM YYYY").format("DD MMMM YYYY");
    },
    choiceState() {
      let num = 0;
      let isPrimary = false;
      this.patientList.forEach((e) => {
        if (e.choice) num += 1;
        if (e.choice && e.id === 1) isPrimary = true;
      });
      return {
        "primary-form": num === 2 && isPrimary,
        "one-form": num === 1,
        "two-form": num === 2,
        "all-form": num === 3,
        default: !num,
      };
    },
    choiceForm(choice, idx) {
      if (choice) {
        if (idx === 2) {
          this.foundPerson = "";
          this.sortData = [];
        }
        return (this.patientList.find((e) => e.id === idx).choice = false);
      }
      this.patientList.find((e) => e.id === idx).choice = true;
    },
    checkPerson(index, arr) {
      arr.map((e) => {
        if (e.id === index) e.check = !e.check;
      });
    },
    selectAll(arr) {
      return arr.find((e) => e.check) ? false : true;
    },
    checkAll(arr) {
      arr.map((e) => (e.check = false));
    },
    searchPerson() {
      this.sortData = this.patientList
        .find((e) => e.label === "Пациенты")
        .data.filter(
          (e) =>
            e.last_name.toLowerCase().substr(0, this.foundPerson.length) ===
            this.foundPerson.toLowerCase()
        );
    },
    choiceData(arr) {
      return this.foundPerson ? this.sortData : arr;
    },
    sortPerson(arr) {
      if (!this.sort) {
        this.sort = true;
        arr.sort((a, b) => (a.last_name > b.last_name ? 1 : -1));
      } else {
        this.sort = false;
        arr.sort((a, b) => (b.last_name > a.last_name ? 1 : -1));
      }
    },
    hideSidebar() {
      this.patientList.forEach((e) => (e.choice = false));
    },
    scrollTo(event) {
      if (
        Math.abs(
          event.currentTarget.scrollHeight -
            event.currentTarget.clientHeight -
            event.currentTarget.scrollTop
        ) < 1
      )
        return (this.isGradient = false);
      this.isGradient = true;
    },
  },
  watch: {
    foundPerson: {
      immediate: true,
      handler(val) {
        if (val && document.getElementById("persons").offsetHeight < 480)
          this.isGradient = false;
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.sidebar-wrapper
  width: 232px

.form
  background: var(--default-white)
  width: 100%
  height: 72px

.expansion
  height: 54px
  background: var(--default-white)
  border-radius: 4px
  color: var(--font-dark-blue-color)
  &:last-child
    border-bottom-left-radius: 0.25rem
    border-bottom-right-radius: 0.25rem

.expansion :deep(.q-item__section--side)
  color: var(--font-dark-blue-color)

.expansion :deep(.q-icon)
  font-size: 20px

.button-open
  border: 1px solid var(--gray-secondary)
  color: var(--font-grey-color)
  background: var(--bg-light-grey)

.select
  &:last-child
    padding-bottom: 16px

.h-55
  height: 55px

.all-icon
  height: 40px
  width: 40px
  border-radius: 50%
  background: var(--surface-blue-big)

.status
  border-radius: 4px
  height: 40px
  &:hover
    background: var(--gray-thirdly)
    cursor: pointer

.q-expansion-item
  border-radius: 0
  height: 100%

.q-expansion-item :deep(.q-separator)
  display: none

.patient :deep(.q-expansion-item__content)
  padding-bottom: 0px

.sort-icon-default
  background-color: var(--bg-light-grey)
  color: var(--font-grey-color)

.sort-icon-active
  background-color: var(--btn-blue-color)
  color: var(--default-white)

#input :deep(.q-field__control)
  height: 32px
  .q-field__prepend
    height: 32px

.q-expansion-item :deep(.q-item__section--main ~ .q-item__section--side)
  padding-left: 0px

.q-expansion-item :deep(.q-item__label)
  line-height: 135% !important

.q-expansion-item :deep(.q-item)
  padding: 10px 16px !important
  min-height: 54px
  &:hover
    opacity: 0.6

.input-icon :deep(path)
  fill: var(--font-grey-color) !important

.priority
  height: 15px
  width: 15px

.grey-color
  color: var(--font-grey-color)

.q-expansion-item :deep(.q-item)
  height: 54px

.expansion :deep(.q-focus-helper)
  display: none

.expansion
  &:deep(path)
    fill: var(--font-dark-blue-color)

.none-priority
  border: 1.5px solid var(--font-grey-color)

.red-color
  background: var(--system-color-red)

.blue-color
  background: var(--btn-blue-color)

.green-color
  background: var(--system-color-green)

.person-wrapper
  overflow-y: auto
  margin-right: -10px
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar-track:vertical
    margin-top: -40px
    margin-bottom: 26px

.person
  height: 55px
  width: calc(100% - 6px)
  border-bottom: 1px solid var(--bg-light-grey)
  &:first-child
    border-top: 1px solid var(--bg-light-grey)
  &:last-child
    border-bottom: none

.one-form
  max-height: calc(100vh - 382px)
  transition: all 0.2s ease-in

.two-form
  max-height: calc(100vh - 558px)
  transition: all 0.2s ease-in

.all-form
  max-height: calc(100vh - 694px)
  transition: all 0.2s ease-in

.primary-form
  max-height: calc(100vh - 518px)
  transition: all 0.2s ease-in

.default
  max-height: 0px
  transition: all 0.5s ease-in

.input :deep(.q-field__prepend)
  padding-right: 4px

.input :deep(.q-field__append)
  padding-left: 6px

.gradient
  border-bottom-left-radius: 0.25rem
  border-bottom-right-radius: 0.25rem
  width: 232px
  height: 44px
  right: 0px
  bottom: 0px
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--default-white) 100%)

.on-left
  margin-right: 8px

.plus :deep(path)
  fill: var(--default-white)

.medcard :deep(path)
  fill: var(--font-grey-color)

.medcard-orange :deep(path)
  fill: var(--bg-yellow-warning)

.medcard-green :deep(path)
  fill: var(--system-color-green)

.icon-patients :deep(path)
  fill: var(--btn-blue-color)

.sort-icon :deep(path)
  fill: var(--font-grey-color)
</style>
