<template lang="pug">
  base-input-container.gap-y-2(:important="important", :label="label", :style="{width: widthInternal, ...sizeVariable}")
    q-input.input(
      v-model="value",
      :name="name",
      :multiple="multiple",
      :class="{'circle': circle, 'font-input': true, 'doc': doc}",
      :input-style="{resize: resize, height: height}",
      :borderless="borderless",
      :placeholder="placeholder",
      outlined,
      :type="type",
      :readonly="readonly",
      :disable="disabled",
      :rules="rule",
      :lazy-rules="lazyRule",
      :item-aligned="itemAligned",
      no-error-icon,
      :standout="readonly",
      :mask="mask",
      :autogrow="autogrow",
      :accept="accept",
      :debounce="debounce",
      :shadow-text="shadowText",
      :autofocus="autofocus",
      hide-bottom-space,
      :error="error",
      @focus="e => $emit('focus', e)"
    )
      template(v-slot:prepend, v-if="iconLeft")
        slot(name="iconLeft")
      template(v-slot:append, v-if="iconRight")
        slot(name="iconRight")
      template(#error v-if="error")
        slot(name="error")
      slot(v-if="!iconLeft && !iconRight")
</template>

<script>
import BaseInputContainer from "@/components/base/BaseInputContainer.vue";

export default {
  name: "BaseInput",
  components: { BaseInputContainer },
  props: {
    multiple: Boolean,
    doc: Boolean,
    autofocus: Boolean,
    borderless: {
      type: Boolean,
      default: false,
    },
    autogrow: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    accept: {
      type: String,
      default: "",
    },
    resize: {
      type: String,
      default: "none",
    },
    shadowText: String,
    mask: String,
    debounce: [String, Number],
    width: [String, Number],
    rule: Array,
    lazyRule: [Boolean, String],
    itemAligned: Boolean,
    modelValue: [String, Date, Number],
    placeholder: String,
    disabled: Boolean,
    label: String,
    readonly: Boolean,
    iconLeft: Boolean,
    iconRight: Boolean,
    name: String,
    important: Boolean,
    size: String,
    circle: Boolean,
    height: String,
    error: Boolean,
    hint: String,
  },
  emits: ["update:modelValue", "focus"],
  computed: {
    value: {
      get() {
        if (this.type === "date") {
          return this.modelValue
            ? this.modelValue?.toISOString().split("T")[0]
            : null;
        }
        return this.modelValue;
      },
      set(value) {
        this.type === "date"
          ? this.$emit("update:modelValue", value ? new Date(value) : null)
          : this.$emit("update:modelValue", value);
      },
    },
    sizeVariable() {
      if (this.size === "XS")
        return {
          "--input-height": "28px",
          "--text-size": "12px",
          "--line-height": "135%",
          "--px": "0 8px",
          "--py": "10px 0",
        };
      if (this.size === "S")
        return {
          "--input-height": "32px",
          "--text-size": "12px",
          "--line-height": "135%",
          "--px": "0 8px",
          "--py": "10px 0",
        };
      if (this.size === "M")
        return {
          "--input-height": "40px",
          "--text-size": "16px",
          "--line-height": "normal",
          "--px": "0 16px",
          "--py": "8px 0",
        };
      if (this.size === "L")
        return {
          "--input-height": "48px",
          "--text-size": "16px",
          "--line-height": "normal",
          "--px": "0 16px",
          "--py": "8px 0",
        };
      if (this.size)
        return {
          "--input-height": this.size,
          "--text-size": "16px",
          "--line-height": "normal",
          "--px": "0 16px",
          "--py": "8px 0",
        };
      return {
        "--input-height": "56px",
        "--text-size": "16px",
        "--line-height": "normal",
        "--px": "0 16px",
        "--py": "8px 0",
      };
    },
    widthInternal() {
      if (typeof this.width === "number") return this.width + "px";
      return this.width;
    },
  },
};
</script>

<style lang="sass" scoped>
.font-input
  font-feature-settings: 'pnum' on, 'lnum' on

.circle
  width: 100%
  height: 100%
  border-radius: 50%
  z-index: 5
  opacity: 0
  cursor: pointer

.doc
  width: 100%
  height: 100%
  z-index: 5
  opacity: 0
  cursor: pointer !important
  ::file-selector-button
    cursor: pointer

.input :deep(input[type="file" i])
  cursor: pointer

.input :deep(.q-field__native)
  font-weight: 500
  font-size: var(--text-size)
  line-height: var(--line-height)
  color: var(--font-dark-blue-color)
  padding: var(--py)
  &::placeholder
    color: var(--font-grey-color)
    opacity: 1

.input :deep(.q-field__control)
  height: var(--input-height) !important
  color: var(--font-dark-blue-color)
  padding: var(--px)
  &:before
    border-color: var(--border-light-grey-color) !important
    transition: none
  &:hover:before
    border-color: var(--font-grey-color) !important
  &:after
    border-width: 1px !important
    transition: none
    transform: none !important

.q-field--disabled :deep(.q-field__control > div)
  opacity: 1 !important

.q-field--outlined.q-field--disabled :deep(.q-field__native)
  color: var(--font-grey-color)

.q-field--outlined.q-field--disabled :deep(.q-field__control)
  background: var(--bg-light-grey) !important

.q-field--outlined.q-field--readonly :deep(.q-field__control)
  background: var(--bg-light-grey) !important
  &:before
    border-color: var(--bg-light-grey) !important
    transition: none
  &:hover:before
    border-color: var(--bg-light-grey) !important

.q-field--outlined.q-field--readonly :deep(.q-field__native)
  cursor: default

.q-field--error :deep(.q-field__bottom)
  padding: 4px 0 0 0
  font-weight: 500
  font-size: 12px
  line-height: 135% !important

.input :deep(.q-field__marginal)
  height: auto !important

.input :deep(.q-field__shadow)
  opacity: 0.5
  top: 3px
  color: var(--font-grey-color)
  margin-left: 8px
</style>
