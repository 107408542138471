<template lang="pug">
  svg(:width="configSize[nameSvg].width" :height="configSize[nameSvg].height" :viewBox="`0 0 ${configSize[nameSvg].width} ${configSize[nameSvg].height}`" fill="none" xmlns="http://www.w3.org/2000/svg")
    g.hover(v-if="nameSvg === 'round'", :opacity="active ? '1' : '0.6'")
      path(opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M7.5 20C7.5 26.9036 13.0964 32.5 20 32.5C26.9036 32.5 32.5 26.9036 32.5 20C32.5 13.0964 26.9036 7.5 20 7.5C13.0964 7.5 7.5 13.0964 7.5 20ZM20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5Z" :fill="active ? '#4772F2' : '#9294A7'")
      path(d="M10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20Z" :fill="active ? '#4772F2' : '#9294A7'")
    g.hover(v-if="nameSvg === 'medcard'", :opacity="active ? '1' : '0.6'")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M14.221 7.30769C14.4088 6.00296 15.5381 5 16.9032 5H23.0968C24.4619 5 25.5912 6.00296 25.779 7.30769H29.2903C30.7868 7.30769 32 8.51308 32 10V32.3077C32 33.7946 30.7868 35 29.2903 35H10.7097C9.21317 35 8 33.7946 8 32.3077V10C8 8.51308 9.21316 7.30769 10.7097 7.30769H14.221ZM16.9032 7.30769C16.6894 7.30769 16.5161 7.47989 16.5161 7.69231V9.23077C16.5161 9.44319 16.6894 9.61538 16.9032 9.61538H23.0968C23.3106 9.61538 23.4839 9.44319 23.4839 9.23077V7.69231C23.4839 7.47989 23.3106 7.30769 23.0968 7.30769H16.9032Z" :fill="active ? '#4772F2' : '#9294A7'")
    g.hover(v-if="nameSvg === 'group'", :opacity="active ? '1' : '0.6'")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M0 23.6157C0 20.3869 2.6735 17.7695 5.97143 17.7695H16.0286C19.3265 17.7695 22 20.3869 22 23.6157V24.8464C22 26.036 21.015 27.0003 19.8 27.0003H2.2C0.984974 27.0003 0 26.036 0 24.8464V23.6157Z" :fill="active ? '#4772F2' : '#9294A7'")
      path(d="M17.1272 8.68044C17.1272 12.0644 14.3839 14.8077 11 14.8077C7.616 14.8077 4.87275 12.0644 4.87275 8.68044C4.87275 5.29647 7.616 2.55322 11 2.55322C14.3839 2.55322 17.1272 5.29647 17.1272 8.68044Z" :fill="active ? '#4772F2' : '#9294A7'")
      g(opacity="0.6")
        path(d="M20.3457 12.077H27.66C30.0584 12.077 32.0028 14.04 32.0028 16.4616V17.3847C32.0028 18.2768 31.2865 19.0001 30.4028 19.0001H23.6888C22.357 16.8586 20.129 15.3317 17.5488 14.8961C18.2892 14.1192 18.8898 13.2091 19.3081 12.2029C19.6406 12.1206 19.9881 12.077 20.3457 12.077Z" :fill="active ? '#4772F2' : '#9294A7'")
        path(d="M24.0677 9.83683C26.5477 9.83683 28.5582 7.82634 28.5582 5.34627C28.5582 2.8662 26.5477 0.855713 24.0677 0.855713C21.5876 0.855713 19.5771 2.8662 19.5771 5.34627C19.5771 7.82634 21.5876 9.83683 24.0677 9.83683Z" :fill="active ? '#4772F2' : '#9294A7'")
    g(v-if="nameSvg === 'sort'")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M5.76088 3.12494C6.10606 3.12494 6.38588 3.40476 6.38588 3.74994V14.5787L8.55012 12.0898C8.77662 11.8293 9.17139 11.8018 9.43186 12.0283C9.69233 12.2548 9.71988 12.6496 9.49338 12.91L6.23251 16.66C6.1138 16.7966 5.94178 16.8749 5.76088 16.8749C5.57998 16.8749 5.40795 16.7966 5.28925 16.66L2.02838 12.91C1.80188 12.6496 1.82942 12.2548 2.0899 12.0283C2.35037 11.8018 2.74514 11.8293 2.97164 12.0898L5.13588 14.5787V3.74994C5.13588 3.40476 5.4157 3.12494 5.76088 3.12494Z" :fill="active ? 'var(--default-white)' : '#9294A7'")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M14.729 2.81763C14.9618 2.81763 15.1752 2.94695 15.283 3.15324L18.0222 8.39692C18.1821 8.70287 18.0636 9.08045 17.7577 9.24028C17.4517 9.4001 17.0741 9.28165 16.9143 8.9757L16.0583 7.33701H13.3998L12.5437 8.9757C12.3839 9.28165 12.0063 9.4001 11.7004 9.24028C11.3944 9.08045 11.276 8.70287 11.4358 8.39692L14.175 3.15324C14.2828 2.94695 14.4963 2.81763 14.729 2.81763ZM14.0528 6.08701H15.4053L14.729 4.79246L14.0528 6.08701Z" :fill="active ? 'var(--default-white)' : '#9294A7'")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M14.0513 10.2358C14.8116 10.071 15.6957 10.1295 16.3039 10.2174C16.6114 10.2619 16.8395 10.5254 16.8395 10.836V16.25C16.8395 16.5951 16.5597 16.875 16.2145 16.875C15.8693 16.875 15.5895 16.5951 15.5895 16.25V14.6599L12.9292 16.7421C12.6573 16.9549 12.2645 16.907 12.0518 16.6352C11.839 16.3634 11.8869 15.9705 12.1587 15.7578L14.3578 14.0366C14.2053 14.004 14.054 13.964 13.9078 13.9147C13.5445 13.7921 13.1658 13.5977 12.8743 13.2766C12.5702 12.9416 12.3977 12.5081 12.3977 11.9898C12.3977 11.4732 12.603 11.0528 12.9447 10.7511C13.2645 10.4687 13.6704 10.3184 14.0513 10.2358ZM15.5895 12.9233C15.1533 12.9047 14.6894 12.8591 14.3075 12.7303C14.0626 12.6476 13.8989 12.5455 13.7999 12.4365C13.7136 12.3414 13.6477 12.2111 13.6477 11.9898C13.6477 11.8351 13.6965 11.7548 13.772 11.6881C13.8695 11.6021 14.0441 11.5164 14.3162 11.4574C14.6968 11.3749 15.1581 11.3673 15.5895 11.3973V12.9233Z" :fill="active ? 'var(--default-white)' : '#9294A7'")

</template>

<script>
export default {
  name: "CalendarSidebarSvg",
  props: {
    nameSvg: { type: String, default: "round" },
    active: Boolean,
  },
  data() {
    return {
      configSize: {
        round: { width: 40, height: 40 },
        medcard: { width: 40, height: 40 },
        group: { width: 33, height: 27 },
        sort: { width: 20, height: 20 },
      },
    };
  },
};
</script>
