export function getConfidantObject(data) {
  if (!data) {
    return {
      networks: [],
      email: {
        value: null,
      },
      phone: {
        value: null,
      },
      first_name: {
        value: null,
      },
      last_name: {
        value: null,
      },
      patronymic: {
        value: null,
      },
      note: {
        value: null,
      },
    };
  } else {
    const phone = data.contacts.find((el) => el.kind === "PHONE");
    const email = data.contacts.find((el) => el.kind === "EMAIL");
    const networks = data.contacts.filter(
      (el) => el.kind !== "PHONE" && el.kind !== "EMAIL"
    );
    return {
      id: data.id,
      first_name: {
        value: data?.first_name,
      },
      last_name: {
        value: data?.last_name,
      },
      patronymic: {
        value: data?.patronymic,
      },
      phone: {
        value: phone?.username,
        id: phone?.id,
      },
      email: {
        value: email?.username,
        id: email?.id,
      },
      networks,
      note: {
        id: data?.note[0]?.id,
        value: data?.note.length ? data?.note[0].description : "",
      },
    };
  }
}

export const getFilledConditions = (condition) => {
  let filledConditions = [];
  let crown = Object.keys(condition.crown)
    .map((key) => ({
      value: condition.crown[key],
      type: "crown",
      part: key,
    }))
    .filter((el) => el.value.length);
  let root = Object.keys(condition.root)
    .map((key) => ({
      value: condition.root[key],
      type: "root",
      part: key,
    }))
    .filter((el) => el.value.length);
  filledConditions = [...crown, ...root];
  if (condition.general)
    filledConditions.unshift({
      value: condition.general,
      type: "general",
    });
  return filledConditions;
};

export function getToothImageConfig(data) {
  let grey = "var(--border-light-grey-color)";
  let yellow = "var(--bg-yellow-warning)";
  let red = "var(--border-red-color)";
  let resulConfig = {};
  Object.keys(data).forEach((key) => {
    if (key === "general") {
      resulConfig[key] = data[key];
    } else if (key === "crown") {
      let keyConfig = {
        general: {
          fill: resulConfig.general === "П" ? red : grey,
          injury: resulConfig.general === "П",
          seal: false,
        },
      };
      Object.keys(data[key]).forEach((part) => {
        let partConfig = {
          fill: grey,
          injury: false,
        };
        if (part === "central" && data[key][part].includes("Пл")) {
          partConfig.fill = yellow;
          keyConfig.general.seal = true;
        } else {
          if (data[key][part].includes("П") || data[key][part].includes("Пт")) {
            partConfig.fill = red;
            partConfig.injury = true;
            if (!keyConfig.general.seal) {
              keyConfig.general.fill = red;
              keyConfig.general.injury = true;
            }
          }
          if (data[key][part].includes("Д")) {
            if (partConfig.fill !== red) {
              partConfig.fill = yellow;
            }
            if (!keyConfig.general.seal && !keyConfig.general.injury) {
              keyConfig.general.fill = yellow;
            }
          }
          if (data[key][part].includes("К")) {
            partConfig.injury = true;
            keyConfig.general.injury = true;
          }
        }
        keyConfig[part] = { ...partConfig };
      });
      resulConfig[key] = { ...keyConfig };
    } else {
      let keyConfig = {};
      Object.keys(data[key]).forEach((part) => {
        let partConfig = {
          fill: grey,
          injury: false,
        };
        if (data[key][part].includes("П") || data[key][part].includes("Пт")) {
          partConfig.fill = red;
          partConfig.injury = true;
        }
        if (data[key][part].includes("КЗ")) {
          partConfig.fill = red;
        }
        keyConfig[part] = { ...partConfig };
      });
      resulConfig[key] = { ...keyConfig };
    }
  });
  return resulConfig;
}
export function getReturnedObjectImg(fields, e, file) {
  let result = {};
  fields.forEach((field) => {
    switch (field) {
      case "file":
        result.file = file;
        break;
      case "photo":
        result.photo = e.target.result;
        break;
      case "id":
        result.id = Math.random();
        break;
      case "name":
        result.name = file.name;
        break;
      case "size":
        result.size = (file.size / 1024 / 1024).toFixed(1);
        break;
    }
  });
  return result;
}
