<template lang="pug">
  div(:class="{active: focused}")
    .header-inputs-wrapper.flex.items-center.font-medium.text-base
      .h-full.px-4.py-2.select.flex.items-center
        q-menu(
          :offset="[0, 6]",
          v-model="isOpen",
          :style="{'box-shadow': '-4px -4px 8px 0px rgba(9, 10, 21, 0.10), 4px 4px 8px 0px rgba(9, 10, 21, 0.10)'}"
        )
          .menu.bg-shite.p-4.flex.flex-col.gap-y-3
            .item.text-base.font-medium.flex.gap-x-2.cursor-pointer(v-for="filter in filters", v-close-popup, @click="selectFilter(filter)")
              .h-6.w-6.items-center.justify-center.flex
                q-icon.ok(name="app:ok", size="24px", v-if="filter === selectedFilter")
              .flex.items-center.text-dark {{ filter }}
        q-icon.mr-2(
          name="app:search",
          size="24px"
        )
        .flex-1.h-full.flex.items-center.justify-between
          .text-base.font-medium {{ selectedFilter }}
          .h-4.w-4.flex.items-center.pt-1.justify-center.ml-1
            q-icon(
              name="app:down-arrow",
              size="16x",
              :class="{'rotate-180': isOpen}"
            )
      q-input.input.px-4(
        type="text",
        placeholder="Искать ...",
        borderless,
        square,
        @focus="focused = true",
        @blur="focused = false"
      )
</template>

<script>
export default {
  name: "HeaderInputs",
  data() {
    return {
      selectedFilter: "Календарь",
      filters: ["Календарь", "Клиенты"],
      isOpen: false,
      focused: false,
    };
  },
  methods: {
    selectFilter(filter) {
      this.selectedFilter = filter;
    },
  },
};
</script>

<style lang="sass" scoped>
.header-inputs-wrapper
  height: 40px
  background-color: var(--default-white)
  border-radius: 4px
  border-width: 1.5px
  border-style: solid
  border-color: #DDDDE8
  &:hover
    border-color: var(--font-grey-color)
    .select
      border-right-color: var(--font-grey-color)

.active
  .header-inputs-wrapper
    border-color: var(--font-dark-blue-color)
  .select
    border-right-color: var(--font-dark-blue-color) !important

.select
  border-right-width: 1.5px
  border-right-style: solid
  border-right-color: var(--border-light-grey-color)
  width: 167px
  color: var(--font-grey-color)

.input
  width: 230px
  &:deep(.q-field__native)
    font-weight: 500
    font-size: 16px
    line-height: normal
    color: var(--font-dark-blue-color)
    &::placeholder
      color: var(--font-grey-color)
      opacity: 1

.menu
  width: 216px

.item:hover
  opacity: 0.6

.astra-vicons :deep(path)
  fill: var(--font-grey-color)

.ok :deep(path)
  fill: var(--font-dark-blue-color)
</style>
