<template lang="pug">
  .sidebar.flex.flex-col.justify-between.pt-4.px-2.pb-7.rounded
    .flex.flex-col.gap-y-4
      base-button-sidebar(
        v-for="button in pageSettings.filter((el) => el.id !== 'settings')",
        :path="button.path",
        :id="button.id",
        :active="button.active",
        :change-style-page="changeStylePage",
        :icon="button.icon"
      )
    .flex.text-4xl.flex-col.gap-y-6
      base-button-sidebar(
        :path="getSettings.path",
        :id="getSettings.id",
        :active="getSettings.active",
        :change-style-page="changeStylePage",
        :icon="getSettings.icon"
      )
</template>

<script>
import BaseButtonSidebar from "@/components/base/BaseSidebarButton";

export default {
  name: "TheSidebar",
  components: { BaseButtonSidebar },
  data() {
    return {
      pageSettings: [
        {
          id: "calendar",
          path: "#/calendar",
          active: true,
          icon: "calendar",
        },
        {
          id: "medcards",
          path: "#/medcards",
          active: false,
          icon: "medcards",
        },
        {
          id: "settings",
          path: "#/settings",
          icon: "settings",
          active: false,
        },
      ],
      currentPageBorder: true,
    };
  },
  methods: {
    changeStylePage(e) {
      this.pageSettings.forEach((el, index) => {
        el.id === e.currentTarget.id
          ? (this.pageSettings[index].active = true)
          : (this.pageSettings[index].active = false);
      });
    },
  },
  computed: {
    getSettings() {
      return this.pageSettings.find((el) => el.id === "settings");
    },
  },
  watch: {
    "$route.path"(value) {
      if (value === "/calendar") {
        this.currentPageBorder = true;
        this.pageSettings.forEach((el) =>
          el.path === "#/calendar" ? (el.active = true) : (el.active = false)
        );
      } else this.currentPageBorder = false;
    },
  },
  mounted() {
    this.pageSettings.forEach((el, index) => {
      el.path === "#" + this.$route.path
        ? (this.pageSettings[index].active = true)
        : (this.pageSettings[index].active = false);
    });
  },
};
</script>

<style lang="sass" scoped>
.sidebar
  max-width: 64px
  min-width: 64px
  background-color: var(--default-white)
</style>
