import CalendarRecordCard from "@/pages/newCalendar/components/CalendarRecordCard.vue";
import { verifyTime } from "@/pages/newCalendar/utils/calendarFunctions.js";
import { mapActions, mapState } from "vuex";

export const columnMixin = {
  props: {
    dayEndTime: Number,
    dayStartTime: Number,
  },
  emits: [],
  components: { CalendarRecordCard },
  data() {
    return {
      pixelsPerHour: 76,
    };
  },
  computed: {
    ...mapState({
      events: (state) => state.calendar.events || [],
    }),
    pixelsPerMinute() {
      return this.pixelsPerHour / 60;
    },
  },
  methods: {
    ...mapActions({
      changeSelectedRecordId: "changeSelectedRecordId",
    }),
    eventCardPosition(startTime, endTime) {
      let start = startTime
        .slice(11, -4)
        .split(":")
        .map((elem) => parseInt(elem, 10));
      let end = endTime.slice(11, -4);
      let position =
        (start[0] - this.dayStartTime) * this.pixelsPerHour +
        start[1] * this.pixelsPerMinute +
        4;
      if (
        parseInt(start[0], 10) < this.dayStartTime ||
        verifyTime(end) > this.dayEndTime
      ) {
        return {
          top: "0px",
          visibility: "hidden",
        };
      }
      return {
        top: `${position}px`,
      };
    },
  },
};
