import personImage from "@/assets/images/person.png";
import accepted from "@/assets/icons/status_accepted.svg";
import not_accepted from "@/assets/icons/status_not_accepted.svg";
import rejected from "@/assets/icons/status_rejected.svg";
import reception from "@/assets/icons/status_reception.svg";

export const networks = [
  {
    id: "VK",
    icon: "app:vk",
  },
  {
    id: "TELEGRAM",
    icon: "app:tg",
  },
  {
    id: "GMAIL",
    icon: "app:google",
  },
  {
    id: "SLACK",
    icon: "app:slack",
  },
  {
    id: "DISCORD",
    icon: "app:discord",
  },
  {
    id: "VIBER",
    icon: "wifi_calling_3",
  },
  {
    id: "PHONE",
    icon: "app:phone",
  },
  {
    id: "EMAIL",
    icon: "app:mail",
  },
];

export const statuses = [
  {
    name: "recording",
    data: [
      {
        id: 0,
        label: "Не принят",
        icon: not_accepted,
        value: "not_accepted",
        class: "not-accepted",
      },
      {
        id: 1,
        label: "Принят",
        icon: accepted,
        value: "accepted",
        class: "accepted",
      },
      {
        id: 2,
        label: "Отказ",
        icon: rejected,
        value: "rejected",
        class: "rejected",
      },
      {
        id: 3,
        label: "На приеме",
        icon: reception,
        value: "reception",
        class: "reception",
      },
    ],
  },
  {
    name: "medicalCard",
    data: [
      {
        label: "Не заполнена",
        icon: "app:medcard",
        value: "not_filled",
      },
      {
        label: "Частично заполнена",
        icon: "app:medcard-orange",
        value: "partially_filled",
      },
      {
        label: "Заполнена",
        icon: "app:medcard-green",
        value: "filled",
      },
    ],
  },
];

// TODO Статусы приема и статусы заполнения медкарты должны быть общими, чтобы не дублировать в каждом экспорте. Исправить
// TODO patientList - исправить название
export const patientList = [
  {
    label: "Статус приема",
    id: 0,
    data: [
      { id: 0, name: "Без приоритета" },
      { id: 1, name: "Высокий" },
      { id: 2, name: "Средний" },
      { id: 3, name: "Низский" },
    ],
    choice: false,
  },
  {
    label: "Первичная медкарта",
    id: 1,
    data: [
      { id: 0, name: "Не заполнена", icon: "app:medcard" },
      { id: 1, name: "Частично заполнена", icon: "app:medcard-orange" },
      { id: 2, name: "Заполнена", icon: "app:medcard-green" },
    ],
    choice: false,
  },
  {
    label: "Пациенты",
    id: 2,
    data: [
      {
        id: 0,
        first_name: "Александр",
        last_name: "Герасимов",
        patronymic: "Александрович",
        birthday: "28.03.1980",
        avatar: personImage,
        medicalNumber: "#12314",
        check: false,
        view: false,
        viewDate: "",
        reception: "31.07.2023",
        time: { from: "12:00", to: "13:00" },
        priority: 1,
        phone: "+7 (910) 623–32–23",
        email: "gaevaia@yandex.ru",
        company: "ООО «АльфаСтрахование»",
        policy: "№ 2131-1331-4142",
        fill: "33%",
        marks: [
          {
            id: 0,
            name: "Неправильный прикус",
            color: "#D8E3FF",
          },
          {
            id: 1,
            name: "Нервозность",
            color: "#CEF1F3",
          },
          {
            id: 2,
            name: "Парадантит",
            color: "#EBDBFF",
          },
        ],
        networks: [
          { id: 0, name: "VK", link: "vk.com/", icon: "app:vk" },
          { id: 1, name: "Telegram", link: "tg.com/", icon: "app:tg" },
        ],
      },
      {
        id: 1,
        first_name: "Петр",
        last_name: "Сложный",
        patronymic: "Олегович",
        birthday: "01.03.1975",
        avatar: personImage,
        medicalNumber: "#12314",
        check: false,
        view: false,
        viewDate: "",
        reception: "31.07.2023",
        time: { from: "13:00", to: "14:00" },
        priority: 1,
        phone: "+7 (910) 623–32–23",
        email: "gaevaia@yandex.ru",
        company: "ООО «АльфаСтрахование»",
        policy: "№ 2131-1331-4142",
        fill: "20%",
        marks: [
          {
            id: 0,
            name: "Неправильный прикус",
            color: "#D8E3FF",
          },
          {
            id: 1,
            name: "Нервозность",
            color: "#CEF1F3",
          },
          {
            id: 2,
            name: "Парадантит",
            color: "#EBDBFF",
          },
        ],
        networks: [
          { id: 0, name: "VK", link: "vk.com/", icon: "app:vk" },
          { id: 1, name: "Telegram", link: "tg.com/", icon: "app:tg" },
        ],
      },
      {
        id: 2,
        first_name: "Олег",
        last_name: "Пложкин",
        patronymic: "Васильевич",
        birthday: "13.07.1999",
        avatar: personImage,
        medicalNumber: "#12314",
        check: false,
        view: false,
        viewDate: "",
        reception: "31.07.2023",
        time: { from: "14:00", to: "15:00" },
        priority: 1,
        phone: "+7 (910) 623–32–23",
        email: "gaevaia@yandex.ru",
        company: "ООО «АльфаСтрахование»",
        policy: "№ 2131-1331-4142",
        fill: "50%",
        marks: [
          {
            id: 0,
            name: "Неправильный прикус",
            color: "#D8E3FF",
          },
          {
            id: 1,
            name: "Нервозность",
            color: "#CEF1F3",
          },
          {
            id: 2,
            name: "Парадантит",
            color: "#EBDBFF",
          },
        ],
        networks: [
          { id: 0, name: "VK", link: "vk.com/", icon: "app:vk" },
          { id: 1, name: "Telegram", link: "tg.com/", icon: "app:tg" },
        ],
      },
      {
        id: 3,
        first_name: "Самвелиуслим",
        last_name: "Акопян",
        patronymic: "Робертович",
        birthday: "11.11.1991",
        avatar: personImage,
        medicalNumber: "#12314",
        check: false,
        view: false,
        viewDate: "",
        reception: "31.07.2023",
        time: { from: "15:00", to: "17:00" },
        priority: 1,
        phone: "+7 (910) 623–32–23",
        email: "gaevaia@yandex.ru",
        company: "ООО «АльфаСтрахование»",
        policy: "№ 2131-1331-4142",
        fill: "50%",
        marks: [
          {
            id: 0,
            name: "Неправильный прикус",
            color: "#D8E3FF",
          },
          {
            id: 1,
            name: "Нервозность",
            color: "#CEF1F3",
          },
          {
            id: 2,
            name: "Парадантит",
            color: "#EBDBFF",
          },
        ],
        networks: [
          { id: 0, name: "VK", link: "vk.com/", icon: "app:vk" },
          { id: 1, name: "Telegram", link: "tg.com/", icon: "app:tg" },
        ],
      },
      {
        id: 4,
        first_name: "Евгений",
        last_name: "Вольный",
        patronymic: "Алексеевич",
        birthday: "12.03.1989",
        avatar: personImage,
        medicalNumber: "#12314",
        check: false,
        view: false,
        viewDate: "",
        reception: "31.07.2023",
        time: { from: "17:00", to: "20:00" },
        priority: 1,
        phone: "+7 (910) 623–32–23",
        email: "gaevaia@yandex.ru",
        company: "ООО «АльфаСтрахование»",
        policy: "№ 2131-1331-4142",
        fill: "50%",
        marks: [
          {
            id: 0,
            name: "Неправильный прикус",
            color: "#D8E3FF",
          },
          {
            id: 1,
            name: "Нервозность",
            color: "#CEF1F3",
          },
          {
            id: 2,
            name: "Парадантит",
            color: "#EBDBFF",
          },
        ],
        networks: [
          { id: 0, name: "VK", link: "vk.com/", icon: "app:vk" },
          { id: 1, name: "Telegram", link: "tg.com/", icon: "app:tg" },
        ],
      },
      {
        id: 5,
        first_name: "Анатолий",
        last_name: "Петров",
        patronymic: "Васильевич",
        birthday: "31.07.1949",
        avatar: personImage,
        medicalNumber: "#12314",
        check: false,
        view: false,
        viewDate: "",
        reception: "31.07.2023",
        time: { from: "10:00", to: "12:00" },
        priority: 1,
        phone: "+7 (910) 623–32–23",
        email: "gaevaia@yandex.ru",
        company: "ООО «АльфаСтрахование»",
        policy: "№ 2131-1331-4142",
        fill: "50%",
        marks: [
          {
            id: 0,
            name: "Неправильный прикус",
            color: "#D8E3FF",
          },
          {
            id: 1,
            name: "Нервозность",
            color: "#CEF1F3",
          },
          {
            id: 2,
            name: "Парадантит",
            color: "#EBDBFF",
          },
        ],
        networks: [
          { id: 0, name: "VK", link: "vk.com/", icon: "app:vk" },
          { id: 1, name: "Telegram", link: "tg.com/", icon: "app:tg" },
        ],
      },
      {
        id: 6,
        first_name: "Людмила",
        last_name: "Романова",
        patronymic: "Олеговна",
        birthday: "12.11.1995",
        avatar: personImage,
        medicalNumber: "#12314",
        check: false,
        view: false,
        viewDate: "",
        reception: "26.07.2023",
        time: { from: "16:00", to: "17:00" },
        priority: 0,
        phone: "+7 (910) 623–32–23",
        email: "gaevaia@yandex.ru",
        company: "ООО «АльфаСтрахование»",
        policy: "№ 2131-1331-4142",
        fill: "50%",
        marks: [
          {
            id: 0,
            name: "Неправильный прикус",
            color: "#D8E3FF",
          },
          {
            id: 1,
            name: "Нервозность",
            color: "#CEF1F3",
          },
          {
            id: 2,
            name: "Парадантит",
            color: "#EBDBFF",
          },
        ],
        networks: [
          { id: 0, name: "VK", link: "vk.com/", icon: "app:vk" },
          { id: 1, name: "Telegram", link: "tg.com/", icon: "app:tg" },
        ],
      },
      {
        id: 7,
        first_name: "Святослав",
        last_name: "Зимний",
        patronymic: "Алексеевич",
        birthday: "15.05.1989",
        avatar: personImage,
        medicalNumber: "#12314",
        check: false,
        view: false,
        viewDate: "",
        reception: "01.08.2023",
        time: { from: "15:00", to: "21:00" },
        priority: 1,
        phone: "+7 (910) 623–32–23",
        email: "gaevaia@yandex.ru",
        company: "ООО «АльфаСтрахование»",
        policy: "№ 2131-1331-4142",
        fill: "50%",
        marks: [
          {
            id: 0,
            name: "Неправильный прикус",
            color: "#D8E3FF",
          },
          {
            id: 1,
            name: "Нервозность",
            color: "#CEF1F3",
          },
          {
            id: 2,
            name: "Парадантит",
            color: "#EBDBFF",
          },
        ],
        networks: [
          { id: 0, name: "VK", link: "vk.com/", icon: "app:vk" },
          { id: 1, name: "Telegram", link: "tg.com/", icon: "app:tg" },
        ],
      },
      {
        id: 8,
        first_name: "Алексей",
        last_name: "Пложкин",
        patronymic: "Васильевич",
        birthday: "13.07.2000",
        avatar: personImage,
        medicalNumber: "#12314",
        check: false,
        view: false,
        viewDate: "",
        reception: "26.06.2023",
        time: { from: "22:00", to: "23:00" },
        priority: 1,
        phone: "+7 (910) 623–32–23",
        email: "gaevaia@yandex.ru",
        company: "ООО «АльфаСтрахование»",
        policy: "№ 2131-1331-4142",
        fill: "50%",
        marks: [
          {
            id: 0,
            name: "Неправильный прикус",
            color: "#D8E3FF",
          },
          {
            id: 1,
            name: "Нервозность",
            color: "#CEF1F3",
          },
          {
            id: 2,
            name: "Парадантит",
            color: "#EBDBFF",
          },
        ],
        networks: [
          { id: 0, name: "VK", link: "vk.com/", icon: "app:vk" },
          { id: 1, name: "Telegram", link: "tg.com/", icon: "app:tg" },
        ],
      },
      {
        id: 9,
        first_name: "Ипполит",
        last_name: "Федос",
        patronymic: "Федорович",
        birthday: "12.12.1986",
        avatar: personImage,
        medicalNumber: "#12314",
        check: false,
        view: false,
        viewDate: "",
        reception: "25.07.2023",
        time: { from: "22:00", to: "23:00" },
        priority: 1,
        phone: "+7 (910) 623–32–23",
        email: "gaevaia@yandex.ru",
        company: "ООО «АльфаСтрахование»",
        policy: "№ 2131-1331-4142",
        fill: "50%",
        marks: [
          {
            id: 0,
            name: "Неправильный прикус",
            color: "#D8E3FF",
          },
          {
            id: 1,
            name: "Нервозность",
            color: "#CEF1F3",
          },
          {
            id: 2,
            name: "Парадантит",
            color: "#EBDBFF",
          },
        ],
        networks: [
          { id: 0, name: "VK", link: "vk.com/", icon: "app:vk" },
          { id: 1, name: "Telegram", link: "tg.com/", icon: "app:tg" },
        ],
      },
      {
        id: 10,
        first_name: "Евгений",
        last_name: "Сложный",
        patronymic: "Алексеевич",
        birthday: "13.03.1979",
        avatar: personImage,
        medicalNumber: "#12314",
        check: false,
        view: false,
        viewDate: "",
        reception: "27.07.2023",
        time: { from: "09:00", to: "10:00" },
        priority: 1,
        phone: "+7 (910) 623–32–23",
        email: "gaevaia@yandex.ru",
        company: "ООО «АльфаСтрахование»",
        policy: "№ 2131-1331-4142",
        fill: "50%",
        marks: [
          {
            id: 0,
            name: "Неправильный прикус",
            color: "#D8E3FF",
          },
          {
            id: 1,
            name: "Нервозность",
            color: "#CEF1F3",
          },
          {
            id: 2,
            name: "Парадантит",
            color: "#EBDBFF",
          },
        ],
        networks: [
          { id: 0, name: "VK", link: "vk.com/", icon: "app:vk" },
          { id: 1, name: "Telegram", link: "tg.com/", icon: "app:tg" },
        ],
      },
    ],
    choice: false,
  },
];
export const patientData = {
  basic: {
    full_name: "",
    birth_date: null,
    priority: {
      id: null,
      label: "",
    },
    contacts: [
      {
        kind: {
          id: "TELEGRAM",
          icon: "app:tg",
        },
        username: "",
      },
    ],
  },
  identity_document: {
    pass: {
      series_number: "",
      issued_by_org: "",
      issued_by_date: null,
      issued_by_org_code: "",
    },
    snils: {
      kind: "СНИЛС",
      number: "",
    },
    inn: {
      kind: "ИНН",
      number: "",
    },
  },
  phone: { username: "" },
  email: { username: "" },
  addresses: {},
  priorityList: [
    {
      id: 1,
      label: "Высокий",
    },
    {
      id: 2,
      label: "Средний",
    },
    {
      id: 3,
      label: "Низкий",
    },
    {
      id: 4,
      label: "-",
    },
  ],
  statuses: [
    { id: 0, name: "Не принят", icon: not_accepted, check: true },
    { id: 1, name: "Принят", icon: accepted, check: false },
    { id: 2, name: "Отказ", icon: rejected, check: false },
    { id: 3, name: "На приеме", icon: reception, check: false },
  ],
  arrSvg: [
    { name: "round", text: "Статус приема", active: false },
    { name: "medcard", text: "Первичная медкарта", active: false },
    { name: "group", text: "Пациенты", active: false },
  ],
};

export const services = [
  {
    name: "Терапия",
    check: true,
    data: [
      {
        title: "Пломбирование кариеса",
        price: "350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Консультация стоматолога",
        price: "1 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Пломбирование кариеса",
        price: "2 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Пломбирование кариеса",
        price: "3 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Пломбирование кариеса",
        price: "5 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Пломбирование кариеса",
        price: "50 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Пломбирование кариеса",
        price: "3 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Пломбирование кариеса",
        price: "5 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Пломбирование кариеса",
        price: "50 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
    ],
  },
  {
    name: "Хирургия",
    check: false,
    data: [
      {
        title: "Пломбирование кариеса",
        price: "2 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Консультация стоматолога",
        price: "1 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
    ],
  },
  {
    name: "Ортопедия  ",
    check: false,
    data: [
      {
        title: "Пломбирование кариеса",
        price: "2 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Консультация стоматолога",
        price: "1 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
        color: "var(--bg-chip-blue-color)",
      },
    ],
  },
  {
    name: "Ортодонтия",
    check: false,
    data: [
      {
        title: "Пломбирование кариеса",
        price: "2 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
      },
      {
        title: "Консультация стоматолога",
        price: "1 350 ₽",
        time: "1 ч. 30 мин.",
        checked: false,
      },
    ],
  },
];

export const recordList = [
  {
    id: "asdf0a95-8093-41f1-9584-5b70ee05e71c",
    start: "2023-09-18T10:00:00Z",
    end: "2023-09-18T11:00:00Z",
    employee: {
      id: "464101e6-b4e6-46a4-8ef6-08ecb2921493",
      last_name: "Каневский",
      first_name: "Леонид",
      patronymic: "Семенович",
    },
    person: {
      id: "3e6e54e4-2706-47d3-8b54-b841ee8f0943",
      last_name: "Харитонова",
      first_name: "Ольга",
      patronymic: "Васильевна",
      birth_date: "1990-03-12",
      photo: personImage,
      contacts: [
        {
          value: "+7 (910) 424–13–13",
          kind: "PHONE",
        },
        {
          value: "Haritonich@mail.ru",
          kind: "EMAIL",
        },
      ],
    },
    status: "not_accepted",
    medicalCard: {
      status: "partially_filled",
    },
    services: [
      {
        title: "Изготовление коронки",
        cost: 2700,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Снятие слепков",
        cost: 400,
        color: "var(--bg-chip-yellow-color)",
      },
    ],
  },
  {
    id: "ss931000-7140-4977-bd09-1ac212b8b8e5",
    start: "2023-09-18T15:00:00Z",
    end: "2023-09-18T15:30:00Z",
    employee: {
      id: "464101e6-b4e6-46a4-8ef6-08ecb2921493",
      last_name: "Каневский",
      first_name: "Леонид",
      patronymic: "Семенович",
    },
    person: {
      id: "d340d344-4fc7-4fbe-9db8-b7562b70438d",
      last_name: "Гаранова",
      first_name: "Наталья",
      patronymic: "Романовна",
      birth_date: "1990-04-12",
      photo: personImage,
      contacts: [
        {
          value: "+7 (910) 424–13–13",
          kind: "PHONE",
        },
        {
          value: "Haritonich@mail.ru",
          kind: "EMAIL",
        },
      ],
    },
    status: "accepted",
    medicalCard: {
      status: "not_filled",
    },
    services: [
      {
        title: "Осмотр",
        cost: 0,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Лечение среднего кариеса",
        cost: 2000,
        color: "var(--bg-chip-yellow-color)",
      },
      {
        title: "Изготовление коронки",
        cost: 2700,
        color: "var(--bg-chip-pink-color)",
      },
      {
        title: "Снятие слепков",
        cost: 400,
      },
    ],
  },
  {
    id: "ccd94bec-a0df-4a18-879d-f64cd6d5698e",
    start: "2023-09-18T13:00:00Z",
    end: "2023-09-18T14:30:00Z",
    employee: {
      id: "111101e6-b4e6-46a4-8ef6-08ecb2921493",
      last_name: "Гурцев",
      first_name: "Семен",
      patronymic: "Семенович",
    },
    person: {
      id: "0b2d1db1-6aab-4e29-b857-fc7c9777280f",
      last_name: "Харитонова",
      first_name: "Ольга",
      patronymic: "Васильевна",
      birth_date: "1990-05-12",
      photo: personImage,
      contacts: [
        {
          value: "+7 (910) 424–13–13",
          kind: "PHONE",
        },
      ],
    },
    status: "reception",
    medicalCard: {
      status: "filled",
    },
    services: [
      {
        title: "Чистка зубов",
        cost: 500,
        color: "var(--bg-chip-blue-color)",
      },
      {
        title: "Осмотр",
        cost: 0,
        color: "var(--bg-chip-yellow-color)",
      },
      {
        title: "Лечение среднего кариеса",
        cost: 2000,
        color: "var(--bg-chip-pink-color)",
      },
      {
        title: "Изготовление коронки",
        cost: 2700,
      },
      {
        title: "Снятие слепков",
        cost: 400,
      },
    ],
  },
  {
    id: "vv931000-7140-4977-bd09-1ac233b8b8e5",
    start: "2023-09-18T15:00:00Z",
    end: "2023-09-18T15:30:00Z",
    employee: {
      id: "333101e6-b4e6-46a4-8ef6-08ecb2921493",
      last_name: "Лебедев",
      first_name: "Леонид",
      patronymic: "Семенович",
    },
    person: {
      id: "d340d344-4fc7-4fbe-9db8-b7562b70438d",
      last_name: "Гаранова",
      first_name: "Наталья",
      patronymic: "Романовна",
      birth_date: "1990-04-12",
      photo: personImage,
      contacts: [
        {
          value: "+7 (910) 424–13–13",
          kind: "PHONE",
        },
        {
          value: "Haritonich@mail.ru",
          kind: "EMAIL",
        },
      ],
    },
    status: "accepted",
    medicalCard: {
      status: "not_filled",
    },
  },
  {
    id: "rr931000-7140-4977-bd09-1ac233b8b8e5",
    start: "2023-09-18T15:00:00Z",
    end: "2023-09-18T15:30:00Z",
    employee: {
      id: "666101e6-b4e6-46a4-8ef6-08ecb7721493",
      last_name: "Петров",
      first_name: "Леонид",
      patronymic: "Семенович",
    },
    person: {
      id: "d340d344-4fc7-4fbe-9db8-b7562b70438d",
      last_name: "Гаранова",
      first_name: "Наталья",
      patronymic: "Романовна",
      birth_date: "1990-04-12",
      photo: personImage,
      contacts: [
        {
          value: "+7 (910) 424–13–13",
          kind: "PHONE",
        },
        {
          value: "Haritonich@mail.ru",
          kind: "EMAIL",
        },
      ],
    },
    status: "accepted",
    medicalCard: {
      status: "not_filled",
    },
  },
  {
    id: "ma931000-7140-4977-bd09-1ac233b8b8e5",
    start: "2023-09-18T15:00:00Z",
    end: "2023-09-18T15:30:00Z",
    employee: {
      id: "999101e6-b4e6-46a4-8ef6-22ecb2921493",
      last_name: "Ленин",
      first_name: "Леонид",
      patronymic: "Семенович",
    },
    person: {
      id: "d340d344-4fc7-4fbe-9db8-b7562b70438d",
      last_name: "Гаранова",
      first_name: "Наталья",
      patronymic: "Романовна",
      birth_date: "1990-04-12",
      photo: personImage,
      contacts: [
        {
          value: "+7 (910) 424–13–13",
          kind: "PHONE",
        },
        {
          value: "Haritonich@mail.ru",
          kind: "EMAIL",
        },
      ],
    },
    status: "accepted",
    medicalCard: {
      status: "not_filled",
    },
  },
];

export const recordPreviewConfig = [
  {
    title: "Статус",
    data: "statusLabel",
    icon: "statusIcon",
  },
  {
    title: "Дата",
    data: "date",
  },
  {
    title: "Время",
    data: "time",
  },
  {
    title: "Пациент",
    data: null,
  },
  {
    title: "Услуги",
    data: null,
  },
];
