<template lang="pug">
  .wrapper.flex.flex-col.w-full.rounded
    .header.flex.items-center.text-sm.font-bold.justify-between.w-full.px-6.py-5
      .left-side.flex.gap-x-2.items-center
        span {{title}}
        .grey-color(v-if="quantity") {{quantity}}
      .link.flex.items-center.cursor-pointer(v-if="titleLink", @click="link")
        span.font-medium {{ titleLink }}
    slot
</template>

<script>
export default {
  name: "MedcardFormWrapper",
  props: {
    title: String,
    titleLink: String,
    link: Function,
    quantity: Number,
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  height: 360px
  background: var(--default-white)
  color: var(--font-dark-blue-color)

.header
  max-height: 62px
  border-bottom: 1px solid var(--gray-secondary)

.grey-color
  color: var(--font-grey-color)

.link
  color: var(--btn-blue-color)
  &:hover
    opacity: 0.7
</style>
