<template lang="pug">
  .table-wrapper.flex.gap-x-2    
    todays-patients-form
    current-patient-form
    viewed-medical-records-form.viev
</template>

<script>
import CurrentPatientForm from "./BasicDataForms/CurrentPatientForm.vue";
import TodaysPatientsForm from "./BasicDataForms/TodaysPatientsForm.vue";
import ViewedMedicalRecordsForm from "./BasicDataForms/ViewedMedicalRecordsForm.vue";

export default {
  name: "TablePatients",
  components: {
    TodaysPatientsForm,
    CurrentPatientForm,
    ViewedMedicalRecordsForm,
  },
};
</script>

<style lang="sass" scoped>
.viev
  @media (max-width: 1820px)
    display: none
</style>
