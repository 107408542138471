<template lang="pug">
  .modal.pt-6
    .flex.gap-x-4.mb-4
      base-input-date(
        v-model="protocolData.date"
        label="Дата осмотра",
        placeholder="Выберите дату"
        :width="202",
        size="M"
      )
      base-input-time(
        v-model="protocolData.startTime"
        label="Время осмотра",
        :width="202",
        placeholder="Выберите время",
        size="M"
      )
    .flex.flex-col.gap-y-6px
      span.color-grey.line-height.text-sm.font-semibold Врач
      .flex.gap-x-2.items-center
        base-avatar(:size="40")
          img.object-cover.h-full(
            v-if="userData?.avatar",
            :src="url + userData?.avatar",
            alt="avatar"
          )
          span.text-sm.text-dark(v-else) {{ avatar }}
        .flex.flex-col
          span.text-sm.text-dark.line-height.mb-2px.font-medium {{ employeeName }}
          span.color-grey.line-height.text-xsx.font-medium {{ userData?.job_title ? userData?.job_title : "Терапевт" }}
    .flex.gap-2.mt-8
      base-button(
        type="secondary",
        label="Отменить",
        width="126px",
        @click="changeShownCreateModal(false)"
      )
      base-button(
        width="174px",
        label="Создать осмотр",
        @click="saveProtocol"
      )
</template>

<script>
import BaseAvatar from "@/components/base/BaseAvatar.vue";
import BaseInputDate from "@/components/base/BaseInputDate.vue";
import BaseInputTime from "@/components/base/BaseInputTime.vue";
import * as moment from "moment/moment";
import { mapGetters, mapActions, mapState } from "vuex";
import BaseButton from "@/components/base/BaseButton.vue";
export default {
  name: "MedicalProtocolCreateModal",
  components: {
    BaseAvatar,
    BaseInputDate,
    BaseInputTime,
    BaseButton,
  },
  props: {
    changeShownCreateModal: Function,
    createInspection: Function,
  },
  data() {
    return {
      protocolData: {
        date: "",
        startTime: null,
      },
    };
  },
  computed: {
    ...mapState({
      url: (state) => state.imgUrl,
    }),
    ...mapGetters({
      userData: "getUserData",
    }),
    avatar() {
      let firstName = this.userData?.first_name
        ? this.userData?.first_name[0]
        : this.userData?.last_name[1];
      return this.userData?.last_name[0] + firstName;
    },
    employeeName() {
      let firstName = this.userData?.first_name
        ? ` ${this.userData?.first_name}`
        : "";
      let patronymic = this.userData?.patronymic
        ? ` ${this.userData?.patronymic}`
        : "";
      return this.userData?.last_name + firstName + patronymic;
    },
  },
  methods: {
    ...mapActions({
      createProtocol: "createProtocol",
    }),
    checkFormat(date) {
      return date && date?.length === 10 ? date : null;
    },
    saveProtocol() {
      if (
        !Object.keys(this.protocolData).every((key) => this.protocolData[key])
      )
        return;
      let time = this.protocolData.startTime.split(":");
      let start = moment(this.checkFormat(this.protocolData.date))
        ?.hour(time[0])
        ?.minute(time[1]);
      let createdProtocol = {
        start: start.isValid() ? start.format() : null,
        end: start.isValid() ? start.add(30, "m").format() : null,
      };
      if (
        Object.keys(createdProtocol)?.some((elem) => !createdProtocol?.[elem])
      )
        return;
      createdProtocol.status = null;
      this.createProtocol(createdProtocol);
      this.changeShownCreateModal(false);
      this.createInspection();
    },
  },
};
</script>

<style lang="sass" scoped>
.modal
  width: 422px
.color-grey
  color: var(--font-grey-color)
.line-height
  line-height: 135%
</style>
