const getOrigin = () =>
  process.env.NODE_ENV === "development"
    ? "https://astra-dev.dopcore.com"
    : window.origin;

function prepareUrl(url) {
  if (url.startsWith("http")) return url;
  return `${getOrigin()}/api/${url}`;
}

function handleRequest(method, url, headers, attempts, body, type) {
  return new Promise((resolve, reject) => {
    (function internalRequest() {
      return request(method, url, headers, body, type)
        .then(resolve)
        .catch((err) => (--attempts > 0 ? internalRequest() : reject(err)));
    })();
  })
    .then((res) => {
      if (res.status === 401) {
        //router.go("/login");
      } else if (res) return res.json();
    })
    .catch((err) => {
      return err;
    });
}

/**
 * Request
 * @param {string} method - method name
 * @param {string} url - endpoint url.
 * @param {object} body - request body.
 * @param {object} headers - request headers
 * @param {string} type - request type
 */
function request(method, url, headers = {}, body, type = "") {
  let requestOptions = {};
  if (method === "GET" || method === "DELETE") {
    requestOptions = {
      method: method,
      credentials: "include",
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (body) {
      requestOptions.body = JSON.stringify(body);
    }
  }
  if (method === "POST" || method === "PUT" || method === "PATCH") {
    if (type && type === "formData") {
      requestOptions = {
        method: method,
        headers: {
          Accept: "application/json",
          ...headers,
        },
        body: body,
        credentials: "include",
      };
    } else
      requestOptions = {
        method: method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        credentials: "include",
        body: JSON.stringify(body),
      };
  }
  return fetch(prepareUrl(url), requestOptions);
}

function get(url, type = "default", headers = {}, attempts = 3) {
  return handleRequest("GET", url, headers, attempts, null, type);
}

function del(url, body, headers, attempts = 3) {
  return handleRequest("DELETE", url, headers, attempts, body);
}

function post(url, body, type, headers, attempts = 3) {
  return handleRequest("POST", url, headers, attempts, body, type);
}

function patch(url, body, type, headers, attempts = 3) {
  return handleRequest("PATCH", url, headers, attempts, body, type);
}

function put(url, headers, body, attempts = 3) {
  return handleRequest("PUT", url, headers, attempts, null, body);
}

export const fetchWrapper = {
  get,
  del,
  post,
  put,
  patch,
};
