<template lang="pug">
  base-input-container.gap-y-2(:label="label", :style="{width: widthInternal, ...sizeVariable }")
    q-input.input(
      v-model="value",
      :name="name",
      :input-style="{resize: resize}",
      :placeholder="placeholder",
      outlined,
      :readonly="readonly",
      :disable="disabled",
      mask="##.##.####",
      :rules="[(val) => rule ? rule(val) : defaultRule(val)]",
      :lazy-rules="lazyRule",
      :item-aligned="itemAligned",
      no-error-icon,
      :accept="accept",
      :debounce="debounce",
      hide-bottom-space,
      lazy-rules
    )
      template(v-slot:append, v-if="!readonly && !disabled")
        q-icon.calendar-icon(
          name="app:calendar",
          class="cursor-pointer",
          size="20px"
        )
          q-menu(
            :style="{'margin-top': '6px !important'}",
            v-model="calendarVisibility",
            transition-show="scale",
            transition-hide="scale",
            self="top middle",
            :offset="[118, 14]",
          )
            base-calendar(
              v-model="internalDate",
              :start-year="2000",
              :save="closeCalendar",
              @update:model-value="transmitInternalDate"
            )
</template>

<script>
import BaseInputContainer from "@/components/base/BaseInputContainer.vue";
import * as moment from "moment/moment";
import BaseCalendar from "@/components/base/Calendar/BaseCalendar.vue";
export default {
  name: "BaseInputDate",
  components: { BaseInputContainer, BaseCalendar },
  props: {
    accept: {
      type: String,
      default: "",
    },
    resize: {
      type: String,
      default: "none",
    },
    debounce: [String, Number],
    width: [String, Number],
    lazyRule: [Boolean, String],
    itemAligned: Boolean,
    rule: Function,
    modelValue: String,
    placeholder: String,
    disabled: Boolean,
    label: String,
    readonly: Boolean,
    iconLeft: Boolean,
    name: String,
    size: String,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      calendarVisibility: false,
      internalDate: null,
    };
  },
  computed: {
    value: {
      get() {
        this.changeInternalDate(
          moment(this.checkFormat(this.modelValue)).isValid()
            ? moment(this.modelValue)
            : null
        );
        return this.convertISOFormat(this.modelValue);
      },
      set(value) {
        this.$emit("update:modelValue", this.convertRUFormat(value));
      },
    },
    sizeVariable() {
      switch (this.size) {
        case "XS":
          return {
            "--input-height": "28px",
            "--text-size": "12px",
            "--line-height": "135%",
            "--px": "0 8px",
            "--py": "10px 0",
          };
        case "S":
          return {
            "--input-height": "32px",
            "--text-size": "12px",
            "--line-height": "135%",
            "--px": "0 8px",
            "--py": "10px 0",
          };
        case "M":
          return {
            "--input-height": "40px",
            "--text-size": "16px",
            "--line-height": "normal",
            "--px": "0 16px",
            "--py": "8px 0",
          };
        case "L":
          return {
            "--input-height": "48px",
            "--text-size": "16px",
            "--line-height": "normal",
            "--px": "0 16px",
            "--py": "8px 0",
          };
        default:
          return {
            "--input-height": "56px",
            "--text-size": "16px",
            "--line-height": "normal",
            "--px": "0 16px",
            "--py": "8px 0",
          };
      }
    },

    widthInternal() {
      if (typeof this.width === "number") return this.width + "px";
      return this.width;
    },
  },
  methods: {
    convertISOFormat(date) {
      return date?.split("-")?.reverse()?.join(".");
    },
    convertRUFormat(date) {
      return date?.split(".")?.reverse()?.join("-");
    },
    checkFormat(date) {
      return date && date?.length === 10 ? date : null;
    },
    defaultRule(val) {
      return (
        !val ||
        moment(
          this.checkFormat(val) ? this.convertRUFormat(val) : null
        ).isValid()
      );
    },
    closeCalendar() {
      this.calendarVisibility = false;
    },
    changeInternalDate(value) {
      this.internalDate = value;
    },
    transmitInternalDate(value) {
      this.value = value?.format("DD.MM.YYYY");
    },
  },
};
</script>

<style lang="sass" scoped>
.font-input
  font-feature-settings: 'pnum' on, 'lnum' on

.input :deep(.q-field__native)
  font-weight: 500
  font-size: var(--text-size)
  line-height: var(--line-height)
  color: var(--font-dark-blue-color)
  padding: var(--py)
  &::placeholder
    color: var(--font-grey-color)
    opacity: 1

.input :deep(.q-field__control)
  height: var(--input-height) !important
  color: var(--font-dark-blue-color)
  padding: var(--px)
  &:before
    border-color: var(--border-light-grey-color) !important
    transition: none
  &:hover:before
    border-color: var(--font-grey-color) !important
  &:after
    border-width: 1px !important
    transition: none
    transform: none !important

.q-field--disabled :deep(.q-field__control > div)
  opacity: 1 !important

.q-field--outlined.q-field--disabled :deep(.q-field__native)
  color: var(--font-grey-color)

.q-field--outlined.q-field--disabled :deep(.q-field__control)
  background: var(--bg-light-grey) !important

.q-field--outlined.q-field--readonly :deep(.q-field__control)
  background: var(--bg-light-grey) !important
  &:before
    border-color: var(--bg-light-grey) !important
    transition: none
  &:hover:before
    border-color: var(--bg-light-grey) !important

.q-field--outlined.q-field--readonly :deep(.q-field__native)
  cursor: default

.q-field--error :deep(.q-field__bottom)
  padding: 4px 0 0 0
  font-weight: 500
  font-size: 12px
  line-height: 135% !important

.input :deep(.q-field__marginal)
  height: auto !important

.calendar-icon :deep(path)
  fill: var(--font-dark-blue-color)
</style>
