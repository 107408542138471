<template lang="pug">
  .wrapper.rounded.flex.flex-col
    .header.w-full.h-fit.flex.justify-between.px-6.pt-4.pb-3.text-sm.font-medium
      span Записи
      span {{ events?.length }}
    .w-full.h-full.py-10px.pl-6.pr-2.items-center 
      .events.flex.flex-col.pr-3.overflow-y-auto(v-if="events?.length")
        .event.flex.justify-between.items-start.py-10px.w-full.gap-x-2(v-for="(event, index) in getEventsList")
          .flex.flex-col.gap-y-6px
            span.date.font-bold.whitespace-nowrap {{ event.date }}
            .time.flex.items-center.gap-x-6px
              q-icon(name="app:time" size="13px")
              span.font-medium.text-smm.whitespace-nowrap {{ `${event.startTime} - ${event.endTime}` }}
          .flex.gap-x-1.text-smm.font-medium.items-center
            q-icon(
              :name="event.status === 'PLANNED' ? 'radio_button_unchecked' : 'radio_button_checked'"
              :style="{'color': statusMap[event.status].color}"
              size="13px"
            )
            span.whitespace-nowrap(:style="{'color': statusMap[event.status].color}") {{ statusMap[event.status].title }}
      .flex.items-center.justify-center.w-full(v-else :style={'height': '124px'})
        span.text-lg.font-medium(:style="{'color': 'var(--font-grey-color)'}") Нет записей
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { getChangeMonth } from "@/shared/utils/getChangeStrings";
import { mapEventStatus } from "@/pages/newMedicalCard/utils/medicalConfig";

export default {
  name: "MedicalRecords",
  data() {
    return {
      statusMap: mapEventStatus,
    };
  },
  computed: {
    ...mapState({
      events: (state) => state?.medical?.events,
    }),
    getEventsList() {
      return [
        ...this.events.map((el) => {
          const start = moment(el.event.start);
          const end = moment(el.event.end);
          return {
            ...el.event,
            date: `${start.format("D")} ${getChangeMonth(
              start.format("MMMM")
            )} (${start.format("dd")})`,
            startTime: start.format("HH:mm"),
            endTime: end.format("HH:mm"),
          };
        }),
      ];
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  width: 16.8%
  height: 18.7%
  min-height: 190px
  min-width: 275px
  background-color: var(--default-white)
.header
  border-bottom: 1px solid var(--border-light-grey-color)
  color: var(--font-grey-color)
.events
  height: 124px
  min-width: 245px
  &::-webkit-scrollbar
    width: 4px
.event
  border-bottom: 1px solid var(--border-light-grey-color)
.date
  color: var(--font-dark-blue-color)
.time
  color: var(--font-grey-color)
</style>
