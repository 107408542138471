<template lang="pug">
  .selection-wrapper.flex
    .left-side.flex.flex-col
      .flex.flex-col.gap-y-4.h-full
        .flex.flex-col.gap-y-6
          .flex.font-bold.text-xl Категории
          base-input.search(
            placeholder="Поиск",
            @keyup.enter="searchCategory",
            @input="searchCategory",
            v-model="searchBenefit",
            debounce="10",
            :width="300",
            size="M",
            iconLeft
          )
            template(v-slot:iconLeft)
              q-icon.icon(name="app:search", size="20px", style="color: var(--font-grey-color)")
        .field.flex.flex-col.overflow-y-scroll(:class="{'filed-category': findSelected(selected?.id)}")
          .flex.items-center(v-for="benefit in benefitData", :key="benefit.id")
            q-item(tag="label", :style="{alignItems: benefit.name.length > 28 ? 'start' : 'center'}")
              q-item-section(top)
                q-radio.radio(
                  v-model="selected",
                  :val="benefit",
                  :style="{paddingTop: benefit.name.length > 28 ? '7px' : '2px'}",
                  size="24px",
                  checked-icon="check_circle",
                  unchecked-icon="radio_button_unchecked"
                )
              q-item-label
                .name.flex.items-center.font-medium.text-xm {{benefit.name}}
      .buttons-group.flex.py-4.px-8.justify-center(v-if="!findSelected(selected?.id)")
        base-button(
          type="secondary",
          label="Сбросить",
          width="146px",
          @click="closeModalCategories"
        )
        base-button(
          label="Сохранить",
          width="146px",
          @click="saveCategories(selected)"
        )
    .right-side.flex.px-10.font-bold.relative
      q-icon(name="app:cancel").text-sm.absolute.top-4.right-4.cursor-pointer(@click="closeModalCategories")
      .flex.gap-y-6.flex-col.w-full(v-if="selected?.id && selected?.name !== 'Без льготы'")
        .right-header.flex
          span.text-6xl {{selected?.name}}
        .flex.text-xm.flex-col.gap-y-3.mt-1 Критерии:
          .flex.flex-col.gap-y-2
            .grey-color.flex.font-medium.text-smm(v-for="item in choiceCriteria", :key="item.id") {{"- " + item}}
        .flex.text-xm.flex-col.gap-y-3 Документы для подтверждения: 
          .flex.flex-col.gap-y-2
            .grey-color.flex.font-medium.text-smm(v-for="item in choiceDocumentation", :key="item.id") {{"- " + item}}
        .flex.text-xm.flex-col.gap-y-3 Льготы:
          .flex.flex-col.gap-y-2
            .grey-color.flex.font-medium.text-smm(v-for="item in choicePrivileges", :key="item.id") {{"- " + item}}
      .flex.items-center.h-full.w-full.justify-center.font-medium.text-6xl(
        v-else,
        :style="{color: 'var(--font-grey-color)'}"
      ) Выберите категорию
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "BaseCategorySelection",
  components: { BaseInput, BaseButton },
  props: {
    benefitData: Array,
    shiftSelected: Function,
    closeModalCategories: Function,
    saveCategories: Function,
    showModal: Boolean,
    basic: Object,
  },
  data() {
    return {
      selected: {},
      searchBenefit: "",
    };
  },
  computed: {
    choiceCriteria() {
      return this.selected?.criteria.split(",");
    },
    choiceDocumentation() {
      return this.selected?.documentation.split(",");
    },
    choicePrivileges() {
      return this.selected?.privileges.split(",");
    },
  },
  methods: {
    findSelected(id) {
      return this.basic?.id === id ? true : false;
    },
    searchCategory() {
      this.$emit("search", this.searchBenefit);
    },
  },
  watch: {
    showModal: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.searchBenefit = "";
        } else {
          this.selected = this.benefitData.find((e) => e.name === "Без льготы");
        }
      },
    },
    benefitData: {
      immediate: true,
      deep: true,
      handler() {
        if (this.benefitData && this.showModal && !this.basic.id) {
          this.selected = this.benefitData.find((e) => e.name === "Без льготы");
        } else if (this.basic.id) {
          this.selected = this.benefitData.find((e) => e.id === this.basic.id);
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.selection-wrapper
  width: 895px
  height: 660px
  font-feature-settings: 'pnum' on, 'lnum' on
  color: var(--font-dark-blue-color)

.left-side
  width: 364px
  padding: 28px 32px 0px

.field
  height: 456px
  margin-right: -16px
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar-track:vertical
    margin-bottom: 24px

.filed-category
  height: 100%
  &::-webkit-scrollbar-track:vertical
    margin-bottom: 16px

.name
  min-height: 40px
  padding-top: 8px
  padding-bottom: 4px
  border-bottom: 1px solid var(--bg-light-grey)
  width: 264px

.q-checkbox :deep(.q-checkbox__bg)
  border-radius: 50%
  border: 1.5px solid var(--font-grey-color)

.buttons-group
  border-top: 1px solid var(--bg-event-gret-color-2)
  margin-left: -32px
  margin-right: -32px
  column-gap: 7px

.right-side
  line-height: 135%
  background: var(--default-white)
  border-left: 1px solid var(--border-light-grey-color)
  width: 531px
  height: 100%

.right-header
  padding: 27px 40px
  margin-left: -40px
  margin-right: -40px
  border-bottom: 1px solid var(--border-light-grey-color)

.grey-color
  color: var(--font-grey-color)

.icon :deep(path)
  fill: var(--font-grey-color)

.q-item
  padding: 0
  min-height: 40px

.q-item__section
  width: 32px
  min-width: 32px

.q-item :deep(.q-focus-helper)
  width: 0
  height: 0

.input :deep(.q-field__native)
  color: var(--font-grey-color)
  font-size: 16px !important
  font-style: normal
  line-height: normal !important

.input :deep(.q-field__control)
  color: var(--font-dark-blue-color)
  &:hover:before
    border: 1px solid var(--font-dark-blue-color)
  &:after
    border-width: 1px !important

.radio :deep(.q-icon)
  font-size: 20px

.radio :deep(.q-radio__inner)
  height: 24px

.search :deep(.q-field__prepend)
  padding-right: 6px
</style>
