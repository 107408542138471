<template lang="pug">
  medical-form-wrapper(
    :title="data.title",
    :is-edit="change",
    :open-edit="openCard",
    :cancel="closeCard"
    :tag="fillInspection || change"
  )
    .protocol-body.flex.flex-col
      .flex.gap-x-4(:style="{height: change || fillInspection ? '300px' : ''}")
        .flex.flex-col.gap-y-1.overflow-y-auto(:class="{'open-textarea': change || fillInspection}")
          .tag-wrap.flex.gap-x-1.gap-y-1
            .tag.flex.items-center.px-3.text-smm(
              v-for="tag in protocolData[data.state]",
              :key="tag.id"
              :class="{'pointer': change || fillInspection}"
            ) {{tag}}
          q-input.input.px-3(v-if="change || fillInspection", type="textarea", autogrow, borderless, placeholder="Введите жалобу...")
        .filter.flex.flex-col.gap-y-4.relative(v-if="change || fillInspection")
          .flex.gap-x-2
            base-input.search(
              placeholder="Поиск",
              :width="232",
              iconLeft
              size="M"
            )
              template(v-slot:iconLeft)
                q-icon.icon-grey(name="app:search", size="20px" )
            .button
              q-btn(
                :style="{width: '40px', height: '40px'}",
                padding="0"
              )
                q-icon.icon-grey(name="app:filter", size="24px")
            .button
              q-btn(
                :style="{width: '40px', height: '40px'}",
                padding="0"
              )
                q-icon.icon-grey(name="app:sort-number", size="24px")
          .field.flex.flex-col.overflow-y-scroll(v-if="data.results.tags")
            .checkbox.flex.flex-col(v-for="complaint in textSorting(data.results.tags)")
              .letter.flex.items-center.font-bold.justify-center {{ complaint.sym }}
              .line.flex.gap-x-4.h-9.items-center(v-for="name in complaint.array")
                q-checkbox(
                  dense,
                  v-model="protocolData[data.state]",
                  :val="name",
                  :style="{border: 'none', width: '16px', height: '16px'}"
                )
                .name.flex.items-center.font-medium.text-smm {{ name }}
          .flex.h-10.w-10.absolute.right-4.bottom-4
            base-button(
              width="40px",
              @click="showModal = true",
            )
              q-icon.icon-white(name="app:plus", size="24px")
        base-modal(v-model="showModal", title="Создание тега", modal-padding)
          tag-creation-form(:close-modal="closeModal", :create-tag="createTag")
    .flex(v-if="data.title === 'Осмотр полости рта' && (fillInspection || change)", :style="{color: 'var(--btn-blue-color)'}")
      q-btn(no-caps, flat, padding="2px 4px")
        q-icon.icon-blue(left, name="app:plus", size="24px")
        span.font-medium Добавить описание зуба
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import TagCreationForm from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/TagCreationForm.vue";
import BaseModal from "@/components/base/BaseModal.vue";
import { mapState } from "vuex";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "MedicalFormTag",
  components: {
    BaseButton,
    BaseInput,
    BaseModal,
    MedicalFormWrapper,
    TagCreationForm,
  },
  props: { data: Object, abc: Array, fillInspection: Boolean },
  data() {
    return {
      change: false,
      showModal: false,
    };
  },
  computed: {
    ...mapState({
      protocolData: (state) => state.medical.protocolData,
    }),
  },
  methods: {
    textSorting(arr) {
      let sortArr = [];
      this.abc.forEach((letter) => {
        arr.forEach((str) => {
          let finded = sortArr.find((obj) => obj.sym === letter);
          if (str[0] === letter) {
            if (!finded) {
              sortArr.push({
                sym: letter,
                array: [str],
              });
            } else if (finded.sym === letter) finded.array.push(str);
          }
        });
      });
      return sortArr;
    },
    openCard() {
      this.change = true;
    },
    closeCard() {
      this.change = false;
    },
    closeModal() {
      this.showModal = false;
    },
    createTag(model) {
      this.showModal = false;
      this.protocolData[this.data.state].push(model.tag);
    },
  },
  watch: {
    fillInspection: {
      immediate: true,
      handler(newVal) {
        if (newVal) this.change = false;
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.protocol-body
  background: var(--default-white)
  border-radius: 4px

.icon
  background: var(--bg-light-grey)

.open-header
  justify-content: space-between

.open-textarea
  width: 800px
  padding: 4px
  border: 1px solid var(--border-light-grey-color)
  border-radius: 4px

.tag
  height: 32px
  background: var(--bg-light-grey)
  border-radius: 4px

.tag-wrap
  flex-wrap: wrap !important

.filter
  border: 1px solid var(--border-light-grey-color)
  border-radius: 4px
  padding: 16px 16px 0
  width: 360px

.button
  display: flex
  align-items: center
  width: 40px
  height: 40px
  justify-content: center
  cursor: pointer
  border-radius: 4px
  background: var(--bg-light-grey)
  &:hover
    background: var(--border-light-grey-color)

.field
  margin-right: -10px
  &::-webkit-scrollbar-track:vertical
    margin-bottom: 12px
  &::-webkit-scrollbar
    width: 4px

.checkbox
  width: 328px

.line
  border-bottom: 1px solid var(--bg-light-grey)
  min-height: 36px

.letter
  width: 18px
  height: 34px
  color: var(--font-grey-color)

.name
  width: 268px
  color: var(--font-dark-blue-color)

.pointer
  cursor: pointer

.q-checkbox :deep(.q-checkbox__bg)
  border-radius: 4px
  border: 1.5px solid var(--font-grey-color)

.q-checkbox :deep(.q-checkbox__inner:before)
  background: none !important

.q-btn :deep(.q-focus-helper)
  display: none
.q-btn :deep(.q-ripple)
  display: none
.on-left
  margin-right: 4px

.input :deep(.q-field__native)
  font-weight: 500
  font-size: 14px
  line-height: 135%
  color: var(--font-dark-blue-color)
  padding: 4px 0
  &::placeholder
    color: var(--font-grey-color)
    opacity: 1

.search :deep(.q-field__prepend)
  padding-right: 6px

.icon-grey :deep(path)
  fill: var(--font-grey-color)

.icon-white :deep(path)
  fill: var(--default-white)

.icon-blue :deep(path)
  fill: var(--btn-blue-color)
</style>
