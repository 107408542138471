<template lang="pug">
  .wrapper.flex.flex-col.w-full.h-full.gap-y-2.overflow-y-auto
    health-state-header
    .flex(v-for="(data, i) in healthState")
      health-state-form(v-model="healthState[i]")
</template>

<script>
import HealthStateHeader from "@/pages/newMedicalCard/components/HealthState/HealthStateHeader.vue";
import HealthStateForm from "@/pages/newMedicalCard/components/HealthState/HealthStateForm.vue";
import { mapState } from "vuex";

export default {
  name: "MedicalHealthStateWrapper",
  components: { HealthStateHeader, HealthStateForm },
  computed: {
    ...mapState({
      healthState: (state) => state.medical.healthState,
    }),
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  &::-webkit-scrollbar
    width: 0
</style>
