<template lang="pug">
  .scroll-wrapper
    .list-wrapper.rounded.w-full.bg-white.h-full
      .w-full.h-full.flex.items-center.justify-center(v-if="dataStatus.title !== 'found'")
        .flex.flex-col.gap-y-2.h-96.items-center
          img.image(:src="dataStatus.img")
          .font-bold.text-xll.grey-color.whitespace-pre-line.text-center {{dataStatus.message}}
      .flex.flex-col.w-full.h-full(v-else)
        .w-full.header.h-10.flex
          .h-full.py-10px.px-4.flex.items-center.justify-start.font-semibold.text-sm.grey-color(
            v-for="field in headerConfig",
            :key="field.title",
            :style="headerStyle(field)"
          ) {{ field.title }}
        .body.w-full
          row(
            v-for="medcard in medicalCards",
            :key="medcard.id"
            :header-style="headerStyle",
            :person="medcard",
          )
</template>

<script>
import { searchListConfig } from "@/pages/medcards/utils/medcardsConfig.js";
import Row from "@/pages/medcards/components/MedicalCardSearchRow.vue";

export default {
  name: "MedicalCardSearchList",
  props: {
    medicalCards: Array,
    dataStatus: Object,
  },
  components: { Row },
  data() {
    return {
      headerConfig: searchListConfig,
    };
  },
  methods: {
    headerStyle(field) {
      return {
        ...field,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.scroll-wrapper
  flex-grow: 1
  overflow-y: hidden
.list-wrapper
  overflow: hidden
  min-width: min-content
.header
  background-color: var(--gray-thirdly)
  border-bottom: 1px solid var(--gray-secondary)
.grey-color
  color: var(--font-grey-color)
.body
  overflow-y: auto
  &::-webkit-scrollbar-track
    margin: 24px 0 24px 0
    background: #E9E9ED
  &::-webkit-scrollbar-thumb
    background: var(--font-grey-color)
.image
  width: 300px
  height: 300px
</style>
