<template lang="pug">
  .flex.w-full(class="gap-x-1.5")
    base-select(:items="items", v-model="network.kind", size="M")  
    base-input.w-full(v-model="network.username", placeholder="Ссылкa", :rule="ruleInput", size="M")
</template>

<script>
import BaseInput from "@/components/base/BaseInput.vue";
import BaseSelect from "@/components/base/BaseSelect.vue";
export default {
  name: "TableAddingNetwork",
  components: { BaseInput, BaseSelect },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    network: {
      type: Object,
      default: () => {},
    },
    ruleInput: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    networkInternal: {
      get() {
        return this.network;
      },
      set(val) {
        this.$emit("update:network", val);
      },
    },
  },
};
</script>
