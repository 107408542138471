<template lang="pug">
  .calendar-column-wrapper.flex.flex-col
    .header.flex.flex-col.items-center.justify-center.top-0.pt-3.pb-10px
      span.font-bold.text-base.color-black {{ date?.format("D MMMM")}}
      span.text-smm.color-grey {{ capitalizeFirstChar(date?.format("dddd"))}}
    .body.h-full.px-1.py-1
      calendar-record-card(
        v-for="record in filteredRecords",
        :key="record?.id",
        :record="record",
        :expanded-type="expandedDisplayType",
        :style="eventCardPosition(record.start, record.end)",
        @click="changeSelectedRecordId(record?.id)",
      )
    .footer.flex.items-center.justify-center.bg-white.h-9(v-if="!expandedDisplayType")
      span.text-smm.color-grey 0 записей
</template>

<script>
import * as moment from "moment/moment";
import { columnMixin } from "@/pages/newCalendar/mixins/columnMixin.js";
import { capitalizeFirstChar } from "@/pages/newCalendar/utils/calendarFunctions.js";
export default {
  name: "CalendarColumn",
  mixins: [columnMixin],
  props: {
    date: Object,
    expandedDisplayType: Boolean,
  },
  data() {
    return {
      capitalizeFirstChar: capitalizeFirstChar,
    };
  },
  computed: {
    filteredRecords() {
      return this.events.filter(
        (elem) =>
          moment.parseZone(elem.start).format("YYYY-MM-DD") ===
          this.date.format("YYYY-MM-DD")
      );
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/pages/newCalendar/sass/columnStyle.sass"
</style>
