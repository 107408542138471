<template lang="pug">
  label.download.flex.relative.items-center.justify-center.rounded(
    :style="{height: `${height}px`, width: width ? `${width}px` : '100%'}"
  )
    base-input.w-full(
      @change="(e) => addNewFiles(e)",
      doc,
      type="file",
      :accept="getAccept",
      id="upload"
      :size="`${height}px`"
      :height="`${height}px`"
    )
    q-icon.absolute(
      v-if="withIcon",
      name="app:download",
      :size="sizeIcon"
      )
    .flex.flex-col.items-center.justify-center.absolute.font-medium.text-base.w-full(
      v-else,
      :style="{color: 'var(--font-grey-color)'}"
    )
      span {{`Загрузите ${titleType}`}}
        label.pointer-events-none.cursor-pointer(:style="{color: 'var(--btn-blue-color)'}")  с компьютера
      span  или перетащите его в это поле
</template>

<script>
import BaseInput from "@/components/base/BaseInput.vue";
import { v_model } from "@/shared/mixins/v-model";
import { getReturnedObjectImg } from "@/pages/newMedicalCard/utils/gettersObjects";
export default {
  name: "BaseDownload",
  mixins: [v_model],
  components: { BaseInput },
  data() {
    return {
      getReturnedObjectImg,
    };
  },
  props: {
    multiple: Boolean,
    height: {
      type: Number,
      default: 92,
    },
    width: Number,
    type: {
      type: String,
      default: "photo",
    },
    withIcon: Boolean,
    sizeIcon: {
      type: String,
      default: "24px",
    },
    titleType: {
      type: String,
      default: "фото",
    },
    returnedFields: {
      type: Array,
      default: () => ["photo", "id"],
    },
  },
  methods: {
    addNewFiles(e) {
      Object.values(e.target.files).forEach((file) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          if (this.multiple) {
            this.value.push(getReturnedObjectImg(this.returnedFields, e, file));
          } else {
            this.value = getReturnedObjectImg(this.returnedFields, e, file);
          }
        };
        reader.readAsDataURL(file);
      });
      e.target.value = null;
    },
  },
  computed: {
    getAccept() {
      if (this.type === "files") {
        return ".xlsx, .xls, .doc, .docx, .pdf, .odt";
      }
      return "image/*";
    },
  },
};
</script>

<style lang="sass" scoped>
.download
  background-color: var(--bg-light-grey)
  border-radius: 4px
  &:hover .q-icon
    opacity: 0.6
  & .q-icon
    color: var(--btn-blue-color)
</style>
