<template lang="pug">
  .wrapper.flex.flex-col.gap-27px.h-full.pb-4
    .flex.flex-col.gap-2
      confidant-form(v-for="(confidant, index) in confidants" :index="index" :confidant="confidant")
    q-btn.ml-2px(
      color="primary",
      flat,
      :style="{'font-weight': 500, width: '174px', height: '24px'}",
      no-caps,
      padding="0",
      @click="addNewConfidant" 
    )
      q-icon.icon(name="app:plus", size="24px", left)
      span Добавить контакт
</template>

<script>
import ConfidantForm from "@/pages/newMedicalCard/components/ConfidantForms/ConfidantForm.vue";
import { getConfidantObject } from "@/pages/newMedicalCard/utils/gettersObjects";
import { mapState } from "vuex";

export default {
  name: "MedicalConfidantWrapper",
  components: { ConfidantForm },
  computed: {
    ...mapState({
      confidants: (state) => state.medical.confidantData,
    }),
  },
  methods: {
    addNewConfidant() {
      this.confidants.push(getConfidantObject());
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  width: 83.2%
  overflow-y: auto
  min-width: 560px
  min-height: 350px
  @media (max-width: 600px)
    width: fit-content
  &::-webkit-scrollbar
    width: 0
.on-left
  margin-right: 4px
.q-btn :deep(.q-ripple)
  display: none
.icon :deep(path)
  fill: var(--btn-blue-color)
</style>
