<template lang="pug">
  .wrapper.flex.flex-col.pt-6.dark-blue.font-medium
    .basic.flex.flex-col.gap-y-4
      base-input.search(
        placeholder="Поиск",
        :width="422",
        iconLeft,
        size="M"
      )
        template(v-slot:iconLeft)
          q-icon(name="app:search", size="20px", style="color: var(--font-grey-color)")
      .flex.gap-x-2
        base-button(
          @click="choiceService(item.name)",
          v-for="item in baseServices",
          size="S",
          width="100px",
          type="grey"
        ) {{item.name}}
      .tags-wrapper.flex(v-if="selectedServices.length")
        .tag.flex.gap-x-2.rounded.items-center.pl-4.pr-1.py-2(v-for="(item, index) in selectedServices", :style="{background: item?.color}")
          span {{item?.title}}
          q-icon.cursor-pointer(@click="removeTag(index)", name="app:cancel-mini", size="24px")
      template(v-for="item in baseServices")
        .serice-wrapper.flex.flex-col(v-if="item.check")
          .service.flex.items-center.gap-x-1.5px(v-for="service in item.data")
            q-checkbox.checkbox(checked-icon="app:checkbox-on", v-model="selectedServices" :val="service" size="34px")
            .info-block.flex.justify-between.w-full
              .flex.flex-col.gap-y-1
                .text-m {{service.title}}
                .grey-color.text-smm {{item.name}}
              .flex.flex-col.gap-y-1
                .text-m.font-bold {{service.price}}
                .grey-color.text-smm {{service.time}}
    .footer.flex.gap-2
      base-button(type="secondary", label="Отменить", width="125px", @click="closeServices")
      base-button(width="132px", label="Сохранить", @click="saveServices(selectedServices)", :disabled="!selectedServices.length")
</template>

<script>
import BaseInput from "@/components/base/BaseInput.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import { services } from "@/pages/newCalendar/utils/calendarConfig.js";

export default {
  name: "SevicesModal",
  components: { BaseInput, BaseButton },
  props: {
    closeServices: Function,
    saveServices: Function,
  },
  data() {
    return {
      selectedServices: [],
      baseServices: services,
    };
  },
  methods: {
    choiceService(name) {
      this.baseServices.forEach((e) => {
        e.check = false;
        if (e.name === name) e.check = true;
      });
    },
    removeTag(index) {
      this.selectedServices.splice(index, 1);
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  width: 422px

.basic
  height: 563px

.search :deep(path)
  fill: var(--font-grey-color)

.search :deep(.q-field__prepend)
  padding-right: 4px

.tags-wrapper
  row-gap: 8px
  flex-wrap: wrap !important
  column-gap: 8px

.tag
  width: fit-content
  height: 32px

.base-service
  border-radius: 4px
  border: 1px solid var(--gray-secondary)
  background: var(--gray-thirdly)

.serice-wrapper
  overflow: auto
  max-height: 546px
  margin-right: -18px
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar-track:vertical
    margin-bottom: 16px

.service
  height: 67px
  margin-right: 14px
  &:last-child .info-block
    border-bottom: none

.info-block
  padding: 11px 0px
  border-bottom: 1px solid var(--gray-secondary)

.footer
  border-top: 1px solid var(--gray-secondary)
  margin: 0px -32px -12px
  padding: 16px 32px 0px 32px

.dark-blue
  color: var(--font-dark-blue-color)

.grey-color
  color: var(--font-grey-color)

.checkbox :deep(.q-checkbox__bg)
  border-radius: 4px
  border: 1.5px solid var(--font-grey-color)

.checkbox :deep(.q-icon)
  font-size: 24px

.checkbox :deep(rect)
  fill: var(--btn-blue-color)
</style>
