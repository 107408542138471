<template lang="pug">
  base-input-container.gap-y-2(:label="label", :style="{width: width + 'px', ...sizeVariable }")
    q-input.input(
      ref="numberInput"
      v-model="value",
      :name="name",
      :multiple="multiple",
      :class="{'circle': circle, 'font-input': true}",
      :input-style="{ resize: resize }",
      :placeholder="placeholder",
      outlined,
      type="number",
      :readonly="readonly",
      :disable="disabled",
      :rules="rule || ruleNumberInput",
      :lazy-rules="lazyRule",
      :item-aligned="itemAligned",
      no-error-icon,
      :mask="mask",
      :autogrow="autogrow",
      :accept="accept",
      :debounce="null",
      :shadow-text="shadowText"
      hide-bottom-space
    )
      .flex.flex-col.mb-1
        q-icon.cursor-pointer.-rotate-90.arrow(
          name="arrow_forward_ios", 
          size="15px",
          @click="increment",
          @mousedown="fastIncrement",
          @mouseup="deleteInterval"
          )
        q-icon.cursor-pointer.-rotate-90.arrow(
          name="arrow_back_ios", 
          size="15px",
          @click="decrement",
          @mousedown="fastDecrement",
          @mouseup="deleteInterval"
          )
</template>

<script>
import BaseInputContainer from "@/components/base/BaseInputContainer.vue";
import { ruleNumberInput } from "@/shared/utils/rulesInputs";
import { roundNumber } from "@/shared/utils/methodsObjects";

export default {
  name: "BaseInputNumber",
  components: { BaseInputContainer },
  data() {
    return {
      intervalId: 0,
      timeoutId: 0,
      ruleNumberInput: [(val) => ruleNumberInput(val, this.max, this.min)],
      roundNumber: roundNumber,
    };
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    reverseValue: Boolean,
    multiple: Boolean,
    circle: Boolean,
    autogrow: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "",
    },
    resize: {
      type: String,
      default: "none",
    },
    shadowText: String,
    mask: String,
    debounce: [String, Number],
    width: Number,
    rule: Array,
    lazyRule: String,
    itemAligned: Boolean,
    modelValue: [Number],
    placeholder: String,
    disabled: Boolean,
    label: String,
    readonly: Boolean,
    iconLeft: Boolean,
    name: String,
    size: String,
  },
  emits: ["update:modelValue"],
  methods: {
    increment() {
      if (this.modelValue < this.max) {
        this.$emit(
          "update:modelValue",
          Number((this.modelValue + this.step).toFixed(this.rounding))
        );
      } else {
        if (this.reverseValue) {
          this.$emit("update:modelValue", this.min);
        }
      }
    },
    decrement() {
      if (this.min < this.modelValue) {
        this.$emit(
          "update:modelValue",
          Number((this.modelValue - this.step).toFixed(this.rounding))
        );
      } else {
        if (this.reverseValue) {
          this.$emit("update:modelValue", this.max);
        }
      }
    },
    fastIncrement() {
      this.timeoutId = setTimeout(() => {
        this.intervalId = setInterval(() => this.increment(), 100);
      }, 500);
    },
    fastDecrement() {
      this.timeoutId = setTimeout(() => {
        this.intervalId = setInterval(() => this.decrement(), 100);
      }, 500);
    },
    deleteInterval() {
      clearTimeout(this.timeoutId);
      clearInterval(this.intervalId);
    },
  },
  watch: {
    value(val) {
      this.value = this.roundNumber(val, this.rounding);
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue ? this.modelValue : 0;
      },
      set(value) {
        this.$emit("update:modelValue", Number(value));
      },
    },
    rounding() {
      return String(this.step).split(".")[1]?.length || 0;
    },

    sizeVariable() {
      switch (this.size) {
        case "XS":
          return {
            "--input-height": "28px",
            "--text-size": "12px",
            "--line-height": "135%",
            "--px": "0 8px",
            "--py": "10px 0",
          };
        case "S":
          return {
            "--input-height": "32px",
            "--text-size": "12px",
            "--line-height": "135%",
            "--px": "0 8px",
            "--py": "10px 0",
          };
        case "M":
          return {
            "--input-height": "40px",
            "--text-size": "16px",
            "--line-height": "normal",
            "--px": "0 16px",
            "--py": "2px 0",
          };
        case "L":
          return {
            "--input-height": "48px",
            "--text-size": "16px",
            "--line-height": "normal",
            "--px": "0 16px",
            "--py": "8px 0",
          };
        case "auto":
          return {
            "--input-height": "auto",
            "--text-size": "16px",
            "--line-height": "normal",
            "--px": "0 16px",
            "--py": "8px 0",
          };
        default:
          return {
            "--input-height": "56px",
            "--text-size": "16px",
            "--line-height": "normal",
            "--px": "0 16px",
            "--py": "8px 0",
          };
      }
    },
  },
};
</script>

<style lang="sass">
.q-field .q-field__control-container
  display: flex
  align-items: center
input[type=number]::-webkit-inner-spin-button
  -webkit-appearance: none
</style>

<style lang="sass" scoped>
.arrow
  color: var(--font-grey-color)
  &:hover
    color: var(--font-dark-blue-color)

.input :deep(.q-field__native)
  font-weight: 500
  font-size: var(--text-size)
  line-height: var(--line-height)
  color: var(--font-dark-blue-color)
  padding: var(--py)
  &::placeholder
    color: var(--font-grey-color)
    opacity: 1

.input :deep(.q-field__control)
  height: var(--input-height) !important
  color: var(--font-dark-blue-color)
  padding: var(--px)
  &:before
    border-color: var(--border-light-grey-color) !important
    transition: none
  &:hover:before
    border-color: var(--font-grey-color) !important
  &:after
    border-width: 1px !important
    transition: none
    transform: none !important

.q-field--disabled :deep(.q-field__control > div)
  opacity: 1 !important

.q-field--outlined.q-field--disabled :deep(.q-field__native)
  color: var(--font-grey-color)

.q-field--outlined.q-field--disabled :deep(.q-field__control)
  background: var(--bg-light-grey) !important

.q-field--outlined.q-field--readonly :deep(.q-field__control)
  background: var(--bg-light-grey) !important
  &:before
    border-color: var(--bg-light-grey) !important
    transition: none
  &:hover:before
    border-color: var(--bg-light-grey) !important

.q-field--outlined.q-field--readonly :deep(.q-field__native)
  cursor: default

.q-field--error :deep(.q-field__bottom)
  padding: 4px 0 0 0
  font-weight: 500
  font-size: 12px
  line-height: 135% !important

.input :deep(.q-field__marginal)
  height: auto !important
</style>
