import BaseCalendar from "@/components/base/Calendar/BaseCalendar.vue";
import { mapState, mapActions } from "vuex";
import BaseInput from "@/components/base/BaseInput.vue";

export const headerMixin = {
  components: { BaseInput, BaseCalendar },
  data() {
    return {
      calendarVisibility: false,
    };
  },
  computed: {
    ...mapState({
      selectedDates: (state) => state.calendar.selectedDates,
    }),
  },
  methods: {
    ...mapActions({
      getEvents: "getEvents",
      changeSelectedDates: "changeSelectedDates",
    }),
    saveDatesChange() {
      this.calendarVisibility = false;
    },
  },
};
