<template lang="pug">
  .flex.gap-x-3.w-full.py-6
    q-btn(
      color="secondary",
      text-color="primary",
      style="width: 40px; height: 40px",
      rounded,
      no-caps,
      padding="0 16px"
    )
      q-icon(v-if="image === defaultIcon", name="app:icon-download")
      q-avatar(v-else, size="40px", round)
        img(:src="image")
      q-menu(anchor="bottom middle", self="top right", v-model="showPopup")
        .flex.flex-col.p-4.gap-y-3
          .flex.items-center.gap-x-2.cursor-pointer(@click="changeOpenModal")
            q-icon(name="app:computer", size="18px", color="primary")
            span.text-smm.title Загрузить с компьютера
          .flex.items-center.gap-x-2.cursor-pointer
            q-icon(name="app:camera", size="18px", color="primary")
            span.text-smm.title Сделать фото
      base-modal(v-model="showModal", title="Загрузить изображение")
        .flex.flex-col.items-center.justify-center(
          :style="{padding: '153px 370px'}"
        )
          .avatar-wrapper.flex.relative
            base-input(
              circle,
              type="file",
              id="image-upload",
              accept="image/*",
              @change="(e) => previewImage(e)",
            )
            .avatar.flex.absolute.items-center.gap-x-6
              img.avatar.object-cover(for="image-upload", :src="image", v-if="image")
              base-button(
                width="48px",
                @click="closeAddImage",
                size="L",
                round,
                padding="2px 0 0 0"
              )
                q-icon(name="app:ok", size="20px")
    base-input.w-full(v-model="value.full_name", placeholder="ФИО пациента*", size="M")
</template>

<script>
import BaseModal from "./BaseModal.vue";
import addImageIcon from "@/assets/icons/photo.svg";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import { v_model } from "@/shared/mixins/v-model";

export default {
  name: "BaseInputFullName",
  components: { BaseModal, BaseInput, BaseButton },
  mixins: [v_model],
  data() {
    return {
      defaultIcon: addImageIcon,
      showModal: false,
      showPopup: false,
      image: addImageIcon,
    };
  },
  methods: {
    closeAddImage() {
      this.showModal = false;
      this.showPopup = false;
    },
    changeOpenModal() {
      this.showModal = true;
      this.showPopup = false;
    },
    previewImage(event) {
      let picture = event.target.files;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(picture[0]);
    },
  },
  watch: {
    showModal: function () {
      if (this.showModal === false) {
        this.closeAddImage();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.avatar-wrapper
  width: 400px
  height: 400px
  border-radius: 50%

.avatar
  height: 100%
  border-radius: 50%
</style>
