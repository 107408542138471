<template lang="pug">
  the-schedule
</template>

<script>
import TheSchedule from "@/pages/schedule/TheSchedule.vue";
export default {
  name: "TheSettings",
  components: { TheSchedule },
};
</script>
