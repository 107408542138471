<template lang="pug">
  .table.flex.flex-col.w-full.box-border(
    :class="{'rounded-t': config.name === 'upper', 'rounded-b': config.name === 'lower','border-botton-none': config.name === 'upper',}",
    )
    .row.flex.w-full(
      v-if="!viewType"
      v-for="row in config.rowMap",
      :key="`${config.name}_${row.key}`",
      :style="{'height': heightRow[row.key]}"
      )
      .cell.flex.justify-start.items-center.pl-4.h-full(:style="{'width': '20%', 'minWidth': '146px'}")
        span.font-medium.text-smm.whitespace-nowrap(:style="{'color': 'var(--font-grey-color)'}") {{ row.label }}
      tooth-formula-cell(
        v-for="cell in config.columns",
        v-model="data.formula[cell.tooth_number]",
        :filtred-tooths="filtredTooths"
        :is-edit="isEdit",
        :jaw-position="config.name",
        :row-key="row.key",
        :config="cell",
        :form-config="formConfig"
        )
    .flex(v-else)
      .cell.flex(
        v-for="cell in config.columns",
        :style="{'width': '6.25%', 'minWidth': '55px'}",
        )
        .flex.flex-col.w-full.h-full.gap-y-7px.py-11px.items-center.justify-around(
          v-if="filtredTooths.includes(cell.tooth_number)",
          :class="{'flex-col-reverse': config.name === 'upper'}"
          )
          span.text-smm.font-medium {{cell.tooth_number}}
          .flex.items-center.justify-center(
            :style="{'width': '35px', 'height': '35px'}"
          )
            detailed-tooth-svg(
              part-tooth="crown",
              :tooth-config="cell",
              :data="getToothImageConfig(data.formula[cell.tooth_number].dental_condition)"
              :view="data.formula[cell.tooth_number].dental_condition.general === 'И' ? 'artificial': 'native'",
              )
          detailed-tooth-svg(
            part-tooth="general",
            :view="data.formula[cell.tooth_number].dental_condition.general === 'И' ? 'artificial': 'native'",
            :tooth-config="cell",
            :jaw-position="config.name",
            :data="getToothImageConfig(data.formula[cell.tooth_number].dental_condition)"
            )
</template>

<script>
import ToothFormulaCell from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/ToothFormulaCell.vue";
import DetailedToothSvg from "@/pages/newMedicalCard/components/InitialInspectionProtocol/Forms/ToothFormula/DetailedToothSvg.vue";
import {
  getFilledConditions,
  getToothImageConfig,
} from "@/pages/newMedicalCard/utils/gettersObjects";

export default {
  name: "ToothFormulaTable",
  components: { ToothFormulaCell, DetailedToothSvg },
  data() {
    return {
      getFilledConditions,
      getToothImageConfig,
      heightRow: {
        tooth_mobility: "36px",
        dental_condition: "64px",
        tooth_number: "64px",
      },
    };
  },
  props: {
    config: Object,
    data: Object,
    modelValue: Object,
    isEdit: Boolean,
    formConfig: Object,
    filtredTooths: Array,
    viewType: Boolean,
  },
  emits: ["update:modelValue"],
};
</script>

<style lang="sass" scoped>
.table
  border: 1px solid var(--border-light-grey-color)
  &.border-botton-none
    border-bottom-width: 0
.row
  border-bottom: 1px solid var(--border-light-grey-color)
  &:last-child
    border-bottom-width: 0
.cell
  border-right: 1px solid var(--border-light-grey-color)
  &:last-child
    border-right-width: 0
</style>
