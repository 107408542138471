<template lang="pug">
  .flex.flex-col.gap-y-6px
    .label.font-semibold.text-sm(v-if="label") {{ label }}
      span.-mt-2(v-if="important", :style="{color: 'var(--font-obligatory-color)'}") *
    slot
</template>

<script>
export default {
  name: "BaseInputContainer",
  props: {
    label: String,
    important: Boolean,
  },
};
</script>

<style lang="sass" scoped>
.label
  color: var(--font-grey-color)
  line-height: 135%
</style>
