<template lang="pug">
  medical-form-wrapper(
      :title="data.title",
      :is-edit="change",
      :open-edit="openCard",
      :cancel="closeCard",
      :tag="fillInspection || change",
      template
    )
      .protocol-body.flex.flex-col
        .flex.gap-x-4(:style="{height: change || fillInspection ? '300px' : ''}")
          .flex.flex-col.gap-y-1(:class="{'edit-form': change || fillInspection}")
            .tag-wrap.flex.gap-x-1.gap-y-1
              .tag.flex.items-center.px-3.text-smm(
                v-if="protocolData[data.state]",
                v-for="item in findData(data.results.diseases)",
                :key="item.id"
                :class="{'pointer': change || fillInspection}"
              ) {{item.label + " - " + item.value}}
          .filter.flex.flex-col.gap-y-4.relative(v-if="change || fillInspection")
            .flex.gap-x-2
              base-input.search(
                placeholder="Поиск",
                :width="452",
                iconLeft,
                size="M",
                icon-left
              )
                template(v-slot:iconLeft)
                  q-icon.icon-grey(name="app:search", size="20px" )
              .button
                q-btn(
                  :style="{width: '40px', height: '40px'}",
                  padding="0"
                )
                  q-icon.icon-grey(name="app:filter", size="24px")
              .button
                q-btn(
                  :style="{width: '40px', height: '40px'}",
                  padding="0"
                )
                  q-icon.icon-grey(name="app:sort-number", size="24px")
            .field.flex.flex-col.overflow-y-scroll.gap-y-1(v-if="data.results.diseases")
              .letter.flex.items-center.font-bold.text-base Некоторые инфекционные и паразитарные болезни
              .checkbox.flex.gap-x-2.items-center(v-for="disease in data.results.diseases")
                q-checkbox(dense, v-model="protocolData[data.state]", :val="disease.label")
                .name.font-semibold.text-smm {{ disease.label }}
                .name.font-medium.text-smm {{ disease.value }}
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import MedicalFormWrapper from "@/pages/newMedicalCard/components/MedicalFormWrapper.vue";
import { mapState } from "vuex";
import BaseInput from "@/components/base/BaseInput.vue";

export default {
  name: "ClinicalForm",
  components: { BaseButton, BaseInput, MedicalFormWrapper },
  props: { data: Object, fillInspection: Boolean },
  data() {
    return {
      change: false,
    };
  },
  computed: {
    ...mapState({
      protocolData: (state) => state.medical.protocolData,
    }),
  },
  methods: {
    findData(data) {
      let arr = [];
      if (!data) return;
      this.protocolData[this.data.state].forEach((e) => {
        data.forEach((el) => {
          if (e === el.label) {
            arr.push(el);
          }
        });
      });
      return arr;
    },
    openCard() {
      this.change = true;
    },
    closeCard() {
      this.change = false;
    },
  },
  watch: {
    fillInspection: {
      immediate: true,
      handler(newVal) {
        if (newVal) this.change = false;
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.protocol-body
  background: var(--default-white)
  border-radius: 4px

.icon
  background: var(--bg-light-grey)

.open-header
  justify-content: space-between

.edit-form
  width: 580px
  padding: 4px
  border: 1px solid var(--border-light-grey-color)
  border-radius: 4px

.tag
  height: 32px
  background: var(--bg-light-grey)
  border-radius: 4px

.tag-wrap
  flex-wrap: wrap !important

.filter
  border: 1px solid var(--border-light-grey-color)
  border-radius: 4px
  padding: 16px 16px 0
  width: 580px

.button
  display: flex
  align-items: center
  width: 40px
  height: 40px
  justify-content: center
  cursor: pointer
  border-radius: 4px
  background: var(--bg-light-grey)

.field
  margin-right: -10px
  &::-webkit-scrollbar-track:vertical
    margin-bottom: 12px
  &::-webkit-scrollbar
    width: 4px

.checkbox
  border-bottom: 1px solid var(--bg-light-grey)
  min-height: 36px

.letter
  color: var(--font-grey-color)
  height: 34px

.name
  width: fit-content
  color: var(--font-dark-blue-color)

.pointer
  cursor: pointer

.q-checkbox :deep(.q-checkbox__bg)
  border-radius: 4px
  border: 1.5px solid var(--font-grey-color)

.q-checkbox :deep(.q-checkbox__inner:before)
  background: none !important

.search :deep(.q-field__prepend)
  padding-right: 6px

.icon-grey :deep(path)
  fill: var(--font-grey-color)
</style>
