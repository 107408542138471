<template lang="pug">
  .card-wrapper.flex.gap-y-2px.flex-col.absolute
    .header.w-full.flex.gap-x-2px.text-sm
      .first-item.color-black.justify-center.gap-x-2(:class="timeClass")
        span.font-semibold.color-black(v-if="expandedType || expandedTime") {{ recordTime }}
        q-icon.cursor-pointer.arrow-icon(
          name="app:left-arrow",
          size="16px",
          v-if="expandedTime",
          @click.stop="changeTimeDisplay(false)"
        )
        q-icon.cursor-pointer.time-icon(
          v-if="!expandedType && !expandedTime",
          name="app:time",
          size="20px",
          @click.stop="changeTimeDisplay(true)"
        )
      .color-black.pl-4.flex-1.font-semibold.py-6px.relative.cursor-default(
        :class="{'pl-6px collapsed-name': expandedTime}"
      ) {{trimPatientName(record?.person?.last_name, record?.person?.first_name, record?.person?.patronymic)}}
        .gradient.absolute.w-5.h-full.right-0(v-if="expandedTime")
      .info-block.justify-center(v-if="expandedType")
        q-icon(
          :name="record?.person?.has_medical_card ? 'app:medcard-green' : 'app:medcard'",
          size="20px",
        )
      .last-item.info-block.justify-center
        .recording-status(:class="recordingStatus?.class")
    .body.background-grey.flex.flex-col.justify-between.pl-4.pr-2.color-grey(
      :style="bodyHeight",
      :class="bodyPadding"
    )
      .flex.gap-x-14(:class="bodyClass")
        .flex.gap-x-2.items-center(
          v-for="contact in contacts",
          :key="contact?.value",
        )
          q-icon.network-icon(:name="contact?.icon", size="16px")
          .text-xxs.cursor-default {{ contact?.value }}
          .h-6.w-6.color-black.background-dark-grey.text-xxs.rounded.flex.items-center.justify-center.cursor-default(
            v-if="collapsedDisplayCondition && contact"
          ) +1
</template>

<script>
import * as moment from "moment/moment";
import {
  statuses,
  networks,
} from "@/pages/newCalendar/utils/calendarConfig.js";
import { trimName } from "@/pages/newCalendar/utils/calendarFunctions.js";
export default {
  name: "CalendarRecordCard",
  props: {
    expandedType: Boolean,
    record: Object,
  },
  data() {
    return {
      pixelsPerHalfHour: 38,
      trimPatientName: trimName,
      expandedTime: false,
    };
  },
  computed: {
    contacts() {
      let contactList = [];
      this.record?.person?.contacts?.find((elem) => {
        if (elem.category === "PHONE") {
          contactList.push({
            kind: elem?.category,
            icon: networks?.find((item) => item.id === elem.category)?.icon,
            value: elem?.value,
          });
          return true;
        }
      });
      this.record?.person?.contacts?.find((elem) => {
        if (elem.category === "EMAIL") {
          contactList.push({
            kind: elem?.category,
            icon: networks?.find((item) => item.id === elem.category)?.icon,
            value: elem?.value,
          });
          return true;
        }
      });
      if (this.collapsedDisplayCondition) {
        contactList.length = 1;
        return contactList;
      }
      return contactList;
    },
    collapsedDisplayCondition() {
      return !this.bodyClass?.["flex-col"] && !this.expandedType;
    },
    recordingStatus() {
      const status = statuses?.[0]?.data?.find(
        (elem) => elem.value === this.record?.status
      );
      return status || statuses?.[0]?.data?.[0];
    },
    startTime() {
      return moment.parseZone(this.record?.start);
    },
    endTime() {
      return moment.parseZone(this.record?.end);
    },
    recordTime() {
      return (
        this.startTime?.format("HH:mm") + " – " + this.endTime?.format("HH:mm")
      );
    },
    recordDuration() {
      if (this.endTime.isAfter(this.startTime))
        return this.endTime.diff(this.startTime, "minutes");
      return null;
    },
    bodyHeight() {
      let halfHourCount = parseInt(this.recordDuration / 30, 10) - 1;
      if (halfHourCount)
        return {
          height: `${this.pixelsPerHalfHour * halfHourCount}px`,
        };
      return {
        display: "none",
      };
    },
    bodyClass() {
      if (parseInt(this.bodyHeight?.height, 10) >= 76)
        return {
          "flex-col": true,
          "gap-y-2": true,
        };
      return {};
    },
    timeClass() {
      if (this.expandedType)
        return {
          expanded: true,
        };
      if (this.expandedTime)
        return {
          "expanded-time": true,
        };
      return {
        collapsed: true,
      };
    },
    bodyPadding() {
      return this.collapsedDisplayCondition
        ? {
            "py-6px": true,
          }
        : {
            "py-10px": true,
          };
    },
  },
  methods: {
    changeTimeDisplay(value) {
      this.expandedTime = value;
    },
  },
  watch: {
    expandedType(value) {
      if (value) this.expandedTime = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.card-wrapper
  width: calc(100% - 8px)
.header
  height: 30px
  & > div
    background-color: var(--bg-light-grey)
    display: flex
    align-items: center
.first-item
  border-top-left-radius: 4px
  border-bottom-left-radius: 4px
.expanded
  width: 130px
.collapsed
  width: 30px
.collapsed-name
  overflow: hidden
  white-space: nowrap
.gradient
  background: linear-gradient(270deg, #EFF1F4 0%, rgba(239, 241, 244, 0.00) 100%)
.expanded-time
  width: 138px
.last-item
  border-top-right-radius: 4px
  border-bottom-right-radius: 4px
.background-grey
  background-color: var(--bg-light-grey)
.background-dark-grey
  background-color: var(--border-light-grey-color)
.color-black
  color: var(--font-dark-blue-color)
.color-grey
  color: var(--font-dark-blue-color-0)
.info-block
  width: 30px
  height: 30px
.body
  border-bottom-left-radius: 4px
  border-bottom-right-radius: 4px
.network-icon :deep(path)
  fill: var(--font-dark-blue-color-0)
.arrow-icon :deep(path)
  fill: var(--font-dark-blue-color)
.time-icon :deep(path)
  fill: var(--font-grey-color)
.recording-status
  border-radius: 9999px
  width: 17px
  height: 17px
.not-accepted
  border: 1.2px solid var(--font-grey-color)
.accepted
  background-color: var(--system-color-green)
.rejected
  background-color: var(--system-color-red)
.reception
  background-color: var(--btn-blue-color)
span
  cursor: default
</style>
