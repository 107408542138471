import { fetchWrapper } from "../fetchWrapper";
import { checkChangeData } from "./changesObjects";

export function getRequestArrayData(
  initData,
  updateData,
  updateName,
  idUpdateName,
  api,
  key
) {
  const deleteRequests = initData
    .filter((el) => !updateData.find((data) => data.id === el.id))
    .map((el) => fetchWrapper.del(`${api}/${key}/${el.id}/delete/`));
  const requests = updateData.map((el) => {
    const requestObj = {
      [updateName]: idUpdateName,
      ...el,
    };
    delete requestObj.id;
    if (!el?.id) {
      return fetchWrapper.post(`${api}/${key}/create/`, { ...requestObj });
    }
    if (
      el?.id &&
      checkChangeData(
        initData.find((obj) => obj.id === el.id),
        el
      )
    ) {
      return fetchWrapper.post(`${api}/${key}/${el.id}/update/`, {
        ...requestObj,
      });
    }
    return Promise.resolve();
  });
  return requests.concat(deleteRequests);
}

export function getRequestNote(note, initNote, personId) {
  if (note.id) {
    const initChangeNote = initNote?.value || {
      id: initNote.id,
      value: initNote.description,
    };
    if (note.value && note.value !== initChangeNote.value) {
      return fetchWrapper.post(`general/note/${note.id}/update/`, {
        title: note.value,
        description: note.value,
      });
    }
    if (!note.value) {
      return fetchWrapper.del(`general/note/${note.id}/delete/`);
    }
  }
  if (!note.id && note.value) {
    return fetchWrapper.post(`general/note/create/`, {
      title: note.value,
      description: note.value,
      person: personId,
    });
  }
  return Promise.resolve();
}

export function getRequestConfidant(confidant, initConfidant, initPerson, id) {
  const medicalId = id;
  const fullName = `${confidant.last_name.value} ${confidant.first_name.value} ${confidant.patronymic.value}`;
  let contacts = [];
  const phone = confidant.phone.value
    ? { username: confidant.phone.value, kind: "PHONE" }
    : null;
  const email = confidant.email.value
    ? { username: confidant.email.value, kind: "EMAIL" }
    : null;
  if (confidant.phone.id) phone.id = confidant.phone.id;
  if (confidant.email.id) email.id = confidant.email.id;
  if (phone) contacts.push(phone);
  if (email) contacts.push(email);
  contacts = contacts.concat(confidant.networks);
  if (!confidant.id) {
    return fetchWrapper
      .post(`general/person/create/`, { full_name: fullName })
      .then((res) => {
        return getRequestNote(confidant.note, null, res.id)
          .then(() =>
            Promise.allSettled(
              getRequestArrayData(
                [],
                contacts,
                "person",
                res.id,
                "general",
                "contact"
              )
            )
          )
          .then(() =>
            fetchWrapper.post(
              `medical_card/medical_history/${medicalId}/update/`,
              {
                confidant: [
                  {
                    person: {
                      id: res.id,
                    },
                  },
                ],
              }
            )
          );
      });
  } else {
    if (initPerson) {
      if (initConfidant && initPerson.id !== initConfidant.id) {
        fetchWrapper.del(
          `medical_card/medical_history/${medicalId}/delete_confidant/`,
          {
            confidants: [
              {
                person: {
                  id: initConfidant.id,
                },
              },
            ],
          }
        );
      }
      return fetchWrapper
        .post(`general/person/${confidant.id}/update/`, {
          full_name: fullName,
        })
        .then(() =>
          getRequestNote(confidant.note, initPerson.note, confidant.id)
        )
        .then(() => {
          if (contacts.length) {
            return Promise.allSettled(
              getRequestArrayData(
                initPerson.contacts,
                contacts,
                "person",
                confidant.id,
                "general",
                "contact"
              )
            ).then(() =>
              fetchWrapper.post(
                `medical_card/medical_history/${medicalId}/update/`,
                {
                  confidant: [
                    {
                      person: {
                        id: confidant.id,
                      },
                    },
                  ],
                }
              )
            );
          }
        });
    }
    if (initConfidant && !initPerson) {
      return fetchWrapper
        .post(`general/person/${confidant.id}/update/`, {
          full_name: fullName,
        })
        .then(() =>
          getRequestNote(confidant.note, initConfidant.note, confidant.id)
        )
        .then(() => {
          if (contacts.length) {
            return Promise.allSettled(
              getRequestArrayData(
                initConfidant.networks,
                contacts,
                "person",
                confidant.id,
                "general",
                "contact"
              )
            ).then(() =>
              fetchWrapper.post(
                `medical_card/medical_history/${medicalId}/update/`,
                {
                  confidant: [
                    {
                      person: {
                        id: confidant.id,
                      },
                    },
                  ],
                }
              )
            );
          }
        });
    }
  }
}
