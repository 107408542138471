<template lang="pug">
  svg(width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg")
    g(v-if="nameSvg === 'expanded'")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4C2.25 3.0335 3.0335 2.25 4 2.25H9C9.9665 2.25 10.75 3.0335 10.75 4V20C10.75 20.9665 9.9665 21.75 9 21.75H4C3.0335 21.75 2.25 20.9665 2.25 20V4ZM4 3.75C3.86193 3.75 3.75 3.86193 3.75 4V20C3.75 20.1381 3.86193 20.25 4 20.25H9C9.13807 20.25 9.25 20.1381 9.25 20V4C9.25 3.86193 9.13807 3.75 9 3.75H4Z" :fill="active ? '#fff' : '#9294A7'")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M13.25 4C13.25 3.0335 14.0335 2.25 15 2.25H20C20.9665 2.25 21.75 3.0335 21.75 4V20C21.75 20.9665 20.9665 21.75 20 21.75H15C14.0335 21.75 13.25 20.9665 13.25 20V4ZM15 3.75C14.8619 3.75 14.75 3.86193 14.75 4V20C14.75 20.1381 14.8619 20.25 15 20.25H20C20.1381 20.25 20.25 20.1381 20.25 20V4C20.25 3.86193 20.1381 3.75 20 3.75H15Z" :fill="active ? '#fff' : '#9294A7'")
    g(v-if="nameSvg === 'compressed'")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4C2.25 3.0335 3.0335 2.25 4 2.25H6C6.9665 2.25 7.75 3.0335 7.75 4V20C7.75 20.9665 6.9665 21.75 6 21.75H4C3.0335 21.75 2.25 20.9665 2.25 20V4ZM4 3.75C3.86193 3.75 3.75 3.86193 3.75 4V20C3.75 20.1381 3.86193 20.25 4 20.25H6C6.13807 20.25 6.25 20.1381 6.25 20V4C6.25 3.86193 6.13807 3.75 6 3.75H4Z" :fill="active ? '#fff' : '#9294A7'")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M9.25 4C9.25 3.0335 10.0335 2.25 11 2.25H13C13.9665 2.25 14.75 3.0335 14.75 4V20C14.75 20.9665 13.9665 21.75 13 21.75H11C10.0335 21.75 9.25 20.9665 9.25 20V4ZM11 3.75C10.8619 3.75 10.75 3.86193 10.75 4V20C10.75 20.1381 10.8619 20.25 11 20.25H13C13.1381 20.25 13.25 20.1381 13.25 20V4C13.25 3.86193 13.1381 3.75 13 3.75H11Z" :fill="active ? '#fff' : '#9294A7'")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M16.25 4C16.25 3.0335 17.0335 2.25 18 2.25H20C20.9665 2.25 21.75 3.0335 21.75 4V20C21.75 20.9665 20.9665 21.75 20 21.75H18C17.0335 21.75 16.25 20.9665 16.25 20V4ZM18 3.75C17.8619 3.75 17.75 3.86193 17.75 4V20C17.75 20.1381 17.8619 20.25 18 20.25H20C20.1381 20.25 20.25 20.1381 20.25 20V4C20.25 3.86193 20.1381 3.75 20 3.75H18Z" :fill="active ? '#fff' : '#9294A7'")
</template>

<script>
export default {
  name: "CalendarDateSwitcherSvg",
  props: {
    nameSvg: { type: String, default: "expanded" },
    active: Boolean,
  },
};
</script>
