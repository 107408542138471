<template lang="pug">
  .wrapper-info
    .grid.grid-cols-2.gap-x-4.gap-y-6
      base-input-date(
        v-model="value.birth_date",
        size="M",
        important,
        label="Дата рождения",
        placeholder="Дата рождения"
      )
      base-input(
        v-model="value.phone",
        placeholder="+7 (915) 644–92–23",
        mask="+7 (###) ###-##-##",
        label="Номер телефона",
        size="M",
        important
      )
</template>

<script>
import BaseAddingNetwork from "@/components/base/BaseAddingNetwork";
import BaseSelect from "@/components/base/BaseSelect";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseInputDate from "@/components/base/BaseInputDate.vue";
import { v_model } from "@/shared/mixins/v-model";

export default {
  name: "FormCreateBasicInfo",
  components: {
    BaseInput,
    BaseAddingNetwork,
    BaseSelect,
    BaseInputDate,
  },
  mixins: [v_model],
};
</script>

<style lang="sass" scoped>
.wrapper-info
  overflow-y: auto
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar-track
    background-color: rgba(211, 212, 220, 0.5)
    border-radius: 8px
  &::-webkit-scrollbar-thumb
    border-radius: 8px
    background-color: var(--btn-blue-color)
.obligatory
  color: var(--font-obligatory-color)
.add-network
  color: var(--btn-blue-color)
.icon
  color: var(--border-light-grey-color)
  width: 48px
  height: 100%
  border: 2px solid var(--border-light-grey-color)
  border-radius: 4px
</style>
