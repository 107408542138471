<template lang="pug">
  .w-full.row-wrapper.h-14.flex    
    .field(:style="headerStyle(headerConfig[0])")
      template(v-if="person.medical_card_id")
        base-button(size="S", @click="openMedicalCard", label="Открыть", color="green")
      template(v-else)      
        base-button(size="S", @click="createMedCard", label="Создать")
    .field.gap-x-3(:style="headerStyle(headerConfig[1])")
      span.font-semibold.text-dark {{patientName}}
    .field(:style="headerStyle(headerConfig[2])")
      span.text-dark {{ birthDate }}        
    .field(:style="{...headerStyle(headerConfig[3])}")
      span.text-dark {{ phones }}  
</template>

<script>
import { searchListConfig } from "@/pages/medcards/utils/medcardsConfig.js";
import * as moment from "moment/moment";
import TheNotificationProvider from "@/components/Notifications/TheNotificationProvider";

import { mapActions } from "vuex";
import BaseButton from "@/components/base/BaseButton.vue";
export default {
  name: "MedicalCardSearchRow",
  components: { TheNotificationProvider, BaseButton },
  props: {
    headerStyle: Function,
    person: Object,
  },
  data() {
    return {
      headerConfig: searchListConfig,
    };
  },
  computed: {
    patientName() {
      return `${this.person?.last_name} ${this.person?.first_name} ${this.person?.patronymic}`;
    },
    birthDate() {
      let retVal =
        this.person?.birth_date &&
        moment(this.person?.birth_date)?.format("DD MMMM YYYY");
      return retVal ? retVal + " г." : "";
    },
    phones() {
      return this.person?.contacts
        ?.filter((el) => el.category === "PHONE")
        .map((el) => el.value)
        .join(", ");
    },
  },
  methods: {
    ...mapActions({
      getMedicalCardData: "getMedicalCardDataByPersonId",
      createMedicalCard: "createMedicalCard",
    }),
    openMedicalCard() {
      this.$router.push("medical-card/" + this.person["medical_card_id"]);
    },
    createMedCard() {
      this.createMedicalCard({
        personId: this.person.id,
      }).then(() => {
        this.openMedicalCard();
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.row-wrapper
  border-bottom: 1px solid var(--gray-secondary)
  & > .field
    height: 100%
    padding: 10px 16px
    display: flex
    align-items: center
    justify-content: start
    font-size: 14px
    line-height: 135%

.medcard-number
  border: 1px solid var(--gray-secondary)
  background: var(--gray-thirdly)
  &:hover
    background: var(--gray-secondary)

.copy :deep(path)
  fill: var(--font-grey-color)

.medcard :deep(path)
  fill: var(--btn-blue-color)

span
  cursor: default

.track
  background: var(--gray-thirdly)

.grey-color
  color: var(--font-grey-color)
</style>
