import { createStore } from "vuex";
import medical from "./modules/medicalCard";
import calendar from "./modules/calendar";

export default createStore({
  state: {
    url: "https://astra-dev.dopcore.com",
    imgUrl: "https://astra-dev.dopcore.com/api/store/",
    routingHistory: window.history,
    userData: {},
    calendarShape: "manager",
  },
  modules: {
    medical,
    calendar,
  },
  getters: {
    getUrl(state) {
      return state.url;
    },
    getUserData(state) {
      let data = {};
      Object.keys(state.userData).forEach((key) => {
        if (key !== "photo") data[key] = state.userData[key];
        else
          data[key] = state.userData[key]
            ? state.url + state.userData[key]
            : "";
      });
      data.job_title = "Ортодонт";
      return data;
    },
  },

  actions: {
    setUserData({ commit }, props) {
      commit("setUserData", props);
    },
  },

  mutations: {
    setUserData(state, props) {
      state.userData = { ...props };
    },
  },
});
